/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import { ProfileHeaderSettings } from '../profileHeaderSettings';
import { BodySettingsProfile } from './components/bodySettingsProfile';
import { BodyPermissionsUser } from './components/bodyPermissionsUser';
//import { MyPortal } from './components/my-portal'

const settingsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingsPage: FC = () => {

  return (
    <div className='column g-5 g-xl-8'>
        <ProfileHeaderSettings />
        <BodySettingsProfile />
        <BodyPermissionsUser />

    </div>
  )
}
const SettingsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={settingsBreadcrumbs}>Configurações de Perfil</PageTitle>
      <SettingsPage />
    </>
  )
}
export {SettingsWrapper}

