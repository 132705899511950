/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import { ProfileHeaderSettings } from '../profileHeaderSettings';
import { BodySettingsCompany } from './components/bodySettingsCompany';
//import { MyPortal } from './components/my-portal'

const settingsCompanyBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingsCompanyPage: FC = () => {

  return (
    <div className='column g-5 g-xl-8'>
        <ProfileHeaderSettings />
        <BodySettingsCompany />
    </div>
  )
}

const SettingsCompanyWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={settingsCompanyBreadcrumbs}>Configurações de Empresa</PageTitle>
      <SettingsCompanyPage />
    </>
  )
}

export {SettingsCompanyWrapper}
