/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../modules/auth';
import { getReportsAll, getReportsAllPagination } from '../../../services/reports';
import { Loading } from '../../components/Loadings/loading';
import { SearchInput } from '../../components/SearchInput';
import { ModalDetailsReports } from '../components/ModalDetailsReport';
import { ModalViewReport } from '../components/ModalViewReports';
import DateSelector from './components/DateInput';

//import { ModalAddUpdateUser } from '../components/ModalAddEditUser';
//import { ModalDeleteUser } from '../components/ModalDeleteUser';
// import { ModalCreateCredential } from '../../../portals/components/modalCreateCredential';
// import { ModalAddUpdateClient } from '../ModalAddEditClient';
// import { ModalDeleteClient } from '../ModalDeleteClient';
// import { ModalDetailsClient } from '../ModalDetailsClient';
import { TableBody } from './TableBody';
import { useToast } from '../../components/Toats';
import { ModalResumoReport } from '../components/ModalResumoReport';


type Props = {
  className: string;
}


const TableReports: React.FC<Props> = ({className}) => {
  const { toggleToast } = useToast();
  const [mySearch, setMySearch] = useState([]);
  const user = useAuth()
  const [control, setControl] = useState(false);
  //const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  //const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [showCreateAppModalView, setShowCreateAppModalView] = useState<boolean>(false)
  const [myReport, setMyReport] = useState<any | null>(null);

  const [reports, setReports] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<string>(user.currentUser?.role.slug !== "CLIENTE" ? "INCOMPLETO" : "GERADO");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [largura, setLargura] = useState(window.innerWidth);

  const [pagination, setPagination] = useState<any>({
    page: 1,
    limit: 100,
    total: 0,
    qtdPages: 1
  });

  const [qtd, setQtd] = useState<any>({
    start: 0,
    end: 100,
  });
    
  const getReports = async () => {
    try {
      setLoading(true)

      const reports = await getReportsAllPagination(filter, selectedDate, "", pagination.limit, pagination.page)
      setPagination({...pagination, qtdPages: reports.lastPage, total: reports.total})
      setReports(reports.data)


    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar relatórios. ", "error")
    }finally{
      setLoading(false)
    }
  }
    
  const refresh = () => {
    getReports()
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    getReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, selectedDate, pagination.page]);

  useEffect(() => {
    const start = (pagination.page - 1) * pagination.limit + 1;
    const end = Math.min(pagination.page * pagination.limit, pagination.total);

    setQtd({start: start, end: end === 0 ? pagination.limit : end})
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page]);

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedDate]);


  useEffect(() => {
    getReports();

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  
  
  // if(loading){
  //   return(
  //     <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
  //       <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
  //     </div>
  //   )
  // }
  
  return (
    <div className={`card ${className}`} style={{height: largura > 1200 ? '72vh' : '68vh', paddingBottom: '1rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0' style={{paddingTop: '2rem', display: 'flex' , flexDirection: 'row'}}>
        {/* <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Lista de Relatórios</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>50 Relatório(s)</span>
        </h3>  */}
        <div 
          style={{
            display: 'flex', 
            gap: '1rem', 
            flexDirection: largura < 1200 ? 'column' : 'row', 
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}>

          <div style={{display: 'flex', flexDirection: largura < 1200 ? 'column' : 'row', gap: '1.5rem', width: '100%'}}>
            <div style={{width: largura < 1200 ? '100%' : '15rem'}}>
              <DateSelector 
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>

            {largura > 1200 ? (
              <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                { user.currentUser?.role.slug !== "CLIENTE" &&
                  <a
                    className={`btn btn-sm btn-color-muted btn-active btn-active-warning active px-5 me-1 nav-item`}
                    data-bs-toggle="tab"
                    href="#"
                    onClick={()=> {
                      setFilter("INCOMPLETO")
                    }}
                  >
                    INCOMPLETO 
                    <span 
                      className='badge badge-dark'
                      style={{marginLeft: '10px'}}
                    >
                      {/* {alarmStatus.total} */}
                    </span>
                  </a>
                }
                <a
                  className={`btn btn-sm btn-color-muted btn-active btn-active-success ${user.currentUser?.role.slug === "CLIENTE" ? 'active' : ''} px-4 me-1 nav-item`}
                  data-bs-toggle="tab"
                  href="#"
                  onClick={()=> {
                    setFilter("GERADO")
                  }}
                >
                  GERADO
                  <span 
                    className='badge badge-success'
                    style={{marginLeft: '10px'}}
                  >
                    {/* {alarmStatus.normalNum} */}
                  </span>
                </a>
              </div>
            )
            :
            (
              <div className='me-0 dropdown' style={{width: '100%'}}>
                <button
                  //style={{color: 'white'}}
                  //disabled={loadingAvaiable || loading || report ? !report.template ? true : false : true}
                  className='btn btn-lg btn-secondary'
                  style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                  type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                >
                  <span>Buscar pelo Status </span>
                  <i className='bi bi-three-dots fs-3'></i>
                </button>

                <div className='dropdown-menu w-150px w-md-150px dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem'}}>
                  <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                    
                    <a
                      style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}
                      className='btn btn-sm btn-color-muted btn-active btn-active-warning active px-4 me-1 nav-item'
                      
                      data-bs-toggle="tab"
                      href="#"
                      onClick={() => {
                        setFilter("INCOMPLETO")
                      }}

                    >
                      <span 
                        className='badge badge-warning'
                        style={{
                          marginLeft: '10px', 
                          padding: '0.5rem', 
                          width: '10rem',
                          display: 'flex',
                          alignItems: 'center', 
                          justifyContent: 'center'
                        }}
                      >
                        INCOMPLETOS
                      </span>
                    </a>
                    <a
                      style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}
                      className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 nav-item'
                      
                      data-bs-toggle="tab"
                      href="#"
                      onClick={() => {
                        setFilter("GERADO")
                      }}
                      
                    >
                      <span 
                        className='badge badge-success'
                        style={{
                          marginLeft: '10px', 
                          padding: '0.5rem', 
                          width: '10rem',
                          display: 'flex',
                          alignItems: 'center', 
                          justifyContent: 'center'
                        }}
                      >
                        GERADOS
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
          
          <SearchInput 
            className='form-control form-control-solid bg-body ps-14'
            placeholder='Buscar Relatórios'
            myDate={reports}
            setMySearch={setMySearch}
            control={control}
            style={{width: largura < 1200 ? "100%" : "300px"}}
            setControl={setControl}
            searchParameter="plant.name"
          />

        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3' style={largura < 1200 ? {height: '20rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
        {/* begin::Table container */}
        <div className='table-responsive'>
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='text-muted text-start fw-bolder fs-7 text-uppercase gs-0'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-300px'>Usina</th>
                <th className='min-w-150px'>Mês</th>
                <th className='min-w-150px'>Status</th>
                <th className='min-w-20px'>Faturas</th>

                {user.currentUser?.role.slug !== "CLIENTE" &&
                  <th className='min-w-40px text-end'>Actions</th>
                }
              </tr>
            </thead>
              {
                loading ? 
                  <tbody>
                    <tr>
                      <td colSpan={5} style={{textAlign: 'center', padding: '2rem'}}>
                        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
                      </td>
                    </tr>
                  </tbody>
                : 
                  control === false ? (
                    <TableBody 
                      reports={reports} 
                      setMyReport={setMyReport}
                      myReport={myReport}
                      refresh={refresh}
                      //setShowCreateAppModal={setShowCreateAppModal}
                      //setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                      setShowCreateAppModalView={setShowCreateAppModalView}
                      setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                    />
                  )
                  : (
                    <TableBody 
                      reports={mySearch} 
                      setMyReport={setMyReport}
                      myReport={myReport}
                      refresh={refresh}
                      //setShowCreateAppModal={setShowCreateAppModal}
                      //setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                      setShowCreateAppModalView={setShowCreateAppModalView}
                      setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                    />
                  )
              }
          </table>
        </div>
      </div>

      <div className='d-flex flex-stack flex-wrap pt-10' style={{marginLeft: '2rem', marginRight: '2rem'}}>
          <div className='fs-6 fw-bold text-gray-700'>Mostrando {qtd.start} a {qtd.end} de {pagination.total} encontradas</div>

          <ul className='pagination'>
            <li className='page-item previous'>
              <a 
                href='#' 
                className='page-link'
                onClick={() => {
                  setPagination({...pagination, page: pagination.page - 1 > 0 ? pagination.page - 1 : pagination.page})
                }}
              >
                <i className='previous'></i>
              </a>
            </li>

            {Array.from({ length: pagination.qtdPages }, (_, index) => (
            <li 
              key={index} 
              className={`page-item ${(index + 1) === pagination.page ? "active" : ""}`}
              onClick={() => {
                setPagination({...pagination, page: index + 1})
              }}
            >
              <a href='#' className='page-link'>
                {index + 1}
              </a>
            </li>
            ))}

            
            <li className='page-item next'>
              <a 
                href='#' 
                className='page-link'
                onClick={() => {
                  setPagination({...pagination, page: pagination.page + 1 <= pagination.qtdPages ? pagination.page + 1 : pagination.page})
                }}
              >
                <i className='next'></i>
              </a>
            </li>
          </ul>
      </div>
        {/*<ModalAddUpdateUser
          show={showCreateAppModal}
          id={myUser ? myUser.id : undefined} 
          data={myUser ? myUser : undefined}
          handleClose={() => {
            setMyUser(null)
            setShowCreateAppModal(false)
            refresh()
          }} 
        /> */}
        {/*<ModalDeleteUser 
          show={showCreateAppModalDelete}
          data={myUser}
          id={myUser ? myUser.id : undefined} 
          handleClose={() => {
            setMyUser(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />*/}


        {<ModalResumoReport
          show={showCreateAppModalDetails}
          id={myReport ? myReport.id : undefined} 
          handleClose={() => {
            setMyReport(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        />}
{/* 
        {<ModalDetailsReports
          show={showCreateAppModalDetails}
          id={myReport ? myReport.id : undefined} 
          handleClose={() => {
            setMyReport(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        />} */}

        {<ModalViewReport
          show={showCreateAppModalView}
          id={myReport ? myReport.id : undefined} 
          handleClose={() => {
            setMyReport(null)
            setShowCreateAppModalView(false)
            refresh()
          }} 
        />}
      {/* begin::Body */}
    </div>
  )
}

export {TableReports}


