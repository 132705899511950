import api from "./api";

export const getItemProposalAll = async (idProposal: any) => {
    const response = await api.get(`itemProposal/listAll?idProposal=${idProposal}`);
    return response.data;
};

export const postItemProposalOne = async (body: any) => {
    const response = await api.post(`itemProposal/`, body);
    return response.data;
};