/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { useAuth } from '../../../../../modules/auth';


type Props = {
    unitConsumers: any[];
    setMyUnitConsumer: any;
    setShowCreateAppModal: any;
    // setShowCreateAppModalDelete: any
    // setShowCreateAppModalDetails: any
}

export function TableBody({
    unitConsumers, 
    setMyUnitConsumer,
    setShowCreateAppModal,
    // setShowCreateAppModalDelete, 
    // setShowCreateAppModalDetails
}: Props) {

    // const handleButtonClickDelete = (event, client) => {
    //     event.stopPropagation();
    //     //unitBranch.idUser = unitBranch.users.length > 0 ? unitBranch.users[0].id : null; 
    //     setMyClient(client)
    //     //setShowCreateAppModalDelete(true)
    // };

    const user = useAuth()
    const handleButtonClickUpdate = (event: any, unitConsumer: any) => {
        event.stopPropagation();
        unitConsumer.idPlant = unitConsumer.plant.id ? unitConsumer.plant.id : null; 
        unitConsumer.idConcessionarie = unitConsumer.concessioraire ? unitConsumer.concessioraire.id : null;
        setMyUnitConsumer(unitConsumer)
        setShowCreateAppModal(true)
    };

  return (
    <tbody>
        {unitConsumers && unitConsumers.length !== 0 ?   
            unitConsumers.map((unitConsumer: any) => {
                return (
                    <tr 
                        onClick={() =>{
                            setMyUnitConsumer(unitConsumer)
                            //setShowCreateAppModalDetails(true)
                        }}
                    >
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                                <KTSVG path="/media/icons/duotune/general/gen001.svg" className="svg-icon-muted svg-icon-3hx" />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                    {unitConsumer.name ? unitConsumer.name : "Não encontrado. "}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                   Descrição da unidade
                                </span>
                            </div>
                            </div>
                        </td>

                        <td>
                            <span 
                              className={
                                `badge badge-light-${
                                    unitConsumer.type_unit_consumer !== null
                                    ? unitConsumer.type_unit_consumer === "GERADORA"
                                    ? "primary"
                                    : unitConsumer.type_unit_consumer === "CONSUMIDORA"
                                    ? "info"
                                    : "secondary"
                                  : "secondary"
                                } fs-7 fw-semibold`
                              }
                            >
                                {
                                 unitConsumer.type_unit_consumer ? unitConsumer.type_unit_consumer : "Não identificado"
                                }
                            </span>
                        </td>

                        
                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {unitConsumer.plant ? unitConsumer.plant.plantName : "Não encontrado"}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {unitConsumer.plant ? unitConsumer.plant.plantId : "Não encontrado"}
                            </span>
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {unitConsumer.contrato ? unitConsumer.contrato : "Não encontrado. "}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                Número do contrato
                            </span>
                        </td>

                        
                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {unitConsumer.concessioraire ? unitConsumer.concessioraire.name : "Não encontrado"}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                Nome da Concessionária
                            </span>
                        </td>
                        

                        {user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
                            && user.currentUser.companies[0].planos === "PREMIUM" && 
                        (<td>
                            {/* <span 
                              className={
                                `badge badge-light-${
                                    unitConsumer.status !== null
                                    ? unitConsumer.status === "ATIVA"
                                    ? "success"
                                    : unitConsumer.status === "INATIVA"
                                    ? "danger"
                                    : "secondary"
                                  : "secondary"
                                } fs-7 fw-semibold`
                              }
                            >
                                {
                                 unitConsumer.status ? unitConsumer.status : "Não identificado"
                                }
                            </span> */}
                            <div 
                            className={
                              `mb-6 bg-${
                                unitConsumer.status !== null
                                  ? unitConsumer.status === "ATIVA"
                                  ? "success"
                                  : unitConsumer.status === "INATIVA"
                                  ? "danger"
                                  : "secondary"
                                : "secondary"
                              
                              } rounded-circle border border-4 border-white h-20px w-20px`}
                          ></div>
                        </td>)}

                        <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                            {/* <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='svg-icon-3'
                                />
                            </a> */}
                            {user && 
                            user.currentUser && 
                            user.currentUser.role && 
                            user.currentUser.role.slug && 
                            user.currentUser?.role.slug !== "CLIENTE" &&(
                            <a
                                onClick={(event: any) =>{
                                    handleButtonClickUpdate(event, unitConsumer)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </a>)}
                            {/* <a
                                onClick={(event: any) => {
                                    //handleButtonClickDelete(event, client)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                                />
                            </a> */}
                            </div>
                        </td>
                    </tr>
                )
            })
        :
            <tr>
                <td colSpan={6} style={{textAlign: 'center'}}>
                    <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                        <span className='text-muted fw-semibold text-muted d-block fs-4'>
                            Unidades Consumidoras não encontradass!
                        </span>
                    </div>
                </td>
            </tr>
        }
    </tbody>
  );
}