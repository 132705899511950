/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { initialLetters } from '../../../../../utils/initialLetters';
import { NotImported } from '../../../../components/NotInformat';

type Props = {
    proposals: any[];
    setMyProposals: any;
    setShowCreateAppModal: any;
    setShowCreateAppModalDelete: any
    setShowCreateAppModalDetails: any
    setShowDocumentAppModalTemplate: any
}

export function TableBody({
    proposals, 
    setMyProposals, 
    setShowCreateAppModal, 
    setShowCreateAppModalDelete, 
    setShowCreateAppModalDetails,
    setShowDocumentAppModalTemplate
}: Props) {

    const handleButtonClickDelete = (event, proposal) => {
        event.stopPropagation();
        
        setMyProposals(proposal)
        setShowCreateAppModalDelete(true)
    };

    const handleButtonClickDetails = (event, proposal) => {
        event.stopPropagation();
        
        setMyProposals(proposal)
        setShowCreateAppModalDetails(true)
    };

    const handleButtonClickUpdate = (event, proposal) => {
        event.stopPropagation();

        proposal.idPhaseFunnel = proposal.phaseFunnel ? proposal.phaseFunnel.id : null; 
        proposal.idsCollaborators = proposal.collaborators && proposal.collaborators.length > 0 ? proposal.collaborators.map((collaborator: any) => collaborator.id) : []
        proposal.idPlant = proposal.plant ? proposal.plant.id : null;
        proposal.idClient = proposal.client ? proposal.client.id : null;
        proposal.idUnitBranch = proposal.unitBranch ? proposal.unitBranch.id : null;
        proposal.idsCollaborator = proposal.collaborators && proposal.collaborators.length > 0 ? [proposal.collaborators[0].id] : null;
        
        setMyProposals(proposal)
        setShowCreateAppModal(true)
    };

    const handleButtonClickTemplate = (event, proposal) => {
        event.stopPropagation();
        setMyProposals(proposal)

        setShowDocumentAppModalTemplate(true)
    }

  return (
    <tbody>
        {
        proposals.length !== 0 ?   
            proposals.map((proposal: any) => {
                return (
                    <tr 
                        // onClick={() =>{
                        //     setMyProposals(proposal)
                        //     setShowCreateAppModalDetails(true)
                        // }}
                    >
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                                <KTSVG path="/media/icons/duotune/general/gen005.svg" className="svg-icon-muted svg-icon-3hx" />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {/* {unitBranch.companyName} */}
                                    {proposal.title ? proposal.title : <NotImported className="fs-5 fw-bolder"/>}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {/* {unitBranch.dateOfBirth} */}
                                </span>
                            </div>
                            </div>
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {/* {unitBranch.cnpj} */}R$ {" "}{proposal.value_final ? proposal.value_final : 0},00
                            </a>
                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                CNPJ da filial
                            </span> */}
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {/* {unitBranch.street}, {unitBranch.district} */}
                            {proposal.phaseFunnel && proposal.phaseFunnel.description ? proposal.phaseFunnel.description : <NotImported className="fs-5 fw-bolder"/>}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {/* {unitBranch.city}, {unitBranch.country} */}
                            </span>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                                {proposal && proposal.collaborators && proposal.collaborators.length > 0 ? 
                                    proposal.collaborators.map((collaborator : any) => {
                                        return (
                                            <div>
                                                <div className='symbol symbol-50px symbol-circle me-5'>
                                                    {/* <img src={toAbsoluteUrl("/media/avatars/300-6.jpg")} /> */}

                                                    <div className='symbol symbol-40px symbol-circle '>
                                                        <span className={`symbol-label bg-light-primary text-primary fs-4 fw-bolder bg-hover-primary text-hover-light`}>
                                                            {initialLetters(collaborator.name)}
                                                        </span>
                                                        {/* <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                : <NotImported className="fs-6 fw-bold"/>}
                            </div>
                        </td>
                        
                        <td>
                            {/* <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                Em Negociação
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            </span>  */}
                            <span 
                                className={
                                    `badge badge-light-${
                                        proposal.status !== null
                                        ? proposal.status === "NEGOCIO FECHADO"
                                        ? "success"
                                        : proposal.status === "NEGOCIO RECUSADO"
                                        ? "danger"
                                        : proposal.status === "EM NEGOCIACAO"
                                        ? "primary"
                                        : "secondary"
                                        : "secondary"
                                    } fs-7 fw-semibold`
                                }
                            >
                                {
                                proposal.status ? proposal.status : <NotImported className="fs-6 fw-bolder"/>
                                }
                            </span>
                        </td>
            
                        <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                                <a
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                    onClick={(event: any) => {
                                        handleButtonClickDetails(event, proposal)
                                    }}
                                >
                                    {/* <KTSVG
                                    path='/media/icons/duotune/general/gen019.svg'
                                    className='svg-icon-3'
                                    /> */}
                                    <i className={`bi bi-eye-fill`} style={{fontSize: '1.2rem'}}></i>
                                </a>
                                <a
                                    onClick={(event: any) =>{
                                        handleButtonClickUpdate(event, proposal)
                                    }}
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                </a>
                                <a
                                    onClick={(event: any) => {
                                        handleButtonClickDelete(event, proposal)
                                    }}
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                    <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                    />
                                </a>

                                <a
                                    onClick={(event: any) => {
                                        //handleButtonClickDelete(event, proposal)
                                        handleButtonClickTemplate(event, proposal)
                                    }}
                                    href='#'
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                >
                                    <KTSVG
                                    path='/media/icons/duotune/text/txt009.svg'
                                    className='svg-icon-3'
                                    />
                                </a>
                            </div>
                        </td>
                    </tr>
                )
            })

        :
            <tr>
                <td colSpan={6} style={{textAlign: 'center'}}>
                <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                    <span className='text-muted fw-semibold text-muted d-block fs-4'>
                        Propostas não encontradas!
                    </span>
                </div>
            </td>
            </tr>
        }
        

    </tbody>
  );
}