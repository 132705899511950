/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'

import {getCSS, getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { getShortMonthNameString } from '../../../../../utils/date'

type Props = {
  className: string;
  charts: any;
  prognostico?: any
  myProg?: any
}

const BarGraph: React.FC<Props> = ({className, charts, prognostico, myProg}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [listDates, setListDates] = useState<any>([]);
  const [myPrognostics, setMyPrognostics] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [chartsValues, setChartsValues] = useState({
    metaDates: [],
    valores: [],
    type: "" 
  });

  const handleChartDeploy = () => {
    if(charts && Object.keys(charts).length > 0){
      const myMetaDates = charts[selectedValue]["type"] === "mounth_charts" 
      ? charts[selectedValue]["dias"]
      : charts[selectedValue]["type"] === "year_charts"
      ? charts[selectedValue]["meses"]
      : charts["type"] === "total_charts"
      ? charts["anos"] 
      : []

      const type = charts[selectedValue]["type"] ? charts[selectedValue]["type"] : ""

      const myValores = charts["type"] && charts["type"] === "total_charts" 
      ? charts["valores"]
      : charts[selectedValue]["valores"]


      if(myProg){
        if(charts && charts[selectedValue]["type"] === "mounth_charts"){
          const value = getShortMonthNameString(selectedValue)
          const value_prop = `prog_${value}`;
          const value_final = myProg[value_prop];

          //setMyPrognostics(value_final / 30)

          const my_array = myMetaDates ? Array(myMetaDates.length).fill(parseFloat(((value_final)/30).toFixed(2))) : [] 
          setMyPrognostics(my_array)

        }else if(charts && charts[selectedValue]["type"] === "year_charts"){
          const my_array = myMetaDates.map(dateString => {
            const shortMonthName = getShortMonthNameString(dateString);
            const propertyName = `prog_${shortMonthName}`;
            return myProg[propertyName] !== null ? myProg[propertyName] : 0;
        });
        setMyPrognostics(my_array)
        }else{
          const my_array = prognostico ? Array(myMetaDates.length).fill(parseFloat(((prognostico)).toFixed(2))) : []
          setMyPrognostics(my_array)
        }
      }

      setChartsValues({
        metaDates: myMetaDates,
        valores: myValores,
        type: type 
      })
    }
  }

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  
  useEffect(() => {
    handleChartDeploy()
  }, [selectedValue])
  
  useEffect(() => {
      if(charts){
        const string_date : string = Object.keys(charts)[0]
        setSelectedValue(string_date)
        const list = Object.entries(charts);
        setListDates(list)
      }

  }, [charts])


  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartsValues])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartsValues.valores, chartsValues.metaDates, chartsValues.type, myPrognostics ? myPrognostics : null))
    if (chart) {
      chart.render()
    }
    return chart
  }

  if (!charts) {
    return <h1>Carrengando...</h1>;
  }


  return (
    <div className={`card ${className}`}>
      {charts["type"] !== "total_charts" && (
        <div className='card-header border-0 pt-1'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{selectedValue}</span>
          </h3>
          <div className='card-toolbar' data-kt-buttons='true'>
            <div className='d-flex align-items-center my-2'>
              <div className='w-130px me-5'>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white form-select-sm'
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  {
                    listDates.map((chart: any) => {
                      return (
                        <option value={chart[0]}>{chart[0]}</option>
                      );
                    })
                  }
                </select>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='card-body' style={{overflowX: "auto"}}>
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px', width: '100%'}} />
      </div>
    </div>
  )
}

export {BarGraph}

function getChartOptions(height: number, valores: any, metaDates: any, type: any, prognostico?: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  // return {
  //   series: [
  //     {
  //       name: 'Energia Gerada',
  //       type: 'bar',
  //       data: valores,
  //     },
  //     {
  //       name: 'Prognóstico',
  //       type: 'line',
  //       data: Array(metaDates.length).fill(prognostico ? 
  //         type === "mounth_charts" ? parseFloat(((prognostico/12)/30).toFixed(2)) : 
  //         type === "year_charts" ? parseFloat(((prognostico/12)).toFixed(2)) :
  //         prognostico 
  //       : 0) 
  //     }
  //   ],
  //   chart: {
  //     fontFamily: 'inherit',
  //     type: 'bar',
  //     height: height,
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       columnWidth: type === "mounth_charts" ? '50%' : type === "year_charts" ? "30%" : "20%",
  //       borderRadius: 5,
  //     },
  //   },
  //   legend: {
  //     show: false,
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     show: true,
  //     width: [0, 3],  // Largura 0 para a barra, 2 para a linha
  //     colors: ['transparent', secondaryColor],  // Cor para a linha
  //     curve: 'smooth'
  //   },
  //   xaxis: {
  //     categories: metaDates,
  //     axisBorder: {
  //       show: false,
  //     },
  //     axisTicks: {
  //       show: false,
  //     },
  //     labels: {
  //       style: {
  //         colors: labelColor,
  //         fontSize: '12px',
  //       },
  //     },
  //   },
  //   yaxis: [
  //     {
  //       min: 0,
  //       seriesName: 'Energia Gerada',
  //       axisTicks: {
  //         show: true,
  //       },
  //       axisBorder: {
  //         show: true,
  //         color: baseColor
  //       },
  //       labels: {
  //         style: {
  //           colors: baseColor,
  //         }
  //       },
  //       title: {
  //         text: "Geração (KWh)",
  //         style: {
  //           color: baseColor,
  //         }
  //       },
  //       tooltip: {
  //         enabled: true
  //       }
  //     },
  //   ],
  //   states: {
  //     normal: {
  //       filter: {
  //         type: 'none',
  //         value: 0,
  //       },
  //     },
  //     hover: {
  //       filter: {
  //         type: 'none',
  //         value: 0,
  //       },
  //     },
  //     active: {
  //       allowMultipleDataPointsSelection: false,
  //       filter: {
  //         type: 'none',
  //         value: 0,
  //       },
  //     },
  //   },
  //   tooltip: {
  //     style: {
  //       fontSize: '12px',
  //     },
  //     y: {
  //       formatter: function (val) {
  //         return val + ' KWh';
  //       },
  //     },
  //     x: {
  //       formatter: function (val) {
  //         return val + ' KWh';
  //       },
  //     },
  //   },
  //   colors: [baseColor, secondaryColor],
  //   grid: {
  //     borderColor: borderColor,
  //     strokeDashArray: 4,
  //     yaxis: {
  //       lines: {
  //         show: true,
  //       },
  //     },
  //   },
  // };

  return {
    series: [
      {
        name: 'Energia Gerada',
        type: 'bar',
        data: valores,
      },
      {
        name: 'Prognóstico',
        type: 'line',
        // data: Array(metaDates.length).fill(prognostico ? 
        //   type === "mounth_charts" ? parseFloat(((prognostico)/30).toFixed(2)) : 
        //   type === "year_charts" ? parseFloat(((prognostico/12)).toFixed(2)) :
        //   prognostico 
        // : 0) 
        data: prognostico ? prognostico : []
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: type === "mounth_charts" ? '50%' : type === "year_charts" ? "30%" : "20%",
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: [0, 3],
      colors: ['transparent', prognostico ?  '#f1416c' : 'labelColor'],  // Cor para a linha
      curve: 'smooth'
    },
    xaxis: {
      categories: metaDates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: [
      {
        min: 0,
        seriesName: 'Energia Gerada',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: baseColor
        },
        labels: {
          style: {
            colors: baseColor,
          }
        },
        title: {
          text: "Geração (KWh)",
          style: {
            color: baseColor,
          }
        },
        tooltip: {
          enabled: true
        }
      },
    ],
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
          if (seriesIndex === 1) {
            return val + ' KWh (Prognóstico)';
          }
          return val + ' KWh';
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}



