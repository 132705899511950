/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'


import {KTSVG} from '../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { getReportsAllAnt, getReportsOne, putReportsOne } from '../../../../services/reports'
import { Loading } from '../../../components/Loadings/loading'
import { generatePDF, linkPDF } from '../../../../utils/savePDF'
import { useAuth } from '../../../../modules/auth'
import { DateAno, DateCompleteBarra, extrairMesDia, getShortMonthName } from '../../../../utils/date'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import ReactDOMServer from 'react-dom/server';

type Props = {
    id: string | undefined;
    show: boolean;
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalDetailsReports = ({show, handleClose, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(false)
  const [loadingAvaiable, setLoadingAvaiable] = useState(false)
  const [report, setReport] = useState<any | null>(null)
  const user = useAuth()
  const newPlugin = defaultLayoutPlugin();
  const [url, setUrl] = useState<any>(null)
  const [loadingReports, setLoadingReports] = useState(true)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    setReport(null);
    handleClose()
  }

  const getReportsAnt = async (plant: any, dataAnt: any, status: any) => {
    setLoadingReports(true)
    try {
      const myReports = await getReportsAllAnt(status, plant, dataAnt)
      if(myReports && myReports.length > 0){
        let reportsArray = [] as any;
        myReports.map((reportAnt: any) => {
          reportsArray.push({
            mes: getShortMonthName(reportAnt.data),
            generation: reportAnt.generation,
            performance: reportAnt.performance,
            consumption: reportAnt.consumption,
            saved: reportAnt.saved,
          });
        })
        if(reportsArray.length > 0){
          // formik.setValues({...formik.values, reportsAnt: reportsArray})
          return reportsArray
        }else{
          return []
        }
        
      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoadingReports(false)
    }
  }

  const getMyReport = async () => {
    try {
        setLoading(true)
        const myReport = await getReportsOne(id ? id : "")
        const myReports = await getReportsAnt(
          myReport && myReport.plant ? myReport.plant.id : "",
          myReport && myReport.data ? myReport.data : "",
          "GERADO"
        )

        const myObject = {
          template: myReport.template,
          data: myReport.data ? new Date(myReport.data) : new Date(),
          generation: myReport.generation ? myReport.generation : 0,
          performance: myReport.performance ? myReport.performance : 0,
          consumption: myReport.consumption ? myReport.consumption : 0,
          available: myReport.available ? myReport.available : false,
          saved: myReport.saved ? myReport.saved : 0,
          financial_feedback_month: myReport.financial_feedback_month ? myReport.financial_feedback_month : 0,
          financial_feedback_year: myReport.financial_feedback_year ? myReport.financial_feedback_year : 0,
          invoiced: myReport.invoiced ? myReport.invoiced : 0,
          total_economy: myReport.total_economy? myReport.total_economy: 0,
          initPeriod: myReport.initPeriod ? DateCompleteBarra(myReport.initPeriod) : DateCompleteBarra(new Date()),
          endPeriod: myReport.endPeriod ? DateCompleteBarra(myReport.endPeriod) : DateCompleteBarra(new Date()), 
          corporateName: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.company && myReport.plant.unitBranch.company.corporateName ? myReport.plant.unitBranch.company.corporateName : "Solar Vision Empresa",
          cnpj: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.company && myReport.plant.unitBranch.company.cnpj ? myReport.plant.unitBranch.company.cnpj : "00.00.00.000/00",
          companyName: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.companyName ? myReport.plant.unitBranch.companyName : "Solar Vision Filial",
          emailCompany: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.emailCompany ? myReport.plant.unitBranch.emailCompany : "solarvisionfilial@lekko.com",
          namePlant: myReport.plant && myReport.plant.name ? myReport.plant.name : "Solar Vision Usina",
          adressPlant: myReport.plant && myReport.plant.address ? myReport.plant.address : "Não identificado. ",
          mark: myReport.plant && myReport.plant.credential && myReport.plant.credential.mark && myReport.plant.credential.mark.name ? myReport.plant.credential.mark.name : "Não identificado. ",
          chart_daily: myReport.chart_daily ? myReport.chart_daily : {},
          chart_mouth: myReport.chart_mouth ? myReport.chart_mouth : {},
          chart_year: myReport.chart_year ? myReport.chart_year : {},
          idTemplate: myReport.template ? myReport.template.id : null,
          reportsAnt: myReports ? myReports : []
        }
        setReport(myObject)
    } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar o relatório. ", "error")
    }finally{
        setLoading(false)
    }
  }

  const putMyReportAvaiable = async (data: any) => {
    try {
        setLoadingAvaiable(true)
        const myReportAvaiable = await putReportsOne(id ? id : "", data)
        setReport(myReportAvaiable)
        toggleToast("Sucesso ao disponibilizar o relatório para o cliente. ", "success")
    } catch (error) {
        console.log(error)
        toggleToast("Erro ao disponibilizar o relatório para o cliente. ", "error")
    }finally{
        setLoadingAvaiable(false)
    }
  }

  const graphicMouth = (charts_mouth: any) => {
    const chart = charts_mouth;

    let valores_total = 0;
    
    if(chart && chart.valores && chart.valores.length > 0){
      chart.valores.map((value: any) => {
        if(value > valores_total){
          valores_total = value;
        }
      })
    }

    return (
      <table
        className="
          charts-css column show-heading show-labels 
          show-primary-axis show-4-secondary-axes show-data-axes 
          data-spacing-5 hide-data"
      >
          <caption><span>Geração - {DateCompleteBarra(report.initPeriod)} à {DateCompleteBarra(report.endPeriod)}</span></caption>

          <thead>
            <tr>
              <th scope="col"> Year </th>
              <th scope="col"> Value </th>
            </tr>
          </thead>

          <tbody>
            {chart && chart.dias && chart.dias.map((dia: any, index: any) => {
              if(chart.valores && chart.valores[index] !== 0){
                return (
                  <tr>
                    <th style={{fontSize: '0.6rem'}}> <span style={{paddingTop: '0.5rem', color: '#181c32 !important'}}>{extrairMesDia(dia)}</span> </th>  
                    <td style={{ '--size': `calc(${chart.valores[index]} / ${valores_total})`,'--color': '#ffc700', color: '#181c32', fontSize: '0.6rem'} as any}>
                      {chart && chart.valores ? chart.valores[index] : 0} KW
                    </td>
                  </tr>
                )
              }
            })}
          </tbody>

        </table>
    )
  }

  const graphicYear = (charts_year: any) => {
    const chart = charts_year;
    
    let valores_total = 100;
    
    if(chart && chart.valores && chart.valores.length > 0){
      chart.valores.map((value: any) => {
        if(value > valores_total){
          valores_total = value;
        }
      })
    }

    return (
      <table 
        className="
          charts-css bar show-heading 
          show-labels show-primary-axis show-4-secondary-axes 
          show-data-axes data-spacing-5 hide-data"
        >
          <caption><span>Geração meses anteriores - {DateAno(report.data)}</span></caption>

          <thead>
            <tr>
              <th scope="col"> Year </th>
              <th scope="col"> Value </th>
            </tr>
          </thead>

          <tbody>
            {chart && chart.meses && chart.meses.map((mes: any, index: any) => {
              if(chart.valores){
                return (
                  <tr>
                    <th style={{fontSize: '0.6rem'}}> <span style={{paddingTop: '0.5rem', color: '#181c32 !important'}}>{mes}</span> </th>  
                    <td style={{ '--size': `calc(${chart.valores[index]} / ${valores_total})`,'--color': '#ffc700', color: '#181c32', fontSize: '0.6rem'} as any}>
                      {chart && chart.valores ? chart.valores[index] : 0}
                    </td>
                  </tr>
                )
              }
            })}
          </tbody>

        </table>
    )
  }

  const replaceValues = (dados: any, html: string) => {
    let novoHtml = html;
    Object.keys(dados).forEach(chave => {
        if(chave === 'data'){
          const mounthYear = [
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
          ];
          const data = new Date(dados[chave]);
          const year = data.getFullYear();
          const month = data.getMonth() + 1;
          const nameMonth = mounthYear[month - 1];
  
          const marcadorYear = new RegExp(`{{year}}`, 'g');
          const marcadorMonth = new RegExp(`{{month}}`, 'g');
  
          novoHtml = novoHtml.replace(marcadorYear, year.toString());
          novoHtml = novoHtml.replace(marcadorMonth, nameMonth);
        
        }else{
          if(chave === 'reportsAnt'){

          const reportsHtml = dados[chave].map((report: any) => {
            const tds = Object.keys(report).map(key => {
              return `
                <td>
                  <div class='d-flex align-items-center'>
                    <div class='d-flex justify-content-start flex-column'>
                      <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                        ${report[key]}
                      </a>
                    </div>
                  </div>
                </td>
              `  
              //return `<td>${report[key]}</td>`;
            }).join('');
            return `<tr>${tds}</tr>`;
          }).join('');

          novoHtml = novoHtml.replace(
            `<tbody id="table_reports"><tr><td colspan="4"><span>Valores de relatorios anteriores</span></td></tr></tbody>`, 
            `<tbody id="table_reports">${reportsHtml}</tbody>`
          );

          }else{
            if(chave === 'chart_mouth'){
              const charts_mouth = dados[chave];
            
              novoHtml = novoHtml.replace(
                `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">Gráficos dos dias</div>`, 
                `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                  ReactDOMServer.renderToString(graphicMouth(charts_mouth))
                  //teste()
                    // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
                  // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
                }
                </div>`
              );

              novoHtml = novoHtml.replace(
                `<div class="border border-dashed rounded" style="border-bottom-color:#e1e3ea;border-left-color:!important;border-right-color:!important;border-top-color:#e1e3ea;height:15.5rem;" id="chart_daily">Gráficos dos dias</div>`, 
                `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                  ReactDOMServer.renderToString(graphicMouth(charts_mouth))
                  //teste()
                    // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
                  // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
                }
                </div>`
              );

            }else{
              if(chave === 'chart_year'){
                const charts_year = dados[chave];

                novoHtml = novoHtml.replace(
                  `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">Gráficos mensais</div>`, 
                  `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                    ReactDOMServer.renderToString(graphicYear(charts_year))
                  }
                  </div>`
                );

                novoHtml = novoHtml.replace(
                  `<div class="border border-dashed rounded" style="border-bottom-color:#e1e3ea;border-left-color:!important;border-right-color:!important;border-top-color:#e1e3ea;flex:7;" id="chart_mouth">Gráficos mensais</div>`, 
                  `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                    ReactDOMServer.renderToString(graphicYear(charts_year))
                  }
                  </div>`
                );

              }else{
                const marcador = new RegExp(`{{${chave}}}`, 'g');
                novoHtml = novoHtml.replace(marcador, dados[chave] ? dados[chave] : "0" );
              }
            }
          }
        }


    });
    if(novoHtml.includes("report_template")){
      return novoHtml;
    }else{
      return "<div id='report_template'>" + novoHtml + "</div>"
    }
    
  };

  
  
  // const replaceValues = (dados: any, html: string) => {
    
  //   let novoHtml = html;
  //   Object.keys(dados).forEach(chave => {
  //     if(chave === 'data'){
        
  //       const mounthYear = [
  //         'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
  //         'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  //       ];

  //       const data = new Date(dados[chave]);
  //       const year = data.getFullYear();
  //       const month = data.getMonth() + 1;
  //       const nameMonth = mounthYear[month - 1];

  //       const marcadorYear = new RegExp(`{{year}}`, 'g');
  //       const marcadorMonth = new RegExp(`{{month}}`, 'g');

  //       novoHtml = novoHtml.replace(marcadorYear, year.toString());
  //       novoHtml = novoHtml.replace(marcadorMonth, nameMonth);
  //     }else{
  //       if(chave === 'reportsAnt'){

  //         const reportsHtml = dados[chave].map((report: any) => {
  //           const tds = Object.keys(report).map(key => {
  //             return `
  //               <td>
  //                 <div class='d-flex align-items-center'>
  //                   <div class='d-flex justify-content-start flex-column'>
  //                     <a href='#' class='text-dark fw-bold text-hover-primary mb-1 fs-9'>
  //                       ${report[key]}
  //                     </a>
  //                   </div>
  //                 </div>
  //               </td>
  //             `  
  //             //return `<td>${report[key]}</td>`;
  //           }).join('');
  //           return `<tr>${tds}</tr>`;
  //       }).join('');
        
  //       // Adiciona as trs ao HTML

  //       novoHtml = novoHtml.replace(
  //         `<tbody id="table_reports"><tr><td colspan="4"><span>Valores de relatorios anteriores</span></td></tr></tbody>`, 
  //         `<tbody id="table_reports">${reportsHtml}</tbody>`
  //       );


  //       }else{
  //         if(chave === 'chart_mouth'){
  //           const charts_mouth = dados[chave];
            
  //           novoHtml = novoHtml.replace(
  //             `<div id="chart_daily" style="height: 15.5rem;" class="border border-gray-300 border-dashed rounded">Gráficos dos dias</div>`, 
  //             `<div id="chart_daily" class="border border-gray-300 border-dashed rounded">${
  //               ReactDOMServer.renderToString(graphicMouth(charts_mouth))
  //               //teste()
  //                 // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
  //               // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
  //             }
  //             </div>`
  //           );

  //           novoHtml = novoHtml.replace(
  //             `<div class="border border-gray-300 border-dashed rounded" style="height:15.5rem;" id="chart_daily">Gráficos dos dias</div>`, 
  //             `<div id="chart_daily" class="border border-gray-300 border-dashed rounded">${
  //               ReactDOMServer.renderToString(graphicMouth(charts_mouth))
  //               //teste()
  //                 // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
  //               // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
  //             }
  //             </div>`
  //           );

  //         }else{
  //           if(chave === 'chart_year'){
            
  //           const charts_year = dados[chave];

  //           novoHtml = novoHtml.replace(
  //             `<div id="chart_mouth" style="flex: 7;" class="border border-gray-300 border-dashed rounded">Gráficos mensais</div>`, 
  //             `<div id="chart_mouth" style="flex: 7;" class="border border-gray-300 border-dashed rounded">${
  //               ReactDOMServer.renderToString(graphicYear(charts_year))
  //             }
  //             </div>`
  //           );

  //           novoHtml = novoHtml.replace(
  //             `<div class="border border-gray-300 border-dashed rounded" style="flex:7;" id="chart_mouth">Gráficos mensais</div>`, 
  //             `<div id="chart_mouth" style="flex: 7;" class="border border-gray-300 border-dashed rounded">${
  //               ReactDOMServer.renderToString(graphicYear(charts_year))
  //             }
  //             </div>`
  //           );

  //           }else{
  //             const marcador = new RegExp(`{{${chave}}}`, 'g');
  //             novoHtml = novoHtml.replace(marcador, dados[chave]);
  //           }
  //         }
  //       }
  //     }
  //   });
  //   return novoHtml;
  // };


  const handleCheckBoxValues = (checked: boolean) => {
    putMyReportAvaiable({available: checked})
  }


  const previewPDF = async () => {
    try {
      setPdfLoading(true)
      const myReturnLink = await linkPDF(replaceValues(report, report.template.textHTML))
      setUrl(myReturnLink) 
    
    } catch (error) {
      console.log(error)  
    }finally{
      setPdfLoading(false)
    }
  }

  useEffect(()=> {
    async function search_reports(){
      if(id){
        await getMyReport()
        //await previewPDF()
      }
    }
    search_reports()

  }, [show])

  if (loading) {
    return (
      <div className='bg-body' style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border bg-body' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Visualização do Relatório</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8' style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        {/* <HtmlEditor /> */}
        {/* <ReportsTeste id={id ? id : undefined}/> */}
        <div>
          <button
              type='button'
              className='btn btn-lg btn-dark'
              //disabled={loading || report ? !report.template ? true : false : true}
              //data-kt-stepper-action='submit'
              onClick={() => {
                previewPDF()
              }}
            >
              Visualizar PDF
              {
                pdfLoading ? 
                  <LoadingSpan style={{marginLeft: '1rem'}}/>
                :
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
              }
            </button>
        </div>
        {report && report.template 
        ? 
          // <div style={{overflowY: 'auto', padding: '1rem', width: '100%'}} className="border border-gray-300 border-dashed rounded">
          //   <div dangerouslySetInnerHTML={{ __html: replaceValues(report, report.template.textHTML)}} />
          // </div>
          url ? (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer fileUrl={url} plugins={[newPlugin]}/>
            </Worker>
          )
          : 
          <div>
            {/* <span className='text-muted fw-semibold text-muted d-block fs-4'>
              Carregando...  
            </span> */}
            <></>
          </div>

        :
          <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
            <span className='text-muted fw-semibold text-muted d-block fs-4'>
              Esse relatório não possui um template! Vá até a parte de edição e selecione um template para o relatório, ou crie um do zero.  
            </span>
          </div>
        }
      </div>
      <div className='d-flex flex-stack modal-footer py-lg-8 px-lg-8' style={{justifyContent: 'end', marginBottom :'1rem', gap: '1rem'}}>
          <div style={{display: 'flex', gap: '1rem'}}>
            <button
              type='button'
              className='btn btn-lg btn-success'
              disabled={loading || report ? !report.template ? true : false : true}
              //data-kt-stepper-action='submit'
              onClick={() => {
                generatePDF(report ? replaceValues(report, report.template.textHTML) : "", "report_template")
              }}
            >
              Gerar PDF
              {
                loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
              }
            </button>  
          </div>

          {user.currentUser?.role.slug !== "CLIENTE" && 
            <div className='me-0 dropdown'>
              <button
                //style={{color: 'white'}}
                disabled={loadingAvaiable || loading || report ? !report.template ? true : false : true}
                className='btn btn-lg btn-primary'
                type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                //data-kt-menu-trigger='click'
                //data-kt-menu-dismiss='false'
                //data-kt-menu-placement='bottom-end'
                //data-kt-menu-flip='top-end'
              >
                  Disponibilizar para:
              </button>
              <div className='dropdown-menu w-100px w-md-100px dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem'}}>
                <label className='form-check form-check-sm form-check-custom form-check-solid me-5' style={{display: 'flex', gap: '1rem'}}>
                  <span className='form-check-label fs-4'>Cliente</span>
                  <input 
                    className='form-check-input' 
                    type='checkbox' value='month' 
                    checked={report && report.available ? report.available : false} 
                    onChange={(e) => {
                      handleCheckBoxValues(e.target.checked)
                    }}
                  />
              </label>
              </div>
          
            </div>
          }

          {/* <div style={{display: 'flex', gap: '1rem'}}>
            <button
              type='button'
              className='btn btn-lg btn-primary'
              disabled={loading}
              //data-kt-stepper-action='submit'
              onClick={submit}
            >
              Salvar Relatório
              {
                loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                :
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
              }
            </button>
          
          </div> */}
        </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalDetailsReports}
function graphicMouth(charts_mouth: any): import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> {
  throw new Error('Function not implemented.')
}

function graphicYear(charts_year: any): import("react").ReactElement<any, string | import("react").JSXElementConstructor<any>> {
  throw new Error('Function not implemented.')
}

