/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { initialLetters } from '../../../../../../utils/initialLetters';
import { DateComplete } from '../../../../../../utils/date';
import useSize from '../../../../../../hooks/useSize';
import { KTSVG } from '../../../../../../../_metronic/helpers';

type Props = {
    companies: any[];
    setMyCompany: any;
    refresh: any;
    setShowDeactiveAppModal: any;
    // setShowCreateAppModalDetails: any
}

export function TableBody({
    companies, 
    setMyCompany,
    refresh,
    setShowDeactiveAppModal
    // setShowCreateAppModalDetails
}: Props) {
    const {width} = useSize()
  return (
    <tbody >
    {companies && companies.length !== 0 ?   
        companies.map((company: any) => {
            return (
                <tr 
                    onClick={() =>{
                        //setMyUser(user)
                        //setShowCreateAppModalDetails(true)
                    }}
                >
                    <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                        </div>
                    </td>

                    <td>
                        {company.corporateName ? (
                        <div className='d-flex align-items-center'>
                            <div className='symbol symbol-40px symbol-circle me-5'>
                                <KTSVG path="/media/icons/duotune/general/gen001.svg" className="svg-icon-muted svg-icon-3hx" />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-5'>
                                    {company.corporateName ? company.corporateName : "Não encontrado. "}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Nome da Empresa
                                </span>
                            </div>
                        </div>
                        )
                        :
                        (
                            <div>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Não encontrado.
                                </span>
                            </div>
                        )
                    }
                    </td>


                    <td >
                        <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {company.cnpj ? company.cnpj : "Não encontrado."}
                        </a>
                    </td>


                    <td >
                        <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {company.created_at ? DateComplete(new Date(company.created_at)) : "Não encontrado."}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            Data de inscrição na plataforma
                        </span>
                    </td>


                    <td>
                        <div className='d-flex align-items-center'>
                            <div className='symbol symbol-50px symbol-circle me-5'>
                                <div className='symbol symbol-40px symbol-circle '>
                                    <span 
                                        className={`symbol-label bg-light-primary text-primary fs-4 fw-bolder`}
                                        data-bs-toggle='tooltip'
                                        title={company && company.users && company.users.length > 0 ? `${company.users.find((user : any) => user.role?.slug === "EMPRESA")?.name || ""}\n${company.users.find((user : any) => user.role?.slug === "EMPRESA")?.email || ""}` : ``}
                                    >
                                        {company && company.users && company.users.length > 0 ? initialLetters(company.users.find((user : any) => user.role?.slug === "EMPRESA")?.name || "") : "Não identificado."}
                                    </span>
                                    {/* <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div> */}
                                </div>
                            </div>
                        </div>
                    </td>


                    <td >
                        <span 
                            className={
                            `badge badge-light-${
                                company.status !== null
                                ? company.status === "ATIVADA"
                                ? "success"
                                : company.status === "DESATIVADA"
                                ? "danger"
                                : "secondary"
                                : "secondary"
                            } fs-7 fw-semibold`
                            }
                        >
                            {
                                company.status ? company.status : "Não identificado"
                            }
                        </span>
                        
                        {/* <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                            {company.status ? company.status : "Não encontrado."}
                        </a> */}
                    </td>

                    <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                            onClick={(event: any) =>{
                                setMyCompany(company)
                                setShowDeactiveAppModal(true)
                            }}
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                            <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
                        </a>

                        {/* <a
                            onClick={(event: any) => {
                                //handleButtonClickDelete(event, user)
                            }}
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                            <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                            />
                        </a> */}
                        </div>
                    </td>
                </tr>
            )
        })
    :
    <tr>
        <td colSpan={width < 1200 ? 2 : 6} style={{textAlign: 'center'}}>
            <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                <span className='text-muted fw-semibold text-muted d-block fs-4'>
                    Empresas não encontradas!
                </span>
            </div>
        </td>
    </tr>
    }
</tbody>
  );
}