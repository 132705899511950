import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik'
import * as Yup from "yup";
//import { StepperComponent } from '../../../../../_metronic/assets/ts/components';
//import { ListUnitBranchLinkedService } from './components/listUnitBranchLinkedService';
//import { getUnitBranchAll } from '../../../../services/unitBranchService';
import { ListPlantsLinkedService } from './components/listPlantsLinkedService';
import { getplantsAll } from '../../../../services/plantsService';
import { Loading } from '../../../components/Loadings/loading';
import { getTypeServicesAll } from '../../../../services/typeServices';
import { postServicesOne } from '../../../../services/servicesService';
import { LoadingSpan } from '../../../components/Loadings/loadingSpan';
import { KTSVG } from '../../../../../_metronic/helpers';
import { useToast } from '../../../components/Toats';
import { formatarDataParaString } from '../../../../utils/date';
import { useAuth } from '../../../../modules/auth';
import { postReportServiceOne } from '../../../../services/reportService';
import { getUnitBranchAll } from '../../../../services/unitBranchService';

type Props = {
    refreshPage : boolean; 
    setRefreshPage: any;
}

type DataForm = {
    description: string | null,
    //date: Date | null,
    status: string | null,
    idUnitBranch: string | null,
    idTypeService: string | null,
    idPlant: string | null,
    idClient: string | null,
}

const validationSchema = Yup.object().shape({
    description: Yup.string().required("Informe um nome para a filial"),
    status: Yup.string().required("Informe um nome para a filial"),
    idUnitBranch: Yup.string().required("Informe um nome para a filial"),
    idTypeService: Yup.string().required("Informe um nome para a filial"),
    idPlant: Yup.string().required("Informe um nome para a filial"),
    idClient:  Yup.string()
 });

export function RequestService({refreshPage, setRefreshPage} : Props) {
    const { toggleToast } = useToast();
    const [hasError, setHasError] = useState(false)
    //const stepperRef = useRef<HTMLDivElement | null>(null)
    //const stepper = useRef<StepperComponent | null>(null)
    const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
    const [loading] = useState<boolean>(false);
    const [plants, setPlants] = useState<any[]>([]);
    const [loadingUnitBranchs, setLoadingUnitBranchs] = useState<boolean>(false);
    const [loadingPlants, setLoadingPlants] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    //const [typeServiceLoading, setTypeServicesLoading] = useState<boolean>(false)
    const [typeServices, setTypeServices] = useState<any>([]);
    const [largura, setLargura] = useState(window.innerWidth);
    const {currentUser} = useAuth()
    
    const [initialValues] = useState<DataForm>({
        description: null,
        status: "PENDENTE",
        idUnitBranch: null,
        idTypeService: null,
        idPlant: null,
        idClient: currentUser && currentUser?.client ? currentUser.client.id : null
    })

    const checkInputs = (): boolean => {
        if (!formik.values.description) {
          return false
        }
        return true
    }


    const getUnitBranchs = async () => {
        try {
            setLoadingUnitBranchs(true)
            const unitBranchs = await getUnitBranchAll()
            setUnitBranchs(unitBranchs)
    
        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar a lista de unidades. ", "error")
    
        }finally{
            setLoadingUnitBranchs(false)
        }
    }

    const getTypeServices = async () => {
        try {
          //setTypeServicesLoading(true)
          const myTypeServices = await getTypeServicesAll()
          setTypeServices(myTypeServices)
        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar os tipos de serviços. ", "error")
        }finally{
          //setTypeServicesLoading(false)
        }
    }

    const getPlants = async () => {
        try {
            setLoadingPlants(true)
            let plants = await getplantsAll(`?unitBranch=${formik.values.idUnitBranch ? formik.values.idUnitBranch : ""}`)
        //   plants = plants.map((plant: any) => {
        //     const exist = formik.values.idsPlants.some((id: string) => id === plant.id)
        //     plant.imported = exist ? true : false
        //     return plant
        //   })
        //   plants = plants.sort((a: any, b: any) => (b.imported ? 1 : -1))
          
          setPlants(plants)
    
        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar a lista de usinas. ", "error")
    
        }finally{
            setLoadingPlants(false)
        }
    }

    const postServices = async () => {
        try {
            setLoadingSubmit(true)
            const myBody = {
                description: formik.values.description,
                status: "PENDENTE",
                idUnitBranch: formik.values.idUnitBranch,
                idTypeService: formik.values.idTypeService,
                idPlant: formik.values.idPlant,
                idClient: formik.values.idClient
            }
            await postReportServiceOne(myBody)
            toggleToast("Sucesso ao solicitar o serviço. ", "success")

        } catch (error) {
            console.log(error)
            toggleToast("Erro ao solicitar o serviço. ", "error")
    
        }finally{
            setLoadingSubmit(false)
            formik.setValues(initialValues)
            setRefreshPage(prevAtivo => !prevAtivo);
            formik.setValues(initialValues)
        }
    }
    

    const submit = () => {
        postServices()
    }

    const nextStep = () => {
        setHasError(false)
        // if (!stepper.current) {
        //   return
        // }
        if (!checkInputs()) {
            setHasError(true)
            return
        }
        submit()
        
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: nextStep,
    });

    const atualizarTamanhoDaTela = () => {
        setLargura(window.innerWidth);
      };
    
    useEffect(() => {
        getTypeServices()
        getUnitBranchs()

        window.addEventListener('resize', atualizarTamanhoDaTela);
        return () => {
        window.removeEventListener('resize', atualizarTamanhoDaTela);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //getUnitBranchs()
        getPlants()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.idUnitBranch]);

    if (loading && loadingPlants) {
        return (
          <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
            <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
          </div>
        );
      }

  return (
    <div style={largura < 1200 ? {padding: '0.5rem'} : {padding: '2rem'}}>
        <div style={largura < 1200 ? {overflow: 'auto', height: '52vh'}: {overflow: 'auto', minHeight: '20rem', maxHeight: '52vh'}}>
            <div className='fv-row mb-10' style={{flex: '1'}}>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2 mt-2'>
                    <span className='required'>Filial</span>
                    <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Selecione para qual filial será a solicitação. '
                    ></i>
                </label>
                <select
                    className='form-select'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    //disabled={loadingSubmit ? true : formik.values.idUnitBranch ? false : true}
                    defaultValue={formik.values.idUnitBranch ? formik.values.idUnitBranch : '1'}
                    onChange={formik.handleChange("idUnitBranch")}
                >
                <option value={'1'}></option>
                {unitBranchs && unitBranchs.map((unitBranch: any) => {
                    return (
                    <option key={unitBranch.id} value={unitBranch.id}>{unitBranch.companyName}</option>
                    )
                })}
                </select>
                {!formik.values.idTypeService && hasError ? (
                    <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                            Informe o tipo de serviço na qual será realizado.
                        </div>
                    </div>
                ) : <></>}
            </div>
            <div style={{display: 'flex', gap: '1rem', justifyContent: 'space-between', marginBottom: '2rem'}}>
                <div style={{flex: '1'}}>
                    <ListPlantsLinkedService 
                        plants={plants} 
                        className="bg-light" 
                        formik={formik}
                        hasError={hasError} 
                    />
                    {!formik.values.idPlant && hasError ? (
                        <div className='fv-plugins-message-container mt-2'>
                            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                Informe a usina na qual o serviço será realizado.
                            </div>
                        </div>
                    ) : <></>}
                </div>
            </div>
            <div style={{display: 'flex', gap: '1rem', flexDirection: largura < 1200 ? 'column' : 'row'}}> 
                <div className='fv-row mb-10' style={{flex: '1'}}>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span className='required'>Tipo de serviço</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Selecione qual unidade prestará o serviço para então escolher algum serviço prestado pela mesma. '
                        ></i>
                    </label>
                    <select
                        className='form-select'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        disabled={loadingSubmit ? true : formik.values.idUnitBranch ? false : true}
                        defaultValue={formik.values.idTypeService ? formik.values.idTypeService : '1'}
                        onChange={formik.handleChange("idTypeService")}
                    >
                    <option value={'1'}></option>
                    {typeServices && typeServices.map((myTypeService: any) => {
                        return (
                        <option key={myTypeService.id} value={myTypeService.id}>{myTypeService.description}</option>
                        )
                    })}
                    </select>
                    {!formik.values.idTypeService && hasError ? (
                        <div className='fv-plugins-message-container'>
                            <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                Informe o tipo de serviço na qual será realizado.
                            </div>
                        </div>
                    ) : <></>}
                </div>
            </div>

            <div style={{display: 'flex', gap: '1rem'}}> 
                <div className='fv-row mb-10' style={{flex: '1'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span >Descrição</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Descreva com mais detalhes o serviço'
                        ></i>
                    </label>
                    <input
                        style={{height: '20rem'}}
                        type='text'
                        className='form-control form-control-lg'
                        name='endPeriod'
                        placeholder=''
                        value={formik.values.description ? formik.values.description : ""}
                        onChange={formik.handleChange("description")}
                    />
                    {/* {!formik.values.description && hasError && (
                    <div className='fv-plugins-message-container'>
                        <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                        Informe uma descrição para o tipo de serviço
                        </div>
                    </div>
                    )} */}
                </div>
            </div>

            
        </div>
        
        <div style={{display: 'flex', justifyContent: 'end', paddingTop: largura < 1200 ? '0.5rem' : '1rem'}}>
            <button
                type='button'
                className='btn btn-lg btn-primary'
                disabled={loadingSubmit || !formik.values.idTypeService || !formik.values.idPlant}
                //data-kt-stepper-action='submit'
                onClick={nextStep}
                >
                    {"Solicitar Serviço" }{' '}
                {
                    loading ? 
                    <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                    :
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                }
            </button>
        </div>
    </div>
  );
}

// function toggleToast(arg0: string, arg1: string) {
//     throw new Error('Function not implemented.');
// }
