/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useAuth } from '../../../../modules/auth';
import { DateAno, DateCompleteBarra, DateMes, extrairMesDia, getShortMonthName } from '../../../../utils/date';
import { generatePDF, generatePDFBase64 } from '../../../../utils/savePDF';
import ReactDOMServer from 'react-dom/server';
import { getReportsAllAnt, getReportsOne, postReportEnviationEmail, putReportsOne } from '../../../../services/reports';
import { useToast } from '../../../components/Toats';
import { getFile, getFileBase64 } from '../../../../services/files';

type Props = {
    reports: any[];
    setMyReport: any;
    myReport: any;
    refresh: any
    setShowCreateAppModalView: any
    setShowCreateAppModalDetails: any
}

export function TableBody({
    reports, 
    setMyReport,
    refresh,
    setShowCreateAppModalView, 
    setShowCreateAppModalDetails
}: Props) {

    /*const handleButtonClickDelete = (event, report) => {
        event.stopPropagation();
        //unitBranch.idUser = unitBranch.users.length > 0 ? unitBranch.users[0].id : null; 
        setMyReport(report)
        //setShowCreateAppModalDelete(true)
    };*/

    const user = useAuth()
    const [largura, setLargura] = useState(window.innerWidth);
    const [loading, setLoading] = useState(false)
    const [loadingEnviationEmail, setLoadingEnviationEmail] = useState(false)
    const [report, setReport] = useState<any | null>(null)
    const [loadingReports, setLoadingReports] = useState(true)
    const { toggleToast } = useToast();
    const [loadingAvaiable, setLoadingAvaiable] = useState(false)
    const [type, setType] = useState("")



    const handleButtonClickUpdate = (event: any, report: any) => {
        event.stopPropagation();
        // user.idUnitBranch = user.unitBranchs.length > 0 ? user.unitBranchs[0].id : null;
        // user.role = user.role ? user.role.slug : null
        // user.client = user.client ? user.client.id : null;
        setMyReport(report)
        setShowCreateAppModalView(true)
    };

    const getImage = async (key: string) => {
      try {
        const url = await getFileBase64(key)
        return url.base64
  
      } catch (error) {
        console.log(error)
        return ""
      }
    }

    const contInvoices = (invoices: any[]) => {
        let cont = 0;
        invoices.map((invoice : any) => {
            if(invoice.status === "INFORMADO"){
                cont += 1;
            }
        })
        return cont
    }

    const atualizarTamanhoDaTela = () => {
        setLargura(window.innerWidth);
    };

    const getReportsAnt = async (plant: any, dataAnt: any, status: any) => {
        setLoadingReports(true)
        try {
          const myReports = await getReportsAllAnt(status, plant, dataAnt)
          if(myReports && myReports.length > 0){
            let reportsArray = [] as any;
            myReports.map((reportAnt: any) => {
              reportsArray.push({
                mes: getShortMonthName(reportAnt.data),
                generation: reportAnt.generation,
                performance: reportAnt.performance,
                consumption: reportAnt.consumption,
                saved: reportAnt.saved,
              });
            })
            if(reportsArray.length > 0){
              // formik.setValues({...formik.values, reportsAnt: reportsArray})
              return reportsArray
            }else{
              return []
            }
            
          }
        } catch (error) {
          console.log(error)
        }finally{
          setLoadingReports(false)
        }
    }

    const graphicYearComsuption = (chart2: any) => {
      if (!chart2 || !chart2.meses || !chart2.valores || chart2.meses.length === 0 || chart2.valores.length === 0) {
          chart2 = { meses: [], valores: [] };
      }
    
      let valores_total = 100;
    
      const todosMeses = [...new Set(chart2.meses)];
    
      todosMeses.forEach(mes => {
          const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
          if (valorB > valores_total) {
              valores_total = valorB;
          }
      });
    
      return (
          <table 
              className="
                charts-css column show-heading 
                show-labels show-primary-axis show-4-secondary-axes 
                show-data-axes data-spacing-5 hide-data"
          >
              <caption><span>Consumo - meses anteriores - {DateAno(report.data)}</span></caption>
    
              <thead>
                  <tr>
                      <th scope="col"> Month </th>
                      <th scope="col"> Value </th>
                  </tr>
              </thead>
    
              <tbody>
                  {todosMeses.length > 0 ? (
                      todosMeses.map((mes: any, index: any) => {
                          const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
                          return (
                              <tr key={index}>
                                  <th style={{fontSize: '0.6rem'}}>
                                      <span style={{paddingTop: '0.5rem', color: '#181c32 !important'}}>{mes}</span>
                                  </th>  
                                  <td 
                                      style={{ 
                                          '--size': `calc(${valorB} / ${valores_total})`,
                                          '--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary_variet : '#00aaff',
                                          color: '#fffefe !important',
                                          fontSize: '0.6rem'
                                      } as any}
                                  >
                                      {valorB}
                                  </td>
                              </tr>
                          )
                      })
                  ) : (
                      <tr>
                          <td colSpan={2} style={{ textAlign: 'center' }}>Nenhum dado disponível</td>
                      </tr>
                  )}
              </tbody>
          </table>
      );
    };


    const graphicMouth = (charts_mouth: any) => {
        const chart = charts_mouth;
    
        let valores_total = 0;
        
        if(chart && chart.valores && chart.valores.length > 0){
          chart.valores.map((value: any) => {
            if(value > valores_total){
              valores_total = value;
            }
          })
        }
    
        return (
          <table
            className="
              charts-css column show-heading show-labels 
              show-primary-axis show-4-secondary-axes show-data-axes 
              data-spacing-5 hide-data"
          >
              <caption><span>Geração - {report ? report.initPeriod : ""} à {report ? report.endPeriod : ""}</span></caption>
    
              <thead>
                <tr>
                  <th scope="col"> Year </th>
                  <th scope="col"> Value </th>
                </tr>
              </thead>
    
              <tbody>
                {chart && chart.dias && chart.dias.map((dia: any, index: any) => {
                  if(chart.valores && chart.valores[index] !== 0){
                    return (
                      <tr>
                        <th style={{fontSize: '0.6rem'}}> <span style={{paddingTop: '0.5rem', color: '#181c32 !important'}}>{extrairMesDia(dia)}</span> </th>  
                        <td style={{ '--size': `calc(${chart.valores[index]} / ${valores_total})`,'--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary : '#ffc700', color: '#181c32', fontSize: '0.6rem'} as any}>
                          {chart && chart.valores ? chart.valores[index] : 0} KW
                        </td>
                      </tr>
                    )
                  }
                })}
              </tbody>
    
            </table>
        )
    }
    
    const graphicYear = (chart1: any, chart2: any) => {
      if (!chart1 || !chart1.meses || !chart1.valores || chart1.meses.length === 0 || chart1.valores.length === 0) {
          chart1 = { meses: [], valores: [] };
      }
    
      if (!chart2 || !chart2.meses || !chart2.valores || chart2.meses.length === 0 || chart2.valores.length === 0) {
          chart2 = { meses: [], valores: [] };
      }
    
      let valores_total = 100;
    
      const todosMeses = [...new Set([...chart1.meses, ...chart2.meses])];
    
      todosMeses.forEach(mes => {
          const valorA = chart1.meses.includes(mes) ? chart1.valores[chart1.meses.indexOf(mes)] : 0;
          const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
          const maiorValor = Math.max(valorA, valorB);
          if (maiorValor > valores_total) {
              valores_total = maiorValor;
          }
      });
    
      return (
          <table 
              className="
                charts-css bar show-heading 
                show-labels show-primary-axis show-4-secondary-axes 
                show-data-axes data-spacing-5 hide-data"
          >
              <caption><span>Geração / Consumo - meses anteriores - {DateAno(report.data)}</span></caption>
    
              <thead>
                  <tr>
                      <th scope="col"> Year </th>
                      <th scope="col"> Value </th>
                  </tr>
              </thead>
    
              <tbody>
                  {todosMeses.length > 0 ? (
                      todosMeses.map((mes: any, index: any) => {
                          const valorA = chart1.meses.includes(mes) ? chart1.valores[chart1.meses.indexOf(mes)] : 0;
                          const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
                          return (
                              <tr key={index}>
                                  <th style={{fontSize: '0.6rem'}}>
                                      <span style={{paddingTop: '0.5rem', color: '#181c32 !important'}}>{mes}</span>
                                  </th>  
                                  <td 
                                      style={{ 
                                          '--size': `calc(${valorA} / ${valores_total})`,
                                          '--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary : '#ffc700',
                                          color: '#fffefe !important',
                                          fontSize: '0.6rem'
                                      } as any}
                                  >
                                      {valorA}
                                  </td>
                                  <td 
                                      style={{ 
                                          '--size': `calc(${valorB} / ${valores_total})`,
                                          '--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary_variet : '#00aaff',
                                          color: '#fffefe !important',
                                          fontSize: '0.6rem'
                                      } as any}
                                  >
                                      {valorB}
                                  </td>
                              </tr>
                          )
                      })
                  ) : (
                      <tr>
                          <td colSpan={3} style={{ textAlign: 'center' }}>Nenhum dado disponível</td>
                      </tr>
                  )}
              </tbody>
          </table>
      );
    };

    const graphicYearGeneration = (chart1: any, chart2: any) => {
      if (!chart1 || !chart1.meses || !chart1.valores || chart1.meses.length === 0 || chart1.valores.length === 0) {
          chart1 = { meses: [], valores: [] };
      }
    
      if (!chart2 || !chart2.meses || !chart2.valores || chart2.meses.length === 0 || chart2.valores.length === 0) {
          chart2 = { meses: [], valores: [] };
      }
    
      let valores_total = 100;
    
      const todosMeses = [...new Set([...chart1.meses, ...chart2.meses])]
        .sort((a: string, b: string) => new Date(a).getTime() - new Date(b).getTime());
    
      todosMeses.forEach(mes => {
          const valorA = chart1.meses.includes(mes) ? chart1.valores[chart1.meses.indexOf(mes)] : 0;
          const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
          const maiorValor = Math.max(valorA, valorB);
          if (maiorValor > valores_total) {
              valores_total = maiorValor;
          }
      });
    
      return (
          <table 
              className="
                  charts-css column show-heading 
                  show-labels show-primary-axis show-4-secondary-axes 
                  show-data-axes data-spacing-5 hide-data"
          >
              <caption><span>Geração / Prognóstico - Ultimos 12 meses</span></caption>
    
              <thead>
                  <tr>
                      <th scope="col">Mês</th>
                      <th scope="col">Valor A</th>
                      <th scope="col">Valor B</th>
                  </tr>
              </thead>
    
              <tbody>
                  {todosMeses.length > 0 ? (
                      todosMeses.map((mes, index) => {
                          const valorA = chart1.meses.includes(mes) ? chart1.valores[chart1.meses.indexOf(mes)] : 0;
                          const valorB = chart2.meses.includes(mes) ? chart2.valores[chart2.meses.indexOf(mes)] : 0;
                          const proporcaoA = (typeof valorA === 'number' && typeof valores_total === 'number') ? valorA / valores_total : 0;
                          const proporcaoB = (typeof valorB === 'number' && typeof valores_total === 'number') ? valorB / valores_total : 0;
                          
                          return (
                              <tr key={index}>
                                  <th style={{fontSize: '0.6rem'}}>
                                      <span style={{paddingTop: '0.5rem', color: '#181c32 !important'}}>{mes}</span>
                                  </th>  
                                  <td 
                                      style={{ 
                                          '--size': `${proporcaoA}`,
                                          '--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary : '#ffc700',
                                          color: '#fffefe !important',
                                          fontSize: '0.6rem'
                                      } as any}
                                  >
                                      {valorA}
                                  </td>
                                  <td 
                                      style={{ 
                                          '--size': `${proporcaoB}`,
                                          '--color': user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0 ? user.currentUser.companies[0].color_primary_variet : '#00aaff',
                                          color: '#fffefe !important',
                                          fontSize: '0.6rem'
                                      } as any}
                                  >
                                      {valorB}
                                  </td>
                              </tr>
                          )
                      })
                  ) : (
                      <tr>
                          <td colSpan={3} style={{ textAlign: 'center' }}>Nenhum dado disponível</td>
                      </tr>
                  )}
              </tbody>
          </table>
      );
    };
    // const graphicYear = (charts_year: any) => {
    // const chart = charts_year;
    
    // let valores_total = 100;
    
    // if(chart && chart.valores && chart.valores.length > 0){
    //     chart.valores.map((value: any) => {
    //     if(value > valores_total){
    //         valores_total = value;
    //     }
    //     })
    // }

    // return (
    //     <table 
    //     className="
    //         charts-css bar show-heading 
    //         show-labels show-primary-axis show-4-secondary-axes 
    //         show-data-axes data-spacing-5 hide-data"
    //     >
    //         <caption><span>Geração meses anteriores - {DateAno(report.data)}</span></caption>

    //         <thead>
    //         <tr>
    //             <th scope="col"> Year </th>
    //             <th scope="col"> Value </th>
    //         </tr>
    //         </thead>

    //         <tbody>
    //         {chart && chart.meses && chart.meses.map((mes: any, index: any) => {
    //             if(chart.valores){
    //             return (
    //                 <tr>
    //                 <th style={{fontSize: '0.6rem'}}> <span style={{paddingTop: '0.5rem', color: '#181c32 !important'}}>{mes}</span> </th>  
    //                 <td style={{ '--size': `calc(${chart.valores[index]} / ${valores_total})`,'--color': '#ffc700', color: '#181c32', fontSize: '0.6rem'} as any}>
    //                     {chart && chart.valores ? chart.valores[index] : 0}
    //                 </td>
    //                 </tr>
    //             )
    //             }
    //         })}
    //         </tbody>

    //     </table>
    // )
    // }

    const replaceValues = (dados: any, html: string) => {
      let novoHtml = html;
      
      Object.keys(dados).forEach(chave => {
        if(chave === 'data'){
          const mounthYear = [
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
          ];
  
          const data = new Date(dados[chave]);
          const year = data.getFullYear();
          const month = data.getMonth() + 1;
          const nameMonth = mounthYear[month - 1];
  
          const marcadorYear = new RegExp(`{{year}}`, 'g');
          const marcadorMonth = new RegExp(`{{month}}`, 'g');
  
          novoHtml = novoHtml.replace(marcadorYear, year.toString());
          novoHtml = novoHtml.replace(marcadorMonth, nameMonth);
        }else{
          if(chave === 'reportsAnt'){
  
            const reportsHtml = dados[chave].map((report: any) => {
              const tds = Object.keys(report).map(key => {
                return `
                  <td>
                    <div class='d-flex align-items-center'>
                      <div class='d-flex justify-content-start flex-column'>
                        <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                          ${report[key]}
                        </a>
                      </div>
                    </div>
                  </td>
                `  
                //return `<td>${report[key]}</td>`;
              }).join('');
              return `<tr>${tds}</tr>`;
          }).join('');
          
          // Adiciona as trs ao HTML
  
          novoHtml = novoHtml.replace(
            `<tbody id="table_reports"><tr><td colspan="4"><span>Valores de relatorios anteriores</span></td></tr></tbody>`, 
            `<tbody id="table_reports">${reportsHtml}</tbody>`
          );
  
  
          }else{
            if(chave === 'chart_mouth'){
              const charts_mouth = dados[chave];
              
              novoHtml = novoHtml.replace(
                `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">Gráficos dos dias</div>`, 
                `<div id="chart_daily" style="height: 10rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                  ReactDOMServer.renderToString(graphicMouth(charts_mouth))
                  //teste()
                    // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
                  // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
                }
                </div>`
              );
  
              novoHtml = novoHtml.replace(
                `<div class="border border-dashed rounded" style="border-bottom-color:#e1e3ea;border-left-color:!important;border-right-color:!important;border-top-color:#e1e3ea;height:15.5rem;" id="chart_daily">Gráficos dos dias</div>`, 
                `<div id="chart_daily" style="height: 10rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                  ReactDOMServer.renderToString(graphicMouth(charts_mouth))
                  //teste()
                    // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
                  // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
                }
                </div>`
              );
  
            }else{
              if(chave === 'chart_year'){
              
              const charts_year = dados[chave];
              const charts_comsuption = dados["chart_comsuption"]
  
              novoHtml = novoHtml.replace(
                `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">Gráficos mensais</div>`, 
                `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                  ReactDOMServer.renderToString(graphicYear(charts_year, charts_comsuption))
                }
                </div>`
              );
  
              novoHtml = novoHtml.replace(
                `<div class="border border-dashed rounded" style="border-bottom-color:#e1e3ea;border-left-color:!important;border-right-color:!important;border-top-color:#e1e3ea;flex:7;" id="chart_mouth">Gráficos mensais</div>`, 
                `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                  ReactDOMServer.renderToString(graphicYear(charts_year, charts_comsuption))
                }
                </div>`
              );
  
              }else{
                if(chave === 'graphic_days'){
                  const charts_mouth = dados['chart_mouth'];
                  
                  const marcador = new RegExp(`{{${chave}}}`, 'g');
                  novoHtml = novoHtml.replace(marcador, `<div id="chart_daily" style="height: 10rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                      ReactDOMServer.renderToString(graphicMouth(charts_mouth))
                      //teste()
                        // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
                      // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
                    }
                    </div>`);
  
                }else{
                  if(chave === 'graphic_months'){
                    const charts_year = dados['chart_year'];
                    const charts_comsuption = dados["chart_comsuption"]
  
                    const marcador = new RegExp(`{{${chave}}}`, 'g');
                    novoHtml = novoHtml.replace(
                      marcador,
                      `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                        ReactDOMServer.renderToString(graphicYear(charts_year, charts_comsuption))
                      }
                      </div>`
                    );
  
                  }else{
                    if(chave === 'initPeriod'){
                      const myDate = DateCompleteBarra(dados[chave]);
                      
                      const marcador = new RegExp(`{{${chave}}}`, 'g');
                      novoHtml = novoHtml.replace(marcador, myDate);
  
                    }else{
                      if(chave === 'endPeriod'){
                        const myDate = DateCompleteBarra(dados[chave]);
                        
                        const marcador = new RegExp(`{{${chave}}}`, 'g');
                        novoHtml = novoHtml.replace(marcador, myDate);
    
                      }else{
                        if(chave === 'invoices'){
                          const invoicesHtml = dados[chave].map((invoice: any) => {
                            const tds = Object.keys(invoice).map(key => {
                              if(key === 'dateInit' || key === 'dateAnd'){
                                  return `
                                    <td>
                                      <div class='d-flex align-items-center'>
                                        <div class='d-flex justify-content-start flex-column'>
                                          <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                                            ${DateCompleteBarra(invoice[key])}
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  `  
                              }
  
                              if(key === 'energia_registrada' 
                                || key === 'energia_compensada' 
                                || key === 'energia_faturada' 
                                || key === 'creditos_ant'
                                || key === 'creditos'
                              ){
                                return `
                                <td>
                                  <div class='d-flex align-items-center'>
                                    <div class='d-flex justify-content-start flex-column'>
                                      <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                                        ${invoice[key]} KWh
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              `  
                              }
  
                              if(key === 'valor_faturado' || key === 'economizado' ){
                                return `
                                <td>
                                  <div class='d-flex align-items-center'>
                                    <div class='d-flex justify-content-start flex-column'>
                                      <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                                        R$ ${invoice[key]}
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              `  
                              }
                              return `
                                <td>
                                  <div class='d-flex align-items-center'>
                                    <div class='d-flex justify-content-start flex-column'>
                                      <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
                                        ${invoice[key]}
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              `  
                              //return `<td>${report[key]}</td>`;
                            }).join('');
                            return `<tr>${tds}</tr>`;
                        }).join('');
                        
                        // Adiciona as trs ao HTML
                
                        novoHtml = novoHtml.replace(
                          `{{informationsConcessionaries}}`, 
                          `<div class='table-responsive'>
                              <table class='table align-middle gs-0'>
                                  <thead>
                                      <tr class='fw-bold text-muted' style="background: #f9f9f9;">
                                          <th class='min-w-30px rounded-start'><span class="fs-9">Contrato</span></th>
                                          <th class='min-w-40px rounded-start'><span class="fs-9">Inicio</span></th>
                                          <th class='min-w-40px rounded-start'><span class="fs-9">Fim</span></th>
                                          <th class='min-w-40px'><span class="fs-9">Consumo Registrado</span></th>
                                          <th class='min-w-40px'><span class="fs-9">Energia Compensada</span></th>
                                          <th class='min-w-40px'><span class="fs-9">Energia Faturada</span></th>
                                          <th class='min-w-40px'><span class="fs-9">Créditos Acumulados (Anterior)</span></th>
                                          <th class='min-w-40px'><span class="fs-9">Créditos Acumulados (Atual)</span></th>
                                          <th class='min-w-40px'><span class="fs-9">Faturado</span></th>
                                          <th class='min-w-40px'><span class="fs-9">Economizado</span></th>
                                      </tr>
                                  </thead>
                                  
                                  <tbody id="table_reports">${invoicesHtml}</tbody>
                              </table>
                          </div>
                          `
                        );
                        }else{
                          if(chave === 'chart_year_generation'){
              
                            const charts_year_generation = dados[chave];
                            const charts_prognosis = dados["chart_prognosis"]
                
                            novoHtml = novoHtml.replace(
                              `{{graphic_mensal_combination}}`, 
                              `<div id="chart_mouth" style="height: 20rem; flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                                ReactDOMServer.renderToString(graphicYearGeneration(charts_year_generation, charts_prognosis))
                              }
                              </div>`
                            );
  
                            novoHtml = novoHtml.replace(
                              `{{graphic_mensal_combination}}`, 
                              `<div id="chart_mouth" style="height: 20rem; flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                                ReactDOMServer.renderToString(graphicYearGeneration(charts_year_generation, charts_prognosis))
                              }
                              </div>`
                            );
  
                          }else{
                            if(chave === 'chart_comsuption'){
                              //const charts_year = dados[chave];
                              const charts_comsuption = dados[chave]
            
                              const marcador = new RegExp(`{{${chave}}}`, 'g');
                              novoHtml = novoHtml.replace(
                                marcador,
                                `<div id="chart_mouth" style="height: 10rem; flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
                                  ReactDOMServer.renderToString(graphicYearComsuption(charts_comsuption))
                                }
                                </div>`
                              );
                            }else{
                              if(chave === 'generation'){
                              
                                if(dados[chave] && !isNaN(Number(dados[chave]))){
                                  const generation = Number(dados[chave])
                                  
                                  const economia_co2 = (generation * 0.53)
                                  const arvores_salvas = economia_co2 / 22
  
                                  const marcador_economia_co2 = new RegExp(`{{economia_co2}}`, 'g');
                                  novoHtml = novoHtml.replace(marcador_economia_co2, (economia_co2 / 1000).toFixed(2).toString());
  
                                  const marcador_arvores_salvas = new RegExp(`{{arvores_salvas}}`, 'g');
                                  novoHtml = novoHtml.replace(marcador_arvores_salvas, arvores_salvas.toFixed(2).toString());
  
                                }else{
                                  const marcador_economia_co2 = new RegExp(`{{economia_co2}}`, 'g');
                                  novoHtml = novoHtml.replace(marcador_economia_co2, "0");
  
                                  const marcador_arvores_salvas = new RegExp(`{{arvores_salvas}}`, 'g');
                                  novoHtml = novoHtml.replace(marcador_arvores_salvas, "0");
                                } 
  
                                const marcador = new RegExp(`{{${chave}}}`, 'g');
                                novoHtml = novoHtml.replace(marcador, dados[chave]);
  
                              }else{
                                const marcador = new RegExp(`{{${chave}}}`, 'g');
                                novoHtml = novoHtml.replace(marcador, dados[chave]);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
      // Object.keys(dados).forEach(chave => {
      //   if(chave === 'data'){
      //     const mounthYear = [
      //       'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      //       'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      //     ];
  
      //     const data = new Date(dados[chave]);
      //     const year = data.getFullYear();
      //     const month = data.getMonth() + 1;
      //     const nameMonth = mounthYear[month - 1];
  
      //     const marcadorYear = new RegExp(`{{year}}`, 'g');
      //     const marcadorMonth = new RegExp(`{{month}}`, 'g');
  
      //     novoHtml = novoHtml.replace(marcadorYear, year.toString());
      //     novoHtml = novoHtml.replace(marcadorMonth, nameMonth);
      //   }else{
      //     if(chave === 'reportsAnt'){
  
      //       const reportsHtml = dados[chave].map((report: any) => {
      //         const tds = Object.keys(report).map(key => {
      //           return `
      //             <td>
      //               <div class='d-flex align-items-center'>
      //                 <div class='d-flex justify-content-start flex-column'>
      //                   <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
      //                     ${report[key]}
      //                   </a>
      //                 </div>
      //               </div>
      //             </td>
      //           `  
      //           //return `<td>${report[key]}</td>`;
      //         }).join('');
      //         return `<tr>${tds}</tr>`;
      //     }).join('');
          
      //     // Adiciona as trs ao HTML
  
      //     novoHtml = novoHtml.replace(
      //       `<tbody id="table_reports"><tr><td colspan="4"><span>Valores de relatorios anteriores</span></td></tr></tbody>`, 
      //       `<tbody id="table_reports">${reportsHtml}</tbody>`
      //     );
  
  
      //     }else{
      //       if(chave === 'chart_mouth'){
      //         const charts_mouth = dados[chave];
              
      //         novoHtml = novoHtml.replace(
      //           `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">Gráficos dos dias</div>`, 
      //           `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
      //             ReactDOMServer.renderToString(graphicMouth(charts_mouth))
      //             //teste()
      //               // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
      //             // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
      //           }
      //           </div>`
      //         );
  
      //         novoHtml = novoHtml.replace(
      //           `<div class="border border-dashed rounded" style="border-bottom-color:#e1e3ea;border-left-color:!important;border-right-color:!important;border-top-color:#e1e3ea;height:15.5rem;" id="chart_daily">Gráficos dos dias</div>`, 
      //           `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
      //             ReactDOMServer.renderToString(graphicMouth(charts_mouth))
      //             //teste()
      //               // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
      //             // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
      //           }
      //           </div>`
      //         );
  
      //       }else{
      //         if(chave === 'chart_year'){
              
      //         const charts_year = dados[chave];
  
      //         novoHtml = novoHtml.replace(
      //           `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">Gráficos mensais</div>`, 
      //           `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
      //             ReactDOMServer.renderToString(graphicYear(charts_year))
      //           }
      //           </div>`
      //         );
  
      //         novoHtml = novoHtml.replace(
      //           `<div class="border border-dashed rounded" style="border-bottom-color:#e1e3ea;border-left-color:!important;border-right-color:!important;border-top-color:#e1e3ea;flex:7;" id="chart_mouth">Gráficos mensais</div>`, 
      //           `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
      //             ReactDOMServer.renderToString(graphicYear(charts_year))
      //           }
      //           </div>`
      //         );
  
      //         }else{
      //           if(chave === 'graphic_days'){
      //             const charts_mouth = dados['chart_mouth'];
                  
      //             const marcador = new RegExp(`{{${chave}}}`, 'g');
      //             novoHtml = novoHtml.replace(marcador, `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
      //                 ReactDOMServer.renderToString(graphicMouth(charts_mouth))
      //                 //teste()
      //                   // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
      //                 // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
      //               }
      //               </div>`);
  
      //           }else{
      //             if(chave === 'graphic_months'){
      //               const charts_year = dados['chart_year'];
  
      //               const marcador = new RegExp(`{{${chave}}}`, 'g');
      //               novoHtml = novoHtml.replace(
      //                 marcador,
      //                 `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
      //                   ReactDOMServer.renderToString(graphicYear(charts_year))
      //                 }
      //                 </div>`
      //               );
  
      //             }else{
      //               if(chave === 'initPeriod'){
      //                 const myDate = DateCompleteBarra(dados[chave]);
                      
      //                 const marcador = new RegExp(`{{${chave}}}`, 'g');
      //                 novoHtml = novoHtml.replace(marcador, myDate);
  
      //               }else{
      //                 if(chave === 'endPeriod'){
      //                   const myDate = DateCompleteBarra(dados[chave]);
                        
      //                   const marcador = new RegExp(`{{${chave}}}`, 'g');
      //                   novoHtml = novoHtml.replace(marcador, myDate);
    
      //                 }else{
      //                   if(chave === 'invoices'){
      //                     const invoicesHtml = dados[chave].map((invoice: any) => {
      //                       const tds = Object.keys(invoice).map(key => {
      //                         if(key === 'dateInit' || key === 'dateAnd'){
      //                             return `
      //                               <td>
      //                                 <div class='d-flex align-items-center'>
      //                                   <div class='d-flex justify-content-start flex-column'>
      //                                     <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
      //                                       ${DateCompleteBarra(invoice[key])}
      //                                     </a>
      //                                   </div>
      //                                 </div>
      //                               </td>
      //                             `  
      //                         }
  
      //                         if(key === 'energia_registrada' 
      //                           || key === 'energia_compensada' 
      //                           || key === 'energia_faturada' 
      //                           || key === 'creditos_ant'
      //                           || key === 'creditos'
      //                         ){
      //                           return `
      //                           <td>
      //                             <div class='d-flex align-items-center'>
      //                               <div class='d-flex justify-content-start flex-column'>
      //                                 <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
      //                                   ${invoice[key]} KWh
      //                                 </a>
      //                               </div>
      //                             </div>
      //                           </td>
      //                         `  
      //                         }
  
      //                         if(key === 'valor_faturado' || key === 'economizado' ){
      //                           return `
      //                           <td>
      //                             <div class='d-flex align-items-center'>
      //                               <div class='d-flex justify-content-start flex-column'>
      //                                 <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
      //                                   R$ ${invoice[key]}
      //                                 </a>
      //                               </div>
      //                             </div>
      //                           </td>
      //                         `  
      //                         }
      //                         return `
      //                           <td>
      //                             <div class='d-flex align-items-center'>
      //                               <div class='d-flex justify-content-start flex-column'>
      //                                 <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
      //                                   ${invoice[key]}
      //                                 </a>
      //                               </div>
      //                             </div>
      //                           </td>
      //                         `  
      //                         //return `<td>${report[key]}</td>`;
      //                       }).join('');
      //                       return `<tr>${tds}</tr>`;
      //                   }).join('');
                        
      //                   // Adiciona as trs ao HTML
                
      //                   novoHtml = novoHtml.replace(
      //                     `{{informationsConcessionaries}}`, 
      //                     `<tbody id="table_reports">${invoicesHtml}</tbody>`
      //                   );
      //                   }else{
      //                     const marcador = new RegExp(`{{${chave}}}`, 'g');
      //                     novoHtml = novoHtml.replace(marcador, dados[chave]);
      //                   }
      //                 }
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // });

      if(novoHtml.includes("report_template")){
        return novoHtml;
      }else{
        return "<div id='report_template'>" + novoHtml + "</div>"
      }
    };

    // const replaceValues = (dados: any, html: string) => {
    //     let novoHtml = html;
    //     Object.keys(dados).forEach(chave => {
    //         if(chave === 'data'){
    //           const mounthYear = [
    //             'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    //             'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    //           ];
    //           const data = new Date(dados[chave]);
    //           const year = data.getFullYear();
    //           const month = data.getMonth() + 1;
    //           const nameMonth = mounthYear[month - 1];
      
    //           const marcadorYear = new RegExp(`{{year}}`, 'g');
    //           const marcadorMonth = new RegExp(`{{month}}`, 'g');
      
    //           novoHtml = novoHtml.replace(marcadorYear, year.toString());
    //           novoHtml = novoHtml.replace(marcadorMonth, nameMonth);
            
    //         }else{
    //           if(chave === 'reportsAnt'){
    
    //           const reportsHtml = dados[chave].map((report: any) => {
    //             const tds = Object.keys(report).map(key => {
    //               return `
    //                 <td>
    //                   <div class='d-flex align-items-center'>
    //                     <div class='d-flex justify-content-start flex-column'>
    //                       <a href='#' style="color: #181c32" class='fw-bold text-hover-primary mb-1 fs-9'>
    //                         ${report[key]}
    //                       </a>
    //                     </div>
    //                   </div>
    //                 </td>
    //               `  
    //               //return `<td>${report[key]}</td>`;
    //             }).join('');
    //             return `<tr>${tds}</tr>`;
    //           }).join('');
    
    //           novoHtml = novoHtml.replace(
    //             `<tbody id="table_reports"><tr><td colspan="4"><span>Valores de relatorios anteriores</span></td></tr></tbody>`, 
    //             `<tbody id="table_reports">${reportsHtml}</tbody>`
    //           );
    
    //           }else{
    //             if(chave === 'chart_mouth'){
    //               const charts_mouth = dados[chave];
                
    //               novoHtml = novoHtml.replace(
    //                 `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">Gráficos dos dias</div>`, 
    //                 `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
    //                   ReactDOMServer.renderToString(graphicMouth(charts_mouth))
    //                   //teste()
    //                     // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
    //                   // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
    //                 }
    //                 </div>`
    //               );
    
    //               novoHtml = novoHtml.replace(
    //                 `<div class="border border-dashed rounded" style="border-bottom-color:#e1e3ea;border-left-color:!important;border-right-color:!important;border-top-color:#e1e3ea;height:15.5rem;" id="chart_daily">Gráficos dos dias</div>`, 
    //                 `<div id="chart_daily" style="height: 15.5rem; border-color: #e1e3ea !important" class="border border-dashed rounded">${
    //                   ReactDOMServer.renderToString(graphicMouth(charts_mouth))
    //                   //teste()
    //                     // ReactDOMServer.renderToString(<BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>)
    //                   // <BarGraph className='card-xl-stretch mb-xl-8' charts={charts_mouth}/>
    //                 }
    //                 </div>`
    //               );
    
    //             }else{
    //               if(chave === 'chart_year'){
    //                 const charts_year = dados[chave];
    
    //                 novoHtml = novoHtml.replace(
    //                   `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">Gráficos mensais</div>`, 
    //                   `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
    //                     ReactDOMServer.renderToString(graphicYear(charts_year))
    //                   }
    //                   </div>`
    //                 );
    
    //                 novoHtml = novoHtml.replace(
    //                   `<div class="border border-dashed rounded" style="border-bottom-color:#e1e3ea;border-left-color:!important;border-right-color:!important;border-top-color:#e1e3ea;flex:7;" id="chart_mouth">Gráficos mensais</div>`, 
    //                   `<div id="chart_mouth" style="flex: 7; border-color: #e1e3ea !important" class="border border-dashed rounded">${
    //                     ReactDOMServer.renderToString(graphicYear(charts_year))
    //                   }
    //                   </div>`
    //                 );
    
    //               }else{
    //                 const marcador = new RegExp(`{{${chave}}}`, 'g');
    //                 novoHtml = novoHtml.replace(marcador, dados[chave] ? dados[chave] : "0" );
    //               }
    //             }
    //           }
    //         }
    
    
    //     });
    //     if(novoHtml.includes("report_template")){
    //       return novoHtml;
    //     }else{
    //       return "<div id='report_template'>" + novoHtml + "</div>"
    //     }
        
    // };

    // const getMyReport = async (id: string) => {
    //     try {
    //         setLoading(true)
    //         const myReport = await getReportsOne(id ? id : "")
    //         const myReports = await getReportsAnt(
    //           myReport && myReport.plant ? myReport.plant.id : "",
    //           myReport && myReport.data ? myReport.data : "",
    //           "GERADO"
    //         )
    
    //         const myObject = {
    //           template: myReport.template,
    //           data: myReport.data ? new Date(myReport.data) : new Date(),
    //           generation: myReport.generation ? myReport.generation : 0,
    //           performance: myReport.performance ? myReport.performance : 0,
    //           consumption: myReport.consumption ? myReport.consumption : 0,
    //           available: myReport.available ? myReport.available : false,
    //           saved: myReport.saved ? myReport.saved : 0,
    //           financial_feedback_month: myReport.financial_feedback_month ? myReport.financial_feedback_month : 0,
    //           financial_feedback_year: myReport.financial_feedback_year ? myReport.financial_feedback_year : 0,
    //           invoiced: myReport.invoiced ? myReport.invoiced : 0,
    //           total_economy: myReport.total_economy? myReport.total_economy: 0,
    //           initPeriod: myReport.initPeriod ? DateCompleteBarra(myReport.initPeriod) : DateCompleteBarra(new Date()),
    //           endPeriod: myReport.endPeriod ? DateCompleteBarra(myReport.endPeriod) : DateCompleteBarra(new Date()), 
    //           corporateName: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.company && myReport.plant.unitBranch.company.corporateName ? myReport.plant.unitBranch.company.corporateName : "Solar Vision Empresa",
    //           cnpj: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.company && myReport.plant.unitBranch.company.cnpj ? myReport.plant.unitBranch.company.cnpj : "00.00.00.000/00",
    //           companyName: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.companyName ? myReport.plant.unitBranch.companyName : "Solar Vision Filial",
    //           emailCompany: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.emailCompany ? myReport.plant.unitBranch.emailCompany : "solarvisionfilial@lekko.com",
    //           namePlant: myReport.plant && myReport.plant.name ? myReport.plant.name : "Solar Vision Usina",
    //           adressPlant: myReport.plant && myReport.plant.address ? myReport.plant.address : "Não identificado. ",
    //           mark: myReport.plant && myReport.plant.credential && myReport.plant.credential.mark && myReport.plant.credential.mark.name ? myReport.plant.credential.mark.name : "Não identificado. ",
    //           chart_daily: myReport.chart_daily ? myReport.chart_daily : {},
    //           chart_mouth: myReport.chart_mouth ? myReport.chart_mouth : {},
    //           chart_year: myReport.chart_year ? myReport.chart_year : {},
    //           idTemplate: myReport.template ? myReport.template.id : null,
    //           reportsAnt: myReports ? myReports : []
    //         }
    //         setReport(myObject);
    //     } catch (error) {
    //         console.log(error)
    //         toggleToast("Erro ao buscar o relatório. ", "error")
    //     }finally{
    //         setLoading(false)
    //         //setReport(null);
    //         //setMyReport(null)
    //     }
    // }

    const getMyReport = async (id: string, type: string) => {
      try {
          setLoading(true)
          const myReport = await getReportsOne(id ? id : "")
          const myReports = await getReportsAnt(
            myReport && myReport.plant ? myReport.plant.id : "",
            myReport && myReport.data ? myReport.data : "",
            "GERADO"
          )
  
          const logo_url = myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.company && myReport.plant.unitBranch.company.image_dark ? await getImage (myReport.plant.unitBranch.company.image_dark) : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.png";
  
          const myObject = {
            id: myReport.id ? myReport.id : "",
            data: myReport.data ? new Date(myReport.data) : new Date(),
            generation: myReport.generation ? myReport.generation : 0,
            performance: myReport.performance ? myReport.performance : 0,
            consumption: myReport.consumption ? myReport.consumption : 0,
            saved: myReport.saved ? myReport.saved : 0,
            financial_feedback_month: myReport.financial_feedback_month ? myReport.financial_feedback_month : 0,
            financial_feedback_year: myReport.financial_feedback_year ? myReport.financial_feedback_year : 0,
            invoiced: myReport.invoiced ? myReport.invoiced : 0,
            total_economy: myReport.total_economy? myReport.total_economy: 0,
  
            consumerRegister: myReport.consumerRegister? myReport.consumerRegister: 0,
            consumerInstant: myReport.consumerInstant? myReport.consumerInstant: 0,
            unitConsumerTotal: myReport.unitConsumerTotal? myReport.unitConsumerTotal: 0,
            energyInjected: myReport.energyInjected? myReport.energyInjected: 0,
            //logo: logo_url,
            logo: `data:image/jpeg;base64,${logo_url}`,
  
            color_company: myReport.plant && myReport.plant.unitBranch 
            && myReport.plant.unitBranch.company 
            && myReport.plant.unitBranch.company.color_primary 
            ? myReport.plant.unitBranch.company.color_primary : null,
  
            color_secondary: myReport.plant && myReport.plant.unitBranch 
            && myReport.plant.unitBranch.company 
            && myReport.plant.unitBranch.company.color_primary_variet 
            ? myReport.plant.unitBranch.company.color_primary_variet : null,
  
            prog_mensal: myReport.prog_mensal? myReport.prog_mensal: "0",
            prog_anual: myReport.prog_anual? myReport.prog_anual: "0",
  
            performance_anual: myReport.performance_anual? myReport.performance_anual: "0",
            generation_anual: myReport.generation_anual? myReport.generation_anual: "0",
            prog_12_meses: myReport.prog_12_meses? myReport.prog_12_meses: "0",
  
            //initPeriod: myReport.initPeriod ? DateCompleteBarra(myReport.initPeriod) : DateCompleteBarra(new Date()),
            //endPeriod: myReport.endPeriod ? DateCompleteBarra(myReport.endPeriod) : DateCompleteBarra(new Date()), 
            initPeriod: myReport.initPeriod ? myReport.initPeriod : new Date().toString(),
            endPeriod: myReport.endPeriod ? myReport.endPeriod : new Date().toString(),
            corporateName: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.company && myReport.plant.unitBranch.company.corporateName ? myReport.plant.unitBranch.company.corporateName : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.png",
            cnpj: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.company && myReport.plant.unitBranch.company.cnpj ? myReport.plant.unitBranch.company.cnpj : "00.00.00.000/00",
            companyName: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.companyName ? myReport.plant.unitBranch.companyName : "Solar Vision Filial",
            emailCompany: myReport.plant && myReport.plant.unitBranch && myReport.plant.unitBranch.emailCompany ? myReport.plant.unitBranch.emailCompany : "solarvisionfilial@lekko.com",
            namePlant: myReport.plant && myReport.plant.name ? myReport.plant.name : "Solar Vision Usina",
            capacity: myReport.plant && myReport.plant.capacity ? myReport.plant.capacity : "0",
            adressPlant: myReport.plant && myReport.plant.address ? myReport.plant.address : "Não identificado. ",
            mark: myReport.plant && myReport.plant.credential && myReport.plant.credential.mark && myReport.plant.credential.mark.name ? myReport.plant.credential.mark.name : "Não identificado. ",
            chart_daily: myReport.chart_daily ? myReport.chart_daily : {},
            chart_mouth: myReport.chart_mouth ? myReport.chart_mouth : {},
            chart_year: myReport.chart_year ? myReport.chart_year : {},
  
            chart_prognosis: myReport.chart_prognosis ? myReport.chart_prognosis : {},
            chart_year_generation:  myReport.chart_year_generation ? myReport.chart_year_generation : {},
            
            chart_comsuption: myReport.chart_comsuption ? myReport.chart_comsuption : {},
            type_template: type,
            invoices: myReport.invoices ? myReport.invoices.map((myInvoice: any) => {
              let economizado_fatura = 0;
  
              if(myInvoice.unitConsumer && myInvoice.unitConsumer.type_unit_consumer === "GERADORA"){
                
                const energy_inject = myInvoice.energia_injetada ? myInvoice.energia_injetada.replace(',', '.') : "0"
                const generation = myReport.generation ? myReport.generation.replace(',', '.')  : "0"
                const consumo_instantaneo = parseFloat(generation) - parseFloat(energy_inject)
                const energia_compensada = myInvoice.energia_compensada? myInvoice.energia_compensada.replace(',', '.') : "0"
                const tarifa = myInvoice.tarifa ? myInvoice.tarifa.replace(',', '.') : "0"
                const consumo_total = consumo_instantaneo + parseFloat(energia_compensada);
                economizado_fatura = parseFloat(tarifa) * consumo_total;
  
              }else{
                const energia_compensada = myInvoice.energia_compensada? myInvoice.energia_compensada.replace(',', '.') : "0"
                const tarifa = myInvoice.tarifa ? myInvoice.tarifa.replace(',', '.') : "0"
                economizado_fatura = parseFloat(tarifa) * energia_compensada;
              }
              return {
                  contrato: myInvoice.unitConsumer && myInvoice.unitConsumer.name ? myInvoice.unitConsumer.name : "",
                  dateInit: myInvoice.leitura_anterior ? myInvoice.leitura_anterior : new Date(),
                  dateAnd: myInvoice.leitura_atual ? myInvoice.leitura_atual : new Date(),
                  energia_registrada: myInvoice.energia_registrada ? myInvoice.energia_registrada : "0",
                  energia_compensada: myInvoice.energia_compensada ? myInvoice.energia_compensada : "0",
                  energia_faturada: myInvoice.energia_faturada ? myInvoice.energia_faturada : "0",
                  creditos_ant: myInvoice.creditos_ant ? myInvoice.creditos_ant : "0",
                  creditos: myInvoice.creditos? myInvoice.creditos: "0",
                  valor_faturado: myInvoice.valor_faturado ? myInvoice.valor_faturado : "0",
                  economizado: economizado_fatura.toFixed(2).toString(),
              }
            }) : [],
  
            graphic_days: {},
            graphic_months: {},
  
            //chart_mouth: myReport.chart_mouth ? myReport.chart_mouth : {},
  
            idTemplate: myReport.templates && myReport.templates.length > 0 ? myReport.templates[0].id : null,
            idsTemplates: myReport.templates && myReport.templates.length > 0 ? myReport.templates.map((template: any) => {return template.id}) : [],
            reportsAnt: myReports ? myReports : [],
            templates: myReport.templates && myReport.templates.length > 0 ? myReport.templates : [],
          }
          setReport(myObject);
        } catch (error) {
            console.log(error)
            toggleToast("Erro ao buscar o relatório. ", "error")
        }finally{
            setLoading(false)
            //setReport(null);
            //setMyReport(null)
        }
    }

    const putMyReportAvaiable = async (data: any, id: any) => {
        try {
            setLoadingAvaiable(true)
            const myReportAvaiable = await putReportsOne(id ? id : "", data)
            setReport(myReportAvaiable)
            toggleToast("Sucesso ao disponibilizar o relatório para o cliente. ", "success")
        } catch (error) {
            console.log(error)
            toggleToast("Erro ao disponibilizar o relatório para o cliente. ", "error")
        }finally{
            setLoadingAvaiable(false)
            refresh()
        }
    }

    const postEnviationEmail = async (idReport: string, base64: string) => {
      try {
        setLoadingEnviationEmail(true)
        await postReportEnviationEmail({
          idReport: idReport ? idReport : "",
          base64: base64 ? base64 : ""
        })
        toggleToast("Sucesso ao enviar o relatório. ", "success")
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao enviar o relatório. ", "error")
      }finally{
        setLoadingEnviationEmail(false)
      }
    }

      
    const handleCheckBoxValues = (checked: boolean, id: string) => {
        putMyReportAvaiable({available: checked}, id)
    }

    useEffect(() => {
        window.addEventListener('resize', atualizarTamanhoDaTela);
        return () => {
          window.removeEventListener('resize', atualizarTamanhoDaTela);
        };
    }, []);


    useEffect(() => {
      
      const fetchData = async (template: any, idReport: string) => {
        console.log(template, idReport)
        //setLoadingEnviationEmail(true) 
        const base64 = await generatePDFBase64(report ? replaceValues(report, template.textHTML) : "<div>template não encontrado<div/>", "report_template")
        postEnviationEmail(idReport, base64)
        console.log('base64', base64)
      }
      
      if(type === "GERAR"){
        if(report && report.templates && report.type_template){
          const template = report.templates.find((template: any) => template.type_template === report.type_template)
          if(template){
            generatePDF(report ? replaceValues(report, template.textHTML) : "<div>template não encontrado<div/>", "report_template")
          }
        }
      }else if(type === "ENVIAR"){
        if(report && report.templates && report.type_template){
          const template = report.templates.find((template: any) => template.type_template === report.type_template)
          if(template){
            fetchData(template, report.id)
          }
        }
      }
      setMyReport(null)
  }, [report]);

  return (
    <tbody>
        {reports && reports.length !== 0 ?   
            reports.map((report: any) => {
                return (
                    <tr key={report.id} 
                        onClick={() =>{
                            // if(report.status === "GERADO"){
                            //     setMyReport(report)
                            //     setShowCreateAppModalDetails(true)
                            // }
                        }}
                    >
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                                <div className='symbol symbol-40px symbol-circle me-5'>
                                    <KTSVG path="/media/icons/duotune/files/fil005.svg" className="svg-icon-muted svg-icon-3hx" />
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                        {report.plant.name ? report.plant.name : "Não encontrado. "}
                                    </a>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {report.plant.plantId ? report.plant.plantId : "Nao encontrado"}
                                    </span>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                      {report.data 
                                          ? DateMes(
                                              (() => {
                                                  const date = new Date(report.data);
                                                  if (isNaN(date.getTime())) return null
                                                  date.setDate(date.getDate() + 3)
                                                  return date.toISOString()
                                              })()
                                          ) 
                                          : "Não encontrado."
                                      }
                                    </a>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {report.data ? DateAno(report.data) : "Nao encontrado"}
                                    </span>
                                </div>
                            </div>
                            
                        </td>

                        <td>
                            <span 
                              className={
                                `badge badge-light-${
                                    report.status !== null
                                    ? report.status === "GERADO"
                                    ? "success"
                                    : report.status === "FALHA"
                                    ? "danger"
                                    : report.status === "INCOMPLETO"
                                    ? "warning"
                                    : "secondary"
                                  : "secondary"
                                } fs-7 fw-semibold`
                              }
                            >
                                {
                                 report.status ? report.status : "Não identificado"
                                }
                            </span>
                        </td>

                        
                        <td>
                            <div className='d-flex align-items-center'>
                                {report.invoices && report.invoices.length > 0 
                                    ?
                                        <div className='d-flex justify-content-start' style={{gap: '0.3rem'}}>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-4'>
                                                {contInvoices(report.invoices)}
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-4'>
                                                de {report.invoices.length} 
                                            </span>
                                        </div>
                                    : 
                                        <div className='d-flex justify-content-start' style={{gap: '0.3rem'}}>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-4'>
                                                0
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-4'>
                                                de 0 
                                            </span>
                                        </div>
                                }
                            </div>
                            
                        </td>
                       
                        <td>
                        {user.currentUser?.role.slug !== "CLIENTE" &&
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              {/* {(report.status === "GERADO" || report.status === "FALHA") && 

                              } */}
                              <a
                                onClick={(event: any) => {
                                  setMyReport(report)
                                  setShowCreateAppModalDetails(true)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                style={{marginRight: '0.3rem'}}
                              >
                                <i className={`bi bi-eye-fill`} style={{fontSize: '1.2rem'}}></i>
                              </a>

                              <a
                                  onClick={(event: any) =>{
                                    if(report && report.plant && report.plant.plano && report.plant.plano !== "GRATUITA"){
                                      handleButtonClickUpdate(event, report)
                                    }
                                    
                                  }}
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                  {
                                    report && report.plant && report.plant.plano && report.plant.plano === "GRATUITA"
                                    ?
                                      <i className={`bi bi-lock-fill`} style={{fontSize: '1.2rem'}}></i>
                                    :
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                  }
                                  
                              </a>

                              {/* <a
                                  onClick={(event: any) =>{
                                      handleButtonClickUpdate(event, report)
                                  }}
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                  a
                              </a> */}

                              {report && report.status !== "INCOMPLETO" && 
                              (<div className='me-0 dropdown'>
                                  <a
                                      onClick={(event: any) =>{
                                        event.stopPropagation();
                                      }}
                                      href='#'
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"
                                  >
                                      <i className='bi bi-three-dots fs-1'></i>
                                  </a>
                              
                                  <div className='dropdown-menu w-300px w-md-170px dropdown-menu-end' aria-labelledby="dropdownMenuButton2" style={{padding: '1rem'}}>
                                      <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                                          <span
                                              className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`
                                              }
                                              style={{width: '100%', display: 'flex',justifyContent: 'space-between', gap: '1rem'}}
                                              data-bs-toggle="tab"
                                              aria-disabled={loading || !report.template || !report.template.textHTML}
                                              onClick={(event)=> {
                                                event.stopPropagation();
                                              }}
                                          >
                                              {loading && 
                                              (<span 
                                                style={{marginRight: '0.2rem'}} 
                                                className='spinner-border spinner-border-sm align-middle ms-2'></span>)}
                                                {" "} 
                                                <span>Gerar PDF</span>
                                              <select
                                                className='form-select form-select-solid bg-white form-select-sm'
                                                style={{width: '2rem'}}
                                                data-kt-select2='true'
                                                disabled={loading}
                                                data-placeholder='Select option'
                                                data-allow-clear='true'
                                                defaultValue={''}
                                                onChange={(evt: any) => {
                                                  evt.stopPropagation();
                                                  setType("GERAR")
                                                  getMyReport(report.id, evt.target.value)
                                                }}
                                              >
                                                  <option value={''} disabled></option>
                                                {<option value={'GERACAO'}>Geração</option>}
                                                {report && report.plant && report.plant.plano === "PREMIUM" &&
                                                (<option value={'DETALHADO'}>Detalhado</option>)}
                                              </select>
                                          </span>

                                          <span
                                              className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`
                                              }
                                              style={{width: '100%', display: 'flex', justifyContent: 'space-between', gap: '1rem'}}
                                              data-bs-toggle="tab"
                                              aria-disabled={loading || !report.template || !report.template.textHTML}
                                              onClick={(event)=> {
                                                event.stopPropagation();
                                              }}
                                          >
                                              {(loading || loadingEnviationEmail) && 
                                              (<span 
                                                style={{marginRight: '0.2rem'}} 
                                                className='spinner-border spinner-border-sm align-middle ms-2'></span>)}
                                                {" "} 
                                                <span>Enviar para e-mail do cliente</span>
                                              <select
                                                style={{width: '2rem'}}
                                                className='form-select form-select-solid bg-white form-select-sm w-2'
                                                data-kt-select2='true'
                                                disabled={loading}
                                                data-placeholder='Select option'
                                                data-allow-clear='true'
                                                defaultValue={''}
                                                onChange={(evt: any) => {
                                                  evt.stopPropagation();
                                                  setType("ENVIAR")
                                                  getMyReport(report.id, evt.target.value)
                                                }}
                                              >
                                                  <option value={''} disabled></option>
                                                {<option value={'GERACAO'}>Geração</option>}
                                                {report && report.plant && report.plant.plano === "PREMIUM" &&
                                                (<option value={'DETALHADO'}>Detalhado</option>)}
                                              </select>
                                          </span>
                                          
                                          <label className='form-check form-check-sm form-check-custom form-check-solid' 
                                              style={{display: 'flex', gap: '1rem', justifyContent: 'space-between', width: '100%', marginBottom: '1rem', paddingLeft: '0.6rem', paddingRight: '1.5rem'}}>
                                              {loadingAvaiable && (<span className='spinner-border spinner-border-sm align-middle ms-2'></span>)}{" "}
                                              <span className='form-check-label'>Disponibilizar no aplicativo do cliente</span>
                                              <input 
                                                  className='form-check-input' 
                                                  type='checkbox' value='month' 
                                                  checked={report && report.available ? report.available : false} 
                                                  onChange={(e) => {
                                                      handleCheckBoxValues(e.target.checked, report.id)
                                                  }}
                                              />
                                              
                                          </label>



                                          {/* {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== 'CLIENTE' &&
                                              <a
                                                  className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`
                                                  }
                                                  style={{width: '100%'}}
                                                  data-bs-toggle="tab"
                                                  href="#"
                                                  onClick={(event)=> {
                                                      event.stopPropagation();
                                                      setShowCreateAppModal(true)
                                                      itemOrderOfService.idUnitBranch = itemOrderOfService && itemOrderOfService.unitBranch ? itemOrderOfService.unitBranch.id : null;
                                                      itemOrderOfService.idClient = itemOrderOfService && itemOrderOfService.client ? itemOrderOfService.client.id : null;
                                                      itemOrderOfService.idPlant = itemOrderOfService && itemOrderOfService.plant ? itemOrderOfService.plant.id : null;
                                                      itemOrderOfService.idCollaborator = itemOrderOfService && itemOrderOfService.collaborators && itemOrderOfService.collaborators.length > 0 ? itemOrderOfService.collaborators[0].id : null;
                                                      setMyOrderService(itemOrderOfService)
                                                      //updateOSOne(itemOrderOfService.id, "AGUARDANDO")
                                                      //formik.setValues({...itemOrderOfService, status: "AGUARDANDO"})
                                                  }}
                                              >
                                                  Editar
                                              </a>
                                          }
                                          {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== 'CLIENTE' &&
                                              <a
                                                  className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`
                                                  }
                                                  style={{width: '100%'}}
                                                  data-bs-toggle="tab"
                                                  href="#"
                                                  onClick={(event)=> {
                                                      event.stopPropagation();
                                                      setShowModalDelete(true)
                                                      setMyOrderService(itemOrderOfService)
                                                      //updateOSOne(itemOrderOfService.id, "AGUARDANDO")
                                                      //formik.setValues({...itemOrderOfService, status: "AGUARDANDO"})
                                                  }}
                                              >
                                                  Excluir
                                              </a>
                                          } */}
                                      </div>
                                  </div>
                              </div>)}

                            </div>
                        }
                        </td>
                    </tr>
                )
            })
        :
            <tr>
                <td colSpan={largura < 1200 ? 2 : 6} style={{textAlign: 'center'}}>
                    <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                        <span className='text-muted fw-semibold text-muted d-block fs-4'>
                            Relatórios não encontrados!
                        </span>
                    </div>
                </td>
            </tr>
        }
        <tr style={{height: '10rem'}}></tr>
    </tbody>
  );
}


