/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
//import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth'
import { getClientAll } from '../../services/clientService'
import { getCompanyAll } from '../../services/companyService'
import { getUnitBranchAll } from '../../services/unitBranchService'
import { Loading } from '../components/Loadings/loading'
import { useToast } from '../components/Toats'
// import { Graphics } from './ Graphics'
import { Maps } from './Maps'
import { StatisticsMenu } from './StatisticsMenu'
import { TablesPlants } from './TablePlants'
import './styles.css'
import { getplantsAll, getplantsOne } from '../../services/plantsService'
import { GraphicsDahsboardPlant } from './ GraphicsModalPlant'
import { getReportsAll } from '../../services/reports'


const DashboardPage: FC = () => {
  const { toggleToast } = useToast();
  const [userAny, setUserAny] = useState<any>(null);
  const [company, setCompany] = useState(null);
  const [unitBranch, setUnitBranch] = useState(null);
  const [client, setClient] = useState(null);
  const [pages, setPages] = useState(1);
  const [loadingPlants, setLoadingPlants] = useState(true);
  const [loadingCharts, setLoadingCharts] = useState(false);
  const [loadingPlantsFilter, setLoadingPlantsFilter] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [data, setData] = useState<any>(null);
  const [dataFilter, setDataFilter] = useState<any>(null);
  const [plant, setPlant] = useState<string | null>(null);
  const [largura, setLargura] = useState(window.innerWidth);
  const [report, setReport] = useState([]);

  const user = useAuth()

  const getCompanys = async () => {
    try {
      const company = await getCompanyAll()
      setCompany(company[0])
    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a empresa. ", "error")
    }
  }

  const getUnitBranch = async () => {
    try {
      const unitBranch = await getUnitBranchAll()
      setUnitBranch(unitBranch[0])

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a unidade filial. ", "error")
    }
  }

  const getClient = async () => {
    try {
      const client = await getClientAll()
      setClient(client)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar o cliente. ", "error")
    }
  }

  const getPlants = async () => {
    try {
      if(plant){
        setLoadingCharts(true)
        const myPlant = await getplantsOne(plant)
        setData(myPlant)
      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoadingCharts(false)
    }
  }

  const getPlantsFilter = async () => {
    try {
        setLoadingPlantsFilter(true)
        const myPlants = await getplantsAll()
        setDataFilter(myPlants)
        
        if(myPlants && myPlants.length > 0){
          setPlant(myPlants[0].id)
        }
    } catch (error) {
      console.log(error)
    }finally{
      setLoadingPlantsFilter(false)
    }
  }

  const getReportPlant = async () => {
    try {
        setLoadingReport(true)
        const myReport = await getReportsAll(
          'GERADO',
          new Date(),
          plant ? plant : ""
        )
        setReport(myReport)
        
    } catch (error) {
      console.log(error)
    }finally{
      setLoadingReport(false)
    }
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };


  const refresh = async () => {
    await getPlants()
    await getPlantsFilter()
  }

  useEffect(() => {
    if(user.currentUser?.role.slug === "EMPRESA"){
      getCompanys()
    }else if(user.currentUser?.role.slug === "FILIAL"){
      getUnitBranch()
    }else if(user.currentUser?.role.slug === "CLIENTE"){
      getClient()
      getPlantsFilter()
      
    }else if (user.currentUser){
      const myUser = {
        email: user.currentUser.email, 
        name: user.currentUser.name,
        role:  user.currentUser?.role.slug
      }
      setUserAny(myUser)
      //getPlants()
    }

    window.addEventListener('resize', atualizarTamanhoDaTela);
    
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
    //getCharts()
    //getChartsBD()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if(plant){
      getPlants()
      getReportPlant()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plant])

  if ((!company && !unitBranch && !client && !userAny) || (user.currentUser?.role.slug === "CLIENTE" && loadingPlantsFilter)) {
    return (
      <div className='spinnerLoadingDashboard'>
        <Loading myClass='spinner-border spinnerDashboard'/>
      </div>
    );
  }

  return (
    <div style={{marginTop: largura > 1200  ? '1rem' : ''}}>
        <StatisticsMenu 
          company={company} 
          unitBranch={unitBranch}
          client={client}
          userAny={userAny}
        />

      {/* <MenuDropdown 
        size='sm'
        color='secondary'
        position='dropdown-menu-start'
        stringButton='Teste'
        iconButton='bi bi-three-dots'
        textButtonClass='text-dark'
        style={{width: '25rem'}}
      >
        epaaaaa
      </MenuDropdown> */}
      
    {!loadingPlants && 
      <ul className={`nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-${largura < 1200 ? '7' : '5'} fw-bolder flex-nowrap mb-${largura < 1200 ? '2' : '5'} ulGlobal`}>
        <li className="nav-item">
          <a
            className="nav-link active text-active-primary me-6 border-active-primary border-hover-primary"
            data-bs-toggle="tab"
            href="#kt_tab_pane_1"
            onClick={() => setPages(1)}
          >
            {user.currentUser?.role.slug === "CLIENTE" ? "Gráficos" : "Tabela"}
          </a>
        </li>
        {user.currentUser?.role.slug === "CLIENTE" &&
          <li className="nav-item">
            <a
              className="nav-link text-active-primary me-6"
              data-bs-toggle="tab"
              href="#kt_tab_pane_2"
              onClick={() => setPages(2)}
            >
              Tabela
            </a>
          </li>
          }
        <li className="nav-item">
          <a
            className="nav-link text-active-primary me-6 border-active-primary border-hover-primary"
            data-bs-toggle="tab"
            href="#kt_tab_pane_3"
            onClick={() => setPages(3)}
          >
            Mapa
          </a>
        </li>
      </ul>
    }

      <div className="tab-content" id="myTabContent">
        <div
            className="tab-pane active fade show"
            id="kt_tab_pane_1"
            role="tabpanel"
          >
            {user.currentUser?.role.slug === "CLIENTE" 
            ? 
              <div className={`${largura > 1200 ? '' : '' }`}
                style={largura < 1200 ? {
                  paddingRight: '1rem' , 
                  paddingLeft: '2.5rem',
                  
                } : {
                  padding: '0rem'
                }}>
                <GraphicsDahsboardPlant
                  typeGraphc='grapich'
                  myProg={data && data.prognosis ? data.prognosis : null}
                  charts={data && data.charts ? data.charts : null} 
                  loading={loadingCharts}
                  plantsFilter={dataFilter}
                  dataFilter={plant}
                  setDataFilter={setPlant}
                  data={data ? data : null} 
                  loadingReport={loadingReport}
                  refresh={refresh}
                  report={report.length === 1 ? report[0] : null}
                  prognostico={data && data.annual_generation_forecast ? data.annual_generation_forecast : null}
                />
              </div>
            :
              <div
                className={`${largura > 1200 ? '' : '' }`}
                style={largura < 1200 ? {
                  paddingRight: '1.2rem' , 
                  paddingLeft: '1.3rem',
                  
                } : {
                  padding: '0rem'
                }}
              >
                <TablesPlants 
                  className='mb-5 mb-xl-8' 
                  setLoadingPlants={setLoadingPlants}
                />
              </div>            
            }
        </div>
        {user.currentUser?.role.slug === "CLIENTE"  &&
          <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
            {/* <Graphics charts={chartsSum} loadingCharts={loadingCharts}/> */}
            <div
              className={`${largura > 1200 ? '' : '' }`}
              style={largura < 1200 ? {
                paddingRight: '1.2rem' , 
                paddingLeft: '1.3rem',
                
              } : {
                padding: '0rem'
              }}
            >
              <TablesPlants 
                className='mb-5 mb-xl-8' 
                setLoadingPlants={setLoadingPlants}
              />
            </div>
            
          </div>
        }

        <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
          <div className={`${largura > 1200 ? '' : '' }`}
            style={largura < 1200 ? {
              paddingRight: '2rem' , 
              paddingLeft: '2.5rem',
              
            } : {
              padding: '0rem'
            }}
          >
            <Maps page={pages}/>
          </div>
        </div>

      </div>
    </div>
  )
}

const DashboardWrapper: FC = () => {
  //const intl = useIntl()

  const [largura, setLargura] = useState(window.innerWidth);

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [])

  return (
    <div>
      <div style={{padding: largura > 1200 ? '0.5rem' : ''}}>
        {/* {intl.formatMessage({id: 'MENU.DASHBOARD'})} */}
        {largura > 1200 && 
        <PageTitle breadcrumbs={[]}>
          Painel de Controle
        </PageTitle>}
      </div>
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
