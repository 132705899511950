import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { useAuth } from '../../../../../../modules/auth';
import { getRoleAll } from '../../../../../../services/roles';
import { Loading } from '../../../../../components/Loadings/loading';
import { useFormik } from 'formik'
import * as Yup from "yup";
import { updateUsersAll } from '../../../../../../services/usersService';
import { putCompanyOne } from '../../../../../../services/companyService';
import { updateUnitBranch } from '../../../../../../services/unitBranchService';

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  companyName: Yup.string().required("Informe um nome para o usuário"),
  cnpj: Yup.string().required("Informe um email para o cliente"),
  cellphone: Yup.string(),
  dateOfBirth: Yup.string(),
  emailCompany: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  district: Yup.string(),
  number: Yup.string(),
  state: Yup.string(),
  street: Yup.string(),
  zipcode: Yup.string(),
});

export function BodySettingsUnitBranch() {
  const { currentUser } = useAuth()
  const [loadingRoles, setLoadingRoles] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<any[]>([]);
  
  const [initialValues, setInitialValues] = useState<any>({
    id: null,
    companyName: null,
    cnpj: null,
    cellphone: null,
    dateOfBirth: null,
    emailCompany: null,
    city: null,
    country: null,
    district: null,
    number: null,
    state: null,
    street: null,
    zipcode: null,
})


  // const getRoles = async () => {
  //   try {
  //     setLoadingRoles(true)
  //     const roles = await getRoleAll()
  //     setRoles(roles)

  //   } catch (error) {
  //     console.log(error)
  //     toggleToast("Erro ao buscar os tipos de usuário. ", "error")

  //   }finally{
  //     setLoadingRoles(false)
  //   }
  // }

  const updateUnitBranchSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        companyName: formik.values.companyName,
        cnpj: formik.values.cnpj,
        dateOfBirth: formik.values.dateOfBirth,
        cellphone: formik.values.cellphone,
        emailCompany: formik.values.emailCompany,
        city: formik.values.city,
        country: formik.values.country,
        district: formik.values.district,
        number: formik.values.number,
        state: formik.values.state,
        street: formik.values.street,
        zipcode: formik.values.zipcode,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await updateUnitBranch(myData, id)
      toggleToast("Sucesso ao atualizar a unidade. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizar a unidade. ", "error")
    }finally{
      setLoading(false)
      //handleClose()
      setTimeout(()=>{
        window.location.reload()
      }, 100);
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
});

  const submit = () => {
    updateUnitBranchSubmit(currentUser && currentUser.unitBranchs && currentUser.unitBranchs.length > 0 ? currentUser.unitBranchs[0].id : "")
  }

  useEffect(() => {
    //getRoles()
    const myDate = {
      id: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].id ? currentUser?.unitBranchs[0].id : null,
      companyName: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].companyName ? currentUser?.unitBranchs[0].companyName : null,
      cnpj: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].cnpj ? currentUser?.unitBranchs[0].cnpj : null, 
      dateOfBirth: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].dateOfBirth ? currentUser?.unitBranchs[0].dateOfBirth : null,
      cellphone: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].cellphone ? currentUser?.unitBranchs[0].cellphone : null,
      emailCompany: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].emailCompany ? currentUser?.unitBranchs[0].emailCompany : null,
      city: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].city? currentUser?.unitBranchs[0].city: null,
      country: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].country? currentUser?.unitBranchs[0].country: null,
      district: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].district? currentUser?.unitBranchs[0].district: null,
      number: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].number? currentUser?.unitBranchs[0].number: null,
      state: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].state? currentUser?.unitBranchs[0].state: null,
      street: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].street? currentUser?.unitBranchs[0].street: null,
      zipcode: currentUser && currentUser.unitBranchs && currentUser?.unitBranchs?.length > 0 && currentUser?.unitBranchs[0].zipcode? currentUser?.unitBranchs[0].zipcode: null,
    }
    formik.setValues(myDate)
  }, []);

  if (loadingRoles) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Dados da Unidade (FILIAL)</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <div>
        <div className='card-body border-top p-9'>
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Foto de Perfil</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    // style={{backgroundImage: `url(${toAbsoluteUrl(data.avatar)})`}}
                  ></div>
                </div>
              </div>
            </div> */}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nome da unidade'
                      defaultValue={formik.values.companyName}
                      onChange={formik.handleChange("companyName")}
                      // {...formik.getFieldProps('fName')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>Necessário informar um nome de usuário</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>CNPJ</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='CNPJ da unidade'
                  defaultValue={formik.values.cnpj}
                  onChange={formik.handleChange("cnpj")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Data da Criação</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Data de criação da unidade'
                  defaultValue={formik.values.dateOfBirth}
                  onChange={formik.handleChange("dateOfBirth")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Telefone</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Telefone da empresa'
                  defaultValue={formik.values.cellphone}
                  onChange={formik.handleChange("cellphone")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>E-mail</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='E-mail da empresa'
                  defaultValue={formik.values.emailCompany}
                  onChange={formik.handleChange("emailCompany")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Endereço</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Telefone da empresa'
                  defaultValue={formik.values.street}
                  onChange={formik.handleChange("street")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Número</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Telefone da empresa'
                  defaultValue={formik.values.number}
                  onChange={formik.handleChange("number")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Bairro</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Telefone da empresa'
                  defaultValue={formik.values.district}
                  onChange={formik.handleChange("district")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Cidade</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Telefone da empresa'
                  defaultValue={formik.values.city}
                  onChange={formik.handleChange("city")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Estado</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Telefone da empresa'
                  defaultValue={formik.values.state}
                  onChange={formik.handleChange("state")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>CEP</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Telefone da empresa'
                  defaultValue={formik.values.zipcode}
                  onChange={formik.handleChange("zipcode")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Tipo de Usuário</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  defaultValue={formik.values.idRole}
                  // {...formik.getFieldProps('country')}
                >
                  {
                    roles.length > 0 && 
                      roles.map((role: any) => {
                        return (<option value={role.id} disabled>{role.slug}</option>)
                      })
                  }      
                </select>
              </div>
            </div> */}

            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Status</label>

              <div className='col-lg-8 fv-row'>
              <a
                  href='#'
                  className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_upgrade_plan'
                >
                  {formik.values.status && formik.values.status ? formik.values.status : "Não identificado"}
                </a>
              </div>
            </div> */}

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button /*type='submit'*/ onClick={submit} className='btn btn-primary' /*disabled={loading}*/>
              {!loading && 'Salvar Unidade'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function toggleToast(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}
