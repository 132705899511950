/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
//import { defaultCreateAppData, ICreateAppData } from '../../../../../_metronic/partials/modals/create-app-stepper/IAppModels'
import { StepModal } from './stepModal'
import { getCredentialOne, postCredentialVerificad, updateCredentialVerificad } from '../../../../services/credentialService'
import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { Loading } from '../../../components/Loadings/loading'


type Props = {
    mark: any
    show: boolean
    id?: string | undefined
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalCreateCredential = ({show, handleClose, mark, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  //const [data, setData] = useState<ICreateAppData>(defaultCreateAppData)
  const [data, setData] = useState<any>({
    username: "",
    senha: "",
    idUnitBranch: ""
  })
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const checkInputs = (): boolean => {
    if (!data.username || !data.senha || !data.idUnitBranch) {
      console.log('erro')
      return false
    }
    return true
  }

  const postCredential = async () => {
    try {
      setLoading(true)
      const myData = {
        username: data.username,
        password: data.senha,
        idUnitBranch: data.idUnitBranch,
        idMark: mark.id
      }
      await postCredentialVerificad(myData)
      toggleToast("Sucesso ao cadastrar credencial. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao cadastrar credencial. ", "error")
    }finally{
      setLoading(false)
      handleClose()
      //window.location.reload()
    }
  }

  const updateCredential = async () => {
    try {
      setLoading(true)
      const myData = {
        username: data.username,
        password: data.senha,
        idUnitBranch: data.idUnitBranch,
        idMark: mark.id
      }
      await updateCredentialVerificad(myData, id ? id : "")
      toggleToast("Sucesso ao atualizar a credencial. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar a credencial. ", "error")
    }finally{
      setLoading(false)
      handleClose()
      //window.location.reload()
    }
  }

  const getCredential = async () => {
    try {
      setLoadingUpdate(true)
      const credential = await getCredentialOne(id ? id : "")
      setData({
        username: credential.username,
        senha: "",
        idUnitBranch: credential.unitBranch.id
      })
    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar credencial. ", "error")
    }finally{
      setLoadingUpdate(false)
      //handleClose()
      //window.location.reload()
    }
  }

  const submit = () => {
    if(id){
      updateCredential()
    }else{
      postCredential()
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    if (!checkInputs()) {
    setHasError(true)
    return
    }
    submit()
  }



  useEffect(() => {
    if(id){
      getCredential()
    }else{
      setData({
        username: "",
        senha: "",
        idUnitBranch: ""
      })
    }
  }, [show]);

  if (loadingUpdate) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
       
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} credencial {" "}</span>
            <span className='text-muted mt-1 fw-semibold fs-5'>( {mark.name} )</span>
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' >
              <StepModal data={data} updateData={updateData} hasError={hasError} />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
                {/*<div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    Previous
                  </button>
                </div>*/}
 
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar" : "Cadastrar"}{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>

                  {/*<button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next Step{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                    </button>*/}
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalCreateCredential}
