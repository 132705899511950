/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { getClientAll } from '../../services/clientService';
import { Loading } from '../components/Loadings/loading';
import useSize from '../../hooks/useSize';
import { AddCompanies } from './components/addCompanies';
import { TableCompanies } from './components/tableCompanies';
//import { MyPortal } from './components/my-portal'

const companiesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CompaniesPage: FC = () => {
  const [myRefresh, setMyRefresh] = useState<boolean>(false);

  useEffect(() => {
    console.log(myRefresh)
  }, [myRefresh])
  return (
    <div className='column g-5 g-xl-8'>
        <AddCompanies myRefresh={myRefresh} setMyRefresh={setMyRefresh}/>
        <TableCompanies myRefresh={myRefresh}/>
    </div>
  )
}

const CompaniesWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={companiesBreadcrumbs}>Gerenciamento de Empresas</PageTitle>
      <CompaniesPage />
    </>
  )
}

export {CompaniesWrapper}


