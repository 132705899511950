import React from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';

type Props = {
    alarms: any[];
};

export function ItemAlarm({alarms} : Props) {
    return (
        <div style={{height: '35rem', overflow: 'auto', padding: '1rem'}}>
            {
                alarms && alarms.length > 0 ? alarms.map((alarm) => {
                    return (<div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
                        <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon-2tx svg-icon-warning me-4'
                        />
        
                        <div className='d-flex flex-stack flex-grow-1'>
                            <div className='fw-bold'>
                                <h4 className='text-gray-800 fw-bolder'>{alarm && alarm.alarmDescription ? alarm.alarmDescription : "Não identificado. "}</h4>
                                <div className='fs-6 text-gray-600'>
                                    <div>
                                        <span className='fs-6 fw-bolder text-gray-600'>USINA: </span>
                                        <span>{alarm && alarm.plantName ? alarm.plantName : "Não identificado. "}, {alarm && alarm.plantId ? alarm.plantId : "Não identificado. "}</span>
                                    </div>
                                    {/* <br /> */}
                                    <div>
                                        <span className='fs-6 fw-bolder text-gray-600'>INVERSOR: </span>
                                        <a className='fw-bolder' href='#'>
                                            {alarm && alarm.inverterSerialNumber ? alarm.inverterSerialNumber : "Não identificado. "}
                                        </a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>)
                }) : 
                    <div className='divNotPlants'>
                        <h3 className='card-title align-items-start flex-column'>
                        <span className='text-muted mt-1 fw-semibold fs-5'>Não há alarmes!</span>
                        </h3>
                    </div>
            }
        </div>
    )
}