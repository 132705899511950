import api from "./api";

export const getPhasesFunnelAll = async(funnel?: string) => {
    const response = await api.get(`phasesFunnel/listAll?funnel=${funnel ? funnel : ""}`);
    return response.data;
};

export const postPhasesFunnelOne = async(body: any) => {
    const response = await api.post(`phasesFunnel/`, body);
    return response.data;
};

export const putPhasesFunnelOne = async(body: any, id: string) => {
    const response = await api.put(`phasesFunnel/${id}`, body);
    return response.data;
};

export const deletePhasesFunnelOne = async(id: string) => {
    const response = await api.delete(`phasesFunnel/${id}`);
    return response.data;
};