import React from 'react';

export function ItemCalendar(eventInfo: any) {
  return (
    <div style={{padding: '0.5rem', whiteSpace: 'pre-line', cursor: 'pointer'}} 
        className={`
        bg-${
            eventInfo.event.extendedProps.status !== null ? 
            eventInfo.event.extendedProps.status === "AGUARDANDO" ? "primary"
            : eventInfo.event.extendedProps.status === "CANCELADO" ? "danger"
            : eventInfo.event.extendedProps.status === "ATRASADO" ? "warning" 
            : eventInfo.event.extendedProps.status === "AGENDADO" || eventInfo.event.extendedProps.status === "PENDENTE" ? "info"
            : eventInfo.event.extendedProps.status === "FINALIZADO" || eventInfo.event.extendedProps.status === "ATENDIDO" ? "success"
            : eventInfo.event.extendedProps.status === "EM ANDAMENTO" ? "warning"
            : "secondary"
            : "secondary" 
        }
        
        `}> 
        <b>{eventInfo.timeText}{" "}{" "}</b>
        <span className='text-white text-hover-secondary' style={{marginRight: '1rem', marginLeft: '0.3rem'}}>
            <span style={{fontWeight: '700'}}>{eventInfo.event.extendedProps.type}</span>
            {" "}-{" "}
            {eventInfo.event.title} 
        </span>

        <span 
            className={
            ` badge badge-light-${
                eventInfo.event.extendedProps.status !== null ? 
                eventInfo.event.extendedProps.status === "AGUARDANDO" ? "primary"
                : eventInfo.event.extendedProps.status === "CANCELADO" ? "danger"
                : eventInfo.event.extendedProps.status === "ATRASADO" ? "warning" 
                : eventInfo.event.extendedProps.status === "AGENDADO" || eventInfo.event.extendedProps.status === "PENDENTE" ? "info"
                : eventInfo.event.extendedProps.status === "FINALIZADO" || eventInfo.event.extendedProps.status === "ATENDIDO" ? "success"
                : eventInfo.event.extendedProps.status === "EM ANDAMENTO" ? "warning"
                : "secondary"
                : "secondary"
            
            } fs-9 fw-semibold`
            }
        >
            {eventInfo.event.extendedProps.status}
        </span>
        {/* <span>{eventInfo.event.extendedProps.plant}</span> */}
    </div>
  );
}