/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { getClientAll } from '../../services/clientService';
import { Loading } from '../components/Loadings/loading';
import { TableClients } from './components/TableClients';
//import { MyPortal } from './components/my-portal'

const clientsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClientsPage: FC = () => {
    const [clients, setClients] = useState<any>([]);
    const [clientsLoading, setclientsLoading] = useState<any>(true);

    const getClients = async () => {
        try {
          setclientsLoading(true)
          const myClient = await getClientAll()
          setClients(myClient)

        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar os clientes!", "error")
        }finally{
          setclientsLoading(false)
        }
    }

    const refresh = () => {
      getClients()
    }

    useEffect(() => {
      getClients()
    }, []);


  return (
    <div className='column g-5 g-xl-8'>
        {clientsLoading ? 
        (
            <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
                <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
            </div>
        )
        :
        (
            // 
            <TableClients
              className='card-xl-stretch mb-xl-8'
              clients={clients}
              refresh={refresh} 
            />
        )
    }
        
    </div>
  )
}

const ClientsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={clientsBreadcrumbs}>Gerenciamento de Clientes</PageTitle>
      <ClientsPage />
    </>
  )
}

export {ClientsWrapper}
function toggleToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}

