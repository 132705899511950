/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'


import {KTSVG} from '../../../../../_metronic/helpers'


import { StepperComponent } from '../../../../../_metronic/assets/ts/components'

import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { StepModal } from './components/stepModal'
import { postTypeServiceOne, putTypeServiceAll } from '../../../../services/typeServices'
import { postProductOne, putProductAll } from '../../../../services/productsService'

type DataForm = {
  description: string | null,
  name: string | null,
  preco: number | null,
  qtd: number | null,
  idsUnitBranch: string[] | []
}

type Props = {
    id?: string;
    data?: DataForm;
    show: boolean
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  description: Yup.string().required("Informe um nome para a filial"),
  name: Yup.string(),
  preco: Yup.number(),
  qtd: Yup.number(),
  idsUnitBranch: Yup.array()
  .of(Yup.string())
  .notRequired()
  .default([]),
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddUpdateProduct = ({show, handleClose, data, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [initialValues, setInitialValues] = useState<DataForm>({
    description: null,
    name: null,
    preco: 0,
    qtd: 0,
    idsUnitBranch: []
  })
  const [hasError, setHasError] = useState(false)
  //const [myError, MyError] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
  }

  const checkInputs = (): boolean => {
    if (!formik.values.description 
        || formik.values.idsUnitBranch.length < 0) {
      return false
    }
    return true
  }

  const closed = () => {
    formik.setValues(initialValues)
    handleClose()
  }

  const postProdutSubmit = async () => {
    try {
      setLoading(true)
      const myData = {
        description: formik.values.description,
        name: formik.values.name ? formik.values.name :  null,
        preco: formik.values.preco ? formik.values.preco : 0,
        qtd: formik.values.qtd ? formik.values.qtd :  0,
        idsUnitBranchs: formik.values.idsUnitBranch
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });

      await postProductOne(myData)
      toggleToast("Sucesso ao cadastrar o produto. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao cadastrar o produto. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
      // setTimeout(()=>{
      //   window.location.reload()
      // }, 2000);
    }
  }

  const updateProductSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        description: formik.values.description,
        name: formik.values.name ? formik.values.name :  null,
        preco: formik.values.preco ? formik.values.preco : 0,
        qtd: formik.values.qtd ? formik.values.qtd :  0,
        idsUnitBranchs: formik.values.idsUnitBranch
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await putProductAll(myData, id)
      toggleToast("Sucesso ao atualizar o produto. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizar o produto. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
      // setTimeout(()=>{
      //   window.location.reload()
      // }, 3000);
    }
  }

  const submit = () => {
    if(id){
      updateProductSubmit(id)
    }else{
      postProdutSubmit()
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    if (!checkInputs()) {
    setHasError(true)
    return
    }
    submit()
    
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(data){
      const myObject = {
        description: data.description ? data.description :  null,
        name: data.name ? data.name :  null,
        preco: data.preco ? data.preco : 0,
        qtd: data.qtd ? data.qtd :  0,
        idsUnitBranch: data.idsUnitBranch ? data.idsUnitBranch : []
      }
      formik.setValues(myObject)
    }else{
      formik.setValues(initialValues)
    }
  }, [show])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Produto {" "}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' >
              <StepModal data={initialValues} updateData={updateData} hasError={hasError} formik={formik} />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
                {/*<div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    Previous
                  </button>
                </div>*/}
 
                <div style={{display: 'flex', gap: '1rem'}}>
                  {
                    // <div className='fv-plugins-message-container'>
                    //   <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    //     o CNPJ é obrigatório!
                    //   </div>
                    // </div>
                  }
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar" : "Cadastrar" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                  {/*<button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next Step{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                    </button>*/}
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddUpdateProduct}
