import React from 'react';


export function NotImported({...rest}) {
  return (
    <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <span {...rest}>Não informado</span>
        <span><i style={{marginLeft: '0.5rem'}} className="bi bi-exclamation-circle-fill text-danger"></i></span>
    </h3>
  );
}