/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'


import {KTSVG} from '../../../../../_metronic/helpers'


import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
//import { defaultCreateAppData, ICreateAppData } from '../../../../../_metronic/partials/modals/create-app-stepper/IAppModels'

//import { postCredentialVerificad } from '../../../../services/credentialService'
import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { useFormik } from 'formik'
import * as Yup from "yup";
//import { getCompanyAll } from '../../../../services/companyService'
//import { postUnitBranch, updateUnitBranch } from '../../../../services/unitBranchService'
import { StepModal } from './components/stepModal'
//import { postClient, updateClient } from '../../../../services/clientService'
import { postUnitConsumerAll, updateUnitConsumerAll } from '../../../../services/unitConsumerService'

type DataForm = {
  name: string | null,
  contrato: string | null,
  username: string | null,
  password: string | null,
  state: string | null,
  idPlant: string | null,
  idConcessionarie: string | null,
  energia_faturada_padrao: number | null,
  energia_registrada_padrao: number | null,
  leitura_anterior_padrao: number | null,
  leitura_atual_padrao: number | null,
  tarifa_padrao: number | null,
  valor_faturado_padrao: number | null,
  dia_padrão: number | null,
  idUnitBranch?: string | null,
}

type Props = {
    id?: string;
    data?: DataForm;
    show: boolean
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Informe um nome para unidade consumidora"),
  contrato: Yup.string().required("Informe o número do contrato."),
  username: Yup.string().required("Informe o username."),
  password: Yup.string().required("Informe a senha."),
  state: Yup.string(),

  energia_faturada_padrao: Yup.number(),
  energia_registrada_padrao: Yup.number(),
  leitura_anterior_padrao: Yup.number(),
  leitura_atual_padrao: Yup.number(),
  tarifa_padrao: Yup.number(),
  valor_faturado_padrao: Yup.number(),
  dia_padrão: Yup.number(),

  idUnitBranch: Yup.string(),
  idPlant: Yup.string().required("Informe qual usina a unidade está vinculada"),
  idConcessionarie: Yup.string().required("Informe qual concessionária a unidade está vinculada")
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddUpdateUnitConsumer = ({show, handleClose, data, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [initialValues, setInitialValues] = useState<DataForm>({
    name:  null,
    contrato: null,
    username: null,
    password: null,
    state: null,
    idPlant: null,
    idConcessionarie: null,
    idUnitBranch: null,

    energia_faturada_padrao: null,
    energia_registrada_padrao: null,
    leitura_anterior_padrao: null,
    leitura_atual_padrao: null,
    tarifa_padrao: null,
    valor_faturado_padrao: null,
    dia_padrão: null,
  })
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
  }

  const checkInputs = (): boolean => {
    if (!formik.values.name 
        || !formik.values.contrato 
        || !formik.values.idPlant
        || !formik.values.idConcessionarie
        ) {
      return false
    }
    return true
  }

  const closed = () => {
    formik.setValues(initialValues)
    handleClose()
  }

  const postUnitConsumerSubmit = async () => {
    try {
      setLoading(true)
      const myData = {
        name: formik.values.name,
        contrato: formik.values.contrato,
        username: formik.values.username,
        password: formik.values.password,
        state: formik.values.state,
        idConcessionarie: formik.values.idConcessionarie,
        idPlant: formik.values.idPlant,

        energia_faturada_padrao: formik.values.energia_faturada_padrao,
        energia_registrada_padrao: formik.values.energia_registrada_padrao,
        leitura_anterior_padrao: formik.values.leitura_anterior_padrao,
        leitura_atual_padrao: formik.values.leitura_atual_padrao,
        tarifa_padrao: formik.values.tarifa_padrao,
        valor_faturado_padrao: formik.values.valor_faturado_padrao,
        dia_padrão: formik.values.dia_padrão,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });

      await postUnitConsumerAll(myData)
      toggleToast("Sucesso ao cadastrar a unidade consumidora. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao cadastrar o cliente. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
      // setTimeout(()=>{
      //   window.location.reload()
      // }, 2000);
    }
  }

  const updateUnitConsumerSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        name: formik.values.name,
        contrato: formik.values.contrato,
        username: formik.values.username,
        password: formik.values.password,
        state: formik.values.state,
        idConcessionarie: formik.values.idConcessionarie,
        idPlant: formik.values.idPlant,

        energia_faturada_padrao: formik.values.energia_faturada_padrao,
        energia_registrada_padrao: formik.values.energia_registrada_padrao,
        leitura_anterior_padrao: formik.values.leitura_anterior_padrao,
        leitura_atual_padrao: formik.values.leitura_atual_padrao,
        tarifa_padrao: formik.values.tarifa_padrao,
        valor_faturado_padrao: formik.values.valor_faturado_padrao,
        dia_padrão: formik.values.dia_padrão,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await updateUnitConsumerAll(myData, id)
      toggleToast("Sucesso ao atualizar a unidade consumidora. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizar a unidade consumidora. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
      // setTimeout(()=>{
      //   window.location.reload()
      // }, 3000);
    }
  }

  const submit = () => {
    if(id){
      updateUnitConsumerSubmit(id)
    }else{
      postUnitConsumerSubmit()
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    if (!checkInputs()) {
    setHasError(true)
    return
    }
    submit()
    
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(data){
      const myObject = {
        contrato: data.contrato ? data.contrato :  null,
        name: data.name ? data.name : null,
        username: data.username ? data.username : null,
        password: data.password ? data.password : null,
        state: data.state ? data.state : null,
        idPlant: data.idPlant ? data.idPlant : null,
        idConcessionarie: data.idConcessionarie ? data.idConcessionarie : null,
        energia_faturada_padrao: data.energia_faturada_padrao,
        energia_registrada_padrao: data.energia_registrada_padrao,
        leitura_anterior_padrao: data.leitura_anterior_padrao,
        leitura_atual_padrao: data.leitura_atual_padrao,
        tarifa_padrao: data.tarifa_padrao,
        valor_faturado_padrao: data.valor_faturado_padrao,
        dia_padrão: data.dia_padrão,
      }
      formik.setValues(myObject)
    }else{
      formik.setValues(initialValues)
    }
  }, [show])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Unidade Consumidora {" "}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' >
              <StepModal 
                data={initialValues} 
                updateData={updateData} 
                hasError={hasError} 
                formik={formik} 
                id={id}
              />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
                {/*<div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    Previous
                  </button>
                </div>*/}
 
                <div style={{display: 'flex', gap: '1rem'}}>
                  {
                    // <div className='fv-plugins-message-container'>
                    //   <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    //     o CNPJ é obrigatório!
                    //   </div>
                    // </div>
                  }
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar" : "Cadastrar" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                  {/*<button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next Step{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                    </button>*/}
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddUpdateUnitConsumer}
