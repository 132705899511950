/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { StepModal } from './components/stepModal'
import { postClient, updateClient } from '../../../../services/clientService'
import { postCollaborator, updateCollaborator } from '../../../../services/collaboratorsService'

type DataForm = {
  email: string | null,
  name: string | null,
  cpf: string | null,
  telephone: string | null,
  idUser: string | null,
  idUnitBranch: string | null,
  idsPlants: string[] | []
}

type Props = {
    id?: string;
    data?: DataForm;
    show: boolean
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Informe um e-mail. "),
  name: Yup.string().required("Informe um nome para a filial"),
  cpf: Yup.string(),
  telephone: Yup.string(),
  idUser: Yup.string(),
  idUnitBranch: Yup.string(),
  idsPlants: Yup.array()
  .of(Yup.string())
  .notRequired()
  .default([]),
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddUpdateCollaborators = ({show, handleClose, data, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [initialValues, setInitialValues] = useState<DataForm>({
    email: null,
    name:  null,
    cpf:  null,
    telephone:  null,
    idUser:  null,
    idUnitBranch: null,
    idsPlants: []
  })
  const [hasError, setHasError] = useState(false)
  const [myError, MyError] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
  }

  const checkInputs = (): boolean => {
    if (!formik.values.name 
        || !formik.values.cpf 
        || !formik.values.email 
        || !formik.values.idUnitBranch) {
      return false
    }
    return true
  }

  const closed = () => {
    formik.setValues(initialValues)
    handleClose()
  }

  const postCollaboratorSubmit = async () => {
    try {
      setLoading(true)
      const myData = {
        email: formik.values.email,
        name: formik.values.name,
        cpf: formik.values.cpf,
        telephone: formik.values.telephone,
        idUser: formik.values.idUser,
        idsUnitBranchs: [formik.values.idUnitBranch],
        idsPlants: formik.values.idsPlants
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });

      await postCollaborator(myData)
      toggleToast("Sucesso ao cadastrar o colaborador. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao cadastrar o colaborador. ", "error")
    }finally{
      setLoading(false)
      closed()
    }
  }

  const updateCollaboratorSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        email: formik.values.email,
        name: formik.values.name,
        cpf: formik.values.cpf,
        telephone: formik.values.telephone,
        idUser: formik.values.idUser,
        idsUnitBranchs: formik.values.idUnitBranch,
        idsPlants: formik.values.idsPlants
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await updateCollaborator(myData, id)
      toggleToast("Sucesso ao atualizar o colaborador. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao atualizar o colaborador. ", "error")
    }finally{
      setLoading(false)
      closed()
    }
  }

  const submit = () => {
    if(id){
      updateCollaboratorSubmit(id)
    }else{
      postCollaboratorSubmit()
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    if (!checkInputs()) {
    setHasError(true)
    return
    }
    submit()
    
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(data){
      const myObject = {
        email: data.email ? data.email :  null,
        name: data.name ? data.name : null,
        cpf: data.cpf ? data.cpf : null,
        telephone: data.telephone ? data.telephone : null,
        idUser: data.idUser ? data.idUser : null,
        idUnitBranch: data.idUnitBranch ? data.idUnitBranch : null,
        idsPlants: data.idsPlants ? data.idsPlants : []
      }
      formik.setValues(myObject)
    }else{
      formik.setValues(initialValues)
    }
  }, [show])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Cliente {" "}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            <form noValidate id='kt_modal_create_app_form' >
              <StepModal data={initialValues} updateData={updateData} hasError={hasError} formik={formik} />

              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
 
                <div style={{display: 'flex', gap: '1rem'}}>
                
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar" : "Cadastrar" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddUpdateCollaborators}
