/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import './style.css'

type Props = {
  className: string
  charts: any
}

const LineGraph: React.FC<Props> = ({className, charts}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [listDates, setListDates] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [chartsValues, setChartsValues] = useState({
    metaDates: [],
    valores: []
  });

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartsValues.valores, chartsValues.metaDates))
    if (chart) {
      chart.render()
    }
    return chart
  }

  const handleChartDeploy = () => {
    if(Object.keys(charts).length  > 0){
      if(selectedValue){
        setChartsValues({
          metaDates: charts[selectedValue]["horarios"],
          valores: charts[selectedValue]["valores"]
        })
      }   
    }
  }
  
  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

        
  useEffect(() => {
    if(charts){
      const string_date : string = Object.keys(charts)[0]
      setSelectedValue(string_date)
      const list = Object.entries(charts);
      setListDates(list)
    }
  }, [charts])
         
  useEffect(() => {
    handleChartDeploy()
  }, [selectedValue])

  useEffect(() => {
    const chart = refreshMode()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartsValues])

  if (!charts) {
    return <h1>Carrengando...</h1>;
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-1'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{selectedValue}</span>
        </h3>
        <div className='card-toolbar' data-kt-buttons='true'>
          <div className='d-flex align-items-center my-2'>
            <div className='w-130px me-5'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-white form-select-sm'
                value={selectedValue}
                onChange={handleSelectChange}
              >
                {
                  listDates.map((chart: any) => {
                    return (
                      <option value={chart[0]}>{chart[0]}</option>
                    );
                  })
                }
              </select>
            </div>
          </div>
        </div>
      </div>
      {/* begin::Body */}
      <div className='card-body' style={{overflowX: "auto"}}>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '150px', width: '100%'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {LineGraph}

function getChartOptions(height: number, valores: any, metaDates: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const lightColor = getCSSVariableValue('--bs-primary-light')

  return {
    series: [
      {
        name: 'Energia Gerada',
        data: valores,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: metaDates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      offsetY: 10,
      offsetX: 10,

      labels: {
        rotate: 15,
        style: { 
          colors: labelColor,
          fontSize: '12px',
          cssClass: 'lineGraph'
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: [
      {
        min: 0,
        seriesName: 'Energia Gerada',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: baseColor
        },
        labels: {
          style: {
            colors: baseColor,
          }
        },
        title: {
          text: "Geração (KW)",
          style: {
            color: baseColor,
          }
        },
        tooltip: {
          enabled: true
        }
      },
    ],
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return 'KW ' + val
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
