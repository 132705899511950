/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { searchSubstitut } from '../../../../utils/search';
import { SearchInput } from '../../../components/SearchInput';
//import { ModalCreateCredential } from '../../../portals/components/modalCreateCredential';
import { ModalAddUnitBranch } from '../ModalAddUnitBranch';
import { ModalDeleteUnitBranch } from '../ModalDeleteUnitBranch';
import { ModalDetailsUnitBranch } from '../ModalDetailsUnitBranch';
import { TableBody } from './TableBody';
import { useAuth } from '../../../../modules/auth';


type Props = {
  className: string;
  unitBranchs: any[]
  refresh: any
}

const TableUnitBranchs: React.FC<Props> = ({className, unitBranchs, refresh}) => {
  
  const [mySearch, setMySearch] = useState<any[]>([]);
  const [control, setControl] = useState(false);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [myUnitBranch, setMyUnitBranch] = useState<any | null>(null);
  const [largura, setLargura] = useState(window.innerWidth);
  const user = useAuth()

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const search_unitBranchs = mySearch.length > 0 ? 
            searchSubstitut(unitBranchs, mySearch)
          : []
          setMySearch(search_unitBranchs)
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitBranchs]);

  
  return (
    <div className={`card ${className}`} style={{height: largura > 1200 ? '72vh' : '68vh', paddingBottom: '1rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
       {/* <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Lista de unidades ( Filiais )</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{unitBranchs.length} Unidade(s)</span>
        </h3>*/}
        <div 
          style={{
            display: 'flex', 
            flexWrap: 'wrap',
            gap: '1rem', 
            alignItems: 'center', 
            justifyContent: "space-between", 
            width: '100%'
          }}>
          <SearchInput 
            className='form-control form-control-solid ps-14 bg-body'
            placeholder='Buscar Unidade Filial'
            myDate={unitBranchs}
            setMySearch={setMySearch}
            control={control}
            style={{width: largura < 1200 ? "100%" : "300px"}}
            setControl={setControl}
            searchParameter="companyName"
          />

          {user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
            && user.currentUser.companies[0].planos === "PREMIUM" && (
            <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click para adicionar uma unidade filial'
                style={largura < 1200 ? {width: '100%', display: 'flex', justifyContent: 'end'} : {}}
              >
                <a
                  onClick={() =>{setShowCreateAppModal(true)}}
                  href='#'
                  className='btn btn-sm btn-light-primary'
                  //data-bs-target='#kt_modal_create_unitBranch'

                >
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  Nova Unidade Filial
                </a>
              </div>)}
        </div>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3' style={largura < 1200 ? {height: '20rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='text-muted text-start fw-bolder fs-7 text-uppercase gs-0'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-250px'>Nome</th>
                <th className='min-w-140px'>CNPJ</th>
                <th className='min-w-150px'>Endereço</th>
                <th className='min-w-140px'>Responsável</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
              {
                control === false ? (
                  <TableBody 
                    unitBranchs={unitBranchs} 
                    setMyUnitBranch={setMyUnitBranch}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
                : (
                  <TableBody 
                    unitBranchs={mySearch} 
                    setMyUnitBranch={setMyUnitBranch}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
              }
              
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
        <ModalAddUnitBranch 
          show={showCreateAppModal}
          id={myUnitBranch ? myUnitBranch.id : undefined} 
          data={myUnitBranch ? myUnitBranch : undefined}
          handleClose={() => {
            setMyUnitBranch(null)
            setShowCreateAppModal(false)
            refresh()
          }} 
        />
        <ModalDeleteUnitBranch 
          show={showCreateAppModalDelete}
          data={myUnitBranch}
          id={myUnitBranch ? myUnitBranch.id : undefined} 
          handleClose={() => {
            setMyUnitBranch(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />

        <ModalDetailsUnitBranch 
          show={showCreateAppModalDetails}
          id={myUnitBranch ? myUnitBranch.id : undefined} 
          handleClose={() => {
            setMyUnitBranch(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        />
      {/* begin::Body */}
    </div>
  )
}

export {TableUnitBranchs}
