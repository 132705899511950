import api from "./api";

export const getMark = async(active: boolean | null) => {
    
    if(active === null){
        const response = await api.get(`mark/listAll`);
        return response.data;
    }else{
        const response = await api.get(`mark/listAll?active=${active}`);
        return response.data;
    }
    
    
};

export const getQtdMark = async() => {
    const response = await api.get(`mark/listQtd`);
    return response.data;
};