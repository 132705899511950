/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { DateComplete } from '../../../../../utils/date';

type Props = {
    collaborators: any[];
    setMyCollaborator: any;
    setShowCreateAppModal: any;
    setShowCreateAppModalDelete: any
    setShowCreateAppModalDetails: any
}

export function TableBody({
    collaborators, 
    setMyCollaborator,
    setShowCreateAppModal,
    setShowCreateAppModalDelete, 
    setShowCreateAppModalDetails
}: Props) {

    const [largura, setLargura] = useState(window.innerWidth);

    const handleButtonClickDelete = (event, collaborator) => {
        event.stopPropagation();
        //unitBranch.idUser = unitBranch.users.length > 0 ? unitBranch.users[0].id : null; 
        setMyCollaborator(collaborator)
        setShowCreateAppModalDelete(true)
    };

    const handleButtonClickUpdate = (event: any, collaborator: any) => {
        event.stopPropagation();
        collaborator.idUser = collaborator.user ? collaborator.user.id : null; 
        collaborator.idUnitBranch = collaborator.unitBranchs && collaborator.unitBranchs.length > 0 ? collaborator.unitBranchs[0].id : null;
        collaborator.idsPlants = collaborator.plants && collaborator.plants.length > 0 ? collaborator.plants.map((plant: any) => plant.id) : null;
        setMyCollaborator(collaborator)
        setShowCreateAppModal(true)
    };


    const atualizarTamanhoDaTela = () => {
        setLargura(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', atualizarTamanhoDaTela);
        return () => {
          window.removeEventListener('resize', atualizarTamanhoDaTela);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <tbody>
        {collaborators && collaborators.length !== 0 ?   
            collaborators.map((collaborators: any) => {
                return (
                    <tr 
                        onClick={() =>{
                            setMyCollaborator(collaborators)
                            setShowCreateAppModalDetails(true)
                        }}
                    >
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                                <div className='symbol symbol-45px me-5'>
                                    < i className = "bi bi-hammer fs-3hx " > </ i > 
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                        {collaborators.name ? collaborators.name : "Não encontrado. "}
                                    </a>
                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        {collaborators.created_at ? DateComplete(new Date(collaborators.created_at)) : "Não encontrado."}
                                    </span>
                                </div>
                            </div>
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {collaborators.cpf ? collaborators.cpf : "Não encntrado. "}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                CPF do cliente
                            </span>
                        </td>

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {collaborators.email ? collaborators.email : "Nao encontrado"}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                E-mail de contato
                            </span>
                        </td>

                        <td>
                            {collaborators.user ? (
                            <div>
                                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {collaborators.user.name}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {collaborators.user.email}
                                </span>
                            </div>
                            )
                            :
                            (
                                <div>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Não encontrado.
                                </span>
                                </div>
                            )
                        }
                            
                        </td>
                        

                        <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                            {/* <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='svg-icon-3'
                                />
                            </a> */}
                            <a
                                onClick={(event: any) =>{
                                    handleButtonClickUpdate(event, collaborators)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </a>
                            <a
                                onClick={(event: any) => {
                                    handleButtonClickDelete(event, collaborators)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                                />
                            </a>
                            </div>
                        </td>
                    </tr>
                )
            })
        :
        <tr>
            <td colSpan={largura < 1200 ? 2 : 6} style={{textAlign: 'center'}}>
                <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                    <span className='text-muted fw-semibold text-muted d-block fs-4'>
                        Colaboradores não encontrados!
                    </span>
                </div>
            </td>
        </tr>
        }
    </tbody>
  );
}