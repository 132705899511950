/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { SearchInput } from '../../../../../components/SearchInput';
import useSize from '../../../../../../hooks/useSize';
import { getUnitBranchAll } from '../../../../../../services/unitBranchService';
import { useToast } from '../../../../../components/Toats';
//import { Dropdown1 } from '../../../../../../../_metronic/partials/content/dropdown/Dropdown1'


type Props = {
  className: string;
  plants: any[]
  formik: any
}

const ListPlantsLinked: React.FC<Props> = ({className, plants, formik}) => {
  const [control, setControl] = useState(false);
  const [mySearch, setMySearch] = useState<any[]>([]);
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const { toggleToast } = useToast();
  const [loadingUnitBranchs, setLoadingUnitBranchs] = useState<boolean>(false);
  const {width} = useSize()
  
  
  const updateData = (value: string, checked: boolean) => {
    if(checked === true){
      formik.setFieldValue('idPlant', value)
    }else{
      formik.setFieldValue('idPlant', null)
    }
  }

  const getUnitBranchs = async () => {
    try {
        setLoadingUnitBranchs(true)
        const unitBranchs = await getUnitBranchAll()
        setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
        setLoadingUnitBranchs(false)
    }
  }

  useEffect(() => {
    getUnitBranchs()
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <div className={`card ${className}`} style={{paddingBottom: '2rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5' style={{flexDirection: 'column'}}>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Lista de usinas</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Vincular usinas à unidade (consumidora)</span>
        </h3>

        <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
          <SearchInput 
            className={`form-control form-control-solid bg-body ps-14 ${width < 1200 ? "fs-7" : "fs-7"}`}
            placeholder='Buscar pelo Nome'
            myDate={plants}
            setMySearch={setMySearch}
            control={control}
            style={{width: width < 1200 ? "100%" : "200px", height: '3rem'}}
            setControl={setControl}
            searchParameter="name"
          />

          <select
            className='form-select'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            //disabled={loadingSubmit ? true : formik.values.idUnitBranch ? false : true}
            defaultValue={formik.values.idUnitBranch ? formik.values.idUnitBranch : '1'}
            onChange={formik.handleChange("idUnitBranch")}
          >
            <option value={'1'}>Filtrar por Filial</option>
            {unitBranchs && unitBranchs.map((unitBranch: any) => {
                return (
                <option key={unitBranch.id} value={unitBranch.id}>{unitBranch.companyName}</option>
                )
            })}
          </select>
        </div>

        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5' style={{height: '25rem', overflowY: "auto"}}>
        {/* begin::Item */}
        {
          plants && plants.length > 0 ?
          control ?
            mySearch.map((plant: any) =>{
              return (
                <div className='d-flex align-items-center mb-7' style={{justifyContent: 'space-between'}}>
                  {/* begin::Symbol */}
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div className='symbol symbol-50px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
                        </div>
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Text */}
                    <div className='d-flex flex-column'>
                      <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                        {plant.name}
                      </a>
                      <span className='text-muted fw-semibold'>{plant.address ? plant.address : "Não localizado."}</span>
                    </div>
                  </div>
                  <div className='form-check form-check-custom form-check-solid mx-5'>
                    <input 
                      className={`form-check-input ${formik.values.idPlant === plant.id ? 'bg-primary' : 'bg-secondary'}`} 
                      type='checkbox'
                      //checked={plant.imported}
                      checked={formik.values.idPlant === plant.id ? true : false}
                      value={plant.id}
                      onChange={(e) =>{
                        updateData(e.target.value, e.target.checked)
                      }}
                      //onChange={formik.handleChange("idsPlants")}
                    />
                  </div>
                  {/* end::Text */}
                </div>
              )
            })
          :
            plants.map((plant: any) =>{
              return (
                <div className='d-flex align-items-center mb-7' style={{justifyContent: 'space-between'}}>
                  {/* begin::Symbol */}
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div className='symbol symbol-50px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
                        </div>
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Text */}
                    <div className='d-flex flex-column'>
                      <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                        {plant.name}
                      </a>
                      <span className='text-muted fw-semibold'>{plant.address ? plant.address : "Não localizado."}</span>
                    </div>
                  </div>
                  <div className='form-check form-check-custom form-check-solid mx-5'>
                    <input 
                      className={`form-check-input ${formik.values.idPlant === plant.id ? 'bg-primary' : 'bg-secondary'}`} 
                      type='checkbox'
                      //checked={plant.imported}
                      checked={formik.values.idPlant === plant.id ? true : false}
                      value={plant.id}
                      onChange={(e) =>{
                        updateData(e.target.value, e.target.checked)
                      }}
                      //onChange={formik.handleChange("idsPlants")}
                    />
                  </div>
                  {/* end::Text */}
                </div>
              )
            })
          :
          <div className='d-flex align-items-center mb-7' style={{height: '100%', justifyContent: 'center'}}>
            <h3 className='card-title align-items-start flex-column'>
              <span className='text-muted mt-1 fw-semibold fs-7'>Não há usinas vinculadas.</span>
            </h3>
          </div>
        }
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListPlantsLinked}
