/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'

type Props = {
  color?: string
  avatar?: string
  online?: boolean
  name: string
  job: string
  avgEarnings: string
  totalEarnings: string
  users: any
}

const UserResponsableUnitBranch: FC<Props> = ({
  color = '',
  avatar = '',
  online = false,
  name,
  job,
  users,
  avgEarnings,
  totalEarnings,
}) => {
  return (
    <div className='card'>
        <div className='card-header pt-5 pb-5'>
            <h3 className='card-title' style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
              <span className='card-label fw-bold text-dark'>Responsável</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>Usuario vinculado à unidade filial</span>
            </h3>
        </div>
        {users ? 
          <div className='card-body d-flex flex-row p-9' style={{gap: '1rem', alignItems: 'center'}}>
              <div className='mb-5'>
              <div className='symbol symbol-75px symbol-circle'>
                  {color ? (
                  <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>
                      {users ? users.name.charAt(0): "Não identificado."}
                  </span>
                  ) : (
                  <img alt='Pic' src={toAbsoluteUrl(avatar)} />
                  )}
                  {online && (
                  <div className='symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3'></div>
                  )}
              </div>
              </div>

              <div>
                  <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                      {users ? users.name : "Não identificado."}
                  </a>
                  <div className='fw-bold text-gray-400'>{users ? users.email : "Não identificado."}</div>
                  <div className='fw-bold text-gray-400 mb-6'>{users ? users.role.slug : "Não identificado."}</div>
              </div>
          </div> 
          : 
            <div className='card-body'  style={{height: '20rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <h3 className='card-title align-items-start flex-column'>
                <span className='text-muted mt-1 fw-semibold fs-7'>Não há usuário vinculados.</span>
              </h3>
            </div>
          }
    </div>
  )
}

export {UserResponsableUnitBranch}
