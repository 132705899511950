/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { getUnitBranchAll } from '../../services/unitBranchService';
import { Loading } from '../components/Loadings/loading';
import { TableUnitBranchs } from './components/TableUnitBranchs';
//import { MyPortal } from './components/my-portal'

const unitBranchsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UnitBranchsPage: FC = () => {
    const [unitBranchs, setUnitBranchs] = useState<any>([]);
    const [unitBranchLoading, setUnitBranchLoading] = useState<any>(true);

    const getUnitBranchs = async () => {
        try {
          setUnitBranchLoading(true)
          const myUnitsBranchs = await getUnitBranchAll()
          setUnitBranchs(myUnitsBranchs)

        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar as unidades filiais!", "error")
        }finally{
          setUnitBranchLoading(false)
        }
    }

    const refresh = () => {
      getUnitBranchs()
    }

    useEffect(() => {
        getUnitBranchs()
    }, []);


  return (
    <div className='column g-5 g-xl-8 pt-2'>
        {unitBranchLoading ? 
        (
            <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
                <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
            </div>
        )
        :
        (
            <TableUnitBranchs 
                className='card-xl-stretch mb-xl-8'
                unitBranchs={unitBranchs}
                refresh={refresh} 
            />
        )
    }
        
    </div>
  )
}

const UnitBranchsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={unitBranchsBreadcrumbs}>Gerenciamento de Unidades (Filiais)</PageTitle>
      <UnitBranchsPage />
    </>
  )
}

export {UnitBranchsWrapper}
function toggleToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}

