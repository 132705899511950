/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { useToast } from '../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../components/Loadings/loadingSpan';
import { getUsersAllCompany } from '../../../../../../services/usersService';
import { initialLetters } from '../../../../../../utils/initialLetters';
import { putCompanyOne } from '../../../../../../services/companyService';

type Props = {
    id: string;
    data: any;
    show: boolean;
    handleClose: () => void
}


const modalsRoot = document.getElementById('root-modals') || document.body

const ModalDesactiveCompany = ({show, handleClose, id, data}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<any[]>([])

  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }

  const getListUser = async () => {
    try {
      setLoading(true)
    
      const myUsers = await getUsersAllCompany(id)
      setUsers(myUsers)

      console.log(myUsers)
      //toggleToast("Sucesso ao deletar o cliente. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao buscar lista de usuários ", "error")
    }finally{
      setLoading(false)
      //closed()
      // handleClose()
    //   setTimeout(()=>{
    //     window.location.reload()
    //   }, 2000);
    }
  }

  const putCompany = async () => {
    try {
      setLoading(true)
      const myData = {
        status: data && data.status === "ATIVADA" ? "DESATIVADA" : "ATIVADA"
      }
      await putCompanyOne(id, myData)
      toggleToast("Sucesso ao alterar a empresa. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao alterar a empresa.  ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
    //   setTimeout(()=>{
    //     window.location.reload()
    //   }, 2000);
    }
  }

  const submit = () => {
    putCompany()
  }

  useEffect(() => {
    getListUser()
  }, [show])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Desativar Empresa</span>
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>



      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          <div className='flex-row-fluid py-lg-0'>
            <div style={{marginBottom: '3rem'}}>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='text-muted mt-1 fw-semibold fs-5'>Deseja {data && data.status && data.status === "ATIVADA" ? "desativar" : "ativar"} a empresa {data && data.corporateName ? data.corporateName : "Não identificado. "} e todos os usuários?</span>
                </h3>
            </div>
          </div>
        </div>

        <div style={{height: '30rem', overflow: 'auto'}}>
            <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                <tr className='text-muted text-start fw-bolder fs-7 text-uppercase gs-0'>
                    <th className='min-w-150px'>Nome</th>
                    <th className='min-w-150px'>E-mail</th>
                    <th className='min-w-50px'>Tipo</th>
                    <th className='min-w-50px'>Status</th>
                </tr>
                </thead>

                <tbody>
                    {users && users.length > 0 &&
                    users.map((user) => {
                        return (
                            <tr>
                                <td>
                                    <div className='d-flex align-items-center' style={{gap: '0.5rem'}}>
                                        <div className='symbol symbol-30px symbol-circle '>
                                            <span 
                                                className={`symbol-label bg-light-primary text-primary fs-7 fw-bolder`}
                                                data-bs-toggle='tooltip'
                                                title={user.name ? user.name : ``}
                                            >
                                                {user.name ? initialLetters(user.name) : ""}
                                            </span>
                                        </div>
                                        
                                        <div className='d-flex justify-content-start flex-column'>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                                                {user.name ? user.name : "Não encontrado. "}
                                            </a>
                                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                Nome da Empresa
                                            </span> */}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center' style={{gap: '0.5rem'}}>
                                        <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
                                            {user.email ? user.email : "Não identificado"}
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center' style={{gap: '0.5rem'}}>
                                        <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-7'>
                                            {user.role && user.role.slug ? user.role.slug : "Não identificado"}
                                        </a>
                                    </div>
                                </td>
                                <td>
                                  {user.status === "active" && 
                                  <div style={{display: 'flex', justifyContent: 'end'}}>
                                    <KTSVG 
                                        path="media/icons/duotune/arrows/arr016.svg" 
                                        className="svg-icon-success svg-icon-2hx" 
                                    />
                                  </div>
                                }
                                {user.status === "inactive" && 
                                  <div style={{display: 'flex', justifyContent: 'end'}}>
                                    <KTSVG 
                                        path="media/icons/duotune/arrows/arr015.svg" 
                                        className="svg-icon-danger svg-icon-2hx" 
                                    />
                                  </div>
                                }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>

                {/* <TableBody 
                companies={companies}
                setMyCompany={setMyCompany}
                refresh={refresh}
                setShowDeactiveAppModal={setShowDeactiveAppModal}
                /> */}
            </table>
          </div>
      </div>
      <div className='modal-footer'>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <button
                type='button'
                className='btn btn-lg btn-secondary'
                disabled={loading}
                onClick={() => {
                    closed()
                }}
                //data-kt-stepper-action='submit'
            >
                <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className='svg-icon-3 ms-2 me-0'
                />
                <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
                
            </button>

            <button
                type='button'
                className={`btn btn-lg btn-${data && data.status === 'ATIVADA' ? 'danger' : 'success'}`}
                disabled={loading}
                //data-kt-stepper-action='submit'
                onClick={submit}
            >
                {data && data.status === "ATIVADA" ? "Desativar" : "Ativar"}
                {
                    loading ? 
                    <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                    :
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                }
            </button>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalDesactiveCompany}
