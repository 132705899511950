import React, { useEffect, useState } from 'react';
import { StatisticsWidget5 } from '../../../../_metronic/partials/widgets';
import { CardsReports } from '../../../../_metronic/partials/widgets/_new/cards/CardsReports';
import { CardsRequestService } from '../../../../_metronic/partials/widgets/_new/cards/CardsRequestService';
import { Client } from '../../../@types/client';
import { Company } from '../../../@types/companies';
import { Statistics } from '../../../@types/statistics';
import { UnitBranch } from '../../../@types/unitBranch';
import { getStatisticsPlants } from '../../../services/statiscsDateService';
import { Loading } from '../../components/Loadings/loading';
import './styles.css'

type Props = {
  company?: Company | null;
  unitBranch?: UnitBranch | null;
  client?: Client | null;
  userAny?: any;
}

export function StatisticsMenu(props: Props) {
  const [statistics, setStatistics] = useState<Statistics>();
  const [largura, setLargura] = useState(window.innerWidth);
  const [loading, setLoading] = useState<boolean>(false)

  const getStatistics = async () => {
    try {
      setLoading(true)
      const myStatistics = await getStatisticsPlants()
      setStatistics(myStatistics)
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    getStatistics()
    window.addEventListener('resize', atualizarTamanhoDaTela);
    
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, []);

  if (loading) {
    return (
      <div className='divSpinnerStatics'>
        <Loading myClass='spinner-border mySpinnerStatics'/>
      </div>
    );
  }

  return (
    <div
      className={`row g-5 g-xl-8 pt-1 card pb-${largura < 1200 ? '3 bg-transparent' : '7 bg-light'} divGlobalStatistics`}
      style={{
        marginBottom: largura < 1200 ? '' : '1rem',
        marginRight: largura > 1200 ? '1rem' : '',
      }}
      >
        <div
          className={`px-${largura < 1200 ? '5' : '9'} pt-${largura < 1200 ? '3' : '7'} card-rounded w-100 divTitleStatistics bg-primary`} 
          style={{height: largura < 1200 ? '13rem' : '15rem',}}
          
          >
          <div 
            className={`text-dark fw-bold fs-2 mb-2 mt-${largura < 1200 ? '3' : '5'}`}
            style={largura < 1200 ? {
              paddingLeft:'0.7rem' ,
              paddingRight: '0.7rem'
            } : {}}
            >
            
            {largura < 1200 ? 
            <h3>
            {
              props.company
              ? props.company.corporateName
              : props.unitBranch
              ? props.unitBranch.companyName
              : props.client
              ? "Olá, " + props.client.nameResponsible
              : props.userAny
              ? "Olá, " + props.userAny.name
              : "Olá ADM"
            }
          </h3>
            : 
            <h1>
              {
                props.company
                ? props.company.corporateName
                : props.unitBranch
                ? props.unitBranch.companyName
                : props.client
                ? "Olá, " + props.client.nameResponsible
                : props.userAny
                ? "Olá, " + props.userAny.name
                : "Olá ADM"
              }
            </h1>}
          </div>
          {/* <div className={`fw-semibold text-gray-800`}>
            {
              props.company ? 
                <h3 className='card-title align-items-start flex-column'>
                  <span className='text-secondary mt-1 fw-semibold fs-6'><span className='spanTitle'>CNPJ:</span> {formatCNPJ(props.company.cnpj)}</span>
                </h3>
              : props.unitBranch ?
                <h3 className='card-title align-items-start flex-column'>
                  <span className='text-secondary mt-1 fw-semibold fs-6'><span className='spanTitle'>CNPJ:</span> {formatCNPJ(props.unitBranch.cnpj)}</span>
                </h3>
              : props.client ? 
                <h3 className='card-title align-items-start flex-column'>
                  <span className='text-secondary mt-1 fw-semibold fs-6'><span className='spanTitle'>CPF:</span> {formatCPF(props.client.cpfResponsible)}</span>
                </h3>
              : props.userAny ?
                <h3 className='card-title align-items-start flex-column'>
                  <span className='text-secondary mt-1 fw-semibold fs-6'><span className='spanTitle'>Tipo:</span> {props.userAny.role}</span>
                </h3> 
              : 
                <h3 className='card-title align-items-start flex-column'>
                  <span className='text-secondary mt-1 fw-semibold fs-6'><span className='spanTitle'>Administrador</span></span>
                </h3> 
            }
          </div> */}
        </div>
        {largura < 1200 ? 
          <div style={{
              marginTop: '-9.5rem',
              paddingRight: '1.4rem',
              paddingLeft: '1.4rem'
            }}
          >
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" style={{padding: '0.5rem'}}>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div style={{display: 'flex', justifyContent: 'space-between', gap: '0.5rem'}}>
                    <StatisticsWidget5
                      className='card-xl-stretch mb-xl-8'
                      svgIcon='/media/icons/duotune/finance/fin010.svg'
                      color='dark'
                      iconColor='light'
                      title={`${statistics && statistics.incomeTotal ? statistics.incomeTotal.toFixed(2) : 0 }`}
                      description='Economia Total (R$)'
                      style={{width: '100%'}}
                      descriptionColor='primary'
                      titleColor='muted'
                      largura={largura}
                    />

                    <StatisticsWidget5
                      className='card-xl-stretch mb-xl-8'
                      svgIcon='/media/icons/duotune/arrows/arr059.svg'
                      color='dark'
                      iconColor='light'
                      descriptionColor='primary'
                      titleColor='muted'
                      style={{width: '100%'}}
                      title={`${statistics && statistics.financial_feedback ? statistics.financial_feedback.toFixed(2) : 0 }`}
                      description='Retorno Financeiro (%)'
                      largura={largura}
                    />

                    <StatisticsWidget5
                      className='card-xl-stretch mb-xl-8'
                      svgIcon='/media/icons/duotune/general/gen032.svg'
                      color='dark'
                      iconColor='light'
                      title={`${statistics && statistics.energyTotal ? statistics.energyTotal.toFixed(2) : 0 }`}
                      description='Energia Total (KWh)'
                      style={{width: '100%'}}
                      descriptionColor='primary'
                      titleColor='muted'
                      largura={largura}
                    />

                    {/* <StatisticsWidget5
                      className='card-xl-stretch mb-xl-8'
                      svgIcon='/media/icons/duotune/general/gen001.svg'
                      color='dark'
                      iconColor='light'
                      title={`${statistics && statistics.plantToday ? statistics.plantToday : 0 }`}
                      description='Total de Usinas'
                      style={{width: '100%'}}
                      descriptionColor='primary'
                      titleColor='muted'
                      largura={largura}
                    /> */}
                  </div>

                </div>

                {/* <div className="carousel-item">
                  <div style={{display: 'flex', justifyContent: 'start', gap: '0.5rem'}}>
                    

                    <StatisticsWidget5
                      className='card-xl-stretch mb-xl-8'
                      svgIcon='/media/icons/duotune/coding/cod001.svg'
                      color='dark'
                      iconColor='light'
                      descriptionColor='primary'
                      titleColor='muted'
                      style={{width: '100%'}}
                      title={`${statistics && statistics.capacityTotal ? statistics.capacityTotal.toFixed(2) : 0 }`}
                      description='Capacidade Total (KWp)'
                      largura={largura}
                    />
                    <div style={{width: '100%'}}></div>
                  </div>
                </div> */}

              </div>
              {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style={{padding: '0rem', width: '1rem'}}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" style={{padding: '0rem', width: '1rem'}}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button> */}
            </div>

            <div style={{marginTop: '0.5rem'}} id="carouselExampleControlsV2" className="carousel carousel-dark slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active" style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                  <div style={{display: 'flex', gap: '0.5rem', justifyContent: 'space-between'}}>
                    <CardsRequestService
                      type='cell' 
                      className={largura > 1200 ? 'pb-3 mb-5 mb-xl-10' : ''}
                      requestServices={statistics && statistics.request_service ? statistics.request_service : {}}
                      largura={largura} 
                    />
                    {largura > 1200 && 
                    <CardsReports 
                      className='pb-3 mb-5 mb-xl-10' 
                      reports={statistics && statistics.report ? statistics.report : {}}  
                      type='cell'
                      largura={largura} 
                    />}
                  </div>
                </div>
              </div>
              {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsV2" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsV2" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button> */}
            </div>
            
          </div>

          :

          <div style={{marginTop: '-9rem', width: '100%'}}>
            <div className='g-5 py-3 px-6' style={{width: '100%', display: 'flex', gap: '1rem'}}>
              <div style={{flex: '1'}}>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/icons/duotune/general/gen001.svg'
                  color='dark'
                  iconColor='light'
                  title={`${statistics && statistics.plantToday ? statistics.plantToday : 0 }`}
                  description='Total de Usinas'
                  descriptionColor='primary'
                  titleColor='muted'
                  style={{width: '100%'}}
                  largura={largura}
                />
              </div>
              <div style={{flex: '1'}}>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8'
                  svgIcon='/media/icons/duotune/coding/cod001.svg'
                  color='dark'
                  iconColor='light'
                  descriptionColor='primary'
                  titleColor='muted'
                  title={`${statistics && statistics.capacityTotal ? statistics.capacityTotal.toFixed(2) : 0 }`}
                  description='Capacidade Total (KWp)'
                  style={{width: '100%'}}
                  largura={largura}
                />
              </div>
              <div style={{flex: '1'}}>
              <StatisticsWidget5
                className='card-xl-stretch mb-xl-8'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='dark'
                iconColor='light'
                descriptionColor='primary'
                titleColor='muted'
                title={`${statistics && statistics.energyTotal ? statistics.energyTotal.toFixed(2) : 0 }`}
                description='Energia Total (KWh)'
                largura={largura}
              />
              </div>
              <div style={{flex: '1'}}>
                <StatisticsWidget5
                  className='card-xl-stretch mb-5 mb-xl-8'
                  svgIcon='/media/icons/duotune/finance/fin010.svg'
                  color='dark'
                  iconColor='light'
                  descriptionColor='primary'
                  titleColor='muted'
                  title={`${statistics && statistics.incomeTotal ? statistics.incomeTotal.toFixed(2) : 0 }`}
                  description='Rendimento Total (R$)'
                  largura={largura}
                />
              </div >
              <div style={{flex: '1'}}>
                <StatisticsWidget5
                  className='card-xl-stretch mb-5 mb-xl-8'
                  svgIcon='/media/icons/duotune/arrows/arr059.svg'
                  color='dark'
                  iconColor='light'
                  descriptionColor='primary'
                  titleColor='muted'
                  title={`${statistics && statistics.financial_feedback ? statistics.financial_feedback.toFixed(2) : 0 }`}
                  description='Retorno Financeiro (%)'
                  largura={largura}
                />
              </div>
            </div>
            <div className='px-6' style={{display: 'flex', gap: '1rem'}}>
              <div className='' style={{flex: '1'}}>
                <CardsRequestService
                  type='desk'  
                  className='pb-3 mb-5 mb-xl-10' 
                  requestServices={statistics && statistics.request_service ? statistics.request_service : {}} 
                />
              </div>
              <div className='' style={{flex: '1'}}>
                <CardsReports 
                  className='pb-3 mb-5 mb-xl-10' 
                  reports={statistics && statistics.report ? statistics.report : {}}
                  type='desk'  
                />
              </div>
            </div>
          </div>

        }
    </div>
  );
}