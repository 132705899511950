import React from 'react';

type Props = {
    style?: any,
    myClass?: string,
}

export function Loading({style, myClass}: Props) {
  return (
    <div className={myClass} role="status" style={style}>
        <span className="visually-hidden">Loading...</span>
    </div>
  );
}