/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { TableUnitConsumers } from './components/TableUnitConsumers';
//import { MyPortal } from './components/my-portal'

const clientsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UnirConsumersPage: FC = () => {

  return (
    <div className='column g-5 g-xl-8'>    
      <TableUnitConsumers
        className='card-xl-stretch mb-xl-8'
      />   
    </div>
  )
}

const UnitConsumerWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={clientsBreadcrumbs}>Gerenciamento de Unidades (Consumidoras)</PageTitle>
      <UnirConsumersPage />
    </>
  )
}

export {UnitConsumerWrapper}
// function toggleToast(arg0: string, arg1: string) {
//     throw new Error('Function not implemented.');
// }

