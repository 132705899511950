/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';

type Props = {
  plants: any;
  updateData: any;
  inputGlobal: any
  plants_list: any;
  loadingSubmit: any
}

export function FormPlantsNotImported({plants, updateData, inputGlobal, plants_list, loadingSubmit}: Props) {
  return (
    <form noValidate>
      {plants.map((plant : any) =>{
        return (
          <div className='d-flex align-items-center mb-7'>
            <div className='symbol symbol-50px me-5'>
              <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
            </div>
            <div className='flex-grow-1'>
              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                {plant.plantName}
              </a>
              <span className='text-muted d-block fw-semibold'>ID Usina: {plant.plantId}</span>
            </div>
            <div className='form-check form-check-custom form-check-solid mx-5'>
              <input 
                className={`form-check-input ${plants_list.includes(Number(plant.plantId)) || plants_list.includes(String(plant.plantId)) ? 'bg-primary' : 'bg-secondary'}`}  
                
                type='checkbox'
                disabled={loadingSubmit ? true : false}
                checked={plants_list.includes(Number(plant.plantId)) || plants_list.includes(String(plant.plantId))}
                value={plant.plantId}
                onChange={(e) =>{
                  if(typeof plant.plantId === 'string'){
                    updateData(e.target.value, e.target.checked)
                  }else{
                    updateData(Number(e.target.value), e.target.checked)
                  }
                  
                }} 
              />
            </div>
          </div>
        )
      })}
    </form>
  );
}