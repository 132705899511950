/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { useToast } from '../../../components/Toats'
import { getUnitBranchOne} from '../../../../services/unitBranchService'
import { ListPlantsDetails } from './components/listPlants'
import { UserResponsableUnitBranch } from './components/userResponsable'

type Props = {
    id: string;
    show: boolean;
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalDetailsUnitBranch = ({show, handleClose, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [unitBranch, setUnitBranch] = useState<any | null>(null)
  const [largura, setLargura] = useState(window.innerWidth);
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }

  const getMyUnitBranch = async () => {
    try {
        setLoading(true)
        const myUnitBranch = await getUnitBranchOne(id ? id : "")
        setUnitBranch(myUnitBranch)
    } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar os gráficos. ", "error")
    }finally{
        setLoading(false)
    }
  }


  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(()=> {
    if(id){
        getMyUnitBranch()
    }

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [show])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Detalhes da Unidade {" - "}{loading ? "Carregando" : unitBranch ? unitBranch.companyName : "Não identificado."}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        
        {!loading 
        ? 
            <div
                ref={stepperRef}
                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
            >
                <div className='flex-row-fluid py-lg-0' style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                    <div style={{display: 'flex', gap: '0.5rem', flexDirection: largura > 1200 ? 'row' : 'column'}}>
                        {largura > 1200 && (<div style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                          <div 
                            className='symbol symbol-100px symbol-fixed position-relative' 
                            style={{
                              height: '110px', 
                              width: '110px', 
                              display: 'flex', 
                              justifyContent: 'center', 
                              alignItems: "center",
                          }}>
                            <img src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm009.svg')} alt='Metornic' />
                            
                          </div>
                        </div>)}
                        
                        <div style={{flex: '1', display: 'flex', gap: '1rem', flexDirection: 'column'}}>
                          <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3' style={{display: "flex", flexDirection: 'column' , gap: '1.5rem', width: '100%'}}>
                            <div style={{display: 'flex', gap: '1.5rem', justifyContent: 'start'}}>
                              
                              <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{unitBranch && unitBranch.companyName ? unitBranch.companyName : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>Nome</div>
                                </div>
                                
                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{unitBranch && unitBranch.dateOfBirth ? unitBranch.dateOfBirth : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>Data de criação</div>
                                </div>

                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{unitBranch && unitBranch.telephone ? unitBranch.telephone : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>Telefone</div>
                                </div>
                              </div>

                              <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>
                                    {unitBranch && unitBranch.emailCompany ? unitBranch.emailCompany : "Não identificado"}
                                  </div>
                                  <div className='fw-bold fs-6 text-gray-400'>E-mail</div>
                                </div>

                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{unitBranch && unitBranch.cnpj ? unitBranch.cnpj : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>CNPJ</div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        

                        <div style={{flex: '1', display: 'flex', gap: '1rem', flexDirection: 'column'}}>
                          {/* <div>
                            <h3 className='card-title' style={{display: 'flex', flexDirection: 'column'}}>
                              <span className='card-label fw-bold fs-4 mb-1'>Localidade</span>
                              <span className='text-muted mt-1 fw-semibold fs-6'>Informações sobre a localidade da filial</span>
                            </h3>
                          </div> */}
                          <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3' style={{display: 'flex', flexDirection: 'column', gap: '1.5rem', height: '100%', width: '100%'}}>
                            <div style={{display: 'flex', gap: '1.5rem', flexWrap: 'wrap', justifyContent: 'start'}}>
                              <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{unitBranch && unitBranch.street ? unitBranch.street : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>Rua</div>
                                </div>

                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{unitBranch && unitBranch.zipcode ? unitBranch.zipcode : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>CEP</div>
                                </div>


                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>
                                    {unitBranch && unitBranch.district ? unitBranch.district : "Não identificado"}
                                  </div>
                                  <div className='fw-bold fs-6 text-gray-400'>Bairro</div>
                                </div>

                              </div>

                              <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{unitBranch && unitBranch.number ? unitBranch.number : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>Número</div>
                                </div>

                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{unitBranch && unitBranch.state ? unitBranch.state : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>Estado</div>
                                </div>

                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{unitBranch && unitBranch.country ? unitBranch.country : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>País</div>
                                </div>
                              </div>
                            </div>
                            



                            {/* <div className='d-flex' style={{flexDirection: "column"}}>
                              <div className='fs-5 fw-bolder'>{unitBranch.site}</div>
                              <div className='fw-bold fs-8 text-gray-400'>Site</div>
                            </div> */}

                          </div>
                        </div>
                    </div>
                    
                    {largura > 1200 ? 
                      (
                        <div style={{display: 'flex', gap: '1rem'}}>
                          <div style={{flex: '1'}}>
                            <ListPlantsDetails className='card-xl-stretch bg-light mb-xl-8' plants={unitBranch ? unitBranch.plants : undefined}/>
                          </div>
                          <div style={{flex: '1'}}>
                            <div>
                              <UserResponsableUnitBranch
                                color='danger'
                                name='Melody Macy'
                                job='Marketing Analytic'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                                online={true}
                                users={unitBranch && unitBranch.users.length > 0  ? unitBranch.users[0] : undefined}
                              />
                            </div>
                            <div></div>
                          </div>
                        </div>
                      )
                    : 
                      (
                        <div>
                          <div className='d-flex overflow-auto h-55px' style={{marginBottom: '1rem'}}>
                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                              <li className='nav-item'>
                                <a
                                  className="nav-link active text-active-primary me-6"
                                  data-bs-toggle="tab"
                                  href="#plant"
                                >
                                  Usinas
                                </a>
                              </li>
                              <li className='nav-item'>
                                <a
                                  className="nav-link text-active-primary me-6"
                                  data-bs-toggle="tab"
                                  href="#user"
                                >
                                  Usuário
                                </a>
                              </li>
                            </ul>
                          </div>

                          <div className="tab-content" id="myTabContent5">
                            <div 
                              className="tab-pane fade active show"
                              id="plant"
                              role="tabpanel"
                            >
                             <ListPlantsDetails className='card-xl-stretch bg-light mb-xl-8' plants={unitBranch ? unitBranch.plants : undefined}/>
                            </div>

                            <div 
                              className="tab-pane fade show divTableReport"
                              id="user"
                              role="tabpanel"
                            >
                              <UserResponsableUnitBranch
                                color='danger'
                                name='Melody Macy'
                                job='Marketing Analytic'
                                avgEarnings='$14,560'
                                totalEarnings='$236,400'
                                online={true}
                                users={unitBranch && unitBranch.users.length > 0  ? unitBranch.users[0] : undefined}
                              />
                            </div>

                          </div>
                        </div>
                        
                      )
                    }
                </div>
            </div>
            : 
            <h1>Carregando...</h1>
        }

      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalDetailsUnitBranch}
