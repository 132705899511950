/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
    valuesProposal: any
    handleCheckBoxValues: any,
    handleCheckBoxValuesImg: any
    myTemplateSelected: any
  }
  
  export function DropdownMenuDadosProposal({valuesProposal, handleCheckBoxValues, handleCheckBoxValuesImg, myTemplateSelected} : Props) {
      return (
        <div className='dropdown-menu w-250px w-md-300px dropdown-menu-end' aria-labelledby="dropdownMenuButton1">
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Dados da Proposta</div>
            </div>
    
            <div className='separator border-gray-200'></div>
            
  
            <div className='px-7 py-5' style={{display: 'flex', flexDirection: 'column', gap: '1.5rem', overflow: 'auto', height: '40rem' , }}>
                <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Empresa</label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='corporateName' checked={valuesProposal.corporateName} 
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Razão Social</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='cnpj' checked={valuesProposal.cnpj}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>CNPJ</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='telephone' checked={valuesProposal.telephone}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Telefone</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='emailCompany' checked={valuesProposal.emailCompany}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>E-mail</span>
                </label>


                <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Endereço</label>

                
                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='street' checked={valuesProposal.street}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Rua</span>
                </label>


                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='district' checked={valuesProposal.district}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Estado</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='number' checked={valuesProposal.number}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Número</span>
                </label>

                
                <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Outros</label>



                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='dateCreate' checked={valuesProposal.dateCreate}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Data de Criação (Proposta)</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='titulo' checked={valuesProposal.titulo}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Titulo</span>
                </label>


                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='status' checked={valuesProposal.status}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Status</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='description' checked={valuesProposal.description}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Descrição</span>
                </label>

                
                <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Cliente</label>

                
                
                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='nameResponsible' checked={valuesProposal.nameResponsible}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Nome do Cliente</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='cpfResponsible' checked={valuesProposal.cpfResponsible}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>CPF do Cliente</span>
                </label>


                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='telephoneClient' checked={valuesProposal.telephoneClient}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Telefone do Cliente</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='cellphoneClient' checked={valuesProposal.cellphoneClient}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Celular do Cliente</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='emailClient' checked={valuesProposal.emailClient}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>E-mail do Cliente</span>
                </label>

                
                <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Usina</label>


                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='namePlant' checked={valuesProposal.namePlant}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Nome da Usina</span>
                </label>


                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='addressPlant' checked={valuesProposal.addressPlant}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Endereço da Usina</span>
                </label>

                
                <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Valores</label>


                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='value_sub_final' checked={valuesProposal.value_sub_final}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Sub Total</span>
                </label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='value_discont' checked={valuesProposal.value_discont}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Valor do Desconto</span>
                </label>


                

                <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Dados da Empresa / Filial</label>

                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='value_final' checked={valuesProposal.value_final}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Valor Final</span>
                </label>

                {/* <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                    <input className='form-check-input' type='checkbox' value='itensProposal' checked={valuesProposal.itensProposal}
                        onChange={(e) => {
                        handleCheckBoxValues(e.target.checked, e.target.value)
                        }}
                    />
                    <span className='form-check-label fs-4'>Itens da Proposta</span>
                </label> */}
            </div>
          
    
        </div>
      )
    }
    