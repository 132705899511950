import api from "./api";

export const postTemplateOSOne = async (body: any) => {
    const response = await api.post(`templateOS/`, body);
    return response.data;
};

export const getTemplateOSAll = async () => {
    const response = await api.get(`templateOS/listAll`,);
    return response.data;
};

export const putTemplateOSAll = async (id: string, body: any) => {
    const response = await api.put(`templateOS/${id}`, body);
    return response.data;
};