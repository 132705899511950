/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
//import PdfViewer from '../components/TesteViewerPDF';
import { TableInvoices } from './components/TableInvoices';
import { useAuth } from '../../modules/auth';
import { TableInvoicesV2 } from './components/TableInvoicesV2';

//import { MyPortal } from './components/my-portal'

const invoicesBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const InvoicesPage: FC = () => {
  const user = useAuth();
  return (

    <div className='column g-5 g-xl-8'>
      {/* <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
          <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
   */}
   {user.currentUser?.role.slug === "CLIENTE" ?
      <TableInvoicesV2
        className='card-xl-stretch mb-xl-8'
        //invoices={invoices}
        //refresh={refresh} 
      />
    : 
      <TableInvoices
        className='card-xl-stretch mb-xl-8'
        //invoices={invoices}
        //refresh={refresh} 
      />
    }
    </div>
  )
}

const InvoicesWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={invoicesBreadcrumbs}>Gerenciamento das Faturas</PageTitle>
      <InvoicesPage />
    </>
  )
}

export {InvoicesWrapper}
// function toggleToast(arg0: string, arg1: string) {
//     throw new Error('Function not implemented.');
// }

