import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StepperComponent } from '../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../_metronic/helpers';
import { CalendarServices } from '../CalendarServices';
import { TableTypeServices } from '../TableTypeServices';
import { TableOrderOfService } from './components/TableOrderOfService';
import { TableReportServices } from './components/TableReportServices';
import { useAuth } from '../../../../modules/auth';
import { TableProducts } from '../TableProducts';

type Props = {
    refreshPage: any
    setRefreshPage: any
}

export function ScreenServices({refreshPage, setRefreshPage} : Props) {
    const { page } = useParams()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [colapse, setColapse] = useState<boolean>(false)
    const [largura, setLargura] = useState(window.innerWidth);
    const {currentUser} = useAuth()
    const [selectorTable, setSelectorTable] = useState<string>("SERVICO")


  const teste = (number: number) => {
      if (!stepper.current) {
          return
      }
      stepper.current.goto(number)
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()

    //setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = () => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      //actions.resetForm()
    }

    //setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    if(stepperRef.current && page){
      teste(Number(page))
    }
  }, [page])


  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [])

  return (
    <div
      ref={stepperRef}
      className={`stepper stepper-pills stepper-column ${largura < 1200 ? "" : "d-flex"}`}
      id='kt_create_account_stepper'
    >
      {/* begin::Aside*/}
      {largura > 1200 ? 
        <div className='d-flex justify-content-start justify-content-xl-start flex-row-auto'>
          {
            colapse === true ?
            (

              // <div style={{paddingRight: '2rem'}} className="py-5">
              //   {/* begin::Nav*/}
              //   <div className='stepper-nav'>
              //     <div className='stepper-item current' 
              //       data-kt-stepper-element='nav' 
              //       onClick={() => {teste(1)}}
              //     >
                    
              //       <div 
              //         className='stepper-wrapper'
              //       >
              //         <div className='stepper-icon w-40px h-40px'>
              //           <i className='stepper-check fas'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/communication/com007.svg'} 
              //                 className={`svg-icon-body stepper-check fas svg-icon-2x ms-n1`} 
              //               />
              //           </i>
              //             <span className='stepper-number'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/communication/com007.svg'} 
              //                 className={`svg-icon-body fas svg-icon-2x ms-n1 stepper-number`} 
              //               />
              //             </span>
              //           </div>
              //         <div className='stepper-label'>
              //           <h3 className='stepper-title'>Solicitações de Serviços</h3>
              //           <div className='stepper-desc fw-semibold'>Lista de pedidos de serviços</div>
              //         </div>
              //       </div>

              //       <div className='stepper-line h-40px'></div>
              //     </div>

              //     <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => {teste(2)}}>
              //       <div className='stepper-wrapper'>
              //         <div className='stepper-icon w-40px h-40px'>
              //           <i className='stepper-check fas'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/text/txt010.svg'} 
              //                 className={`svg-icon-body stepper-check fas svg-icon-2x ms-n1`} 
              //               />
              //           </i>
              //             <span className='stepper-number'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/text/txt010.svg'} 
              //                 className={`svg-icon-body fas svg-icon-2x ms-n1 stepper-number`} 
              //               />
              //             </span>
              //           </div>
              //         <div className='stepper-label'>
              //           <h3 className='stepper-title'>Ordem de Serviço</h3>
              //           <div className='stepper-desc fw-semibold'>Gestão de OS (Ordem de Serviços)</div>
              //         </div>
              //       </div>
              //       {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== "CLIENTE" &&
              //       <div className='stepper-line h-40px'></div>}
              //     </div>

              //     {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== "CLIENTE" &&
              //     <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => {teste(3)}}>
              //       <div className='stepper-wrapper'>
              //         <div className='stepper-icon w-40px h-40px'>
              //           <i className='stepper-check fas'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/general/gen014.svg'} 
              //                 className={`svg-icon-body stepper-check fas svg-icon-2x ms-n1`} 
              //               />
              //           </i>
              //             <span className='stepper-number'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/general/gen014.svg'} 
              //                 className={`svg-icon-body fas svg-icon-2x ms-n1 stepper-number`} 
              //               />
              //             </span>
              //           </div>
              //         <div className='stepper-label'>
              //           <h3 className='stepper-title'>Agenda</h3>
              //           <div className='stepper-desc fw-semibold'>Calendario dos serviços / tarefas</div>
              //         </div>
              //       </div>
              //       {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== "CLIENTE" &&
              //         <div className='stepper-line h-40px'></div>}
              //     </div>}

              //     {/* <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => {teste(4)}}>
                    
              //     </div> */}

              //     {/* begin::Step 4*/}
                  
              //     {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== "CLIENTE" &&
              //     <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => {teste(4)}}>
              //       <div className='stepper-wrapper'>
              //           <div className='stepper-icon w-40px h-40px'>
              //             <i className='stepper-check fas'>
              //                 <KTSVG 
              //                   path={'/media/icons/duotune/general/gen016.svg'} 
              //                   className={`svg-icon-body stepper-check fas svg-icon-2x ms-n1`} 
              //                 />
              //             </i>
              //             <span className='stepper-number'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/general/gen016.svg'} 
              //                 className={`svg-icon-body fas svg-icon-2x ms-n1 stepper-number`} 
              //               />
              //             </span>
              //           </div>
              //         <div className='stepper-label'>
              //           <h3 className='stepper-title'>Catálogo de Serviços</h3>
              //           <div className='stepper-desc fw-semibold'>Cadastro dos serviços prestados pela empresa</div>
              //         </div>
              //       </div>
              //       {/* <div className='stepper-line h-40px'></div> */}
              //     </div>}

              //     {/* <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => {teste(5)}}>
              //       <div className='stepper-wrapper'>
              //         <div className='stepper-icon w-40px h-40px'>
              //           <i className='stepper-check fas fa-check'></i>
              //           <span className='stepper-number'>5</span>
              //         </div>

              //         <div className='stepper-label'>
              //           <h3 className='stepper-title'>Completed</h3>
              //           <div className='stepper-desc fw-semibold'>Woah, we are here</div>
              //         </div>
              //       </div>
              //     </div> */}
                  
              //   </div>
              //   <div>
              //   <span 
              //       className={
              //         `badge fs-7 fw-semibold text-hover-primary`
              //       }
              //       style={{cursor: 'pointer', marginTop: '1rem'}}
              //       onClick={() => {setColapse(!colapse)}}
              //     >
              //       <KTSVG 
              //         path={'/media/icons/duotune/arrows/arr060.svg'} 
              //         className={`svg-icon-body svg-icon-secondary fas svg-icon-2x ms-n1`} 
              //       />
              //     </span>
              //   </div>
              // </div>
              <div className='stepper-nav'>
                <div className='stepper-item current' data-kt-stepper-element='nav'></div>
                <div className='stepper-item current' data-kt-stepper-element='nav'></div>
                <div className='stepper-item current' data-kt-stepper-element='nav'></div>
                <div className='stepper-item current' data-kt-stepper-element='nav'></div>
              </div>
            ):
            (
              // <div style={{paddingRight: '2rem'}} className="py-5">
              //   {/* begin::Nav*/}
              //   <div className='stepper-nav'>
              //     <div className='stepper-item current' 
              //       data-kt-stepper-element='nav' 
              //       onClick={() => {teste(1)}}
              //     >
              //       <div 
              //         className='stepper-wrapper'
              //       >
              //         <div className='stepper-icon w-40px h-40px'>
              //           <i className='stepper-check fas'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/communication/com007.svg'} 
              //                 className={`svg-icon-body stepper-check fas svg-icon-2x ms-n1`} 
              //               />
              //           </i>
              //             <span className='stepper-number'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/communication/com007.svg'} 
              //                 className={`svg-icon-body fas svg-icon-2x ms-n1 stepper-number`} 
              //               />
              //             </span>
              //           </div>
              //       </div>

              //       <div className='stepper-line h-40px'></div>
              //     </div>

              //     <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => {teste(2)}}>
              //       <div className='stepper-wrapper'>
              //         <div className='stepper-icon w-40px h-40px'>
              //           <i className='stepper-check fas'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/text/txt010.svg'} 
              //                 className={`svg-icon-body stepper-check fas svg-icon-2x ms-n1`} 
              //               />
              //           </i>
              //             <span className='stepper-number'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/text/txt010.svg'} 
              //                 className={`svg-icon-body fas svg-icon-2x ms-n1 stepper-number`} 
              //               />
              //             </span>
              //           </div>
              //       </div>
              //       {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== "CLIENTE" &&
              //       <div className='stepper-line h-40px'></div>}
              //     </div>

              //     {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== "CLIENTE" &&
              //     <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => {teste(3)}}>
              //       <div className='stepper-wrapper'>
              //         <div className='stepper-icon w-40px h-40px'>
              //           <i className='stepper-check fas'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/general/gen014.svg'} 
              //                 className={`svg-icon-body stepper-check fas svg-icon-2x ms-n1`} 
              //               />
              //           </i>
              //             <span className='stepper-number'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/general/gen014.svg'} 
              //                 className={`svg-icon-body fas svg-icon-2x ms-n1 stepper-number`} 
              //               />
              //             </span>
              //           </div>
              //       </div>
              //       <div className='stepper-line h-40px'></div>
              //     </div>}

              //     {/* <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => {teste(4)}}>
                    
              //     </div> */}

              //     {/* begin::Step 4*/}
                  
              //     {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== "CLIENTE" &&
              //     <div className='stepper-item' data-kt-stepper-element='nav' onClick={() => {teste(4)}}>
              //       <div className='stepper-wrapper'>
              //       <div className='stepper-icon w-40px h-40px'>
              //           <i className='stepper-check fas'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/general/gen016.svg'} 
              //                 className={`svg-icon-body stepper-check fas svg-icon-2x ms-n1`} 
              //               />
              //           </i>
              //             <span className='stepper-number'>
              //               <KTSVG 
              //                 path={'/media/icons/duotune/general/gen016.svg'} 
              //                 className={`svg-icon-body fas svg-icon-2x ms-n1 stepper-number`} 
              //               />
              //             </span>
              //           </div>
              //       </div>
              //       {/* <div className='stepper-line h-40px'></div> */}
              //     </div>}
              //   </div>
              //     <span 
              //       className={
              //         `badge fs-7 fw-semibold text-hover-primary`
              //       }
              //       style={{cursor: 'pointer', marginTop: '1rem'}}
              //       onClick={() => {setColapse(!colapse)}}
              //     >
              //       <KTSVG 
              //         path={'/media/icons/duotune/arrows/arr069.svg'} 
              //         className={`svg-icon-body svg-icon-secondary fas svg-icon-2x ms-n1`} 
              //       />
              //     </span>
              //   {/* <div>
              //     <button onClick={() => {setColapse(!colapse);}}>a</button>
              //   </div> */}
              // </div>
              <div className='stepper-nav'>
                <div className='stepper-item current' data-kt-stepper-element='nav'></div>
                <div className='stepper-item current' data-kt-stepper-element='nav'></div>
                <div className='stepper-item current' data-kt-stepper-element='nav'></div>
                <div className='stepper-item current' data-kt-stepper-element='nav'></div>
              </div>
            )
          }    
        </div> 
        //<div className='stepper-wrapper'></div>
        :
        // <div style={{display: 'flex', justifyContent: 'end'}}>
        //   <div className='me-5' style={{width: '50%'}}>
        //     <select
        //       name='status'
        //       data-control='select2'
        //       data-hide-search='true'
        //       className='form-select form-select-white form-select-sm stepper-nav'
        //       //value={dataFilter ? dataFilter : "1"}
        //       onChange={(event: any) => {
        //         teste(event.target.value)
        //       }}
        //     >
        //       <option className='stepper-item current' data-kt-stepper-element='nav' value={1}>Lista de Solicitações</option>
        //       <option className='stepper-item current' data-kt-stepper-element='nav' value={2}>Ordem de Serviço</option>
        //       {currentUser && currentUser.role && currentUser.role.slug !== "CLIENTE" && 
        //         <option className='stepper-item current' data-kt-stepper-element='nav' value={3}>Agenda</option>}
        //       {currentUser && currentUser.role && currentUser.role.slug !== "CLIENTE" && 
        //         <option className='stepper-item current' data-kt-stepper-element='nav' value={4}>Catálogo de Serviço</option>}
        //       {/* {
        //         plantsFilter && plantsFilter.length > 0 && plantsFilter.map((plant: any) => {
        //           return (
        //             <option value={plant.id}>{`${plant.plantId} - ${plant.name}`}</option>
        //           );
        //         })
        //       } */}
        //     </select>
        //   </div>
        // </div>
        <div className='stepper-nav'>
          <div className='stepper-item current' data-kt-stepper-element='nav'></div>
          <div className='stepper-item current' data-kt-stepper-element='nav'></div>
          <div className='stepper-item current' data-kt-stepper-element='nav'></div>
          <div className='stepper-item current' data-kt-stepper-element='nav'></div>
        </div>
      }
      

      <div className='d-flex flex-row-fluid rounded' style={{padding: '1rem'}}>
        
        <div className='' id='kt_create_account_form' style={{width: '100%'}}>
            
            <div className='current' data-kt-stepper-element='content'>
              <TableReportServices 
                className={'w-100'}
                refresh={() => {}}
                refreshPage={refreshPage}
                setRefreshPage={setRefreshPage}
              />
            </div>

            <div data-kt-stepper-element='content'>
              <TableOrderOfService 
                className={'w-100'}
                refreshPage={refreshPage}
                setRefreshPage={setRefreshPage}
              />
            </div>

            <div data-kt-stepper-element='content'>
                <CalendarServices 
                  refreshPage={refreshPage}
                  setRefreshPage={setRefreshPage}
                />
            </div>

            <div data-kt-stepper-element='content'>
              <div style={{width: '100%'}}>
                <div style={{}}>
                  <div className="btn-group mb-5" role="group" aria-label="Basic radio toggle button group" 
                    style={{width: '10rem', height: '3rem'}}
                  >
                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" checked={selectorTable === "SERVICO"} onClick={() => {setSelectorTable("SERVICO")}}/>
                    <label className="btn btn-outline-primary" htmlFor="btnradio1" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      Serviços
                    </label>

                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" checked={selectorTable === "PRODUTO"} onClick={() => {setSelectorTable("PRODUTO")}}/>
                    <label className="btn btn-outline-primary" htmlFor="btnradio3" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      Produtos
                    </label>
                  </div>
                </div>

                {selectorTable === "PRODUTO" && 
                (<TableProducts 
                  className={'w-100'}
                  refresh={() => {}}
                  refreshPage={refreshPage}
                  setRefreshPage={setRefreshPage}
                />)}

                {selectorTable === "SERVICO" && 
                (<TableTypeServices 
                  className={'w-100'}
                  refresh={() => {}}
                  refreshPage={refreshPage}
                  setRefreshPage={setRefreshPage}
                />)}
              </div>
            </div>

            {/* <div data-kt-stepper-element='content'>
            <h1>4</h1>
            </div>

            <div data-kt-stepper-element='content'>
            <h1>5</h1>
            </div> */}
{/* 
            <div className='d-flex flex-stack pt-10'>
            <div className='mr-2'>
                <button
                  onClick={prevStep}
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                  data-kt-stepper-action='previous'
                >
                <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className='svg-icon-4 me-1'
                />
                Back
                </button>
            </div>

            <div>
                <button type='submit' className='btn btn-lg btn-primary me-3' onClick={() => {submitStep()}}>
                <span className='indicator-label'>
                    {stepper.current?.currentStepIndex !==
                    stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                    {stepper.current?.currentStepIndex ===
                    stepper.current?.totatStepsNumber! - 1 && 'Submit'}
                    <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                    />
                </span>
                </button>
            </div>
            </div> */}
        </div>
      </div>
    </div>
  )
}