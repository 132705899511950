import api from "./api";

export const getCredential = async(mark: string) => {
    const response = await api.get(`credential/listAll?mark=${mark}`);
    return response.data;
};

export const getCredentialOne = async(credential: string) => {
    const response = await api.get(`credential/${credential}`);
    return response.data;
};

export const updateCredentialVerificad = async(data: any, id: string) => {
    const response = await api.put(`credential/updateVerificad/${id}`, data);
    return response.data;
};

export const postCredentialVerificad = async(data: any) => {
    const response = await api.post(`credential/createVerificad`, data);
    return response.data;
};

export const deleteCredential = async(id: any) => {
    const response = await api.delete(`credential/${id}`,);
    return response.data;
};