import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';
import { useAuth } from '../../../../../../modules/auth';
import { getRoleAll } from '../../../../../../services/roles';
import { Loading } from '../../../../../components/Loadings/loading';
import { useFormik } from 'formik'
import * as Yup from "yup";
import { updateUsersAll } from '../../../../../../services/usersService';
import { putCompanyOne } from '../../../../../../services/companyService';
import { updateUnitBranch } from '../../../../../../services/unitBranchService';
import { updateClient } from '../../../../../../services/clientService';
import useSize from '../../../../../../hooks/useSize';

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  nameResponsible: Yup.string().required("Informe um nome para o cliente"),
  cpfResponsible: Yup.string().required("Informe um CPF para o cliente"),
  cellphone: Yup.string(),
  telephone: Yup.string(),
  email: Yup.string(),
});

export function BodySettingsClient() {
  const { currentUser } = useAuth()
  const [loadingRoles, setLoadingRoles] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<any[]>([]);
  const {width} = useSize()
  
  const [initialValues, setInitialValues] = useState<any>({
    id: null,
    nameResponsible: null,
    cpfResponsible: null,
    cellphone: null,
    telephone: null,
    email: null,
})


  // const getRoles = async () => {
  //   try {
  //     setLoadingRoles(true)
  //     const roles = await getRoleAll()
  //     setRoles(roles)

  //   } catch (error) {
  //     console.log(error)
  //     toggleToast("Erro ao buscar os tipos de usuário. ", "error")

  //   }finally{
  //     setLoadingRoles(false)
  //   }
  // }

  const updateClientSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        nameResponsible: formik.values.nameResponsible,
        cpfResponsible: formik.values.cpfResponsible,
        cellphone: formik.values.cellphone,
        telephone: formik.values.telephone,
        email: formik.values.email,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      const myUser = await updateClient(myData, id)
      toggleToast("Sucesso ao atualizar o cliente. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar o cliente. ", "error")
    }finally{
      setLoading(false)
      //handleClose()
      setTimeout(()=>{
        window.location.reload()
      }, 100);
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
});

  const submit = () => {
    updateClientSubmit(currentUser && currentUser.client ? currentUser.client.id : "")
  }

  useEffect(() => {
    //getRoles()
    const myDate = {
      id: currentUser && currentUser.client && currentUser?.client.id ? currentUser?.client.id : null,
      nameResponsible: currentUser && currentUser.client && currentUser?.client.nameResponsible ? currentUser?.client.nameResponsible : null,
      cpfResponsible: currentUser && currentUser.client && currentUser?.client.cpfResponsible ? currentUser?.client.cpfResponsible : null, 
      telephone: currentUser && currentUser.client && currentUser?.client.telephone ? currentUser?.client.telephone : null,
      cellphone: currentUser && currentUser.client && currentUser?.client.cellphone ? currentUser?.client.cellphone : null,
      email: currentUser && currentUser.client && currentUser?.client.email ? currentUser?.client.email : null,
    }
    formik.setValues(myDate)
  }, []);

  if (loadingRoles) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  
  return (
    <div className={`card mb-${width < 1200 ? '20' : '5'} mb-xl-10`}>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Dados do Cliente</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <div>
        <div className='card-body border-top p-9'>
            {/* <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Foto de Perfil</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    // style={{backgroundImage: `url(${toAbsoluteUrl(data.avatar)})`}}
                  ></div>
                </div>
              </div>
            </div> */}

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nome do cliente'
                      defaultValue={formik.values.nameResponsible}
                      onChange={formik.handleChange("nameResponsible")}
                      // {...formik.getFieldProps('fName')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>Necessário informar um nome do cliente</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>CPF</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='CPF da unidade'
                  defaultValue={formik.values.cpfResponsible}
                  onChange={formik.handleChange("cpfResponsible")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Telefone</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Data de criação da unidade'
                  defaultValue={formik.values.telephone}
                  onChange={formik.handleChange("telephone")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Celular</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Telefone da empresa'
                  defaultValue={formik.values.cellphone}
                  onChange={formik.handleChange("cellphone")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>E-mail</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='E-mail da empresa'
                  defaultValue={formik.values.email}
                  onChange={formik.handleChange("email")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button /*type='submit'*/ onClick={submit} className='btn btn-primary' /*disabled={loading}*/>
              {!loading && 'Salvar Cliente'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor, aguarde...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function toggleToast(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}
