/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  style?: any
  largura?: any
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  style,
  largura
}) => {
  return (
    largura > 1200 ?
      <a href='#' className={`card bg-${color} hoverable ${className}`} style={style}>
        <div className='card-body' style={largura && largura < 1200 ? {padding: '1rem'} : {}}>
          <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-${largura && largura < 1200 ? '2x' : '3x'} ms-n1`} />
          <div className={`text-${titleColor} fw-bold fs-${largura && largura < 1200 ? '5' : '2'} mb-2 mt-5`}>{title}</div>

          <div className={`fw-semibold fs-${largura && largura < 1200 ? '8' : ''} text-${descriptionColor}`}>{description}</div>
        </div>
      </a>
    : 
    <a href='#' className={`card bg-${color} hoverable ${className}`} style={style}>
        <div className='card-body' style={{padding: '0.8rem'}}>
          <div style={{display: 'flex', alignItems: 'center', gap: '0.3rem'}}>
            <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-${largura && largura < 1200 ? '2x' : '3x'} mb-2 ms-n1`} />
            <div className={`text-${titleColor} fw-bold fs-${largura && largura < 1200 ? '7' : '2'} mb-2`}>{title}</div>
          </div>
          
          <div className={`fw-semibold fs-${largura && largura < 1200 ? '9' : ''} text-${descriptionColor}`}>{description}</div>
        </div>
      </a>
  )
}

export {StatisticsWidget5}
