import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import { LoginAppV2 } from './components/LoginApp'
import { useEffect, useState } from 'react'

const AuthPage = () => {
  const [largura, setLargura] = useState(window.innerWidth);

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [])
  
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={largura < 1000  ? <LoginAppV2 /> : <Login />} />
        <Route path='registration' element={<Registration />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route index element={largura < 1000  ? <LoginAppV2 /> : <Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
