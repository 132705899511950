import api from "./api";

export const getCollaboratosAll = async () => {
    const response = await api.get(`collaborator/listAll`);
    return response.data;
};

export const postCollaborator = async (body: any) => {
    const response = await api.post(`collaborator/`, body);
    return response.data;
};

export const updateCollaborator = async (body: any, id: string) => {
    const response = await api.put(`collaborator/${id}`, body);
    return response.data;
};

export const deleteCollaborator = async (id: string) => {
    const response = await api.delete(`collaborator/${id}`);
    return response.data;
};

export const getClientOne = async (id: string) => {
    const response = await api.get(`client/${id}`);
    return response.data;
};

