/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { DateAno, DateMes } from '../../../../../utils/date';
import { useAuth } from '../../../../../modules/auth';
import { getInvoicePDF } from '../../../../../services/invoices';
import { useToast } from '../../../../components/Toats';


type Props = {
    invoices: any[];
    setMyInvoice: any;
    setShowCreateAppModal: any;
    // setShowCreateAppModalDelete: any
    // setShowCreateAppModalDetails: any
}

export function TableBody({
    invoices, 
    setMyInvoice,
    setShowCreateAppModal,
    // setShowCreateAppModalDelete, 
    // setShowCreateAppModalDetails
}: Props) {

    const [largura, setLargura] = useState(window.innerWidth);
    const user = useAuth()
    const [loading, setLoading] = useState(false)
    const { toggleToast } = useToast();
    // const handleButtonClickDelete = (event, client) => {
    //     event.stopPropagation();
    //     //unitBranch.idUser = unitBranch.users.length > 0 ? unitBranch.users[0].id : null; 
    //     setMyClient(client)
    //     //setShowCreateAppModalDelete(true)
    // };

    // const getPDF = async (link_file) => {
    //     try {
    //       setLoading(true)
    //       let myPDF = await getInvoicePDF(link_file ? link_file : "")
          
    //       //setViewPdf(myPDF.url);
      
    //     } catch (error) {
    //       console.log(error)
    //       //toggleToast("Fatura não encontrada. ", "error")
      
    //     }finally{
    //       setLoading(false)
    //     }
    // }

    const getPDF = async (link_file: any) => {
        try {
          setLoading(true);
          let myPDF = await getInvoicePDF(link_file ? link_file : "");
          
          const link = document.createElement('a');
          link.href = myPDF.url;
          link.setAttribute('download', 'fatura.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      
        } catch (error) {
          console.log(error);
          toggleToast("Fatura não encontrada.", "error");
      
        } finally {
          setLoading(false);
        }
      };

    const handleButtonClickUpdate = (event: any, invoice: any) => {
        event.stopPropagation();
        invoice.idUnitConsumer = invoice.unitConsumer.id ? invoice.unitConsumer.id : null; 
        invoice.idConcessionarie = invoice.unitConsumer && invoice.unitConsumer.concessioraire ? invoice.unitConsumer.concessioraire.id : null;
        setMyInvoice(invoice)
        setShowCreateAppModal(true)
    };

    const handleButtonClickDownload = (event: any, link_file: any) => {
        event.stopPropagation();
        getPDF(link_file)
    };

    const atualizarTamanhoDaTela = () => {
        setLargura(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', atualizarTamanhoDaTela);
        return () => {
          window.removeEventListener('resize', atualizarTamanhoDaTela);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <tbody>
        {invoices && invoices.length !== 0 ?   
            invoices.map((invoice: any) => {
                return (
                    <tr 
                        onClick={() =>{
                            setMyInvoice(invoice)
                            //setShowCreateAppModalDetails(true)
                        }}
                    >
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                                {/* <KTSVG path="/media/icons/duotune/general/gen001.svg" className="svg-icon-muted svg-icon-4hx" /> */}
                                < i className = " bi bi-upc fs-5hx " > </ i > 
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                    {invoice.unitConsumer.name ? invoice.unitConsumer.name : "Não encontrado. "}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    {invoice.unitConsumer.contrato ? invoice.unitConsumer.contrato : invoice.unitConsumer.contrato}
                                </span>
                            </div>
                            </div>
                        </td>

                        {/* <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {invoice.contrato ? invoice.contrato : "Não encontrado. "}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                Número do contrato
                            </span>
                        </td> */}

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {invoice.mes ? DateMes(invoice.mes) : "Não encontrado. "}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {invoice.mes ? DateAno(invoice.mes) : "Nao encontrado"}
                            </span>
                        </td>

                        <td>
                            <span 
                              className={
                                `badge badge-light-${
                                    invoice.status !== null
                                    ? invoice.status === "INFORMADO"
                                    ? "success"
                                    : invoice.status === "NÃO INFORMADO"
                                    ? "danger"
                                    : "secondary"
                                    : "secondary"
                                } fs-7 fw-semibold`
                              }
                            >
                                {
                                 invoice.status ? invoice.status : "Não identificado"
                                }
                            </span>
                        </td>

                        

                        <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                {invoice.unitConsumer ? invoice.unitConsumer.concessioraire ? invoice.unitConsumer.concessioraire.name : "Não Informado. " : "Não Informado. "}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                ***********
                            </span>
                        </td>

                        {user.currentUser && user.currentUser.companies && user.currentUser.companies.length > 0
                        && user.currentUser.companies[0].planos === "PREMIUM" && (
                        <td>
                            <div 
                                className={
                                `mb-6 bg-${
                                    invoice.unitConsumer && invoice.unitConsumer.status !== null
                                    ? invoice.unitConsumer.status === "ATIVA"
                                    ? "success"
                                    : invoice.unitConsumer.status === "INATIVA"
                                    ? "danger"
                                    : "secondary"
                                    : "secondary"
                                
                                } rounded-circle border border-4 border-white h-20px w-20px`}
                            ></div>
                        </td>)}

                    
                        <td>
                            <div className='d-flex justify-content-end flex-shrink-0 gap-2'>
                                
                                {
                                    invoice.confirm_payment === true ?
                                        <a
                                            onClick={(event: any) =>{
                                                //handleButtonClickUpdate(event, reportService)
                                            }}
                                            href='#'
                                            className='btn btn-icon btn-bg-success btn-active-color-primary btn-sm'
                                        >
                                            <KTSVG 
                                                path='/media/icons/duotune/arrows/arr085.svg' 
                                                className='svg-icon-3 svg-icon-light' 
                                            />
                                        </a>
                                    :

                                    <a
                                        onClick={(event: any) => {
                                            //handleButtonClickDelete(event, typeService)
                                            //handleButtonClickDelete(event, reportService)
                                        }}
                                        href='#'
                                        className='btn btn-icon btn-bg-danger btn-active-color-primary btn-sm'
                                    >
                                        <KTSVG
                                        path='/media/icons/duotune/arrows/arr097.svg'
                                        className='svg-icon-3 svg-icon-light'
                                        />
                                    </a>

                                }

                            </div>
                        </td>


                        <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                            {/* <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='svg-icon-3'
                                />
                            </a> */}
                            <a
                                onClick={(event: any) =>{
                                    handleButtonClickUpdate(event, invoice)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </a>
                            <a
                                onClick={(event: any) =>{
                                    if(invoice.link_file){
                                        handleButtonClickDownload(event, invoice.link_file)
                                    }else{
                                        console.log('epa')
                                        toggleToast("Não há arquivo de fatura para download. ", "alert");
                                    }
                                }}
                                aria-disabled={loading || (!invoice.link_file ? true : false)}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-3' />
                            </a>
                            {/* <a
                                onClick={(event: any) => {
                                    //handleButtonClickDelete(event, client)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                                />
                            </a> */}
                            </div>
                        </td>
                    </tr>
                )
            })
        :
            <tr>
                <td colSpan={largura < 1200 ? 2 : 6} style={{textAlign: 'center'}}>
                    <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                        <span className='text-muted fw-semibold text-muted d-block fs-4'>
                            Faturas não encontradas!
                        </span>
                    </div>
                </td>
            </tr>
        }
    </tbody>
  );
}