/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  const [largura, setLargura] = useState(window.innerWidth);
  
  const estilo = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${toAbsoluteUrl('/media/imgs/imgfundoV2.png')})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Aside */}
      {largura > 1000 && 
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={estilo}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100' style={{justifyContent: 'space-between'}}>
          {/* begin::Logo */}
          {largura < 1200 ? 
          <Link to='/' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/imgs/novalogo.png')} className='h-50px' />
          </Link>
          : 
          <Link to='/' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/imgs/novalogo.png')} className='h-80px' />
          </Link>
          }
          {/* end::Logo */}

          {/* begin::Image */}
          {/* <img
            className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          /> */}
          {/* end::Image */}

          {/* begin::Title */}
          <div>
            {
              largura < 1200 ? 
              <h5 className='text-white fs-2 fw-bolder text-center mb-4'>
                Monitoramento <span className='text-warning'>Inteligente</span> <br/>e <span className='text-warning'>Centralizado</span> de Usinas Solares
              </h5>
              :
              <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
                Monitoramento <span className='text-warning'>Inteligente</span> <br/>e <span className='text-warning'>Centralizado</span> de Usinas Solares
              </h1>
            }
            {/* <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
              Monitoramento <span className='text-warning'>Centralizado</span> <br/>de Usinas Solares
            </h1> */}
            <div className='text-white fs-base text-center'>
              <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
              Plataforma e aplicativo
              </a>
              completo para gestão do pós venda solar. <br /> <span className='text-warning'>Ideal para você!</span>
              {/* <a href='#' className='opacity-75-hover text-warning fw-bold me-1'>
                the interviewee
              </a>
              and their <br /> work following this is a transcript of the interview. */}
            </div>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>}
      {/* end::Aside */}
      
      {/* begin::Body */}
      <div className={`d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 ${largura < 1000 ? "h-100" : ""}`} 
        style={{justifyContent: largura < 1000 ? 'center' : ''}}
      >
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className={`${largura < 1200 ? "w-100 p-1" : "w-lg-500px p-10"}`}>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          {/* <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div> */}
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {AuthLayout}
