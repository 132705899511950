/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { useToast } from '../../../../../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../../../../../components/Loadings/loadingSpan';
import { StepModal } from './components/stepModal';
import { postItemProposalOne } from '../../../../../../../../../../services/itemProposalService';

type DataForm = {
  description: string | null,
  value: number | null,
  sub_value: number | null,
  amount: number | null,
  idTypeService: string | null,
  idProposal: string | null,
  idProduct: string | null,
  tipo: string | null,
}

type Props = {
    idProposal: string;
    id?: string;
    data?: DataForm;
    show: boolean
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  description: Yup.string(),
  value: Yup.number(),
  sub_value: Yup.number(),
  amount: Yup.number(),
  idClient: Yup.string(),
  idTypeService: Yup.string(),
  idProposal: Yup.string(),
  idProduct: Yup.string(),
  tipo: Yup.string(),
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddServiceProposal = ({show, handleClose, data, id, idProposal}: Props) => {
  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [typeServicesRaiz, setTypeServicesRaiz] = useState<any[]>([]);
  const [productsRaiz, setProductsRaiz] = useState<any[]>([]);

  const [initialValues, setInitialValues] = useState<DataForm>({
    description: null,
    value: null,
    sub_value: null,
    amount: null,
    idTypeService: null,
    idProduct: null,
    idProposal: null,
    tipo: null
  })
  const [hasError, setHasError] = useState(false)
  const [myError, MyError] = useState(false)
  const [loading, setLoading] = useState(false)
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
  }

  // const checkInputs = (): boolean => {
  //   if (!formik.values.nameResponsible 
  //       || !formik.values.cpfResponsible 
  //       || !formik.values.email 
  //       || !formik.values.idUnitBranch) {
  //     return false
  //   }
  //   return true
  // }

  const closed = () => {
    formik.setValues(initialValues)
    handleClose()
  }

  const postItemProposalSubmit = async () => {
    try {
      setLoading(true)
      const myData = {
        description: formik.values.description,
        value: Number(formik.values.value),
        sub_value: formik.values.value && formik.values.amount ? Number(formik.values.value) * formik.values.amount : 0,
        amount: formik.values.amount,
        idTypeService: formik.values.idTypeService,
        idProposal: idProposal ? idProposal : "",
        idProduct: formik.values.idProduct ? formik.values.idProduct : "",
        tipo: formik.values.tipo ? formik.values.tipo : null,
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });


      await postItemProposalOne(myData)
      toggleToast("Sucesso ao cadastrar o item. ", "success")

    } catch (error) {
      toggleToast("Erro ao cadastrar o item. ", "error")
    }finally{
      setLoading(false)
      closed()
    }
  }

  // const updateClientSubmit = async (id: string) => {
  //   try {
  //     setLoading(true)
  //     const myData = {
  //       email: formik.values.email,
  //       nameResponsible: formik.values.nameResponsible,
  //       cellphone: formik.values.cellphone,
  //       cpfResponsible: formik.values.cpfResponsible,
  //       telephone: formik.values.telephone,
  //       idUser: formik.values.idUser,
  //       idUnitBranch: formik.values.idUnitBranch,
  //       idsPlants: formik.values.idsPlants
  //     }
  //     Object.keys(myData).forEach((prop) => {
  //       if (myData[prop] === null) {
  //         delete myData[prop];
  //       }
  //     });
  //     const myFilial = await updateClient(myData, id)
  //     toggleToast("Sucesso ao atualizar o cliente. ", "success")

  //   } catch (error) {
  //     console.log('erro',error)
  //     toggleToast("Erro ao atualizar o cliente. ", "error")
  //   }finally{
  //     setLoading(false)
  //     closed()
  //   }
  // }

  const submit = () => {
    if(id){
    }else{
      postItemProposalSubmit()
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    // if (!checkInputs()) {
    // setHasError(true)
    // return
    // }
    submit()
    
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(data){
      const myObject = {
        description: data && data.description ? data.description : null,
        value: data &&  data.value ? data.value : null,
        sub_value: data &&  data.sub_value ? data.sub_value : null,
        amount: data &&  data.amount ? data.amount : null,
        idTypeService: data &&  data.idTypeService ? data.idTypeService : null,
        idProposal : data &&  data.idProposal ? data.idProposal : null,
        tipo: data &&  data.tipo ? data.tipo : null,
        idProduct: data &&  data.idProduct ? data.idProduct : null,
      }
      formik.setValues(myObject)
    }else{
      formik.setValues(initialValues)
    }
  }, [show])


  useEffect(() => {
    if(formik.values.tipo === "PRODUTO" && formik.values.idProduct){
      
      const product = productsRaiz.find((prod: any) => prod.id === formik.values.idProduct);
      formik.setValues({...formik.values, value: product && product.preco ? product.preco : null})

    }else{
      const typeService = typeServicesRaiz.find((type: any) => type.id === formik.values.idTypeService);
      formik.setValues({...formik.values, value: typeService && typeService.preco ? typeService.preco : null})
    }
    //formik.setValues(initialValues)
  }, [formik.values.idTypeService, formik.values.idProduct])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header bg-primary'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Item {" "}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            <form noValidate id='kt_modal_create_app_form' >
              <StepModal 
                data={initialValues} 
                updateData={updateData} 
                hasError={hasError} 
                formik={formik} 
                setTypeServicesRaiz={setTypeServicesRaiz}
                setProductsRaiz={setProductsRaiz}
              />

              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
 
                <div style={{display: 'flex', gap: '1rem'}}>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar Item" : "Adicionar Item" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddServiceProposal}
