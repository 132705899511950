import React, { useEffect, useState } from 'react';
import useSize from '../../../../hooks/useSize';
import { SearchInput } from '../../../components/SearchInput';
import { getCompanyAll } from '../../../../services/companyService';
import { useToast } from '../../../components/Toats';
import { TableBody } from './components/TableBody';
import { ModalDesactiveCompany } from './components/ModalDesactiveCompany';
import { ModalDeleteClient } from '../../../clients/components/ModalDeleteClient';

export function TableCompanies({myRefresh}: any) {
    const {width} = useSize();
    const [mySearch, setMySearch] = useState<any[]>([]);
    const [control, setControl] = useState(false);
    const { toggleToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState<any[]>([]);
    const [showDeactiveAppModal, setShowDeactiveAppModal] = useState(false);
    const [myCompany, setMyCompany] = useState<any>(null);

    const getCompany = async () => {
      try {
        setLoading(true)
        const myCompanies = await getCompanyAll()
        setCompanies(myCompanies)

        setMySearch(prevMySearch =>
          prevMySearch.map(objMenor => {
            const objMaior = companies.find(obj => obj.id === objMenor.id);
            return objMaior ? { ...objMenor, ...objMaior } : objMenor;
          })
        );

      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar empresa vinculada. ", "error")
      }finally{
        setLoading(false)
      }
    }

    useEffect(() => {
      getCompany()
    }, [])

    useEffect(() => {
      getCompany();
    }, [myRefresh])

    const refresh = () => {
      getCompany();
    }

  return (
    <div className={`card card-xl-stretch mb-xl-8`} style={{height: width > 1200 ? '72vh' : '68vh', paddingBottom: '1rem'}}>
      <div className='card-header border-0 pt-6'>
        {/* <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Lista de Usuários</span>
        <span className='text-muted mt-1 fw-semibold fs-7'>{users.length} Usuários(s)</span>
        </h3> */}
        {/* <div 
        style={{
            display: 'flex', 
            gap: '1rem',  
            justifyContent: 'space-between', 
            width: '100%',
            flexDirection: width > 1200 ? 'row' : 'column'
        }}>
        </div> */}

        <div 
          style={{
            display: 'flex', 
            gap: '1rem',  
            justifyContent: 'space-between', 
            width: '100%',
            flexDirection: width > 1200 ? 'row' : 'column'
          }}>
          <SearchInput 
            className='form-control form-control-solid bg-body ps-14'
            placeholder='Buscar Empresas'
            myDate={companies}
            setMySearch={setMySearch}
            control={control}
            style={{width: width > 1200 ? "300px" : "100%"}}
            setControl={setControl}
            searchParameter="corporateName"
          />

          {/* <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click para adicionar uma unidade filial'
            style={largura < 1200 ? {width: "100%", display: 'flex', justifyContent: 'end'} : {}}
          >
            <a
              onClick={() =>{setShowCreateAppModal(true)}}
              href='#'
              className='btn btn-sm btn-light-primary'
              //data-bs-target='#kt_modal_create_unitBranch'

            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Novo Usuário
            </a>
          </div> */}
        </div>
      </div>
      <div className='card-body py-3' style={width < 1200 ? {height: '20rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
      <div className='table-responsive'>
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='text-muted text-start fw-bolder fs-7 text-uppercase gs-0'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-250px'>Nome</th>
                <th className='min-w-150px'>CNPJ</th>
                <th className='min-w-50px'>Data de criação</th>
                <th className='min-w-100px'>Usuário</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-150px text-end'>Actions</th>
              </tr>
            </thead>

            <TableBody 
              companies={control ? mySearch : companies}
              setMyCompany={setMyCompany}
              refresh={refresh}
              setShowDeactiveAppModal={setShowDeactiveAppModal}
            />
          </table>
        </div>

        <ModalDesactiveCompany 
          show={showDeactiveAppModal}
          data={myCompany ? myCompany : undefined}
          id={myCompany ? myCompany.id : ""} 
          handleClose={() => {
            setMyCompany(null)
            setShowDeactiveAppModal(false)
            refresh()
          }} 
        />
      </div>
    </div>
  );
}