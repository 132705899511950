/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { useToast } from '../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../components/Loadings/loadingSpan';
import { getUsersAllCompany } from '../../../../../../services/usersService';
import { initialLetters } from '../../../../../../utils/initialLetters';
import { putCompanyOne } from '../../../../../../services/companyService';
import { DateComplete } from '../../../../../../utils/date';

type Props = {
    data: any;
    show: boolean;
    handleClose: () => void
}


const modalsRoot = document.getElementById('root-modals') || document.body

const ModalDetailsCreate = ({show, handleClose, data}: Props) => {

    console.log('dados',data)

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<any[]>([])

  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Resumo da Empresa Criada</span>
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>



      <div className='modal-body py-lg-10 px-lg-10' style={{display: 'flex', gap: '2rem'}}>
        {/*begin::Stepper */}
        <div className='border border-gray-300 border-dashed rounded p-2' style={{height: '19rem', width: '19rem'}}>
            <div className='bg-light' style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <KTSVG path="/media/icons/duotune/general/gen001.svg" className="svg-icon-muted svg-icon-3hx" />
            </div>
        </div>
        
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          <div className='flex-row-fluid py-lg-0'>
            <div style={{marginBottom: '2rem'}}>
                <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-bold fs-5 mb-1'>Nome da Empresa: </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>
                        {data && data.companies && data.companies.length > 0 ? data.companies[0].corporateName : "Não identificado"}
                    </span>
                </h3>
                <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-bold fs-5 mb-1'>CNPJ: </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>
                        {data && data.companies && data.companies.length > 0 ? data.companies[0].cnpj : "Não identificado"}
                    </span>
                </h3>
                <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-bold fs-5 mb-1'>Data de Criação: </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>
                        {data && data.companies && data.companies.length > 0 ? DateComplete(new Date(data.companies[0].created_at)) : "Não identificado"}
                    </span>
                </h3>
            </div>

            <div style={{marginBottom: '2rem'}}>
                <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-bold fs-5 mb-1'>Plano: </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>
                        {
                        data 
                        && data.companies 
                        && data.companies.length > 0 
                        && data.companies[0].planosCompany
                        && data.companies[0].planosCompany.plano
                        ? data.companies[0].planosCompany.plano : "Não identificado"}
                    </span>
                </h3>
                <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-bold fs-5 mb-1'>Qtd. Usinas no Plano PREMIUM: </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>
                    {
                        data 
                        && data.companies 
                        && data.companies.length > 0 
                        && data.companies[0].planosCompany
                        && data.companies[0].planosCompany.qtd_plants_plano !== null
                        ? data.companies[0].planosCompany.qtd_plants_plano: "0"}
                    </span>
                </h3>

                <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-bold fs-5 mb-1'>Qtd. Usinas no Plano STARTER: </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>
                    {
                        data 
                        && data.companies 
                        && data.companies.length > 0 
                        && data.companies[0].planosCompany
                        && data.companies[0].planosCompany.qtd_plants_plano_starter !== null
                        ? data.companies[0].planosCompany.qtd_plants_plano_starter: "0"}
                    </span>
                </h3>

                <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-bold fs-5 mb-1'>Qtd. Usinas Gratuitas: </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>
                    {
                        data 
                        && data.companies 
                        && data.companies.length > 0 
                        && data.companies[0].planosCompany
                        && data.companies[0].planosCompany.qtd_plants_gratuita !== null
                        ? data.companies[0].planosCompany.qtd_plants_gratuita: "0"}
                    </span>
                </h3>
            </div>

            <div style={{marginBottom: '2rem'}}>
                <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-bold fs-5 mb-1'>Nome do Usuário: </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>{data && data.name ? data.name : "Não identificado. "}</span>
                </h3>
                <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-bold fs-5 mb-1'>E-mail: </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>{data && data.email ? data.email : "Não identificado. "}</span>
                </h3>
                <h3 className='card-title align-items-start flex-row'>
                    <span className='card-label fw-bold fs-5 mb-1'>Senha: </span>
                    <span className='text-muted mt-1 fw-semibold fs-5'>{data && data.senha ? data.senha : "Não identificado. "}</span>
                </h3>
            </div>
          </div>
        </div>

      </div>
      <div className='modal-footer'>
        <div style={{display: 'flex', justifyContent: 'end', width: '100%'}}>
            {/* <button
                type='button'
                className='btn btn-lg btn-secondary'
                disabled={loading}
                onClick={() => {
                    closed()
                }}
                //data-kt-stepper-action='submit'
            >
                <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className='svg-icon-3 ms-2 me-0'
                />
                <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
                
            </button> */}

            <button
                type='button'
                className={`btn btn-lg btn-${data && data.status === 'ATIVADA' ? 'danger' : 'success'}`}
                disabled={loading}
                //data-kt-stepper-action='submit'
                onClick={() => {closed()}}
            >
                OK
                {
                    loading ? 
                    <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                    :
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                }
            </button>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalDetailsCreate}
