/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { useToast } from '../../../../../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../../../../../components/Loadings/loadingSpan';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { putServiceOne } from '../../../../../../../../../../services/servicesService';

type Props = {
    id: string;
    data: any;
    show: boolean;
    refresh: any
    handleClose: () => void
}


const validationSchema = Yup.object().shape({
  description: Yup.string()
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalDescriptionOS = ({show, handleClose, id, data, refresh}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [loadingUpdateOS, setLoadingUpdateOS] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({
    description: null,
  })
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    formik.setValues({description: ''})
    handleClose()
  }

  const updateOSOne = async () => {
    try {
      setLoadingUpdateOS(true)
      const body = {
        description: formik.values.description
      }
      await putServiceOne(id, body)
      toggleToast("Sucesso ao atualizar a O.S. ", "success")
      //setClients(MyClient)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar a O.S. ", "error")
      
    }finally{
      setLoadingUpdateOS(false)
      refresh()
    }
}

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });

  const submit = () => {
    updateOSOne()
  }

  useEffect(() => {
    if(data && data.description){
      formik.setValues({description: data.description})
    }
  }, [show])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
      //style={{minWidth: '1000px'}}
    >
      <div className='modal-header bg-primary'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Descrição da O.S</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10 bg-light'>
        <div className='row mb-6'>
            <label className='col-lg-2 col-form-label fw-bold fs-6'>Descrição </label>
            <div className='col-lg-10'>
                <input
                    style={{height: '10rem', width: '100%'}}
                    type='text'
                    className='form-control form-control-lg'
                    name='description'
                    placeholder=''
                    value={formik.values.description ? formik.values.description : ""}
                    onChange={formik.handleChange("description")}
                />
            </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <button
                type='button'
                className='btn btn-lg btn-secondary'
                disabled={loading}
                onClick={() => {
                    closed()
                }}
                //data-kt-stepper-action='submit'
            >
                <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className='svg-icon-3 ms-2 me-0'
                />
                <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
                
            </button>

            <button
                type='button'
                className='btn btn-lg btn-primary'
                disabled={loadingUpdateOS}
                //data-kt-stepper-action='submit'
                onClick={submit}
            >
                Salvar
                {
                    loadingUpdateOS ? 
                    <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                    :
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                }
            </button>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalDescriptionOS}
