import api from "./api";

export const postSettingsCompanyOne = async (body: any) => {
    const response = await api.post(`settingsCompany/`, body);
    return response.data;
};

export const getTemplateAll = async () => {
    const response = await api.get(`template/listAll`,);
    return response.data;
};

export const putSettingsCompanyAll = async (id: string, body: any) => {
    const response = await api.put(`settingsCompany/${id}`, body);
    return response.data;
};


