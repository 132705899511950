import {useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {DrawerComponent} from '../../../assets/ts/components'
import {WithChildren} from '../../../helpers'
import useSize from '../../../../app/hooks/useSize'

const Content = ({children}: WithChildren) => {
  const {config, classes} = useLayout()
  const location = useLocation()
  const {width} = useSize()

  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  const appContentContainer = config.app?.content?.container
  return (
    <div
      id='kt_app_content'
      className={clsx(
        'app-content flex-column-fluid',
        classes.content.join(' '),
        config?.app?.content?.class
      )}
    >
      {appContentContainer ? (
        <div
          id='kt_app_content_container'
          style={ width < 1200 ? {
            paddingLeft: '0.5rem', 
            paddingRight: '0.6rem', 
          } : {}}
          className={clsx(width < 1200 && location.pathname === '/dashboard' ? 'container-fluid' : 'app-container', classes.contentContainer.join(' '), {
            'container-xxl': appContentContainer === 'fixed',
            'container-fluid' : width > 1200 && appContentContainer === 'fluid',
            //'container-fluid': appContentContainer === 'fluid',
          })}
          
        >
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export {Content}
