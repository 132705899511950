export function DateMes(date: any){
    const dataObjeto = new Date(date);
    const nomeMes = new Intl.DateTimeFormat('pt-BR', { month: 'long' }).format(dataObjeto).replace(/^\w/, (c) => c.toUpperCase());
    
    const resultado = `${nomeMes}`;
    return resultado
}

export function DateAno(date: any){
    const dataObjeto = new Date(date);
    const ano = dataObjeto.getFullYear();

    const resultado = `${ano}`;
    return resultado
}

export function DateCommon(date: any){
    const dataObjeto = new Date(date);
    const dia = dataObjeto.getDate();
    const mes = dataObjeto.getMonth() + 1;
    

    const dataFormat = `${dia}/${mes}`;
    return dataFormat
}

export function DateComplete(date: any){
    const dataObjeto = new Date(date);
    const dia = padZero(dataObjeto.getDate());
    const mes = padZero(dataObjeto.getMonth() + 1);
    const ano = padZero(dataObjeto.getFullYear());

    const dataFormat = `${dia}-${mes}-${ano}`;
    return dataFormat
}

export function DateCompleteBarra(date: any){
    const dataObjeto = new Date(date);
    const dia = padZero(dataObjeto.getDate());
    const mes = padZero(dataObjeto.getMonth() + 1);
    const ano = padZero(dataObjeto.getFullYear());

    const dataFormat = `${dia}/${mes}/${ano}`;
    return dataFormat
}

function padZero(numero: number) {
    return numero < 10 ? `0${numero}` : numero;
}

export function formatarDataParaString(data: Date) {
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês é base 0, então somamos 1
    const dia = String(data.getDate()).padStart(2, '0');
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');
  
    return `${ano}-${mes}-${dia}T${horas}:${minutos}`;
}

export function formatarData(dataISO) {
    const diasSemana = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
    const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    
    const data = new Date(dataISO);
    
    const diaSemana = diasSemana[data.getDay()];
    const dia = data.getDate();
    const mes = meses[data.getMonth()];
    const horas = data.getHours();
    const minutos = data.getMinutes();
    
    const dataFormatada = diaSemana + ", " + dia + " de " + mes + ", às " + horas + ":" + minutos + " horas";
    
    return dataFormatada;
}

export function formatarDataV2(dataISO) {
  const diasSemana = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
  const meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  
  const data = new Date(dataISO);
  
  const diaSemana = diasSemana[data.getDay()];
  const dia = data.getDate();
  const mes = meses[data.getMonth()];
  const horas = data.getHours();
  const minutos = data.getMinutes();
  
  const dataFormatada = dia + " de " + mes + ", às " + horas + ":" + minutos + " horas";
  
  return dataFormatada;
}

export function extrairMesDia(dataString: any) {
    const partes = dataString.split("-");
    const mes = partes[1];
    const dia = partes[2];
    return `${dia}-${mes}`
    //return { mes: parseInt(mes, 10), dia: parseInt(dia, 10) };
  }


export function getShortMonthName(dateString: string) {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    return months[monthIndex];
}

export function formatDate(date: Date) {
    const dataObjeto = new Date(date);
    const monthNames = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"];
    const month = dataObjeto.getMonth();
    const year = dataObjeto.getFullYear();
    return `${monthNames[month]}/${year}`;
}

export const formatDateToYYYYMMDD = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  };

export function getShortMonthNameString(dateString: string) {
    const shortMonthNames = {
        '01': 'jan',
        '02': 'fev',
        '03': 'mar',
        '04': 'abr',
        '05': 'may', 
        '06': 'jun',
        '07': 'jul',
        '08': 'ago',
        '09': 'sep', 
        '10': 'oct', 
        '11': 'nov',
        '12': 'dez'
    };

    const month = dateString.split('-')[1];

    return shortMonthNames[month];
}


const isDateString = (dateString) => {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(dateString);
};
  
export const parseDate = (dateString: string) => {
    if (isDateString(dateString)) {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    }
    return new Date(dateString);
};
  
export const nameMes = (dateString: string) => {
    const mounthYear = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      ];

      const data = new Date(dateString);
      const year = data.getFullYear();
      const month = data.getMonth() + 1;
      const nameMonth = mounthYear[month - 1];

      return nameMonth
}

export function tempoPassado(dataEspecifica: string): string {
    const dataAtual = new Date();
    const dataInicio = new Date(dataEspecifica);
  
    let diferenca = dataAtual.getTime() - dataInicio.getTime();
  
    const segundos = Math.floor(diferenca / 1000);
    const minutos = Math.floor(segundos / 60);
    const horas = Math.floor(minutos / 60);
    const dias = Math.floor(horas / 24);
    const meses = Math.floor(dias / 30); 
    const anos = Math.floor(meses / 12);
  
    const valores = {
      anos: anos,
      meses: meses % 12,
      dias: dias % 30,
      horas: horas % 24,
      minutos: minutos % 60,
      segundos: segundos % 60,
    };
  
    const formatarTempo = (valor: number, unidade: string) => {
      return valor > 0 ? `${valor}${unidade}` : '';
    };
  
    let partes: string[] = [];
  
    if (valores.anos) {
      partes.push(formatarTempo(valores.anos, 'a'));
      if (valores.meses) partes.push(formatarTempo(valores.meses, 'm'));
    } else if (valores.meses) {
      partes.push(formatarTempo(valores.meses, 'm'));
      if (valores.dias) partes.push(formatarTempo(valores.dias, 'd'));
    } else if (valores.dias) {
      partes.push(formatarTempo(valores.dias, 'd'));
      if (valores.horas) partes.push(formatarTempo(valores.horas, 'h'));
    } else if (valores.horas) {
      partes.push(formatarTempo(valores.horas, 'h'));
      if (valores.minutos) partes.push(formatarTempo(valores.minutos, 'min'));
    } else if (valores.minutos) {
      partes.push(formatarTempo(valores.minutos, 'min'));
    } else if (valores.segundos) {
      partes.push(formatarTempo(valores.segundos, 's'));
    }
  
    return partes.length > 1 ? partes.slice(0, -1).join(', ') + ' e ' + partes.slice(-1) : partes[0];
  }
  

