import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list'
import { ItemCalendar } from './components/itemCalendar';
import { getServicesAll } from '../../../../services/servicesService';
import { Loading } from '../../../components/Loadings/loading';
import './styles.css'
import { useAuth } from '../../../../modules/auth';
import { ModalDetailsOS } from '../ScreenServices/components/ModalDetailsOS';
import { getReportServicesAll } from '../../../../services/reportService';
import { ModalRequestService } from '../ScreenServices/components/ModalDetailsRequestService';
import { getplantsAll } from '../../../../services/plantsService';
import { getUnitBranchAll } from '../../../../services/unitBranchService';
import { getCollaboratosAll } from '../../../../services/collaboratorsService';

type Props = {
    refreshPage : boolean; 
    setRefreshPage: any;
}

export function CalendarServices({refreshPage} : Props) {
    const [services, setServices] = useState<any[]>([]);
    const [reportServices, setReportServices] = useState<any[]>([]);
    const [itensCalendar, setItensCalendar] = useState<any[]>([]);
    const [plants, setPlants] = useState<any[]>([]);
    const [collaboratos, setCollaboratos] = useState<any[]>([]);
    const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingPlants, setLoadingPlants] = useState<boolean>(false);
    const [loadingCollaborators, setLoadingCollaborators] = useState<boolean>(false);
    const [loadingReportServices, setLoadingReportServices] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [showModalConfirmReport, setShowModalConfirmReport] = useState<boolean>(false)
    const [myService, setMyService] = useState<any | null>(null);
    const [myReportService, setMyReportService] = useState<any | null>(null);
    const [filterServices, setFilterServices] = useState<string>("1");

    const [filterPlant, setFilterPlant] = useState<string>("");
    const [filterColaborator, setFilterColaborator] = useState<string>("");
    const [filterUnitBracnh, setFilterUnitBracnh] = useState<string>("");

    const [largura, setLargura] = useState(window.innerWidth);
    const user = useAuth()

    const getServices = async () => {
        try {
          setLoading(true)
          const myServices = await getServicesAll(
            "", 
            filterPlant ? filterPlant : "", 
            filterUnitBracnh ? filterUnitBracnh : "", 
            filterColaborator ? filterColaborator : ""
          )
          
          if(myServices && myServices.length > 0){
            const list_itens = myServices.map((myServiceOne: any) => {
              const myDate = {
                title: myServiceOne.title,
                start: myServiceOne.dateInit ? myServiceOne.dateInit : new Date(),
                end: myServiceOne.dateEnd ? myServiceOne.dateEnd : new Date(),
                //id: myServiceOne.id,
                plant: myServiceOne.plant ? myServiceOne.plant.name : "Usina não identificada.",
                status: myServiceOne.status ? myServiceOne.status : "Status não identificado.", 
                myId: myServiceOne.id ? myServiceOne.id : null,
                type: "O.S"
              }
              return myDate;
            })
            setServices(list_itens)
            setItensCalendar(list_itens)
          }else{
            setServices([])
            setItensCalendar([])
          }
    
        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar os serviços. ", "error")
    
        }finally{
          setLoading(false)
        }
    }

    const getReportServices = async () => {
      try {
        setLoading(true)
        const myReportServices = await getReportServicesAll(
          filterPlant ? filterPlant : "", 
          filterUnitBracnh ? filterUnitBracnh : ""
        )
        //setServices(myReportServices)
        
        if(myReportServices && myReportServices.length > 0){
          const list_itens = myReportServices.map((myReportService: any) => {
            const myDate = {
              title: myReportService.description              ,
              start: myReportService.created_at ? myReportService.created_at : new Date(),
              //id: myReportService.id,
              plant: myReportService.plant ? myReportService.plant.name : "Usina não identificada.",
              status: myReportService.status ? myReportService.status : "Status não identificado.", 
              myId: myReportService.id ? myReportService.id : null,
              type: "S.S"
            }
            return myDate;
          })
          setReportServices(list_itens)
          setItensCalendar((prevItensCalendar) => [
            ...prevItensCalendar,
            ...list_itens,
          ]);
        }else{
          setReportServices([])
          setItensCalendar((prevItensCalendar) => [
            ...prevItensCalendar,
            ...[],
          ]);
        }
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar os serviços. ", "error")
  
      }finally{
        setLoading(false)
      }
  }

  const getPlants = async () => {
    try {
      setLoadingPlants(true)
      const myPlants = await getplantsAll()
      setPlants(myPlants)

    } catch (error) {
      console.log(error)
    }finally{
      setLoadingPlants(false)
    }
  }

  const getUnitBranch = async () => {
    try {
      const unitBranch = await getUnitBranchAll()
      setUnitBranchs(unitBranch)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar as unidades filiais. ", "error")
    }
  }

  const getCollaboratos = async () => {
    try {
      setLoadingCollaborators(true)
      const myCollaborators = await getCollaboratosAll()
      setCollaboratos(myCollaborators)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar os collaboradores!", "error")
    }finally{
      setLoadingCollaborators(false)
    }
}


  const handleEventClick = (evt: any) => {
    if(evt.event._def.extendedProps.type === "O.S"){
      setMyService({
        id: evt.event._def.extendedProps.myId
      })
      setShowModalConfirm(true)
    }else if(evt.event._def.extendedProps.type === "S.S"){
      setMyReportService({id: evt.event._def.extendedProps.myId})
      setShowModalConfirmReport(true)
    }
      
  }

  const refresh = () => {
    const fetch = async() => {
      await getServices()
      await getReportServices()
      await getPlants()
      await getUnitBranch()
      await getCollaboratos()
    }
    fetch() 
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };


  useEffect(() => {
    const fetch = async() => {
      await getServices()
      await getReportServices()
      await getPlants()
      await getUnitBranch()
      await getCollaboratos()
    }
    fetch() 

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [])

  useEffect(() => {
    const fetch = async() => {
      await getServices()
      await getReportServices()
    }
    fetch() 
  }, [
    filterPlant, 
    filterColaborator, 
    filterUnitBracnh
  ])

  useEffect(() => {
      getServices()
  }, [refreshPage])



  // if (loading || loadingReportServices || loadingPlants) {
  //     return (
  //       <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
  //         <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
  //       </div>
  //     );
  // }

  return (
    <div style={{width: '100%'}}>
        <div style={largura < 1200 ? {height: '30rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
          <div 
            style={{
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: largura < 1200 ? 'start' : 'center',
              flexDirection: largura < 1200 ? 'column' : 'row'
            }}>
            <div>
              <h3 className='align-items-start flex-column' style={{display: "flex", marginBottom: '2rem'}}>
                  <span className='card-label fw-bold fs-4 mb-1'>Agenda</span>
                  <span className='text-muted fw-semibold fs-7'>Todas as O.S/Solicitações de Serviços</span>
              </h3>
            </div>
            <div style={{marginBottom: '1.5rem'}}>
              <div style={{display: 'flex', alignItems: 'center', gap: '1rem', flexWrap: 'wrap'}}>
                {/* <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Filtrar por: </span>
                </label> */}
                <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                  <div>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                      <span>O.S / S.S: </span>
                    </label>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={filterServices}
                      onChange={(event) => {setFilterServices(event.target.value)}}
                    >
                      <option value={'1'}>Todos</option>
                      <option value={'2'}>Ordem de Serviço (O.S)</option>
                      <option value={'3'}>Solicitações de Serviço (S.S)</option>
                    </select>
                  </div>

                  <div>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                      <span>Usina: </span>
                    </label>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={filterServices}
                      onChange={(event) => {setFilterPlant((event.target.value).toString())}}
                    >
                      <option value={''}>Todos</option>
                      {plants && plants.map((plant: any) => {
                        return (
                          <option key={plant.id} value={plant.id}>{plant.name}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
 
                <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                  <div>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                      <span>Filial: </span>
                    </label>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={filterServices}
                      onChange={(event) => {setFilterUnitBracnh((event.target.value).toString())}}
                    >
                      <option value={''}>Todos</option>
                      {unitBranchs && unitBranchs.map((unitBranch: any) => {
                        return (
                          <option key={unitBranch.id} value={unitBranch.id}>{unitBranch.companyName}</option>
                        )
                      })}
                    </select>
                  </div>

                  {filterServices === "2" &&
                  <div>
                    <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                      <span>Colaborador: </span>
                    </label>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={filterServices}
                      onChange={(event) => {setFilterColaborator((event.target.value).toString())}}
                    >
                      <option value={''}>Todos</option>
                      {collaboratos && collaboratos.map((colaborator: any) => {
                        return (
                          <option key={colaborator.id} value={colaborator.id}>{colaborator.name}</option>
                        )
                      })}
                    </select>
                  </div>}
                </div>
                
              </div>
            </div>
          </div>

          <div style={largura < 1200 ? {overflow: 'auto', height: '30rem'}: {overflow: 'auto', minHeight: '20rem', maxHeight: '52vh'}}>
              {/* <div id='calendar'></div> */}
              <FullCalendar
                  plugins={user.currentUser?.role.slug !== "CLIENTE" ? [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin] : [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                  locales={allLocales} 
                  locale={'pt-br'}
                  headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: user.currentUser?.role.slug !== "CLIENTE" ? 'dayGridMonth,timeGridDay,listWeek' : 'dayGridMonth, listWeek'
                  }}
                  eventClick={handleEventClick}
                  //initialView="dayGridMonth"
                  //eventClassNames={"bg-secondary colorItem"}
                  eventContent={ItemCalendar}
                  
                  // events={services && services.length > 0 ? 
                  //     services.map((service) => {
                  //         return {
                  //             title: service.title,
                  //             start: service.dateInit ? service.dateInit : new Date(),
                  //             //id: service.id,
                  //             plant: service.plant ? service.plant.name : "Usina não identificada.",
                  //             status: service.status ? service.status : "Status não identificado.", 
                  //             myId: service.id ? service.id : null
                  //         }
                  //     })    
                  //     : [{}]
                  // }

                  events={
                    filterServices === '1' 
                    ? 
                    itensCalendar && itensCalendar.length > 0 ? 
                    itensCalendar.map((itemCalendar) => {
                          return {
                              title: itemCalendar.title,
                              start: itemCalendar.start ? itemCalendar.start : new Date(),
                              end: itemCalendar.end ? itemCalendar.end : null,
                              //id: itemCalendar.id,
                              plant: itemCalendar.plant ? itemCalendar.plant : "Usina não identificada.",
                              type: itemCalendar.type ? itemCalendar.type : "",
                              status: itemCalendar.status ? itemCalendar.status : "Status não identificado.", 
                              myId: itemCalendar.myId ? itemCalendar.myId : null
                          }
                      })    
                      : [{}]
                    :
                    filterServices === '2'
                    ?
                    services && services.length > 0 ? 
                    services.map((itemCalendar) => {
                          return {
                              title: itemCalendar.title,
                              start: itemCalendar.start ? itemCalendar.start : new Date(),
                              end: itemCalendar.end ? itemCalendar.end : null,
                              //id: itemCalendar.id,
                              plant: itemCalendar.plant ? itemCalendar.plant : "Usina não identificada.",
                              type: itemCalendar.type ? itemCalendar.type : "",
                              status: itemCalendar.status ? itemCalendar.status : "Status não identificado.", 
                              myId: itemCalendar.myId ? itemCalendar.myId : null
                          }
                      })    
                      : [{}]
                    :
                    filterServices === '3'
                    ?
                    reportServices && reportServices.length > 0 ? 
                    reportServices.map((itemCalendar) => {
                          return {
                              title: itemCalendar.title,
                              start: itemCalendar.start ? itemCalendar.start : new Date(),
                              end: itemCalendar.end ? itemCalendar.end : null,
                              //id: itemCalendar.id,
                              plant: itemCalendar.plant ? itemCalendar.plant : "Usina não identificada.",
                              type: itemCalendar.type ? itemCalendar.type : "",
                              status: itemCalendar.status ? itemCalendar.status : "Status não identificado.", 
                              myId: itemCalendar.myId ? itemCalendar.myId : null
                          }
                      })    
                      : [{}]
                    :
                    [{}] 
                  }

                  // events={[
                  //     { title: 'event 1', start: new Date() },
                  //     { title: 'event 2', start: new Date() }
                  // ]}
              />
          </div>
        </div>

        <ModalRequestService 
          show={showModalConfirmReport}
          data={myReportService}
          id={myReportService ? myReportService.id : undefined} 
          handleClose={() => {
            setMyReportService(null)
            setShowModalConfirmReport(false)
            refresh()
          }} 
        />


        <ModalDetailsOS
          show={showModalConfirm}
          data={myService}
          id={myService ? myService.id : undefined} 
          handleClose={() => {
            setMyService(null)
            setShowModalConfirm(false)
            refresh()
          }} 
        />

    </div>
  );
}

function toggleToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}
