/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import { KTSVG } from '../../../_metronic/helpers';
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { useAuth } from '../../modules/auth';
import { SearchInput } from '../components/SearchInput';
import { TableProposals } from './components/TableProposals';
import { getFunnelsAll } from '../../services/funnels';
import { useToast } from '../components/Toats';
import { ModalAddEditFunnel } from './components/ModalAddEditFunnels';
import { ModalDeleteFunnel } from './components/ModalDeleteFunnel';
import { Loading } from '../components/Loadings/loading';
import { getProposalsAll, getProposalsQtd } from '../../services/proposalsService';
import DateSelectorDays from '../components/DateInputDays';
import { getCollaboratosAll } from '../../services/collaboratorsService';
import { getClientAll } from '../../services/clientService';
import KanbanBoard from './components/KanbanBoard';
import KanbanBoardV2 from './components/KanbanBoardV2';

const proposalGeneratorBreadcrumbs: Array<PageLink> = [
  {
    title: 'Painel de Controle',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProposalGeneratorPage: FC = () => {
    const { toggleToast } = useToast();
    const user = useAuth()
    const [mySearch, setMySearch] = useState<any[]>([]);
    const [control, setControl] = useState(false);
    const [myProposals, setMyProposals] = useState<any[]>([]);

    const [myProposalsQtd, setMyProposalsQtd] = useState<any>(null);
    const [funnels, setFunnels] = useState<any[]>([]);
    const [loadingFunnels, setLoadingFunnels] = useState(true);
    const [loadingProposals, setLoadingProposals] = useState(true);
    const [collaborators, setCollaborators] = useState<any[]>([]);
    const [clients, setClients] = useState<any[]>([]);
    const [loadingProposalsQtd, setLoadingProposalsQtd] = useState(true);
    const [filterFunnel, setFilterFunnel] = useState<any>(null);
    const [filterClient, setFilterClient] = useState<any>("");
    const [filterStatus, setFilterStatus] = useState<any>("");
    const [filterDateInit, setFilterDateInit] = useState<any>("");
    const [filterDateEnd, setFilterDateEnd] = useState<any>("");
    const [filterCollaborator, setFilterCollaborator] = useState<any>("");
    const [myFunnel, setMyFunnel] = useState<any>(null)
    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
    const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
    const [showCreateAppModalProposal, setShowCreateAppModalProposal] = useState<boolean>(false)

    const [tab, setTab] = useState<any>("COLUNA");
    

    const getFunnels = async () => {
      try {
        setLoadingFunnels(true)
        const funnels = await getFunnelsAll()
        setFunnels(funnels)

        if(filterFunnel === null){
          setFilterFunnel(funnels.length > 0 ? funnels[0] : {})
        }else{
          setFilterFunnel((prev) => funnels ? funnels.find((funnel: any) => funnel.id === prev.id) : null)
        }
        


      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar os funis. ", "error")
      }finally{
        setLoadingFunnels(false)
      }
    }

    const getProposals = async () => {
      try {
        setLoadingProposals(true)
        const proposals = await getProposalsAll(
          filterStatus, 
          filterFunnel && 
          filterFunnel.id ? filterFunnel.id : "", 
          filterDateInit, 
          filterDateEnd,
          filterCollaborator,
          filterClient
        )
        setMyProposals(proposals)
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar as propostas. ", "error")
      }finally{
        setLoadingProposals(false)
      }
    }

    const getProposalsQtdAll = async () => {
      try {
        setLoadingProposalsQtd(true)
        const qtd = await getProposalsQtd(filterFunnel && filterFunnel.id ? filterFunnel.id : "")
        setMyProposalsQtd(qtd)
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar as quantidades de propostas. ", "error")
      }finally{
        setLoadingProposalsQtd(false)
      }
    }

    const getUsersAllCollaborators = async () => {
      try {
        //setLoadingCollaborators(true)
        const collaboratos = await getCollaboratosAll()
        setCollaborators(collaboratos)
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar os colaboradores. ", "error")
  
      }finally{
        //setLoadingCollaborators(false)
      }
    }

    const getClients = async () => {
      try {
        const myClient = await getClientAll()
        setClients(myClient)
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar os clientes!", "error")
      }finally{
      }
    }


    const refresh = () => {
      getFunnels()
      getProposals()
    }

    const refreshV2 = () => {
      getProposals();
    }

    useEffect(() => {
      async function all(){
        await getFunnels()
        await getProposalsQtdAll()
        await getProposals()
        await getUsersAllCollaborators()
        await getClients()
      }
      all()
    }, [])

    useEffect(() => {
      getProposals()
      getProposalsQtdAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      filterStatus, 
      filterFunnel, 
      filterDateEnd, 
      filterDateInit, 
      filterCollaborator,
      filterClient
    ])

    useEffect(() => {
      console.log(tab)
    }, [tab])

    if (loadingFunnels) {
      return (
        <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
          <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
        </div>
      );
    }

  return (
    <div className='column g-5 g-xl-8 card p-7'>
      
      <div style={{display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
          <div style={{display: 'flex'}}>
            <a
              onClick={() => {setTab('LINHA')}}
              href='#'
              style={{
                width: '4rem', 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center',
                borderTopRightRadius: '0px',
                borderEndEndRadius: '0px',

              }}
              className={`btn btn-sm btn-light-primary ${tab === 'LINHA' ? 'active' : ''} text-active-white`}
            >
              <KTSVG path='/media/icons/duotune/text/txt012.svg' className='svg-icon-2' />
            </a>

            <a
              onClick={() => {setTab('COLUNA')}}
              href='#'
              style={{
                width: '4rem', 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center',
                borderTopLeftRadius: '0px',
                borderEndStartRadius: '0px'
              }}
              className={`btn btn-sm btn-light-primary ${tab === 'COLUNA' ? 'active' : ''} text-active-white`}
            >
              <KTSVG path='/media/icons/duotune/general/gen009.svg' className='svg-icon-2' />
            </a>
          </div>

          {/* <div className="btn-group" role="group" aria-label="Basic radio toggle button group" 
            style={{width: '10rem', height: '3rem'}}
          >
            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" checked onClick={() => {setTab('LINHA')}}/>
            <label className="btn btn-outline-primary" htmlFor="btnradio1" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <KTSVG path='/media/icons/duotune/text/txt012.svg' className='svg-icon-3' />
            </label>

            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" onClick={() => {setTab('COLUNA')}}/>
            <label className="btn btn-outline-primary" htmlFor="btnradio2" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <KTSVG path='/media/icons/duotune/general/gen009.svg' className='svg-icon-3' />
            </label>
          </div> */}

          <div style={{width: '25rem'}}>
            <div className='me-0 dropdown' style={{width: '100%'}}>
              <button
                className='btn btn-lg btn-light buttonFilterMark'
                type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                style={{display: 'flex', gap: '1rem'}}
              >
                {/* <span>{filterConcessionaries !== "" ? filterConcessionaries : "Todas"} </span> */}
                <span>{filterFunnel ? filterFunnel.description : ""}</span>
                <i className='bi bi-three-dots fs-3'></i>
              </button>

              <div className='dropdown-menu dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem', width: '100%'}}>
                <div className='card-toolbar nav nav-tabs' data-kt-buttons='true' style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start'}}>
                  {funnels && funnels.map((funnel: any) => {
                  return (
                    <a
                      className={`btn btn-sm btn-active btn-active-primary ${filterFunnel && filterFunnel.id === funnel.id ? 'active' : ''} px-5 me-1 nav-item`}
                      style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                      data-bs-toggle="tab"
                      href="#"
                      onClick={()=> {
                        setFilterFunnel(funnel ? funnel : "")
                      }}
                    >
                    <span style={{textAlign: 'start'}}>
                      {funnel.description ? funnel.description : "Não identificado"} 
                    </span>
                    <div>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                            onClick={(event: any) =>{
                                setShowCreateAppModal(true)
                                setMyFunnel(funnel)
                                //handleButtonClickUpdate(event, unitBranch)
                            }}
                            href='#'
                            className='btn btn-icon btn-bg-light btn-sm me-1'
                        >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3 text-primary' />
                        </a>
                        <button
                          disabled={funnel.type && funnel.type === "FIXO" ? true : false}
                          onClick={(event: any) => {
                            setMyFunnel(funnel)
                            setShowCreateAppModalDelete(true)

                          }}
                          //href='#'
                          className='btn btn-icon btn-bg-light btn-sm'
                        >
                            <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3 text-primary'
                            />
                        </button>
                      </div>
                    </div>
                  </a>
                    // <option key={concessionarie.id} value={concessionarie.name}>{concessionarie.name}</option>
                  )
                })}
                <div className='separator my-2 mb-2' style={{width: '100%'}}></div>

                <div style={{width: '100%'}}>
                  <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click para adicionar uma unidade filial'
                    style={{width: '100%', display: 'flex', justifyContent: 'end'}}
                  >
                    <a
                      onClick={() =>{
                        setShowCreateAppModal(true)
                      }}
                      href='#'
                      style={{width: "100%"}}
                      className='btn btn-sm btn-light-primary'
                      //data-bs-target='#kt_modal_create_unitBranch'

                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                      Adicionar Funil
                    </a>
                  </div>
                </div>

                </div>
              </div>
            </div>
          </div>

          <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
            <a
                className='btn btn-sm btn-color-muted btn-active btn-active-dark active px-5 me-1 nav-item'
                data-bs-toggle="tab"
                href="#"
                style={{display: 'flex', alignItems: 'center'}}
                onClick={()=> {
                  setFilterStatus("")
                }}
            >
                Todas 
                <span 
                className='badge badge-dark spanFilter'

                >
                {myProposals.length}
                </span>
            </a>
            <a
                className='btn btn-sm btn-color-muted btn-active btn-active-warning px-4 nav-item'
                data-bs-toggle="tab"
                href="#"
                style={{display: 'flex', alignItems: 'center'}}
                onClick={()=> {
                  setFilterStatus("EM NEGOCIACAO")
                }}
            >
                Em Negociação
                <span 
                className='badge badge-warning spanFilter'
                >
                {myProposalsQtd && myProposalsQtd.em_negociacao ? myProposalsQtd.em_negociacao : 0}
                </span>
            </a>
            <a
                className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                data-bs-toggle="tab"
                href="#"
                style={{display: 'flex', alignItems: 'center'}}
                onClick={()=> {
                  setFilterStatus("NEGOCIO RECUSADO")
                }}
            >
                Negócio Recusado
                <span 
                className='badge badge-danger spanFilter'
                >
                {myProposalsQtd && myProposalsQtd.negocio_recusado ? myProposalsQtd.negocio_recusado : 0}
                </span>
            </a>
            <a
                className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 me-1 nav-item'
                data-bs-toggle="tab"
                href="#"
                style={{display: 'flex', alignItems: 'center'}}
                onClick={()=> {
                  setFilterStatus("NEGOCIO FECHADO")
                }}
            >
                Negócio Fechado
                <span 
                className='badge badge-success spanFilter'
                >
                {myProposalsQtd && myProposalsQtd.negocio_fechado ? myProposalsQtd.negocio_fechado : 0}
                </span>
            </a>
          </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'end'}}>
          <div>
            <div className="dropdown">
              <button 
                type="button" 
                className="btn btn-lg btn-light btn-active-color-primary btn-sm dropdown-toggle" 
                data-bs-toggle="dropdown" 
                aria-expanded="false" 
                data-bs-auto-close="outside"
              >
                <span><KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-3'/></span>
              </button>
              <div className="dropdown-menu dropdown-menu-end p-4" style={{width: 'auto', minWidth: '375px', maxWidth: '400px'}}>
                <label className='form-label fw-bold' style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}>Outros Filtros</label>
                <div className='separator my-2'></div>

                <div style={{display: 'flex',  gap: '1rem'}}>
                  <div>
                    <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Data de Inicio</label>
                    <DateSelectorDays 
                        selectedDate={filterDateInit !== "" ? new Date(filterDateInit) : new Date()}
                        //selectedDate={new Date()}
                        setSelectedDate={(date: any) => {
                            //formik.setValues({...formik.values, initPeriod: date.toString()})
                            setFilterDateInit(date.toString())
                        }}
                    />
                  </div>

                  <div>
                    <label className='form-label' style={{marginBottom: '0.5rem'}}>Data de Encerramento</label>
                    <DateSelectorDays 
                        //selectedDate={new Date(formik.values.initPeriod)}
                        //selectedDate={new Date()}
                        selectedDate={filterDateEnd !== "" ? new Date(filterDateEnd) : new Date()}
                        setSelectedDate={(date: any) => {
                            //formik.setValues({...formik.values, initPeriod: date.toString()})
                            setFilterDateEnd(date.toString())
                        }}
                    />
                  </div>
                </div>

                <div style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                  <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Colaborador</label>
                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={filterCollaborator !== "" ? filterCollaborator : ""}
                      value={filterCollaborator}
                      onChange={(e: any) => {
                        setFilterCollaborator(e.target.value);
                      }}
                    >
                      <option value={""}></option>
                      {collaborators && collaborators.map((collaborator: any) => {
                        return (
                          <option key={collaborator.id} value={collaborator.id}>{collaborator.name}</option>
                        )
                      })}
                      
                    </select>
                  </div>
                </div>

                <div style={{marginTop: '0.5rem', marginBottom: '1rem'}}>
                  <label className='form-label fs-7' style={{marginBottom: '0.5rem'}}>Cliente</label>
                  <div>
                    <select
                      className='form-select form-select-solid'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      defaultValue={filterClient !== "" ? filterClient : ""}
                      value={filterClient}
                      onChange={(e: any) => {
                        setFilterClient(e.target.value);
                      }}
                    >
                      <option value={""}></option>
                      {clients && clients.length > 0 && clients.map((client: any) => {
                        return (
                          <option key={client.id} value={client.id}>{client.nameResponsible}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>

                <div className='separator my-2'></div>

                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <a 
                    href="#" 
                    className='btn btn-color-gray-600 btn-active-color-primary' 
                    onClick={() => {
                      setFilterDateEnd("")
                      setFilterDateInit("")
                      setFilterStatus("")
                      setFilterCollaborator("")
                      setFilterClient("")
                    }}>
                    Limpar todos os filtros <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
                  </a>
                </div>

              </div>
            </div>
          </div>

          <SearchInput 
              className='form-control form-control-solid ps-14 bg-body'
              placeholder='Buscar Propostas'
              myDate={myProposals}
              setMySearch={setMySearch}
              control={control}
              setControl={setControl}
              searchParameter="title"
              style={{width: "15rem"}}
          />
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click para adicionar uma unidade filial'
            style={{display: 'flex', justifyContent: 'end'}}
          >
            <a
              onClick={() =>{
                setShowCreateAppModalProposal(true)
              }}
              href='#'
              className='btn btn-sm btn-light-primary'
              //data-bs-target='#kt_modal_create_unitBranch'

            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Nova Proposta
            </a>
          </div>
        </div>
      </div>

      {tab === 'LINHA' ?
      <div>
        {loadingProposals ? 
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
        </div>
        : 
          <TableProposals 
            className='card-xl-stretch mb-xl-8'
            proposals={myProposals}
            refresh={refresh}
            filterFunnel={filterFunnel} 
            mySearch={mySearch}
            control={control}
            setShowCreateAppModal={setShowCreateAppModalProposal}
            showCreateAppModal={showCreateAppModalProposal}
          />
        }
        
      </div>
      :
        control ?
          <div>
            <KanbanBoard 
              className='card-xl-stretch mb-xl-8'
              proposals={mySearch}
              refresh={refresh}
              filterFunnel={filterFunnel} 
              mySearch={mySearch}
              control={control}
              setShowCreateAppModal={setShowCreateAppModalProposal}
              showCreateAppModal={showCreateAppModalProposal}

              setMyFunnel={setMyFunnel}
              setShowCreateAppModalFunnel={setShowCreateAppModal}
            />
          </div>
        :
          <div>
            <KanbanBoard
              className='card-xl-stretch mb-xl-8'
              proposals={myProposals}
              refresh={refresh}
              filterFunnel={filterFunnel} 
              mySearch={mySearch}
              control={control}
              setShowCreateAppModal={setShowCreateAppModalProposal}
              showCreateAppModal={showCreateAppModalProposal}

              setMyFunnel={setMyFunnel}
              setShowCreateAppModalFunnel={setShowCreateAppModal}
            />
          </div>
      }
      
      <ModalAddEditFunnel 
        show={showCreateAppModal}
        id={myFunnel && myFunnel.id ? myFunnel.id : undefined} 
        handleClose={() => {
          setMyFunnel(null)
          setShowCreateAppModal(false)
          refresh()
        }} 
      />
      <ModalDeleteFunnel 
        show={showCreateAppModalDelete}
        data={myFunnel}
        id={myFunnel ? myFunnel.id : undefined} 
        handleClose={() => {
          setMyFunnel(null)
          setShowCreateAppModalDelete(false)
          refresh()
        }} 
      />
    </div>
  )
}

const ProposalGeneratorWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={proposalGeneratorBreadcrumbs}>Gestão Comercial</PageTitle>
      <ProposalGeneratorPage />
    </>
  )
}

export {ProposalGeneratorWrapper}
// function toggleToast(arg0: string, arg1: string) {
//     throw new Error('Function not implemented.');
// }

