import api from "./api";

export const getUnitConsumerAll = async () => {
    const response = await api.get(`unitConsumer/listAll`);
    return response.data;
};

export const getUnitConsumerAllFilter = async (concessionarie?: string | null, plant?: string | null) => {
    const response = await api.get(`unitConsumer/listAll?concessionarie=${concessionarie ? concessionarie : ""}&plant=${plant ? plant : ""}`);
    return response.data;
};

export const getUnitConsumerAllFilterPagination = async (concessionarie?: string | null, plant?: string | null, limit?: number, page?: number) => {
    const response = await api.get(`unitConsumer/listAllPagination?concessionarie=${concessionarie ? concessionarie : ""}&plant=${plant ? plant : ""}&limit=${limit ? limit : ""}&page=${page ? page : ""}`);
    return response.data;
};

export const postUnitConsumerAll = async (body: any) => {
    const response = await api.post(`unitConsumer/`, body);
    return response.data;
};

export const updateUnitConsumerAll = async (body: any, id: string) => {
    const response = await api.put(`unitConsumer/${id}`, body);
    return response.data;
};