/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'

import {getCSS, getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { formatDateToYYYYMMDD, getShortMonthNameString } from '../../../../../utils/date'

type Props = {
  className: string
  charts: any
  setShowCreateAppModal: any
  prognostico?: any;
  largura: any
  myProg?: any
}

const BarGraph: React.FC<Props> = ({className, charts, setShowCreateAppModal, prognostico, myProg}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [listDates, setListDates] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<any>("");
  const [largura, setLargura] = useState(window.innerWidth);
  const [altura, setAltura] = useState(window.innerHeight);
  const [myPrognostics, setMyPrognostics] = useState<any>(null);
  
  const [chartsValues, setChartsValues] = useState({
    metaDates: [],
    valores: [],
    type: "" 
  });

  const handleChartDeploy = () => {
    if (charts && Object.keys(charts).length > 0 && selectedValue && selectedValue !== "" && charts[selectedValue]) {
      const selectedChart = charts[selectedValue];
      const myMetaDates = selectedChart.type && (selectedChart.type === "mounth_charts" || selectedChart.type === "week_charts") 
        ? selectedChart.dias
        : selectedChart.type === "year_charts"
        ? selectedChart.meses
        : selectedChart.type === "total_charts"
        ? selectedChart.anos 
        : [];
  
      const myValores = selectedChart.valores ? selectedChart.valores : [];

      if(myProg){
        if(charts && charts[selectedValue]["type"] === "week_charts"){
          const value = getShortMonthNameString(selectedValue)
          const value_prop = `prog_${value}`;
          const value_final = myProg[value_prop];

          //setMyPrognostics(value_final / 30)

          const my_array = myMetaDates ? Array(myMetaDates.length).fill(parseFloat(((value_final)/30).toFixed(2))) : [] 
          setMyPrognostics(my_array)
        }
      }
  
      setChartsValues({
        metaDates: myMetaDates,
        valores: myValores,
        type: ""
      });
    }
  };

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
    setAltura(window.innerHeight);
  };
  
  useEffect(() => {
    handleChartDeploy()
  }, [selectedValue])
  
  useEffect(() => {
      if(charts){
        const string_date : string = Object.keys(charts)[0]
        setSelectedValue(string_date)
        const list = Object.entries(charts);
        setListDates(list)
      }

  }, [charts])

  useEffect(() => {
    setSelectedValue(formatDateToYYYYMMDD())

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [])

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartsValues])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartsValues.valores, chartsValues.metaDates, myPrognostics ? myPrognostics : null, largura))
    if (chart) {
      chart.render()
    }
    return chart
  }

  if (!charts) {
    return <h1>Carrengando...</h1>;
  }

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '1rem', paddingLeft: '0.5rem'}}>
        <h3 className='align-items-start flex-column'>
          <span className={`fw-bold fs-${largura < 1200 ? '6' : '3'}`}>Ultimos 7 dias</span>
        </h3>
        <div className='card-toolbar' data-kt-buttons='true'>
          {/* <div className='d-flex ap0: Datelign-items-center my-2'>
            <div className='w-130px me-5'>
              <select
                name='status'
                data-control='select2'
                data-hide-search='true'
                className='form-select form-select-white form-select-sm'
                value={selectedValue}
                onChange={handleSelectChange}
              >
                {
                  listDates.map((chart: any) => {
                    return (
                      <option value={chart[0]}>{chart[0]}</option>
                    );
                  })
                }
              </select>
            </div>
          </div> */}
        </div>
        <div className='text-hover-primary' onClick={() => {setShowCreateAppModal(true)}}>
          <KTSVG 
            path={'/media/icons/duotune/arrows/arr024.svg'} 
            className={`svg-icon-muted svg-icon-2x ms-n1`} 
          />
        </div>
      </div>

      <div className=''>
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: largura && largura < 1200 ? '250px' : '450px', width: '100%'}} />
      </div>
    </div>
  )
}

export {BarGraph}

function getChartOptions(height: number, valores: any, metaDates: any, prognostico?: any, largura? : any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')


  return {
    series: [
      {
        name: 'Energia Gerada',
        type: 'bar',
        data: valores,
      },
      {
        name: 'Prognóstico',
        type: 'line',
        //data: Array(metaDates.length).fill(prognostico ? parseFloat(((prognostico/12)/30).toFixed(2)) : 0) 
        data: prognostico ? prognostico : []
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: [0, 3],
      colors: ['transparent', '#f1416c'],
      curve: 'smooth'
    },
    xaxis: {
      categories: metaDates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: largura && largura < 1200 ? '9px' : '14px',
        },
      },
    },
    yaxis: [
      {
        min: 0,
        seriesName: 'Energia Gerada',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: baseColor
        },
        labels: {
          style: {
            colors: baseColor,
          }
        },
        title: {
          text: "Geração (KWh)",
          style: {
            color: baseColor,
          }
        },
        tooltip: {
          enabled: true
        }
      },
    ],
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
          if (seriesIndex === 1) {
            return val + ' KWh (Prognóstico)';
          }
          return val + ' KWh';
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
  // return {
  //   series: [
  //     {
  //       name: 'Energia Gerada',
  //       type: 'bar',
  //       data: valores,
  //     },
  //     {
  //       name: 'Prognóstico',
  //       type: 'line',
  //       data: Array(metaDates.length).fill(prognostico ? parseFloat(((prognostico/12)/30).toFixed(2)) : 0) 
  //     }
  //   ],
  //   chart: {
  //     fontFamily: 'inherit',
  //     type: 'bar',
  //     height: height,
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       columnWidth: '50%',
  //       borderRadius: 5,
  //     },
  //   },
  //   legend: {
  //     show: false,
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     show: true,
  //     width: [0, 3],  // Largura 0 para a barra, 2 para a linha
  //     colors: ['transparent', secondaryColor],  // Cor para a linha
  //     curve: 'smooth'
  //   },
  //   xaxis: {
  //     categories: metaDates,
  //     axisBorder: {
  //       show: false,
  //     },
  //     axisTicks: {
  //       show: false,
  //     },
  //     labels: {
  //       style: {
  //         colors: labelColor,
  //         fontSize: '12px',
  //       },
  //     },
  //   },
  //   yaxis: [
  //     {
  //       min: 0,
  //       seriesName: 'Energia Gerada',
  //       axisTicks: {
  //         show: true,
  //       },
  //       axisBorder: {
  //         show: true,
  //         color: baseColor
  //       },
  //       labels: {
  //         style: {
  //           colors: baseColor,
  //         }
  //       },
  //       title: {
  //         text: "Geração (KWh)",
  //         style: {
  //           color: baseColor,
  //         }
  //       },
  //       tooltip: {
  //         enabled: true
  //       }
  //     },
  //   ],
  //   states: {
  //     normal: {
  //       filter: {
  //         type: 'none',
  //         value: 0,
  //       },
  //     },
  //     hover: {
  //       filter: {
  //         type: 'none',
  //         value: 0,
  //       },
  //     },
  //     active: {
  //       allowMultipleDataPointsSelection: false,
  //       filter: {
  //         type: 'none',
  //         value: 0,
  //       },
  //     },
  //   },
  //   tooltip: {
  //     style: {
  //       fontSize: '12px',
  //     },
  //     y: {
  //       formatter: function (val) {
  //         return val + ' KWh';
  //       },
  //     },
  //   },
  //   colors: [baseColor, secondaryColor],
  //   grid: {
  //     borderColor: borderColor,
  //     strokeDashArray: 4,
  //     yaxis: {
  //       lines: {
  //         show: true,
  //       },
  //     },
  //   },
  // };
  // return {
  //   series: [
  //     {
  //       name: 'Energia Gerada',
  //       data: valores,
  //     }
  //   ],
  //   chart: {
  //     fontFamily: 'inherit',
  //     type: 'bar',
  //     height: height,
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: false,
  //       columnWidth: '50%',
  //       borderRadius: 5,
  //     },
  //   },
  //   legend: {
  //     show: false,
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     show: true,
  //     width: 2,
  //     colors: ['transparent'],
  //   },
  //   xaxis: {
  //     categories: metaDates,
  //     axisBorder: {
  //       show: false,
  //     },
  //     axisTicks: {
  //       show: false,
  //     },
  //     labels: {
  //       style: {
  //         colors: labelColor,
  //         fontSize: '12px',
  //       },
  //     },
  //   },
  //   yaxis: {
  //     labels: {
  //       style: {
  //         colors: labelColor,
  //         fontSize: '12px',
  //       },
  //     },
  //   },
  //   fill: {
  //     opacity: 1,
  //   },
  //   states: {
  //     normal: {
  //       filter: {
  //         type: 'none',
  //         value: 0,
  //       },
  //     },
  //     hover: {
  //       filter: {
  //         type: 'none',
  //         value: 0,
  //       },
  //     },
  //     active: {
  //       allowMultipleDataPointsSelection: false,
  //       filter: {
  //         type: 'none',
  //         value: 0,
  //       },
  //     },
  //   },
  //   tooltip: {
  //     style: {
  //       fontSize: '12px',
  //     },
  //     y: {
  //       formatter: function (val) {
  //         return val + ' KWh'
  //       },
  //     },
  //   },
  //   colors: [baseColor, secondaryColor],
  //   grid: {
  //     borderColor: borderColor,
  //     strokeDashArray: 4,
  //     yaxis: {
  //       lines: {
  //         show: true,
  //       },
  //     },
  //   },
  // }
}
