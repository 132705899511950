/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { useToast } from '../../../components/Toats'
import { LoadingSpan } from '../../../components/Loadings/loadingSpan'
import { Loading } from '../../../components/Loadings/loading'
import { getFunnelsOne, postFunnelsOne, putFunnelsOne } from '../../../../services/funnels'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { StepModal } from './components/stepModal'
import { postPhasesFunnelOne, putPhasesFunnelOne } from '../../../../services/phasesFunnel'


type Props = {
    show: boolean
    id?: string | undefined
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
    idFunnel: Yup.string(),
    description: Yup.string(),
    idsUnitBranch: Yup.array()
    .of(Yup.string())
    .notRequired()
    .default([]),
});

const validationSchemaPhases = Yup.object().shape({
    idPhaseFunnel: Yup.string(),
    description: Yup.string(),
    order: Yup.number(),
    idFunnel: Yup.string()
});



const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddEditFunnel = ({show, handleClose, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingUpdatePhaseFunnel, setLoadingUpdatePhaseFunnel] = useState(false)
  const [loadingPhaseFunnel, setLoadingPhaseFunnel] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)

  const [initialValues, setInitialValues] = useState<any>({
    idFunnel: null,
    description: null,
    idsUnitBranch: [],
  })

  const [initialValuesPhases, setInitialValuesPhases] = useState<any>({
    idPhaseFunnel: null,
    description: null,
    order: null,
    idFunnel: null,
  })
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const getFunnel = async () => {
    try {
      setLoadingUpdate(true)
      const funnel = await getFunnelsOne(id ? id : "")
      const myData = {
        idFunnel: funnel && funnel.id ? funnel.id : "",
        description: funnel && funnel.description ? funnel.description : "",
        idsUnitBranch: funnel.unitBranchs.length > 0 ? funnel.unitBranchs.map((unitBranch: any) => unitBranch.id) : []
      }
      formik.setValues(myData)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar o funil. ", "error")
    }finally{
      setLoadingUpdate(false)
      //handleClose()
      //window.location.reload()
    }
  }

  const postFunnis = async () => {
    try {
      setLoading(true)
      const myData = {
        description: formik.values.description,
        idsUnitBranchs: formik.values.idsUnitBranch
      }
      const funil = await postFunnelsOne(myData)
      if(funil){
        formik.setValues({...formik.values, description: funil.description})
        formik.setValues({...formik.values, idFunnel: funil.id})
      }
      toggleToast("Sucesso ao cadastrar o funil. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao cadastrar o funil. ", "error")
    }finally{
      setLoading(false)
      //handleClose()
      //window.location.reload()
    }
  }

  const updateFunnel = async () => {
    try {
      setLoading(true)
      const myData = {
        description: formik.values.description,
        idsUnitBranchs: formik.values.idsUnitBranch
      }
      const funnel = await putFunnelsOne(myData, id ? id : formik.values.idFunnel ? formik.values.idFunnel : "")
      if(funnel){
        formik.setValues({...formik.values, description: funnel.description})
        formik.setValues({...formik.values, idFunnel: funnel.id})
      }
      toggleToast("Sucesso ao atualizar o funil. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar o funil. ", "error")
    }finally{
      setLoading(false)
      //handleClose()
      //window.location.reload()
    }
  }

  const updatePhasesFunnel = async () => {
    try {
      setLoadingPhaseFunnel(true)
      const myData = {
        order: formikPhases.values.order,
        description: formikPhases.values.description,
        //idsUnitBranchs: formik.values.idsUnitBranch
      }
      await putPhasesFunnelOne(myData, formikPhases.values.idPhaseFunnel ? formikPhases.values.idPhaseFunnel : "")
      
      toggleToast("Sucesso ao atualizar a etapa do funil. ", "success")

      formikPhases.setValues({
        idPhaseFunnel: null,
        description: "",
        order: 0,
        idFunnel: null,
      });

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar a etapa do funil. ", "error")
    }finally{
      setLoadingPhaseFunnel(false)
      //handleClose()
      //window.location.reload()
    }
  }

  const postPhaseFunnel = async () => {
    try {
      setLoadingPhaseFunnel(true)
      const myData = {
        description: formikPhases.values.description,
        order: formikPhases.values.order,
        idFunnel: formik.values.idFunnel
      }
      await postPhasesFunnelOne(myData)

      toggleToast("Sucesso ao cadastrar a etapa no funil. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao cadastrar a etapa no funil. ", "error")
    }finally{
      setLoadingPhaseFunnel(false)
      formikPhases.setValues({
        idPhaseFunnel: null,
        order: 0,
        description: "",
        idFunnel: null,
      });
      //handleClose()
      //window.location.reload()
    }
  }
//   const updateData = (fieldsToUpdate: any) => {
//     const updatedData = {...data, ...fieldsToUpdate}
//     setData(updatedData)
//   }

//   const checkInputs = (): boolean => {
//     if (!data.username || !data.senha || !data.idUnitBranch) {
//       console.log('erro')
//       return false
//     }
//     return true
//   }




  const submit = () => {
    if(id || formik.values.idFunnel){
      updateFunnel()
    }else{
      postFunnis()
      
    }
  }

  const submitPhases = () => {
    if(formikPhases.values.idPhaseFunnel){
      updatePhasesFunnel()
    }else{
      if(id || formik.values.idFunnel){
        postPhaseFunnel()
      }
    }
  }

  const nextStep = () => {
    // setHasError(false)
    // if (!stepper.current) {
    //   return
    // }
    // if (!checkInputs()) {
    // setHasError(true)
    // return
    // }
    submit()
  }


  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });

  const formikPhases = useFormik({
    initialValues: initialValuesPhases,
    validationSchema: validationSchemaPhases,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(id){
        getFunnel()
    }
  }, [show]);

  if (loadingUpdate) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
       
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Gestão de Funis</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {mark.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary'
          onClick={() => {
            formik.setValues({
              idFunnel: null,
              description: null,
              idsUnitBranch: [],
            });
            formikPhases.setValues({
              idPhaseFunnel: null,
              order: null,
              description: null,
              idFunnel: null,
            });
            handleClose()
          }}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         

          {/*begin::Content */}
          <div className='flex-row-fluid'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' >
                <StepModal 
                  hasError={hasError} 
                  formik={formik} 
                  formikPhases={formikPhases}
                  submit={submit}
                  submitPhases={submitPhases}
                  loadingPhaseFunnel={loadingPhaseFunnel}
                  loadingFunnel={loading}
                />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
                {/*<div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    Previous
                  </button>
                </div>*/}
 
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-dark'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={() => {
                      formik.setValues({
                        idFunnel: null,
                        description: null,
                        idsUnitBranch: [],
                      })

                      formikPhases.setValues({
                        idPhaseFunnel: null,
                        description: null,
                        idFunnel: null,
                      })
                      handleClose()
                    }}
                  >
                    {"Concluir"}{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>

                  {/*<button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next Step{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                    </button>*/}
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddEditFunnel}
