/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../../../_metronic/helpers'

type Props = {
  unitBranchs: any
}

const UnitBranchLinkedClient: FC<Props> = ({
  unitBranchs,
}) => {
  return (
    <div className='card'>
        <div className='card-header pt-5 pb-5'>
            <h3 className='card-title' style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
              <span className='card-label fw-bold text-dark'>Unidade (Filial)</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>Unidade Filial vinculada ao Cliente</span>
            </h3>
        </div>
        {unitBranchs ? 
          unitBranchs.map((unitBranch: any) => {
            return (
              <div className='card-body d-flex flex-row p-9' style={{gap: '1rem', alignItems: 'center'}}>
                <div className='mb-5'>
                <div className='symbol symbol-75px'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/icons/duotune/general/gen001.svg')} />
                </div>
                </div>
                <div>
                    <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
                        {unitBranch ? unitBranch.companyName : "Não identificado."}
                    </a>
                    <div className='fw-bold text-gray-400'>{unitBranch ? unitBranch.cnpj : "Não identificado."}</div>
                    <div className='fw-bold text-gray-400'>{unitBranch ? unitBranch.emailCompany : "Não identificado."}</div>
                </div>
              </div> 
            )
          })   
          : 
            <div className='card-body'  style={{height: '20rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <h3 className='card-title align-items-start flex-column'>
                <span className='text-muted mt-1 fw-semibold fs-7'>Não há unidades filiais vinculados.</span>
              </h3>
            </div>
          }
    </div>
  )
}

export {UnitBranchLinkedClient}
