import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { getFile } from '../../../../app/services/files'
import useSize from '../../../../app/hooks/useSize'

const SidebarLogoV2 = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth();
  
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled

  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  const {width} = useSize()
  const [imgUrl, setImgUrl] = useState<string | null>(null);

  const getImage = async (key: string) => {
    try {
      const url = await getFile(key)
      setImgUrl(url.url)

    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    if(currentUser 
      && currentUser.companies 
      && currentUser.companies.length > 0 
      && currentUser.companies[0].image){
        if(width < 1200){
          getImage(currentUser.companies[0].image_cell)
        }else{
          getImage(currentUser.companies[0].image_dark)
        }
        
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo' 
      style={{
        minHeight: '5.4rem',
        height: 'auto', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
      }}>
      <Link to='/dashboard'>
        {config.layoutType === 'dark-sidebar' ? (
          // <img
          //   alt='Logo'
          //   src={toAbsoluteUrl('/media/logos/default-dark.svg')}
          //   className='h-25px app-sidebar-logo-default'
          // />

          <div>
            {imgUrl
            ? 
              width < 1200 ? 
              (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl(imgUrl)}
                  className="h-50px app-sidebar-logo-default p-2 m-5"
                  //className='h-200px app-sidebar-logo-default border border-dashed p-2 m-5'
                  //style={{border: 'red !important'}}
                />
              )
              : 
              (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl(imgUrl)}
                  className="h-200px app-sidebar-logo-default p-2 m-5"
                  //className='h-200px app-sidebar-logo-default border border-dashed p-2 m-5'
                  //style={{border: 'red !important'}}
                />
              )
            :
              (
                <img
                alt='Logo'
                src={toAbsoluteUrl('/media/imgs/sua-logo-aqui.png')}
                className="h-200px app-sidebar-logo-default p-2 m-5"
                //className='h-200px app-sidebar-logo-default border border-dashed p-2 m-5'
                //style={{border: 'red !important'}}
                
            />
              )
            }

          </div>
        ) : (
          <>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/imgs/logo.png')}
              className='h-50px app-sidebar-logo-default'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/imgs/logo.png')}
              className='h-50px app-sidebar-logo-default'
            />
          </>
        )}

        {imgUrl
          ? 
          (
            <img
              alt='Logo'
              src={toAbsoluteUrl(imgUrl)}
              className='h-30px app-sidebar-logo-minimize'
            />
          )
          :
          (
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/imgs/sua-logo-aqui.png')}
              className='h-30px app-sidebar-logo-minimize'
            />
          )
        }
      </Link>

      {width > 1200 && (appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            {active: appSidebarDefaultMinimizeDefault}
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180' />
        </div>
      )}
    </div>
  )
}

export {SidebarLogoV2}
