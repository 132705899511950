import api from "./api";

export const postPermissionsOne = async (body: any) => {
    const response = await api.post(`permissions/`, body);
    return response.data;
};

// export const getPermissionsUser = async () => {
//     const response = await api.get(`template/listAll`,);
//     return response.data;
// };

export const putPermissionsOne = async (id: string, body: any) => {
    const response = await api.put(`permissions/${id}`, body);
    return response.data;
};
