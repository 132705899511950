import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';
import { putServiceOne } from '../../../../../../../../services/servicesService';
import { formatarData } from '../../../../../../../../utils/date';
import { linkPDF } from '../../../../../../../../utils/savePDF';
import { useToast } from '../../../../../../../components/Toats';
import { useAuth } from '../../../../../../../../modules/auth';
import { getFileBase64 } from '../../../../../../../../services/files';

type Props = {
    itemOrderOfService: any;
    refresh: any;
    setShowModalDelete: any
    setMyOrderService: any
    setShowCreateAppModal: any
    setShowModalTemplate: any
}

export function ItemOrderOfService({
    itemOrderOfService, 
    refresh, 
    setShowModalDelete, 
    setMyOrderService, 
    setShowCreateAppModal,
    setShowModalTemplate
} : Props) {

    const [loadingUpdateOS, setLoadingUpdateOS] = useState<boolean>(true);
    const { toggleToast } = useToast();
    const [loadingPDF, setLoadingPDF] = useState<boolean>(false)
    const [largura, setLargura] = useState(window.innerWidth);
    const {currentUser} = useAuth()
    
    const updateOSOne = async (id: any, status: any) => {
        try {
          setLoadingUpdateOS(true)
          const body = {
            status: status
          }
          await putServiceOne(id, body)
          toggleToast("Sucesso ao atualizar a O.S. ", "success")
          //setClients(MyClient)
    
        } catch (error) {
          console.log(error)
          toggleToast("Erro ao atualizar a O.S. ", "error")
          
        }finally{
          setLoadingUpdateOS(false)
          refresh()
        }
    }


    const getImage = async (key: string) => {
        try {
          const url = await getFileBase64(key)
          return url.base64
    
        } catch (error) {
          console.log(error)
          return ""
        }
      }

    const replaceValues = async (dados: any, html: string) => {
        let novoHtml = html;
        const imageCompany = dados 
        && dados.unitBranch 
        && dados.unitBranch.company 
        && dados.unitBranch.company.image 
        ? await getImage(dados.unitBranch.company.image_dark)
        : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.pngs";

        let dados_OS = {
            imageCompany: imageCompany ? `data:image/jpeg;base64,${imageCompany}` : "https://i.postimg.cc/kXzT1qjT/sua-logo-aqui.pngs",
            corporateName: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.corporateName ? dados.unitBranch.company.corporateName : "",
            cnpj: dados && dados.unitBranch && dados.unitBranch.company && dados.unitBranch.company.cnpj ? dados.unitBranch.company.cnpj : "",
            street: dados && dados.unitBranch && dados.unitBranch.street ? dados.unitBranch.street : "",
            district: dados && dados.unitBranch && dados.unitBranch.district ? dados.unitBranch.district : "",
            number: dados && dados.unitBranch && dados.unitBranch.number ? dados.unitBranch.number : "",
            telephone: dados && dados.unitBranch && dados.unitBranch.telephone ? dados.unitBranch.telephone : "",
            emailCompany: dados && dados.unitBranch && dados.unitBranch.emailCompany ? dados.unitBranch.emailCompany : "",
            number_order_service: dados && dados.number_order_service ? dados.number_order_service : "",
            dateCreate: dados && dados.created_at ? formatarData(dados.created_at) : "",
            nameResponsible: dados && dados.client && dados.client.nameResponsible ? dados.client.nameResponsible : "",
            cpfResponsible: dados && dados.client && dados.client.cpfResponsible ? dados.client.cpfResponsible : "",
            telephoneClient: dados && dados.client && dados.client.telephone ? dados.client.telephone : "0000-000",
            cellphoneClient: dados && dados.client && dados.client.cellphone ? dados.client.cellphone : "(00) 0 0000-0000",
            emailClient: dados && dados.client && dados.client.email ? dados.client.email : "",
            namePlant: dados && dados.plant && dados.plant.name ? dados.plant.name : "",
            addressPlant: dados && dados.plant && dados.plant.address ? dados.plant.address : "",
            titulo: dados && dados.title ? dados.title : "",
            status: dados && dados.status ? dados.status : "",
            description: dados && dados.description ? dados.description : "",
            companyName: dados && dados.unitBranch && dados.unitBranch.companyName ? dados.unitBranch.companyName : "",
            value_sub_final: dados && dados.value_sub_final ? dados.value_sub_final : "0",
            value_discont: dados && dados.value_discont ? dados.value_discont : "0",
            value_final: dados && dados.value_final ? dados.value_final : "0",
            
            nameCollaborator : dados && dados.collaborators && dados.collaborators.length > 0 && dados.collaborators[0].name ? dados.collaborators[0].name : "",
            telefoneCollaborator: dados && dados.collaborators && dados.collaborators.length > 0 && dados.collaborators[0].telephone ? dados.collaborators[0].telephone : "",
            emailCollaborator : dados && dados.collaborators && dados.collaborators.length > 0 && dados.collaborators[0].email ? dados.collaborators[0].email : "",

            itensServices: dados && dados.itensServices ?     
            dados.itensServices.map((item: any) => {
                const my_item = {
                    service: item && item.typeService && item.typeService.description ?  item.typeService.description : "",
                    description: item && item.description ? item.description : "",
                    value: item && item.value ? item.value : "",
                    amount: item && item.amount ? item.amount : "",
                    sub_value: item && item.sub_value ? item.sub_value : "",
                }
                return my_item
            }) : []
    
        }
    
        Object.keys(dados_OS).forEach(chave => {
            if(chave === 'itensServices'){
                const servicesHtml = dados_OS[chave].map((service: any) => {
                    const tds = Object.keys(service).map(key => {
                      
                        if(key === 'sub_value' || key === 'value'){
                            return `<td>R$ ${service[key]}</td>`
                        }else{
                            return `<td>${service[key]}</td>`
                        }
    
                      //return `<td>${report[key]}</td>`;
                    }).join('');
                    return `<tr>${tds}</tr>`;
                  }).join('');
        
                  novoHtml = novoHtml.replace(
                    `{{${chave}}}`,
                    `<table style="width: 100%;">
                        <thead>
                            <tr>
                                <th style="background: #e1e3ea;">Serviço/Produto</th>
                                <th style="background: #e1e3ea;">Descrição</th>
                                <th style="background: #e1e3ea;">Valor Unit.</th>
                                <th style="background: #e1e3ea;">Qtd</th>
                                <th style="background: #e1e3ea;">Total</th>
                            </tr>
                        </thead>
                        <tbody id="table_services">${servicesHtml}</tbody>
                      </table>` 
                    // `<tbody id="table_services">${servicesHtml}</tbody>`
                  );
            }else{
                const marcador = new RegExp(`{{${chave}}}`, 'g');
                novoHtml = novoHtml.replace(marcador, dados_OS[chave] );
            }
        });
        return novoHtml;
      }

    const fetchHtmlAsString = async () => {
        try {
          const response = await axios.get('/media/HTMLs/template_OS.html', { responseType: 'text' });
          return response.data;
        } catch (error) {
          console.error('Error fetching HTML file:', error);
          return null;
        }
      };

    const previewPDF = async () => {
        try {
          setLoadingPDF(true)
          //const template = await fetchHtmlAsString()
          const template = itemOrderOfService 
          && itemOrderOfService.templatesOS 
          && itemOrderOfService.templatesOS.length > 0 
          ? itemOrderOfService.templatesOS[0].textHTML 
          : await fetchHtmlAsString();

          const template_modificad = await replaceValues(itemOrderOfService, template)
          const myReturnLink = await linkPDF(template_modificad ? template_modificad : "<h1>Template não encontrado! </h1>")
          window.open(myReturnLink, '_blank');
          
          //setUrl(myReturnLink) 
        
        } catch (error) {
          console.log(error)  
        }finally{
          setLoadingPDF(false)
        }
    }

    const atualizarTamanhoDaTela = () => {
        setLargura(window.innerWidth);
      };
      
      useEffect(() => {  
        window.addEventListener('resize', atualizarTamanhoDaTela);
        return () => {
          window.removeEventListener('resize', atualizarTamanhoDaTela);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
        //getReportServices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


  return (
    <div style={{borderLeft: '4px solid', marginBottom: '1rem'}} 
        className={`border-${
            itemOrderOfService && itemOrderOfService.status && itemOrderOfService.status !== null
            ? itemOrderOfService.status === "AGUARDANDO"
            ? "primary"
            : itemOrderOfService.status === "CANCELADO"
            ? "danger"
            : itemOrderOfService.status === "FINALIZADO"
            ? "success"
            : itemOrderOfService.status === "AGENDADO"
            ? "info"
            : itemOrderOfService.status === "EM ANDAMENTO"
            ? "warning"
            : "secondary"
            : "secondary"
        }`}
    >
        <div className='card card-flush bg-light p-3'>
            <div className='card-title' style={{display: 'flex', justifyContent: 'space-between'}}>
                <h3 className='align-items-start flex-column' style={{display: "flex"}}>
                    <span className={`card-label fw-bold fs-${largura < 1200 ? '6' : '4'}`}>
                        {itemOrderOfService && itemOrderOfService.number_order_service 
                        ? itemOrderOfService.number_order_service : 0} 
                        {" "}-{" "} 
                        {itemOrderOfService && itemOrderOfService.title ? itemOrderOfService.title : "Sem Descrição"}
                    </span>

                </h3>
                
                <div className='me-0 dropdown'>
                    <button
                        //disabled={loadingAvaiable || loading || report ? !report.template ? true : false : true}
                        className='btn btn-lg btn-light'
                        style={{padding: '0rem'}}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        //style={{width: largura < 1200 ? '100%' : '',display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem'}}
                        type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"
                        //data-kt-menu-trigger='click'
                        //data-kt-menu-dismiss='false'
                        //data-kt-menu-placement='bottom-end'
                        //data-kt-menu-flip='top-end'
                    >
                    <i className='bi bi-three-dots fs-1'></i>

                    </button>
                
                    <div className='dropdown-menu w-150px w-md-150px dropdown-menu-end' aria-labelledby="dropdownMenuButton2" style={{padding: '1rem'}}>
                        <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                            <a
                                className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`
                                }
                                style={{width: '100%'}}
                                data-bs-toggle="tab"
                                aria-disabled={loadingPDF}
                                href="#"
                                onClick={(event)=> {
                                    event.stopPropagation();
                                    previewPDF();
                                    //updateOSOne(itemOrderOfService.id, "AGUARDANDO")
                                    //formik.setValues({...itemOrderOfService, status: "AGUARDANDO"})
                                }}
                            >
                                Imprimir
                            </a>

                            {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== 'CLIENTE' &&
                                <a
                                    className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`
                                    }
                                    style={{width: '100%'}}
                                    data-bs-toggle="tab"
                                    href="#"
                                    onClick={(event)=> {
                                        event.stopPropagation();
                                        setShowCreateAppModal(true)
                                        itemOrderOfService.idUnitBranch = itemOrderOfService && itemOrderOfService.unitBranch ? itemOrderOfService.unitBranch.id : null;
                                        itemOrderOfService.idClient = itemOrderOfService && itemOrderOfService.client ? itemOrderOfService.client.id : null;
                                        itemOrderOfService.idPlant = itemOrderOfService && itemOrderOfService.plant ? itemOrderOfService.plant.id : null;
                                        itemOrderOfService.idCollaborator = itemOrderOfService && itemOrderOfService.collaborators && itemOrderOfService.collaborators.length > 0 ? itemOrderOfService.collaborators[0].id : null;
                                        setMyOrderService(itemOrderOfService)
                                        //updateOSOne(itemOrderOfService.id, "AGUARDANDO")
                                        //formik.setValues({...itemOrderOfService, status: "AGUARDANDO"})
                                    }}
                                >
                                    Editar
                                </a>
                            }

                            {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== 'CLIENTE' &&
                                <a
                                    className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`
                                    }
                                    style={{width: '100%'}}
                                    data-bs-toggle="tab"
                                    href="#"
                                    onClick={(event)=> {
                                        event.stopPropagation();
                                        setShowModalTemplate(true)
                                        //setShowCreateAppModal(true)
                                        itemOrderOfService.idUnitBranch = itemOrderOfService && itemOrderOfService.unitBranch ? itemOrderOfService.unitBranch.id : null;
                                        itemOrderOfService.idClient = itemOrderOfService && itemOrderOfService.client ? itemOrderOfService.client.id : null;
                                        itemOrderOfService.idPlant = itemOrderOfService && itemOrderOfService.plant ? itemOrderOfService.plant.id : null;
                                        itemOrderOfService.idCollaborator = itemOrderOfService && itemOrderOfService.collaborators && itemOrderOfService.collaborators.length > 0 ? itemOrderOfService.collaborators[0].id : null;
                                        setMyOrderService(itemOrderOfService)
                                        //updateOSOne(itemOrderOfService.id, "AGUARDANDO")
                                        //formik.setValues({...itemOrderOfService, status: "AGUARDANDO"})
                                    }}
                                >
                                    Editar Template
                                </a>
                            }

                            {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== 'CLIENTE' &&
                                <a
                                    className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`
                                    }
                                    style={{width: '100%'}}
                                    data-bs-toggle="tab"
                                    href="#"
                                    onClick={(event)=> {
                                        event.stopPropagation();
                                        setShowModalDelete(true)
                                        setMyOrderService(itemOrderOfService)
                                        //updateOSOne(itemOrderOfService.id, "AGUARDANDO")
                                        //formik.setValues({...itemOrderOfService, status: "AGUARDANDO"})
                                    }}
                                >
                                    Excluir
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-body p-0' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                        <KTSVG path="/media/icons/duotune/communication/com006.svg" className={`svg-icon-muted svg-icon-${largura < 1200 ? "2x" : "2hx"}`}/>
                        <h3>
                            <span className={`text-muted fw-semibold fs-${largura < 1200 ? "7" : "6"}`}>
                                {itemOrderOfService && itemOrderOfService.client && itemOrderOfService.client.nameResponsible ? itemOrderOfService.client.nameResponsible : "Não identificado."}
                            </span>
                        </h3>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                        <KTSVG path="/media/icons/duotune/files/fil002.svg" className={`svg-icon-muted svg-icon-${largura < 1200 ? "2x" : "2hx"}`}/>
                        <div>
                            <h3 style={{marginBottom: '0rem', paddingBottom: '0rem'}}>
                                <span className={`text-dark fw-bold fs-${largura < 1200 ? "7" : "6"}`}>
                                    Início: {" "}
                                </span>
                                <span className={`text-muted fw-semibold fs-${largura < 1200 ? "7" : "6"}`}>
                                    {itemOrderOfService && itemOrderOfService.dateInit ? formatarData(itemOrderOfService.dateInit) : "Sem data para iniciar o serviço."}
                                </span>
                            </h3>

                            <h3 style={{marginBottom: '0rem', paddingBottom: '0rem'}}>
                                <span className={`text-dark fw-bold fs-${largura < 1200 ? "7" : "6"}`} >
                                    Fim: {" "}
                                </span>
                                <span className={`text-muted fw-semibold fs-${largura < 1200 ? "7" : "6"}`}>
                                    {itemOrderOfService && itemOrderOfService.dateEnd ? formatarData(itemOrderOfService.dateEnd) : "Sem data para finalizar o serviço."}
                                </span>
                            </h3>
                        </div>
                        
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                    < i className = "bi bi-hammer fs-2hx " > </ i >
                        {/* <KTSVG path="/media/icons/duotune/files/fil002.svg" className={`svg-icon-muted svg-icon-${largura < 1200 ? "2x" : "2hx"}`}/> */}
                        <div>
                            <h3 style={{marginBottom: '0rem', paddingBottom: '0rem'}}>
                                <span className={`text-muted fw-semibold fs-${largura < 1200 ? "7" : "6"}`}>
                                    {itemOrderOfService && itemOrderOfService.collaborators && itemOrderOfService.collaborators.length > 0 ? itemOrderOfService.collaborators[0].name : "Não identificado"}
                                </span>
                            </h3>
                        </div>
                        
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                        <KTSVG path="/media/icons/duotune/maps/map001.svg" className={`svg-icon-muted svg-icon-${largura < 1200 ? "2x" : "2hx"}`}/>
                            <div className='me-0 dropdown'>
                            <button
                                //disabled={loadingAvaiable || loading || report ? !report.template ? true : false : true}
                                className='btn btn-lg btn-light'
                                style={{padding: '0rem'}}
                                disabled={currentUser && currentUser.role && currentUser.role.slug !== 'CLIENTE' ? false : true}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                //style={{width: largura < 1200 ? '100%' : '',display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem'}}
                                type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                                //data-kt-menu-trigger='click'
                                //data-kt-menu-dismiss='false'
                                //data-kt-menu-placement='bottom-end'
                                //data-kt-menu-flip='top-end'
                            >
                                <span 
                                className={
                                    `badge badge-light-${
                                        itemOrderOfService && itemOrderOfService.status && itemOrderOfService.status !== null
                                        ? itemOrderOfService.status === "AGUARDANDO"
                                        ? "primary"
                                        : itemOrderOfService.status === "CANCELADO"
                                        ? "danger"
                                        : itemOrderOfService.status === "FINALIZADO"
                                        ? "success"
                                        : itemOrderOfService.status === "AGENDADO"
                                        ? "info"
                                        : itemOrderOfService.status === "EM ANDAMENTO"
                                        ? "warning"
                                        : "secondary"
                                    : "secondary"
                                    } fs-${largura < 1200 ? "9" : "7"} fw-semibold`
                                }
                                >
                                    {
                                    itemOrderOfService && itemOrderOfService.status !== null ? itemOrderOfService.status : "Não identificado"
                                    }
                                </span>
                                
                                {/* <i className='bi bi-three-dots fs-3'></i> */}
            
                            </button>
                    
                            <div className='dropdown-menu w-150px w-md-150px dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem'}}>
                                <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                                <a
                                    className={`btn btn-sm btn-color-muted btn-active btn-active-primary
                                    ${itemOrderOfService.status === "AGUARDANDO" ? "active" : ""} px-5 me-1 nav-item`
                                    }
                                    style={{width: '100%'}}
                                    data-bs-toggle="tab"
                                    href="#"
                                    onClick={()=> {
                                        updateOSOne(itemOrderOfService.id, "AGUARDANDO")
                                        //formik.setValues({...itemOrderOfService, status: "AGUARDANDO"})
                                    }}
                                >
                                    AGUARDANDO
                                    {/* <span 
                                    className='badge badge-dark'
                                    style={{marginLeft: '10px'}}
                                    >
                                    AGUARDANDO
                                    </span> */}
                                </a>
                                <a
                                    className={`btn btn-sm btn-color-muted btn-active btn-active-info 
                                    ${itemOrderOfService.status === "AGENDADO" ? "active" : ""} px-5 me-1 nav-item`
                                    }
                                    //className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 nav-item'
                                    data-bs-toggle="tab"
                                    style={{width: '100%'}}
                                    href="#"
                                    onClick={()=> {
                                        updateOSOne(itemOrderOfService.id, "AGENDADO")
                                        //formik.setValues({...itemOrderOfService, status: "AGENDADO"})
                                    }}
                                >
                                    AGENDADO
                                    {/* <span 
                                    className='badge badge-success'
                                    style={{marginLeft: '10px'}}
                                    >
                                    {alarmStatus.normalNum}
                                    </span> */}
                                </a>
                                <a
                                    className={`btn btn-sm btn-color-muted btn-active btn-active-warning 
                                    ${itemOrderOfService.status === "EM ANDAMENTO" ? "active" : ""} px-5 me-1 nav-item`
                                    }
                                    //className='btn btn-sm btn-color-muted btn-active btn-active-info px-4 nav-item'
                                    data-bs-toggle="tab"
                                    style={{width: '100%'}}
                                    href="#"
                                    onClick={()=> {
                                        updateOSOne(itemOrderOfService.id, "EM ANDAMENTO")
                                    //formik.setValues({...itemOrderOfService, status: "EM ANDAMENTO"})
                                    }}
                                >
                                    EM ANDAMENTO
                                    {/* <span 
                                    className='badge badge-warning'
                                    style={{marginLeft: '10px'}}
                                    >
                                    {alarmStatus.attention}
                                    </span> */}
                                </a>
                                <a
                                    className={`btn btn-sm btn-color-muted btn-active btn-active-success 
                                    ${itemOrderOfService.status === "FINALIZADO" ? "active" : ""} px-5 me-1 nav-item`
                                    }
                                    //className='btn btn-sm btn-color-muted btn-active btn-active-warning px-4 nav-item'
                                    data-bs-toggle="tab"
                                    style={{width: '100%'}}
                                    href="#"
                                    onClick={()=> {
                                        updateOSOne(itemOrderOfService.id, "FINALIZADO")
                                    //formik.setValues({...itemOrderOfService, status: "FINALIZADO"})
                                    }}
                                >
                                    FINALIZADO
                                    {/* <span 
                                    className='badge badge-danger'
                                    style={{marginLeft: '10px'}}
                                    >
                                    {alarmStatus.offlineNum}
                                    </span> */}
                                </a>

                                <a
                                    className={`btn btn-sm btn-color-muted btn-active btn-active-danger
                                    ${itemOrderOfService.status === "CANCELADO" ? "active" : ""} px-5 me-1 nav-item`
                                    }
                                    //className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                                    data-bs-toggle="tab"
                                    style={{width: '100%'}}
                                    href="#"
                                    onClick={()=> {
                                        updateOSOne(itemOrderOfService.id, "CANCELADO")
                                    //formik.setValues({...itemOrderOfService, status: "CANCELADO"})
                                    }}
                                >
                                    CANCELADO
                                    {/* <span 
                                    className='badge badge-danger'
                                    style={{marginLeft: '10px'}}
                                    >
                                    {alarmStatus.offlineNum}
                                    </span> */}
                                </a>
                                </div>
                            </div>
                            </div>
                        <h3>
                            <span className='text-muted fw-semibold fs-6'>
                                {/* {itemOrderOfService && itemOrderOfService.status ? itemOrderOfService.status : "Não identificado. "} */}
                                {/* <span 
                                className={
                                    `badge badge-light-${
                                        itemOrderOfService && itemOrderOfService.status && itemOrderOfService.status !== null
                                        ? itemOrderOfService.status === "AGUARDANDO"
                                        ? "primary"
                                        : itemOrderOfService.status === "CANCELADO"
                                        ? "danger"
                                        : itemOrderOfService.status === "FINALIZADO"
                                        ? "success"
                                        : itemOrderOfService.status === "AGENDADO"
                                        ? "info"
                                        : itemOrderOfService.status === "EM ANDAMENTO"
                                        ? "warning"
                                        : "secondary"
                                    : "secondary"
                                    } fs-7 fw-semibold`
                                }
                                >
                                    {
                                    itemOrderOfService && itemOrderOfService.status !== null ? itemOrderOfService.status : "Não identificado"
                                    }
                                </span> */}
                            </span>
                        </h3>
                    </div>
                </div>
                {/* <div>
                    <a
                        onClick={(event: any) => {
                            setShowModalDelete(true)
                            setMyOrderService(itemOrderOfService)
                        }}
                        href='#'
                        className='btn btn-icon btn-bg-secondary btn-active-color-primary btn-sm'
                    >
                        <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-1'
                        />
                    </a>
                </div> */}
            </div>
        </div>
    </div>
  );
}