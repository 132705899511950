import React, { useState, useEffect } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LoadingSpan } from '../Loadings/loadingSpan';
import { KTSVG } from '../../../../_metronic/helpers';
import { postTemplateOne, putTemplateAll } from '../../../services/templates';

const HtmlEditorV3 = ({ initialHtml, id }) => {
  const [loading, setLoading] = useState(false)
  const [editorStateV2, setEditorStateV2] = useState(() => {
      return { __html: '' };
  });

  const [editorState, setEditorState] = useState(() => {
    if (initialHtml) {
      return EditorState.createWithContent(
        ContentState.createFromText(initialHtml)
      );
    } else {
      return EditorState.createEmpty();
    }
  });

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const postTemplate = async () => {
    try {
      setLoading(true)
      const myData = {
        textHTML: editorStateV2.__html,
        description: "TEMPLATE 03",
        type: "public"
      }
 
      await postTemplateOne(myData)
      toggleToast("Sucesso ao cadastrar o template. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao cadastrar template. ", "error")
    }finally{
      setLoading(false)
    }
  }

  const putTemplate = async () => {
    try {
      setLoading(true)
      const myData = {
        textHTML: editorStateV2.__html,
        description: "TEMPLATE 03",
        type: "public"
      }
 
      await putTemplateAll(id, myData)
      toggleToast("Sucesso ao atualizar o template. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar o template. ", "error")
    }finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    let myHTML = ''
    rawContentState.blocks.map((block: any) =>{
        myHTML = myHTML + block.text.toString()
    })
    setEditorStateV2({ __html: myHTML });
  }, [editorState]);


  useEffect(() => {
    setEditorState(() => {
      return EditorState.createWithContent(
        ContentState.createFromText(initialHtml)
      );
    })
  }, [initialHtml]);

  return (
    <div style={{ display: 'flex', gap: '2rem', height: '40rem', padding: '1rem', background: '#F8F8FF'}}>
      <div style={{ flex: '1', overflowY: 'auto' }}>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
          //stripPastedStyles={true} // Adicionando stripPastedStyle
        />
      </div>

      <div style={{ flex: '1', height: '85%' }}>
        <div style={{marginBottom: '1rem'}}>
          <h3 className='align-items-start flex-column' style={{display: "flex"}}>
            <span className='card-label fw-bold fs-3 mb-1'>Visualização do layout</span>
            <span className='text-muted fw-semibold fs-7'>Renderização da edição do relatório</span>
          </h3>
        </div>
        
        <div style={{height: '100%', overflowY: 'auto', padding: '1rem',}} className="border border-gray-300 border-dashed rounded">
          <div dangerouslySetInnerHTML={editorStateV2} />
        </div>
        
        <br></br>

        <button
            type='button'
            className='btn btn-lg btn-success'
            //disabled={loading}
            //data-kt-stepper-action='submit'
            onClick={postTemplate}
          >
            Submit
            {
              loading ? 
              <LoadingSpan style={{marginLeft: '0.5rem'}}/>
              :
              <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
              />
            }
        </button>

        <button
            type='button'
            className='btn btn-lg btn-danger'
            //disabled={loading}
            //data-kt-stepper-action='submit'
            onClick={putTemplate}
          >
            Update
            {
              loading ? 
              <LoadingSpan style={{marginLeft: '0.5rem'}}/>
              :
              <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
              />
            }
        </button>


        {/* <div>{JSON.stringify(convertToRaw(editorState.getCurrentContent()))}</div> */}
      </div>
    </div>
  );
};

export default HtmlEditorV3;

function toggleToast(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}
