import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';
import { getItemServiceAll } from '../../../../../../../../services/itemServiceService';
import { Loading } from '../../../../../../../components/Loadings/loading';
import { useToast } from '../../../../../../../components/Toats';

import { ModalAddServiceProposal } from './components/modalAddServiceProposal';
import { useAuth } from '../../../../../../../../modules/auth';
import { getItemProposalAll } from '../../../../../../../../services/itemProposalService';
import { ModalAddDescontoProposal } from './components/modalAddDescontoProposal';

type Props = {
  itemProposal: any;
  refresh: any
}

export function ServicesQtdProposal({itemProposal, refresh} : Props) {
    const [loading, setLoading] = useState<boolean>(false)
    const [itensProposals, setItensProposals] = useState<any[]>([])
    const [showModalAddService, setShowModalAddService] = useState<boolean>(false)
    const [showModalAddDesconto, setShowModalAddDesconto] = useState<boolean>(false)
    const { toggleToast } = useToast();
    const {currentUser} = useAuth()

    const getItensProposal = async () => {
      try {
        if(itemProposal && itemProposal.id){
          setLoading(true)
          const myItensServices = await getItemProposalAll(itemProposal && itemProposal.id ? itemProposal.id : "")
          setItensProposals(myItensServices)
        }
      } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar os itens da proposta. ", "error")
      }finally{
          setLoading(false)
      }
    }

    const refreshV2 = () =>{
      getItensProposal()
    }

    useEffect(() => {
      if(itemProposal && itemProposal.id){
        getItensProposal()
      }
    }, [itemProposal])

    if (loading) {
      return (
        <div className='spinnerLoadingDashboard'>
          <Loading myClass='spinner-border spinnerDashboard'/>
        </div>
      );
    }

  return (
    <div className='card' style={{}}>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details_service'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0' style={{justifyContent: 'space-between', width: '100%'}}>
            <h3 className='fw-bolder m-0'>Itens {itensProposals ? `(${itensProposals.length})` : "(0)"}</h3>
            {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== 'CLIENTE' && 
              <button
                  className='btn btn-lg text-hover-primary'
                  style={{padding: '0rem'}}
                  onClick={(event) => {
                      event.stopPropagation();
                      setShowModalAddService(true)
                      //setShowModalDescriptionOS(true)
                  }}
                  type="button"
              >
                <KTSVG className='svg-icon-muted svg-icon-2hx' path='/media/icons/duotune/general/gen035.svg' />
              </button>
            }
          </div>
        </div>

        <div id='kt_account_profile_details_service' className='collapse'>
          <div>
            <div className='card-body border-top p-9'>

              <div className='table-responsive'>
                <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead className='bg-light'>
                    <tr className='text-muted text-start fw-bolder fs-7 text-uppercase gs-0'>
                      <th className='min-w-400px'>Tipo</th>
                      <th className='min-w-400px'>Item</th>
                      <th className='min-w-100px'>Valor Unit.</th>
                      <th className='min-w-100px'>Qtd</th>
                      <th className='min-w-100px'>Total</th>
                      <th className='min-w-100px text-end'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      itensProposals && itensProposals.map((itemProposal) => {
                        return (
                          <tr>
                            <td>
                                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  {
                                    itemProposal 
                                    && itemProposal.tipo 
                                    ? itemProposal.tipo
                                    : "Não identificado. "
                                  }
                                </a>
                            </td>
                            <td>
                                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  {
                                    itemProposal ? 
                                      itemProposal.tipo === "SERVICO" ? 
                                        itemProposal.typeService 
                                        && itemProposal.typeService.description 
                                        ? itemProposal.typeService.description 
                                        : "Não identificado. "
                                      : 
                                      itemProposal.tipo === "PRODUTO" ?
                                        itemProposal.product 
                                          && itemProposal.product.description 
                                          ? itemProposal.product.description 
                                          : "Não identificado. " 
                                      : "Não identificado"
                                    : "Não identificado. "
                                  }
                                </a>
                            </td>
                            
                            <td>
                                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  R$ {" "}
                                  {
                                    itemProposal 
                                    && itemProposal.value
                                    ? itemProposal.value
                                    : "0"
                                  },00
                                </a>
                            </td>
                            <td>
                                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  {
                                    itemProposal 
                                    && itemProposal.amount
                                    ? itemProposal.amount
                                    : "0"
                                  }
                                </a>
                            </td>
                            <td>
                                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                  R$ {" "}
                                  {
                                    itemProposal 
                                    && itemProposal.sub_value
                                    ? itemProposal.sub_value
                                    : "0"
                                  },00
                                </a>
                            </td>
                            <td style={{display: 'flex', justifyContent: 'end'}}>
                              <a
                                  onClick={(event: any) => {
                                      //handleButtonClickDelete(event, user)
                                  }}
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                  <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                  />
                              </a>
                            </td>
                          </tr>
                        )
                      })
                    }
                    
                  </tbody>
                </table>
              </div>

              <div style={{display: 'flex', justifyContent: 'end', flexDirection: 'column', alignItems: 'end'}}>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-5 mb-1'>Sub Total: </span>
                  <span className='text-muted mt-1 fw-semibold fs-5'>{itemProposal && itemProposal.value_sub_final ? itemProposal.value_sub_final : "0"},00</span>
                </h3>
                <h3 className='card-title' style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.4rem'}}>
                  <a 
                    href='#' 
                    className='text-primary fw-bold text-hover-muted d-block fs-5'
                    onClick={() => {
                      if(currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== 'CLIENTE'){
                        setShowModalAddDesconto(true)
                      }
                    }}
                  >
                    + Desconto:
                  </a>
                  <span className='text-muted mt-1 fw-semibold fs-5'>{itemProposal && itemProposal.value_discont ? itemProposal.value_discont : "0"},00</span>
                </h3>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-5 mb-1'>Total: </span>
                  <span className='text-muted mt-1 fw-semibold fs-5'>{itemProposal && itemProposal.value_final ? itemProposal.value_final : "0"},00</span>
                </h3>
              </div>

            </div>



            {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                className='btn btn-primary'>
                {!loading && 'Salvar Descrição'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div> */}
          </div>
        </div>
        <ModalAddServiceProposal
          idProposal={itemProposal && itemProposal.id ? itemProposal.id : ""}
          show={showModalAddService} 
          handleClose={() => {
            setShowModalAddService(false)
            refresh()
            refreshV2()
          }}
        />

        <ModalAddDescontoProposal
          show={showModalAddDesconto}
          id={itemProposal && itemProposal.id ? itemProposal.id : null}
          data={itemProposal} 
          handleClose={() => {
            setShowModalAddDesconto(false)
            refresh()
            refreshV2()
          }}
        />
      </div>
  );
}