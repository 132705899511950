/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
//import { Dropdown1 } from '../../../../../../../_metronic/partials/content/dropdown/Dropdown1'


type Props = {
  className: string;
  plants: any[]
}

const ListPlantsDetails: React.FC<Props> = ({className, plants}) => {
  return (
    <div className={`card ${className}`} style={{paddingBottom: '2rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Lista de usinas ({plants && plants.length ? plants.length : 0})</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Usinas vinculadas à unidade filial</span>
        </h3>

        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5' style={{height: '20rem', overflowY: "auto"}}>
        {/* begin::Item */}
        {
          plants && plants.length > 0 ?
            plants.map((plant: any) =>{
              return (
                <div className='d-flex align-items-center mb-7'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-50px me-5'>
                    <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <div className='symbol symbol-50px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
                      </div>
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Text */}
                  <div className='d-flex flex-column'>
                    <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                      {plant.name}
                    </a>
                    <span className='text-muted fw-semibold'>{plant.address ? plant.address : "Não localizado."}</span>
                  </div>
                  {/* end::Text */}
                </div>
              )
            })
          :
          <div className='d-flex align-items-center mb-7' style={{height: '100%', justifyContent: 'center'}}>
            <h3 className='card-title align-items-start flex-column'>
              <span className='text-muted mt-1 fw-semibold fs-7'>Não há usinas vinculadas.</span>
            </h3>
          </div>
        }
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListPlantsDetails}
