import api from "./api";

export const postReportServiceOne = async (body: any) => {
    const response = await api.post(`requestService/`, body);
    return response.data;
};

export const getReportServicesAll = async (plant?: string, unitBranch?: string) => {
    const response = await api.get(`requestService/listAll/?plant=${plant ? plant : ""}&unitBranch=${unitBranch ? unitBranch : ""}`,);
    return response.data;
};

export const getReportServiceOne = async (id: string) => {
    const response = await api.get(`requestService/${id}`);
    return response.data;
};

export const putReportService = async (body: any, id: string) => {
    const response = await api.put(`requestService/${id}`, body);
    return response.data;
};
