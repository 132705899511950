/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { getUnitBranchAll } from "../../../../../services/unitBranchService";
import { Loading } from "../../../../components/Loadings/loading";

const StepModal = ({data, updateData, hasError}: any) => {
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getUsersAllUnitBranchs = async () => {
    try {
      setLoading(true)
      const unitBranchs = await getUnitBranchAll()
      setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoading(false)
    }
  }
  
  useEffect(() => {
    const fuction_async = async () =>{
      //await getUsersAllClients()
      await getUsersAllUnitBranchs()
      //await getPlants()
    }
    fuction_async()
  }, []);


  if (loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*begin::Form Group */}
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Username</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Digite um nome de usuario para o cadastro'
            ></i>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='username'
            placeholder=''
            value={data.username}
            onChange={(e) =>
              /*updateData({
                appBasic: {
                  appName: e.target.value,
                  appType: data.appBasic.appType,
                },
              })*/
              updateData({
                username: e.target.value
              })
            }
          />
          {!data.username && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                O username é obrigatório!
              </div>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
            <span className='required'>Senha</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Digite uma senha para o cadastro'
            ></i>
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='senha'
            placeholder=''
            value={data.senha}
            onChange={(e) =>
              updateData({
                senha: e.target.value
              })
            }
          />
          {!data.senha && hasError && (
            <div className='fv-plugins-message-container'>
              <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                a senha é obrigatória!
              </div>
            </div>
          )}
        </div>

        <div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Lista de Unidades (Filiais)</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione para qual unidade filial essa credencial será vinculada. '
              ></i>
            </label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={data.idUnitBranch ? data.idUnitBranch : '1'}
                onChange={(e) =>
                  updateData({
                    idUnitBranch: e.target.value
                  })
                }
                //onChange={formik.handleChange("idUnitBranch")}
              >
                <option value={'1'}></option>
                {unitBranchs && unitBranchs.map((myUnitBranch: any) => {
                  return (
                    <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
                  )
                })}
                
              </select>
              {!data.idUnitBranch && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Selecione a unidade!
                </div>
              </div>
            )}
            </div>
          </div>
        </div>

        {/*end::Form Group */}

        {/*begin::Form Group */}
        {/*end::Form Group */}
      </div>
    </div>
  )
}

export {StepModal}

function toggleToast(arg0: string, arg1: string) {
  throw new Error("Function not implemented.");
}
