/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
//import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
//import { getClientAll } from '../../services/clientService';
//import { getReportsAll } from '../../services/reports';
import { getTemplateAll } from '../../services/templates';
import HtmlEditorV3 from '../components/HTMLEditorV3';



//import { MyPortal } from './components/my-portal'

const reportsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportsPage: FC = () => {
  //const initialHtml = '<h1>Seu HTML inicial aqui...</h1>';

  const [initialHtml, setInitialHtml] = useState("<h1>Teste</h1>")
  const [loading, setLoading] = useState(true)
  const [myTemplates, setMyTemplates] = useState<any[]>([])
  const [id, setId] = useState<string>("")
  // const [editorStateV2, setEditorStateV2] = useState(() => {
  //   return { __html: '' };
  // });

  const getTemplate = async () => {
    try {
      setLoading(true)
 
      const templates = await getTemplateAll()
      setMyTemplates(templates)

      toggleToast("Sucesso ao buscar os templates. ", "success")

    } catch (error) {
      console.log('erro',error)
      toggleToast("Erro ao buscar os templates. ", "error")
    }finally{
      setLoading(false)
    }
  }

  const selectTemplate = (id: string) => {
    const selectTemplateOne = myTemplates.find((template) => template.id === id)
    setInitialHtml(selectTemplateOne.textHTML)
  }
  
  useEffect(() => {
    const fetchHtml = async () => {
      try {
        setLoading(true)
        // const response = await axios.get(toAbsoluteUrl(`/media/HTMLs/template_2.html`));
        // const htmlString = response.data;
        // setInitialHtml(htmlString);
        // setEditorStateV2({ __html: htmlString })

        getTemplate()
      } catch (error) {
        console.error(error);
      }finally{
        //setLoading(false)
      }
    };
    fetchHtml();
  }, []);
  
  if(loading){
    return (
      <div>
        <h1>Carregando...</h1>
      </div>
    )
  }

  return (
    <div>
      {/* <div className='column g-5 g-xl-8' style={{display: 'flex', gap: '1rem'}}>
        <div style={{flex: '1', overflow: 'auto', height: '50rem'}}>{initialHtml}</div>
        <div style={{flex: '1', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
          <PreviewReport editorStateV2={editorStateV2}/>
          <button
            type='button'
            className='btn btn-lg btn-success'
            disabled={loading}
            //data-kt-stepper-action='submit'
            onClick={postTemplate}
          >
            Submit
            {
              loading ? 
              <LoadingSpan style={{marginLeft: '0.5rem'}}/>
              :
              <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
              />
            }
        </button>
        
        </div>
      </div> */}
        <div>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Lista de Templates</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selecione para qual unidade filial essa credencial será vinculada. '
              ></i>
            </label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                //defaultValue={formik.values.idUser ? formik.values.idUser : '1'}
                onChange={(e: any) => {
                  selectTemplate(e.target.value)
                  setId(e.target.value)
                }}
              >
                <option value={'1'}>Selecione um template</option>
                {myTemplates && myTemplates.map((myTemplate: any) => {
                  return (
                    <option key={myTemplate.id} value={myTemplate.id}>{myTemplate.description}</option>
                  )
                })}    
              </select>
            </div>
          </div>
        </div>

      <div>
        <HtmlEditorV3 initialHtml={initialHtml} id={id} />
      </div>
      
    </div>
  )
}

const TemplatesWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={reportsBreadcrumbs}>Gerenciamento de Templates</PageTitle>
      <ReportsPage />
    </>
  )
}

export {TemplatesWrapper}

function toggleToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}

