import React, { useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { formatDate } from '../../../utils/date';
import { BarGraph } from './Componentes/BarGraphModal';
import './styles.css'
import { Loading } from '../../components/Loadings/loading';
import { ModalPlant } from '../../components/modalPlant';
import { useNavigate } from 'react-router-dom';
import { ModalViewReport } from '../../reports/components/ModalViewReports';
import { ModalDetailsReports } from '../../reports/components/ModalDetailsReport';

type Props = {
  charts: any,
  loading: boolean,
  typeGraphc?: string
  plantsFilter?: any[];
  dataFilter?: any;
  setDataFilter?: any;
  data?: any;
  refresh: any;
  prognostico?: any
  loadingReport: boolean
  report: any;
  myProg?: any
}

export function GraphicsDahsboardPlant({
  charts, 
  refresh, 
  loading, 
  typeGraphc, 
  plantsFilter, 
  dataFilter, 
  setDataFilter, 
  data, prognostico, 
  loadingReport,
  myProg,
  report}: Props) {
  //const [dailyCharts, setDailyCharts] = useState({});
  //const [mounthCharts, setMounthCharts] = useState({});
  const [weekCharts, setWeekCharts] = useState({
    '2024-06-13' : {
      dias: [],
      valores: [],
      type: "week_charts"
    }
  });
  //const [yearCharts, setYearCharts] = useState({});
  //const [totalCharts, setTotalCharts] = useState({});
  const [largura, setLargura] = useState(window.innerWidth);
  const [altura, setAltura] = useState(window.innerHeight);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false);

  const toFillCharts = () => {
    if(charts){
      /*const daily_charts_result = dailyChartsFunction(charts.daily_charts)
      const mount_charts_result = mounthChartsFunction(charts.mounth_charts)
      const year_charts_result = yearChartsFunction(charts.year_charts)
      const total_charts_result = totalChartsFunction(charts.total_charts)*/
      
      //setDailyCharts(charts && charts.daily_charts ? charts.daily_charts : null)
      //setMounthCharts(charts && charts.month_charts ? charts.month_charts : null)
      //setYearCharts(charts && charts.year_charts ? charts.year_charts : null)
      //setTotalCharts(charts && charts.total_charts ? charts.total_charts : null)
      setWeekCharts(charts && charts.week_charts ? charts.week_charts : null)
    }
  }
  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
    setAltura(window.innerHeight);
  };

  const closedModal = (active: boolean) => {
    refresh()
    setShowCreateAppModal(active)
  }
  
  useEffect(() => {
    toFillCharts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charts]);

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, []);

  if (loading) {
    return (
      <div 
        className='row g-xl-8 card p-0 divLoadingCharts' style={{marginTop: '1.5rem', height: '20rem', marginLeft: '0rem'}}>
          <Loading myClass='spinner-border loadingChart' />
      </div>)
  }else{
    if(!charts){
      return (
        <div 
          className='row g-xl-8 card p-0 divNotCharts' 
          style={{
            width: largura < 1200 ? '100%' : '100%',
            height: altura <= 750 ? '35vh' 
          : altura <= 850 ? '42vh'
          : altura <= 930 ? '45vh'
          : '50vh',
          }}>
            <h3 className='h3DivNotChart'>
              <span className='text-muted mt-1 fw-semibold fs-5' style={{fontWeight: '800'}}>Não há dados de graficoss.</span>
            </h3>
        </div>)
    }
  }

  return (
    <div
      className={`row p-${largura < 1200 ? '0': '6'} card divGlobal`} 
      style={{
        width: largura < 1200 || (typeGraphc && typeGraphc === 'grapich') ? '100%' : '60%',
        marginTop: '1.5rem',
      }}
    >
      <div className='' style={{marginTop: '1rem'}}>
        <div className='d-flex align-items-center' style={{gap: '1.5rem'}}>
          
          <div className='d-flex align-items-center' style={{flex: '1', justifyContent: 'start'}}>
            <div className={`symbol symbol-${largura < 1200 ? '40px' : '50px'} me-2`}>
              <img
                  src={toAbsoluteUrl('/media/imgs/plant2.png')}
                  className=''
                  alt=''
              />
            </div>
            <h3 className='card-title align-items-start flex-column' style={{display: 'flex'}}>
              <span className={`card-label fw-bold fs-${largura < 1200 ? '7' : '4'} mb-1`}>{data && data.name ? data.name : "Não identificado."}</span>  
              <span 
                className={
                  `badge badge-light-${
                    data && data.status !== null
                      ? data.status === 0
                      ? "success"
                      : data.status === 1
                      ? "danger"
                      : data.status === 2
                      ? "warning"
                      : data.status === 7 || data.status === 3
                      ? "info"
                      : "secondary"
                    : "secondary"
                  } fs-8 fw-semibold`
                }
              >
                  {
                    data.status !== null
                      ? data.status === 0
                      ? "Online"
                      : data.status === 1
                      ? "Offline"
                      : data.status === 2
                      ? "Alerta"
                      : data.status === 7 || data.status === 3
                      ? "Lembrar"
                      : "Desconhecido"
                  : "Desconhecido"
                  }
              </span>
            </h3>
          </div>
          

          <div  style={{flex: '1', maxWidth: largura < 1200 ? '10rem' : '20rem'}}>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-white form-select-sm'
              value={dataFilter ? dataFilter : "1"}
              onChange={(event: any) => {
                setDataFilter ? setDataFilter(event.target.value) : console.log('Sem função de filtro')
              }}
            >
              <option value="1"></option>
              {
                plantsFilter && plantsFilter.length > 0 && plantsFilter.map((plant: any) => {
                  return (
                    <option value={plant.id}>{`${plant.plantId} - ${plant.name}`}</option>
                  );
                })
              }
            </select>
          </div>

        </div>
        
        {
          typeGraphc && typeGraphc === 'grapich' ?
          (
            <div className='d-flex align-items-center my-2'>
              <div>
                <div>
                  
                </div>
              </div>

            </div>
          )
          : (<></>)
        }
      </div>

      <div 
        style={{
          overflow: 'auto', 
          height: altura <= 750 ? '35vh' 
          : altura <= 850 ? '42vh'
          : altura <= 930 ? '45vh'
          : '50vh',
        }}
      >
        <div className="" id="" style={{marginBottom: '0rem'}}>
          <div
            className=""
            // id="graphicModal1"
            // role="tabpanel"
          >
            <div className=''>
              <BarGraph myProg={myProg} largura={largura} className='' charts={weekCharts} setShowCreateAppModal={setShowCreateAppModal} prognostico={prognostico ? prognostico : null}/>
            </div>
          </div>
          {/* <div className="tab-pane fade" id="graphicModal2" role="tabpanel">
            <BarGraph className='card-xl-stretch mb-xl-8' charts={mounthCharts}/>
          </div>
          <div className="tab-pane fade" id="graphicModal3" role="tabpanel">
            <BarGraph className='card-xl-stretch mb-xl-8' charts={yearCharts}/>
          </div>
          <div className="tab-pane fade" id="graphicModal4" role="tabpanel">
            <BarGraph className='card-xl-stretch mb-xl-8' charts={totalCharts}/>
          </div> */}
        </div>

        {typeGraphc && typeGraphc === 'grapich' ? 
        <div style={{marginTop: '0rem', paddingTop: '0rem', paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
          <div style={{marginBottom: '1rem', display: 'flex', alignItems: 'center', gap: '1rem'}}>
            <h3 className='card-title align-items-start flex-column'>
              <span className='text-muted fw-semibold fs-7'>Ciclo de <span className='fw-bold'>{formatDate(new Date())}</span></span>
            </h3>
            <button
              type='button'
              disabled={report ? false : true}
              className='btn btn-sm btn-primary'
              style={{padding: '0.3rem', display: 'flex', gap: '', alignItems: 'center'}}
              onClick={() => {
                  //navigate('/storeServices/1')
                  setShowCreateAppModalDetails(true)
              }}
              >
                <span className='fs-7'>Ver Relatório</span>
                <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-4 ms-2 me-0'
                />     
            </button>
          </div>
          
          {/* <div style={{display: 'flex', gap: '2rem', justifyContent: 'space-between', marginTop: '0rem', paddingTop: '0rem'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <KTSVG path={'/media/icons/duotune/general/gen060.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} />
              <h3 
                className='card-title align-items-start flex-column' 
                style={{
                  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                }}>
                <span className='text-muted fw-semibold fs-9' style={{width: '100%', textAlign: 'center'}}>Gerado</span>
                <span className='card-label fw-bold fs-7 mb-1' style={{width: '100%', textAlign: 'center'}}>{data && data.energyMonth ? data.energyMonth : 0} KWh</span>
              </h3>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <KTSVG path={'/media/icons/duotune/finance/fin003.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} />
              <h3 
                className='card-title align-items-start flex-column' 
                style={{
                  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                }}>
                <span className='text-muted fw-semibold fs-9' style={{width: '100%', textAlign: 'center'}}>Faturado</span>
                
                <span className='card-label fw-bold fs-7 mb-1' style={{width: '100%', textAlign: 'center'}}>R$ {data && data.value_fat_mes ? data.value_fat_mes : 0}</span>
              </h3>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <KTSVG path={'/media/icons/duotune/finance/fin008.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} />
              <h3 
                className='card-title align-items-start flex-column' 
                style={{
                  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                }}>
                <span className='text-muted fw-semibold fs-9' style={{width: '100%', textAlign: 'center'}}>Economizado</span>
                <span className='card-label fw-bold fs-7 mb-1' style={{width: '100%', textAlign: 'center'}}>R$ {data && data.economy_mes ? data.economy_mes : 0}</span>
              </h3>
            </div>
          </div> */}

          <div style={{marginBottom: '1rem'}} id="carouselExampleControlsV3" className="carousel carousel-dark slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active" style={{paddingLeft: '2rem', paddingRight: '2rem'}}>
                  <div style={{display: 'flex', gap: '2rem', justifyContent: 'space-between', marginTop: '0rem', paddingTop: '0rem'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <KTSVG path={'/media/icons/duotune/general/gen060.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} />
                      <h3 
                        className='card-title align-items-start flex-column' 
                        style={{
                          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                        }}>
                        <span className='text-muted fw-semibold fs-9' style={{width: '100%', textAlign: 'center'}}>Gerado</span>
                        <span className='card-label fw-bold fs-7 mb-1' style={{width: '100%', textAlign: 'center'}}>{data && data.energyMonth ? data.energyMonth : 0} KWh</span>
                      </h3>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <KTSVG path={'/media/icons/duotune/finance/fin003.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} />
                      <h3 
                        className='card-title align-items-start flex-column' 
                        style={{
                          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                        }}>
                        <span className='text-muted fw-semibold fs-9' style={{width: '100%', textAlign: 'center'}}>Faturado</span>
                        
                        <span className='card-label fw-bold fs-7 mb-1' style={{width: '100%', textAlign: 'center'}}>R$ {data && data.value_fat_mes ? data.value_fat_mes : 0}</span>
                      </h3>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <KTSVG path={'/media/icons/duotune/finance/fin008.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} />
                      <h3 
                        className='card-title align-items-start flex-column' 
                        style={{
                          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                        }}>
                        <span className='text-muted fw-semibold fs-9' style={{width: '100%', textAlign: 'center'}}>Economizado</span>
                        <span className='card-label fw-bold fs-7 mb-1' style={{width: '100%', textAlign: 'center'}}>R$ {data && data.economy_mes ? data.economy_mes : 0}</span>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="carousel-item" style={{paddingLeft: '2rem', paddingRight: '2rem'}}>
                  <div style={{display: 'flex', gap: '2rem', justifyContent: 'space-between', marginTop: '0rem', paddingTop: '0rem'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      {/* <KTSVG path={'/media/icons/duotune/general/gen060.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} /> */}
                      <img src={toAbsoluteUrl('/media/imgs/co2.png')} style={{height: '40px', width: '40px'}} alt='Metornic' />
                      <h3 
                        className='card-title align-items-start flex-column' 
                        style={{
                          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                        }}>
                        <span className='text-muted fw-semibold fs-9' style={{width: '100%', textAlign: 'center'}}>CO2</span>
                        <span className='card-label fw-bold fs-7 mb-1' style={{width: '100%', textAlign: 'center'}}>{data && data.co2 ? data.co2 : 0} ton</span>
                      </h3>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      {/* <KTSVG path={'/media/icons/duotune/finance/fin003.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} /> */}
                      <img src={toAbsoluteUrl('/media/imgs/so2.png')} style={{height: '40px', width: '40px'}} alt='Metornic' />
                      <h3 
                        className='card-title align-items-start flex-column' 
                        style={{
                          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                        }}>
                        <span className='text-muted fw-semibold fs-9' style={{width: '100%', textAlign: 'center'}}>SO2</span>
                        
                        <span className='card-label fw-bold fs-7 mb-1' style={{width: '100%', textAlign: 'center'}}>R$ {data && data.so2 ? data.so2 : 0} ton</span>
                      </h3>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      {/* <KTSVG path={'/media/icons/duotune/finance/fin008.svg'} className={`svg-icon-muted svg-icon-3x ms-n1`} /> */}
                      <img src={toAbsoluteUrl('/media/imgs/coal.png')} style={{height: '40px', width: '40px'}} alt='Metornic' />
                      <h3 
                        className='card-title align-items-start flex-column' 
                        style={{
                          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                        }}>
                        <span className='text-muted fw-semibold fs-9' style={{width: '100%', textAlign: 'center'}}>Coal</span>
                        <span className='card-label fw-bold fs-7 mb-1' style={{width: '100%', textAlign: 'center'}}>R$ {data && data.coal ? data.coal : 0} ton</span>
                      </h3>
                    </div>
                  </div>
                </div>

              </div>
                <button style={{width: '1rem'}} className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsV3" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button style={{width: '1rem'}} className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsV3" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
            </div>

        </div>
        :
        <></>  
        }
      </div>

      <ModalPlant show={showCreateAppModal} handleClose={() => closedModal(false)} plant={dataFilter}/>
      <ModalDetailsReports
        show={showCreateAppModalDetails}
        id={report && report.id ? report.id : undefined} 
        handleClose={() => {
          setShowCreateAppModalDetails(false)
          //refresh()
        }} 
      />
    </div>
  );
}