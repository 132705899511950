/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { getUnitBranchAll } from "../../../../../../services/unitBranchService";
import { Loading } from "../../../../../components/Loadings/loading";
import { ListUnitBranchLinked } from "../listUnitBranchLinked";


const StepModal = ({hasError, formik}: any) => {
  const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  

  const getUnitBranchs = async () => {
    try {
      setLoading(true)
      const unitBranchs = await getUnitBranchAll()
      setUnitBranchs(unitBranchs)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar a lista de unidades. ", "error")

    }finally{
      setLoading(false)
    }
  }


  useEffect(() => {
    const fuction_async = async () =>{
      await getUnitBranchs()
    }
    fuction_async()
  }, []);

  if (loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  // }else{
  //   if(users.length === 0){
  //     return (
  //       <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
  //         <h3 style={{display: "flex", flexDirection: "column"}}>
  //           <span className='text-muted mt-1 fw-semibold fs-7'>Há um problema no cadastro. Tente novamente mais tarde. </span>
  //         </h3>
  //       </div>
  //     )
  //   }
  // }

  return (
    <div className='current' data-kt-stepper-element='content' >
      <div className='w-100'>
        {/*begin::Form Group */}
        
        <div style={{display: 'flex', gap: '1rem'}}> 
          <div className='fv-row mb-10' style={{width: '100%'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Descrição</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite uma descrição para o tipo de serviço'
              ></i>
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='description'
              placeholder=''
              value={formik.values.description}
              onChange={formik.handleChange("description")}
            />
            {!formik.values.description && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe uma descrição para o tipo de serviço
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10' style={{width: '100%'}}>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>Preço</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Digite uma descrição para o tipo de serviço'
              ></i>
            </label>
            <input
              type='number'
              className='form-control form-control-lg form-control-solid'
              name='preco'
              placeholder=''
              value={formik.values.preco}
              onChange={formik.handleChange("preco")}
            />
            {/* {!formik.values.description && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Informe uma descrição para o tipo de serviço
                </div>
              </div>
            )} */}
          </div>
        </div>

        
        

        <div style={{display: 'flex'}}>
          <div style={{flex: '3'}}>
            <ListUnitBranchLinked unitBranchs={unitBranchs} className="className='card-xl-stretch bg-light mb-xl-8" formik={formik}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export {StepModal}
function toggleToast(arg0: string, arg1: string) {
  throw new Error("Function not implemented.");
}

