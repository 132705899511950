import React, { createContext, useContext, useState, useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';

interface ToastContextType {
  showToast: boolean;
  toggleToast: (message?: string, type?: string) => void;
  closeToast: () => void;
}

const ToastContext = createContext<ToastContextType>({
  showToast: false,
  toggleToast: () => {},
  closeToast: () => {},
});

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');

  // Fecha o toast automaticamente após 10 segundos
  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        closeToast();
      }, 10000); // 10 segundos

      return () => clearTimeout(timer); // Limpa o timer se o toast for fechado antes
    }
  }, [showToast]);

  const toggleToast = (message?: string, type?: string) => {
    setToastMessage(message || ''); // Use uma mensagem em branco se nenhum parâmetro for fornecido
    setToastType(type ? type : "");
    setShowToast(true);
  };

  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <ToastContext.Provider value={{ showToast, toggleToast, closeToast }}>
      {children}
      {showToast && (
        <div
          className="position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: 1055 }} 
        >
          <Toast
            show={showToast}
            onClose={closeToast}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            className={`bg-${toastType === "error" ? "danger" : toastType === "success" ? "success" : toastType === "alert" ? "warning" : "primary"}`}
            style={{ 
              width: '90vw',        // Largura responsiva
              maxWidth: '40rem',    // Limite máximo da largura
              height: '4rem', 
              display: 'flex', 
              alignItems: 'center',
              zIndex: 1055,  // Certifique-se de que o Toast tenha um z-index elevado
            }}
          >
            <Toast.Body
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '1.2rem',
                alignItems: 'center',
                width: '100%',
                color: 'white'
              }}
            >
              {toastMessage}
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={closeToast}
                aria-label="Close"
              ></button>
            </Toast.Body>
          </Toast>
        </div>
      )}
    </ToastContext.Provider>
  );
};