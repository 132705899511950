/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { searchSubstitut } from '../../../../utils/search';
import { SearchInput } from '../../../components/SearchInput';
//import { ModalCreateCredential } from '../../../portals/components/modalCreateCredential';
import { ModalAddUpdateClient } from '../ModalAddEditClient';
import { ModalDeleteClient } from '../ModalDeleteClient';
import { ModalDetailsClient } from '../ModalDetailsClient';
import { TableBody } from './TableBody';
import { ModalAddClientPlanilha } from '../ModalAddClientPlanilha';


type Props = {
  className: string;
  clients: any[]
  refresh: any
}

const TableClients: React.FC<Props> = ({className, clients, refresh}) => {
  
  const [mySearch, setMySearch] = useState<any[]>([]);
  const [control, setControl] = useState(false);
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateAppModalPlanilha, setShowCreateAppModalPlanilha] = useState<boolean>(false)
  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
  const [myClient, setMyClient] = useState<any | null>(null);
  const [largura, setLargura] = useState(window.innerWidth);

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(()=> {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=> {
    const search_clients = mySearch.length > 0 ? 
    searchSubstitut(clients, mySearch)
      : []
    setMySearch(search_clients)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients])
  
  return (
    <div className={`card ${className}`} style={{height: largura > 1200 ? '72vh' : '68vh', paddingBottom: '1rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        {/*<h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Lista de Clientes</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{clients.length} Cliente(s)</span>
        </h3>*/}
        <div 
          style={{
            display: 'flex', 
            flexDirection: largura > 1200 ? 'row' : 'column',
            gap: '1rem', 
            alignItems: 'center', 
            width: "100%", 
            justifyContent: 'space-between'
          }}>
          <SearchInput 
            className='form-control form-control-solid ps-14 bg-body'
            placeholder='Buscar Clientes'
            myDate={clients}
            setMySearch={setMySearch}
            control={control}
            style={{width: largura < 1200 ? "100%" : "300px"}}
            setControl={setControl}
            searchParameter="nameResponsible"
          />

          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click para adicionar uma unidade filial'
            style={{width: largura < 1200 ? '100%' : "", display: 'flex', justifyContent: "end"}}
          >
            <a
              onClick={() =>{setShowCreateAppModal(true)}}
              href='#'
              className='btn btn-sm btn-light-primary me-1'
              //data-bs-target='#kt_modal_create_unitBranch'

            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Novo Cliente
            </a>

            <a
              onClick={() =>{setShowCreateAppModalPlanilha(true)}}
              href='#'
              className='btn btn-sm btn-light-primary'
            >
              <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-2' />
            </a>
          </div>
        </div>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3' style={largura < 1200 ? {height: '20rem', overflow: 'auto', paddingTop: '0rem'} : {overflow: 'auto'}}>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted fs-7 text-uppercase gs-0'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-250px'>Nome</th>
                <th className='min-w-140px'>CPF</th>
                <th className='min-w-140px'>E-mail</th>
                <th className='min-w-140px'>Usuario Vinculado</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
              {
                control === false ? (
                  <TableBody 
                    clients={clients} 
                    setMyClient={setMyClient}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
                : (
                  <TableBody 
                    clients={mySearch} 
                    setMyClient={setMyClient}
                    setShowCreateAppModal={setShowCreateAppModal}
                    setShowCreateAppModalDelete={setShowCreateAppModalDelete}
                    setShowCreateAppModalDetails={setShowCreateAppModalDetails}
                  />
                )
              }
              
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
        <ModalAddClientPlanilha
          show={showCreateAppModalPlanilha}
          //data={myClient ? myClient : undefined}
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalPlanilha(false)
            refresh()
          }} 
        />

        <ModalAddUpdateClient
          show={showCreateAppModal}
          id={myClient ? myClient.id : undefined} 
          data={myClient ? myClient : undefined}
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModal(false)
            refresh()
          }} 
        />
        <ModalDeleteClient 
          show={showCreateAppModalDelete}
          data={myClient}
          id={myClient ? myClient.id : undefined} 
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />

        <ModalDetailsClient
          show={showCreateAppModalDetails}
          id={myClient ? myClient.id : undefined} 
          handleClose={() => {
            setMyClient(null)
            setShowCreateAppModalDetails(false)
            refresh()
          }} 
        />
      {/* begin::Body */}
    </div>
  )
}

export {TableClients}
