import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'

const App = () => {
  const {currentUser} = useAuth()

  useEffect(() => {
    if(currentUser && currentUser.companies && currentUser.companies.length > 0){
      if(currentUser.companies[0].color_primary){
        document.documentElement.style.setProperty('--bs-primary', currentUser.companies[0].color_primary);
        document.documentElement.style.setProperty('--bs-text-primary', currentUser.companies[0].color_primary);
        document.documentElement.style.setProperty('--bs-secondary', currentUser.companies[0].color_primary_variet);
        //document.documentElement.style.setProperty('--bs-primary-light', currentUser.companies[0].color_primary); 
      }

      if(currentUser.companies[0].color_primary_variet){
        document.documentElement.style.setProperty('--bs-primary-active', currentUser.companies[0].color_primary_variet);
      }
      //document.documentElement.style.setProperty('--bs-primary-light', 'pink');
      //document.documentElement.style.setProperty('--bs-btn-bg', 'pink');
    }
  }, [currentUser])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
