import { useEffect, useState } from "react";
import useSize from "../../../hooks/useSize";
import { getReportsAll } from "../../../services/reports";
import { useToast } from "../../components/Toats";
import { KTSVG } from "../../../../_metronic/helpers";
import { getplantsAll } from "../../../services/plantsService";
import { Loading } from "../../components/Loadings/loading";
import { DateMes } from "../../../utils/date";
import { ModalDetailsReports } from "../components/ModalDetailsReport";
import { ModalResumoReport } from "../components/ModalResumoReport";

type Props = {
    className: string;
}

const TableReportsV2: React.FC<Props> = ({className}) => {
    const {width} = useSize();
    const [loading, setLoading] = useState<boolean>(true);
    const [reports, setReports] = useState<any[]>([]);
    const [loadingPlantsFilter, setLoadingPlantsFilter] = useState(false);
    const [plantString, setPlantString] = useState<string | null>(null);
    const [dataFilter, setDataFilter] = useState<any[]>([]);
    const [years, setYears] = useState<any[]>([]);
    const [showCreateAppModalDetails, setShowCreateAppModalDetails] = useState<boolean>(false)
    const [myReport, setMyReport] = useState<any | null>(null);
    const {height} = useSize()

    const { toggleToast } = useToast();

    function getUniqueSortedYears(data: any) {
        const years = [...new Set(data.map(obj => new Date(obj.data).getFullYear()))];
        setYears(years.sort((a: any, b: any) => b - a));
    }

    const getReports = async () => {
        try {
          setLoading(true)
    
          const reports = await getReportsAll("GERADO", undefined, plantString ? plantString : undefined)

          if(reports && reports.length > 0){
            getUniqueSortedYears(reports)
          }else{
            setYears([])
          }
          setReports(reports)
    
        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar relatórios. ", "error")
        }finally{
          setLoading(false)
        }
    }

    const getPlantsFilter = async () => {
        try {
            setLoadingPlantsFilter(true)
            const myPlants = await getplantsAll()
            setDataFilter(myPlants)
            
            if(myPlants && myPlants.length > 0){
                setPlantString(myPlants[0].id)
            }
        } catch (error) {
          console.log(error)
        }finally{
          setLoadingPlantsFilter(false)
        }
    }

    const refresh = () => {
        getReports()
    }

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plantString]);

    useEffect(() => {
        getPlantsFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loadingPlantsFilter || loading) {
        return (
          <div className='spinnerLoadingDashboard'>
            <Loading myClass='spinner-border spinnerDashboard'/>
          </div>
        );
    }

    return (
        // <div className={`card ${className}`} style={{height: width > 1200 ? '72vh' : '68vh', paddingBottom: '1rem'}}></div>
        <div style={{
            paddingBottom: '1rem', 
            overflow: 'auto',
            height: height <= 750 ? '65vh' 
            : height <= 850 ? '65vh'
            : height <= 930 ? '70vh'
            : '70vh',
        }}>
            <div  
                style={{
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '1rem', 
                    flexDirection: 'row', 
                    marginTop: '0.5rem', 
                    justifyContent: 'end'
                }}>
                {/* <h3 className='card-title align-items-start flex-column pb-3 py-3'>
                    <span className='text-body mt-1 fw-semibold fs-5'>Usina</span>
                </h3> */}
                <div>
                    <select
                        name='status'
                        data-control='select2'
                        data-hide-search='true'
                        className='form-select form-select-white form-select-sm'
                        value={plantString ? plantString : "1"}
                        onChange={(event: any) => {
                            setPlantString(event.target.value)
                        
                        }}
                        >
                        {
                            dataFilter && dataFilter.length > 0 && dataFilter.map((plant: any) => {
                            return (
                                <option value={plant.id}>{`${plant.name}`}</option>
                            );
                            })
                        }
                    </select>
                </div>
            </div>

            {years && years.length > 0 ?
                years.map((year: string) => {
                    return (
                        <div>
                            <h3 className='card-title align-items-start flex-column pb-3 py-3'>
                                <span className='card-label fw-bold fs-5 mb-1'>{year}</span>
                                {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
                            </h3>
                            
                            <div className={`card p-2`}>
                                {reports && reports.length > 0 ?
                                    reports.map((report: any) => {
                                        if(new Date(report.data).getFullYear() === parseInt(year)){
                                            return (
                                                <div className="bg-hover-primary" 
                                                    style={{
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        justifyContent: 'space-between', 
                                                        borderRadius: '10px',
                                                        padding: '0.5rem'
                                                    }}
                                                    onClick={() => {
                                                        setMyReport(report)
                                                        setShowCreateAppModalDetails(true)
                                                    }}
                                                >
                                            
                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                        <div className='symbol symbol-40px symbol-circle me-5'>
                                                            <KTSVG path="/media/icons/duotune/files/fil005.svg" className="svg-icon-muted svg-icon-2hx" />
                                                        </div>
                                                        <div>
                                                            <h3 className='card-title align-items-start flex-column'>
                                                                <span className='card-label fs-5'>{DateMes(report.data)}</span>
                                                                {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='symbol symbol-10px symbol-circle me-5'>
                                                            <KTSVG path="media/icons/duotune/arrows/arr001.svg" className="svg-icon-muted svg-icon-1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                    })
                                :
                                    <>
                                        <h3 className='card-title align-items-start flex-column pb-3 py-3'>
                                            {/* <span className='text-body mt-1 fw-semibold fs-5'>Usina</span> */}
                                            <span className='text-muted mt-1 fw-semibold fs-5'>Não há relatórios</span>
                                        </h3>
                                    </>
                                }
                            </div>
                        </div>
                    )
                })
            :
            <div style={{display: 'flex'}}>
                <h3 className='card-title align-items-start flex-column pb-3 py-3'>
                    {/* <span className='text-body mt-1 fw-semibold fs-5'>Usina</span> */}
                    <span className='text-muted mt-1 fw-semibold fs-5'>Não há relatórios gerados!</span>
                </h3>
            </div>}

            {<ModalResumoReport
                show={showCreateAppModalDetails}
                id={myReport ? myReport.id : undefined} 
                handleClose={() => {
                    setMyReport(null)
                    setShowCreateAppModalDetails(false)
                    refresh()
                }} 
        />}
        </div>
    )

}

export {TableReportsV2}