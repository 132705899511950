import React from 'react';

type Props = {
    style?: any;
}

export function LoadingSpan({style, ...rest}: Props) {
  return (
    <span 
      className="spinner-border spinner-border-sm ms-2 me-0" 
      role="status" 
      aria-hidden="true" 
      {...rest}
    >
    </span>
  );
}