import React from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Loading } from '../Loadings/loading';

type Props = {
    devices?: any[]
    loading: boolean
}

export function TableDevicesModalPlant({devices, loading} : Props) {
    //const [control, setControl] = useState(false);
    
  return (
    <div className='table-responsive'>
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='text-muted text-start fw-bolder fs-8 text-uppercase gs-0'>
                <th className='min-w-100px'>Nº de Série</th>
                <th className='min-w-100px'>Modelo</th>
                <th className='min-w-100px'>Status</th>
              </tr>
            </thead>
              {
                loading ? 
                  <tbody>
                    <tr>
                      <td colSpan={5} className="tdLoadingUnit">
                        <Loading myClass='spinner-border loadingCenterUnit' />
                      </td>
                    </tr>
                  </tbody>
                : 
                   devices ? (
                    devices.length > 0 ?
                        devices.map((inverter: any) => {
                            return (
                                <tr 
                                    onClick={() =>{
                                        // if(report.status === "GERADO"){
                                        //     setMyReport(report)
                                        //     setShowCreateAppModalDetails(true)
                                        // }
                                        // else{
                                        //     setMyReport(report)
                                        //     setShowCreateAppModalView(true)
                                        // }
                                    }}
                                >
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-30px symbol-circle me-5'>
                                                <img src={toAbsoluteUrl("/media/imgs/inverter.png")} />
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                                                        {
                                                            inverter.inverterSerialNumber 
                                                            ? inverter.inverterSerialNumber 
                                                            : inverter.inverterSerialNum ?
                                                            inverter.inverterSerialNum : "Não encontrado. "
                                                        }
                                                        
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div className='d-flex align-items-center'>
                            
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                                                        {
                                                            inverter.inverterModel 
                                                            ? inverter.inverterModel
                                                            : "Não encontrado. "
                                                        }
                                                        
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <span 
                                            className={
                                                `badge badge-light-${
                                                    inverter.status !== null
                                                    ? inverter.status === 0
                                                    ? "success"
                                                    : inverter.status === 1
                                                    ? "danger"
                                                    : "secondary"
                                                : "secondary"
                                                } fs-7 fw-semibold`
                                            }
                                            >
                                                {
                                                    inverter.status !== null
                                                    ? inverter.status === 0
                                                    ? "ATIVO"
                                                    : inverter.status === 1
                                                    ? "INATIVO"
                                                    : "Não identificado. "
                                                : "Não identificado. "
                                                }
                                            </span>
                                    </td>
                            
                                </tr>
                            )
                        })
                    : 
                    <tr>
                        <td colSpan={6} style={{textAlign: 'center'}}>
                            <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Inversores não encontrados!
                                </span>
                            </div>
                        </td>
                    </tr>
                   )
                   :
                   (
                    <tr>
                        <td colSpan={6} style={{textAlign: 'center'}}>
                            <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Inversores não encontrados!
                                </span>
                            </div>
                        </td>
                    </tr>
                   )
              }
          </table>
        </div>
  );
}