/* eslint-disable jsx-a11y/anchor-is-valid */

const StepV2 = ({hasError, formik}: any) => {
  return (
    <div className='current' data-kt-stepper-element='content' >
      <div className='w-100' style={{display: 'flex', gap: '1rem', flexWrap: 'wrap'}}>
        
        <div className="border border-gray-300 border-dashed rounded" style={{padding: '1rem', flex: '1'}}>

          <h3 className='align-items-start flex-column mb-10 mt-10' style={{display: "flex", marginBottom: '1rem'}}>
            <span className='card-label fw-bold fs-4 mb-1'>
              Automação das Faturas 
              <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Informe os valores padrão que normalmente são exibidos na fatura dessa unidade'
              ></i>
            </span> 
            <span className='text-muted fw-semibold fs-6'>Valores padrões/aproximados nas faturas não-informadas</span>
          </h3>

          
          <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
            <div className='fv-row' style={{flex: '1'}}>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span>Dia da Cobrança</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Dia que é gerado (feito a leitura) da fatura da unidade'
                ></i>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='dia_padrão'
                placeholder='0'
                value={formik.values.dia_padrão}
                onChange={formik.handleChange("dia_padrão")}
              />
              {/* {!formik.values.companyName && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    Informe um nome para a unidade consumidora
                  </div>
                </div>
              )} */}
            </div>

          </div>


          <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
            <div className='fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span>Energia Registrada (KWh)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Total de energia consumida pela unidade (concessionaria + créditos/usina)'
                ></i>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='energia_registrada_padrao'
                //disabled={concessionarie && concessionarie.status_mon_aut !== "NÃO MONITORADA" ? false : true}
                placeholder='0.0'
                value={formik.values.energia_registrada_padrao}
                onChange={formik.handleChange("energia_registrada_padrao")}
              />
              {/* {!formik.values.companyName && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    Informe um nome para a unidade consumidora
                  </div>
                </div>
              )} */}
            </div>

            <div className='fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span>Energia Faturada (KWh)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Total de energia consumida da concessionária (que foi cobrado na fatura)'
                ></i>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='energia_faturada_padrao'
                //disabled={concessionarie && concessionarie.status_mon_aut !== "NÃO MONITORADA" ? false : true}
                placeholder='0.0'
                value={formik.values.energia_faturada_padrao}
                onChange={formik.handleChange("energia_faturada_padrao")}
              />
              {/* {!formik.values.companyName && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    Informe um nome para a unidade consumidora
                  </div>
                </div>
              )} */}
            </div>

            
          </div>

          <div style={{display: 'flex', gap: '1rem'}} className="mb-5">
            <div className='fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span>Tarifa (R$)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Valor padrão da tarifa'
                ></i>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='tarifa_padrao'
                //disabled={concessionarie && concessionarie.status_mon_aut !== "NÃO MONITORADA" ? false : true}
                placeholder='0.00'
                value={formik.values.tarifa_padrao}
                onChange={formik.handleChange("tarifa_padrao")}
              />
              {/* {!formik.values.companyName && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    Informe um nome para a unidade consumidora
                  </div>
                </div>
              )} */}
            </div>

            <div className='fv-row'>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                <span>Valor Faturado (R$)</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Valor que foi cobrado pela concessionária na fatura'
                ></i>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='valor_faturado_padrao'
                //disabled={concessionarie && concessionarie.status_mon_aut !== "NÃO MONITORADA" ? false : true}
                placeholder='0.00'
                value={formik.values.valor_faturado_padrao}
                onChange={formik.handleChange("valor_faturado_padrao")}
              />
              {/* {!formik.values.companyName && hasError && (
                <div className='fv-plugins-message-container'>
                  <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                    Informe um nome para a unidade consumidora
                  </div>
                </div>
              )} */}
            </div>

            
          </div>

        </div>

        {/* <div style={{flex: '1'}}>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <a
                className="nav-link active text-active-primary border-active-primary border-hover-primary me-6"
                data-bs-toggle="tab"
                href="#details"
              >
                Usina Vinculada
              </a>
            </li>
            <li className='nav-item'>
              <a
                className="nav-link text-active-primary border-active-primary border-hover-primary me-6"
                data-bs-toggle="tab"
                href="#relators"
              >
                Aut. das Faturas
              </a>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent5">
            <div
              className="tab-pane fade active show"
              id="details"
              role="tabpanel"
            >
              <ListPlantsLinked plants={plants} className="card-xl-stretch bg-body mb-xl-8" formik={formik}/>
            </div>

            <div 
              className="tab-pane fade show divTableReport"
              id="relators"
              role="tabpanel"
            >
              Epaaaaaaaaaaaaaaaa
            </div>
          </div>

          
        </div> */}

      </div>
    </div>
  )
}
export {StepV2}


