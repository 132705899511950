import api from "./api";

export const getCompanyAll = async () => {
    const response = await api.get(`company/listAll`);
    return response.data;
};

export const putCompanyOne = async (id: string, data: any) => {
    const response = await api.put(`company/${id}`, data);
    return response.data;
};