import React, { useState } from 'react';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';
import { ModalDescriptionOS } from './components/modalDescriptionOS';
import { useAuth } from '../../../../../../../../modules/auth';


type Props = {
  itemOrderOfService: any;
  refresh: any
}

export function DescriptionOS({itemOrderOfService, refresh} : Props) {
    const [loading, setLoading] = useState()
    const [showModalDescriptionOS, setShowModalDescriptionOS] = useState<boolean>(false)
    const {currentUser} = useAuth()


  return (
    <div className='card'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0' style={{justifyContent: 'space-between', width: '100%'}}>
            <h3 className='fw-bolder m-0'>Descrição {itemOrderOfService && itemOrderOfService.description ? "(1)" : "(0)"}</h3>

            {currentUser && currentUser.role && currentUser.role.slug && currentUser.role.slug !== 'CLIENTE' && 
              <button
                  className='btn btn-lg text-hover-primary'
                  style={{padding: '0rem'}}
                  onClick={(event) => {
                      event.stopPropagation();
                      setShowModalDescriptionOS(true)
                  }}
                  type="button"
              >
                <KTSVG className='svg-icon-muted svg-icon-2hx' path='/media/icons/duotune/general/gen035.svg' />
              </button>
            }

          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse'>
          <div>
            <div className='card-body border-top p-9'>
            <div className='mb-6' style={{display: 'flex', gap: '5rem', alignItems: 'center'}}>
              <label className='col-form-label fw-bold fs-6'>Descrição </label>

              <div>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <span className='text-muted fw-semibold fs-6'>
                        {itemOrderOfService && itemOrderOfService.description ? itemOrderOfService.description : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            </div>


            {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button 
                className='btn btn-primary'>
                {!loading && 'Salvar Descrição'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div> */}
          </div>
        </div>

        <ModalDescriptionOS 
          id={itemOrderOfService && itemOrderOfService.id ? itemOrderOfService.id : null}
          refresh={refresh}
          data={itemOrderOfService ? itemOrderOfService : null}
          handleClose={() => {
            setShowModalDescriptionOS(false)
          }}
          show={showModalDescriptionOS}
        />
      </div>
  );
}