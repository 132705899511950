import api from "./api";

export const getFunnelsAll = async(unitBranch?: string) => {
    const response = await api.get(`funnels/listAll?unitBranch=${unitBranch ? unitBranch : ""}`);
    return response.data;
};

export const getFunnelsOne = async(id: string) => {
    const response = await api.get(`funnels/${id}`);
    return response.data;
};

export const postFunnelsOne = async(body: any) => {
    const response = await api.post(`funnels/`, body);
    return response.data;
};

export const putFunnelsOne = async(body: any, id: string) => {
    const response = await api.put(`funnels/${id}`, body);
    return response.data;
};

export const deleteFunnelsOne = async(id: string) => {
    const response = await api.delete(`funnels/${id}`);
    return response.data;
};