import api from "./api";

export const postProductOne = async (body: any) => {
    const response = await api.post(`products/`, body);
    return response.data;
};

export const getProductsAll = async () => {
    const response = await api.get(`products/listAll`,);
    return response.data;
};

export const putProductAll = async (body: any, id: string) => {
    const response = await api.put(`products/${id}`, body);
    return response.data;
};

export const deleteProductOne = async (id: string) => {
    const response = await api.delete(`products/${id}`);
    return response.data;
};