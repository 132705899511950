/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'
import { SearchInput } from '../../../../../components/SearchInput';


type Props = {
  className: string;
  plants: any[]
  formik: any;
  hasError: any
}

const ListPlantsLinkedService: React.FC<Props> = ({className, plants, formik, hasError}) => {

  const [largura, setLargura] = useState(window.innerWidth);
  const [control, setControl] = useState(false);
  const [mySearch, setMySearch] = useState<any[]>([]);

  const updateData = (value: string, checked: boolean) => {
    if(checked === true){
      formik.setFieldValue('idPlant', value)
      // const myPlant = plants.filter((plant: any) => plant.id === value)
      // if(myPlant.length > 0 ){
      //   if(myPlant[0].unitBranch){
      //     formik.setFieldValue('idUnitBranch', myPlant[0].unitBranch.id)
      //   }
      // }
    }  
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
    window.removeEventListener('resize', atualizarTamanhoDaTela);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <div className={`card ${className}`} style={{paddingBottom: '2rem', flex: '1'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5' style={{alignItems: 'center'}}>
        <div>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-dark'>Lista de usinas</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Usina referente ao serviço a ser realizado</span>
          </h3>
        </div>

        <div>
          <SearchInput 
            className={`form-control form-control-solid bg-body ps-14 ${largura < 1200 ? "fs-7" : ""}`}
            placeholder='Buscar pelo Nome'
            myDate={plants}
            setMySearch={setMySearch}
            control={control}
            style={{width: largura < 1200 ? "100%" : "300px", flex: '1'}}
            setControl={setControl}
            searchParameter="name"
          />
        </div>


        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5' style={{height: '20rem', overflowY: "auto"}}>
        {/* begin::Item */}
        {
          plants && plants.length > 0 ?
          control ?
            mySearch.map((plant: any) => {
              return (
                <div className='d-flex align-items-center mb-7' style={{justifyContent: 'space-between'}}>
                  {/* begin::Symbol */}
                  <div className='d-flex align-items-center' style={{flex: '4'}}>
                    <div className={`symbol symbol-${largura < 1200 ? '40px' : '50px'} me-5`}>
                      <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div className={`symbol symbol-${largura < 1200 ? '40px' : '50px'}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
                        </div>
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Text */}
                    <div className='d-flex flex-column'>
                      <a href='#' className={`text-dark text-hover-primary fs-${largura < 1200 ? '7' : '6'} fw-bold`}>
                        {plant.name}
                      </a>
                      <span className={`text-muted fw-semibold fs-${largura < 1200 ? '7' : '6'}`}>{plant.address ? plant.address : "Não localizado."}</span>
                    </div>
                  </div>
                  <div className='form-check form-check-custom form-check-primary form-check-solid mx-5' style={{flex: '1', display: 'flex', justifyContent: 'end'}}>
                    <input
                      className={`form-check-input ${formik.values.idPlant !== plant.id ? 'bg-secondary' : ''}`} 
                      type='checkbox'
                      //checked={plant.imported}
                      checked={formik.values.idPlant === plant.id ? true : false}
                      value={plant.id}
                      onChange={(e) =>{
                        updateData(e.target.value, e.target.checked)
                      }}
                      //onChange={formik.handleChange("idsPlants")}
                    />
                  </div>
                  {/* end::Text */}
                </div>
              )
            })
          :
          plants.map((plant: any) =>{
              return (
                <div className='d-flex align-items-center mb-7' style={{justifyContent: 'space-between'}}>
                  {/* begin::Symbol */}
                  <div className='d-flex align-items-center' style={{flex: '4'}}>
                    <div className={`symbol symbol-${largura < 1200 ? '40px' : '50px'} me-5`}>
                      <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div className={`symbol symbol-${largura < 1200 ? '40px' : '50px'}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <img src={toAbsoluteUrl('/media/imgs/plant2.png')} className='' alt='' />
                        </div>
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Text */}
                    <div className='d-flex flex-column'>
                      <a href='#' className={`text-dark text-hover-primary fs-${largura < 1200 ? '7' : '6'} fw-bold`}>
                        {plant.name}
                      </a>
                      <span className={`text-muted fw-semibold fs-${largura < 1200 ? '7' : '6'}`}>{plant.address ? plant.address : "Não localizado."}</span>
                    </div>
                  </div>
                  <div className='form-check form-check-custom form-check-primary form-check-solid mx-5' style={{flex: '1', display: 'flex', justifyContent: 'end'}}>
                    <input
                      className={`form-check-input ${formik.values.idPlant !== plant.id ? 'bg-secondary' : ''}`} 
                      type='checkbox'
                      //checked={plant.imported}
                      checked={formik.values.idPlant === plant.id ? true : false}
                      value={plant.id}
                      onChange={(e) =>{
                        updateData(e.target.value, e.target.checked)
                      }}
                      //onChange={formik.handleChange("idsPlants")}
                    />
                  </div>
                  {/* end::Text */}
                </div>
              )
          })
          :
          <div className='d-flex align-items-center mb-7' style={{height: '100%', justifyContent: 'center'}}>
            <h3 className='card-title align-items-start flex-column'>
              <span className='text-muted mt-1 fw-semibold fs-7'>Não há usinas vinculadas.</span>
            </h3>
          </div>
        }

        
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListPlantsLinkedService}
