import React from 'react';
import { checkIsActive, KTSVG } from '../../../../helpers';
import {Link, useLocation} from 'react-router-dom'
import clsx from 'clsx';

export function SideBarBaixo() {
    const {pathname} = useLocation()
  return (
    <nav className="navbar navbar-expand-lg navbar-light"
        style={{
            position: 'fixed',
            zIndex: '10',
            bottom: '0.5rem',
            width: '100%',
            //height: '4rem',
            paddingLeft: '1.2rem',
            paddingRight: '1rem'
        }}
    >
            <div id="navbarSupportedContent" className='bg-primary' style={{width: '100%', paddingBottom: '0rem', padding: '0.2rem', borderRadius: '10px'}}>
                <ul 
                    className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
                    style={{justifyContent: 'space-evenly'}}    
                >
                    <li className="nav-item">
                        <Link 
                            className={clsx('nav-link text-active-primary me-6 bg-active-secondary bg-hover-primary p-2', {active: checkIsActive(pathname, '/dashboard')})}
                            style={{borderRadius: '5px'}}
                            to={'/dashboard'}
                        >
                            <KTSVG path="media/icons/duotune/graphs/gra005.svg" className="svg-icon-light svg-icon-2hx" />
                        </Link>
                        {/* <a
                            className="nav-link active text-active-primary me-6 bg-active-secondary bg-hover-primary"
                            data-bs-toggle="tab"
                            href="#kt_tab_pane_1"
                        >
                            <KTSVG path="media/icons/duotune/graphs/gra005.svg" className="svg-icon-muted svg-icon-2hx" />
                        </a> */}
                    </li>
                    <li className="nav-item">
                        <Link 
                            className={clsx('nav-link text-active-primary me-6 bg-active-secondary bg-hover-primary p-2', {active: checkIsActive(pathname, '/relatorios')})}
                            style={{borderRadius: '5px'}}
                            to={'/relatorios'}
                        >
                             <KTSVG path="media/icons/duotune/files/fil005.svg" className="svg-icon-light svg-icon-2hx" />
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link 
                            className={clsx('nav-link text-active-primary me-6 bg-active-secondary bg-hover-primary p-2', {active: checkIsActive(pathname, '/faturas')})}
                            style={{borderRadius: '5px'}}
                            to={'/faturas'}
                        >
                             <KTSVG path="media/icons/duotune/files/fil001.svg" className="svg-icon-light svg-icon-2hx" />
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link 
                            className={clsx('nav-link text-active-primary me-6 bg-active-secondary bg-hover-primary p-2', {active: checkIsActive(pathname, '/storeServices')})}
                            style={{borderRadius: '5px'}}
                            to={'/storeServices'}
                        >
                            <KTSVG path="media/icons/duotune/ecommerce/ecm006.svg" className="svg-icon-light svg-icon-2hx" />
                        </Link>
                        
                    </li>
                </ul>
            </div>
    </nav>
  );
}