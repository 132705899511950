import api from "./api";

export const getItemServiceAll = async (idService: any) => {
    const response = await api.get(`itemService/listAll?idService=${idService}`);
    return response.data;
};

export const postItemServiceOne = async (body: any) => {
    const response = await api.post(`itemService/`, body);
    return response.data;
};

export const deleteItemServiceOne = async (id: string) => {
    const response = await api.delete(`itemService/${id}`);
    return response.data;
};