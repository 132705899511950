/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { NotImported } from '../../../../components/NotInformat';
//import { DateComplete } from '../../../../../utils/date';

type Props = {
    typeServices: any[];
    setMyTypeService: any;
    setShowCreateAppModal: any;
    setShowCreateAppModalDelete: any
    setShowCreateAppModalDetails: any
}

export function TableBody({
    typeServices, 
    setMyTypeService,
    setShowCreateAppModal,
    setShowCreateAppModalDelete, 
    setShowCreateAppModalDetails
}: Props) {

    const handleButtonClickDelete = (event, typeService) => {
        event.stopPropagation();
        //unitBranch.idUser = unitBranch.users.length > 0 ? unitBranch.users[0].id : null; 
        setMyTypeService(typeService)
        setShowCreateAppModalDelete(true)
    };

    const handleButtonClickUpdate = (event: any, typeService: any) => {
        event.stopPropagation();
        typeService.idsUnitBranch = typeService.unitBranchs.length > 0 ? typeService.unitBranchs.map((unitBranch: any) => unitBranch.id) : null;
        setMyTypeService(typeService)
        setShowCreateAppModal(true)
    };

  return (
    <tbody>
        {typeServices && typeServices.length !== 0 ?   
            typeServices.map((typeService: any) => {
                return (
                    <tr 
                        onClick={() =>{
                            setMyTypeService(typeServices)
                            //setShowCreateAppModalDetails(true)
                        }}
                    >
                        <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                                <KTSVG path="/media/icons/duotune/general/gen016.svg" className="svg-icon-muted svg-icon-3hx" />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                    {typeService.description? typeService.description : "Não encontrado. "}
                                </a>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Descrição do tipo de serviço prestado
                                </span>
                            </div>
                            </div>
                        </td>

                        <td>
                            <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                        {typeService.preco? "R$ " +typeService.preco + ",00" : <NotImported className="fs-5 fw-bolder"/>}
                                    </a>
                                    {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        Descrição do tipo de serviço prestado
                                    </span> */}
                                </div>
                            </div>
                        </td>

                        <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                            {/* <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen019.svg'
                                className='svg-icon-3'
                                />
                            </a> */}
                            <a
                                onClick={(event: any) =>{
                                    handleButtonClickUpdate(event, typeService)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                            </a>
                            <a
                                onClick={(event: any) => {
                                    handleButtonClickDelete(event, typeService)
                                }}
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                                <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                                />
                            </a>
                            </div>
                        </td>
                    </tr>
                )
            })
        :
        <tr>
            <td colSpan={6} style={{textAlign: 'center'}}>
                <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                    <span className='text-muted fw-semibold text-muted d-block fs-4'>
                        Tipos de Serviço não encontrados!
                    </span>
                </div>
            </td>
        </tr>
        }
    </tbody>
  );
}