/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import { useToast } from '../../../components/Toats'
import { ListPlantsDetails } from './components/listPlants'
import { getClientOne } from '../../../../services/clientService'
import { UserResponsableClient } from './components/userResponsable'
import { UnitBranchLinkedClient } from './components/UnitBranchLinked'

type Props = {
    id: string;
    show: boolean;
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalDetailsClient = ({show, handleClose, id}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const [client, setClient] = useState<any | null>(null)
  const [largura, setLargura] = useState(window.innerWidth);
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }

  const getMyClient = async () => {
    try {
        setLoading(true)
        const myClient = await getClientOne(id ? id : "")
        setClient(myClient)
    } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar o cliente. ", "error")
    }finally{
        setLoading(false)
    }
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(()=> {
    if(id){
      getMyClient()
    }

    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
  }, [show])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Detalhes do Cliente {" - "}{loading ? "Carregando" : client ? client.nameResponsible : "Não identificado."}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        
        {!loading 
        ? 
            <div
                ref={stepperRef}
                className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
            >
                <div className='flex-row-fluid py-lg-0' style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                    <div style={{display: 'flex', gap: '0.5rem', width: '100%'}}>
                        {largura > 1200 && (<div style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                            <div 
                              className='symbol symbol-100px symbol-fixed position-relative' 
                              style={{
                                height: '110px', 
                                width: '110px', 
                                display: 'flex', 
                                justifyContent: 'center', 
                                alignItems: "center",
                            }}>
                              <img src={toAbsoluteUrl('/media/icons/duotune/communication/com006.svg')} alt='Metornic' />
                            </div>
                        </div>)}
                        
                        <div 
                          style={{
                            flex: largura > 1200 ? '3' : '', 
                            display: 'flex', 
                            gap: '1rem', 
                            width: largura < 1200 ? '100%' : '',
                            flexDirection: 'column', 
                          }}>
                          <div 
                            className='border border-gray-300 border-dashed rounded py-3 px-4 me-6 mb-3' 
                            style={{
                              display: "flex", 
                              flexDirection: 'column' , 
                              gap: '2rem', 
                              width: '100%'
                            }}>
                            <div style={{display: 'flex', gap: '1.2rem', flexWrap: 'wrap', alignItems: 'start'}}>
                              <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{client && client.nameResponsible ? client.nameResponsible : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>Nome</div>
                                </div>

                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{client && client.cpfResponsible ? client.cpfResponsible : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>CPF</div>
                                </div>

                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>
                                    {client && client.email ? client.email : "Não identificado"}
                                  </div>
                                  <div className='fw-bold fs-6 text-gray-400'>E-mail</div>
                                </div>
                              </div>

                              <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{client && client.cellphone ? client.cellphone : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>Celular</div>
                                </div>

                                <div className='d-flex' style={{flexDirection: "column"}}>
                                  <div className='fs-5 fw-bolder'>{client && client.telephone ? client.telephone : "Não identificado"}</div>
                                  <div className='fw-bold fs-6 text-gray-400'>Telefone</div>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                        <div style={{flex: '2'}}></div>
                    </div>

                    {largura > 1200  ? (
                      <div style={{display: 'flex', gap: '1rem'}}>
                        <div style={{flex: '1'}}>
                          <ListPlantsDetails className='card-xl-stretch bg-light mb-xl-8' plants={client ? client.plants : undefined}/>
                        </div>
                        <div style={{flex: '1'}}>
                          <div className='overflow-auto' style={{marginBottom: '1rem'}}>
                            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap' style={{marginLeft: '1rem'}}>
                              <li className='nav-item'>
                                <a
                                  className="nav-link active text-active-primary me-6"
                                  data-bs-toggle="tab"
                                  href="#responsable"
                                >
                                  Responsável
                                </a>
                              </li>
                              <li className='nav-item'>
                                <a
                                  className="nav-link text-active-primary me-6"
                                  data-bs-toggle="tab"
                                  href="#unitBranchLinked"
                                >
                                  Unidades (filiais)
                                </a>
                              </li>
                            </ul>

                            <div className="tab-content" id="myTabContent2">
                              <div
                                className="tab-pane fade active show"
                                id="responsable"
                                role="tabpanel"
                              >
                                <UserResponsableClient
                                  color='danger'
                                  name='Melody Macy'
                                  job='Marketing Analytic'
                                  avgEarnings='$14,560'
                                  totalEarnings='$236,400'
                                  online={true}
                                  users={client && client.user  ? client.user : undefined}
                                />
                              </div>
                              <div
                                className="tab-pane fade show"
                                id="unitBranchLinked"
                                role="tabpanel"
                              >
                                <UnitBranchLinkedClient unitBranchs={client && client.unitBranchs ? client.unitBranchs : undefined }/>
                              </div>
                            </div>

                          </div>
                          
                        </div>
                      </div>)
                    :
                      
                    <div>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap' style={{marginLeft: '1rem', marginBottom:"2rem"}}>
                              <li className='nav-item'>
                                <a
                                  className="nav-link active text-active-primary me-6"
                                  data-bs-toggle="tab"
                                  href="#usinas"
                                >
                                  Usinas vinculadas
                                </a>
                              </li>
                              <li className='nav-item'>
                                <a
                                  className="nav-link text-active-primary me-6"
                                  data-bs-toggle="tab"
                                  href="#all"
                                >
                                  Outras informações
                                </a>
                              </li>
                          </ul>

                          <div className="tab-content" id="myTabContent3">
                            <div 
                              style={{flex: '1'}}
                              className="tab-pane fade active show"
                              id="usinas"
                              role="tabpanel"  
                            >
                              <ListPlantsDetails className='card-xl-stretch bg-light mb-xl-8' plants={client ? client.plants : undefined}/>
                            </div>

                            <div 
                              style={{flex: '1'}}
                              className="tab-pane fade active show border border-gray-300 border-dashed rounded"
                              id="all"
                              role="tabpanel" 
                            >
                              <div className='overflow-auto' style={{marginBottom: '1rem'}}>
                                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap' style={{marginLeft: '1rem'}}>
                                  <li className='nav-item'>
                                    <a
                                      className="nav-link active text-active-primary me-6"
                                      data-bs-toggle="tab"
                                      href="#responsable"
                                    >
                                      Responsável
                                    </a>
                                  </li>
                                  <li className='nav-item'>
                                    <a
                                      className="nav-link text-active-primary me-6"
                                      data-bs-toggle="tab"
                                      href="#unitBranchLinked"
                                    >
                                      Unidades (filiais)
                                    </a>
                                  </li>
                                </ul>

                                <div className="tab-content" id="myTabContent2">
                                  <div
                                    className="tab-pane fade active show"
                                    id="responsable"
                                    role="tabpanel"
                                  >
                                    <UserResponsableClient
                                      color='danger'
                                      name='Melody Macy'
                                      job='Marketing Analytic'
                                      avgEarnings='$14,560'
                                      totalEarnings='$236,400'
                                      online={true}
                                      users={client && client.user  ? client.user : undefined}
                                    />
                                  </div>
                                  <div
                                    className="tab-pane fade show"
                                    id="unitBranchLinked"
                                    role="tabpanel"
                                  >
                                    <UnitBranchLinkedClient unitBranchs={client && client.unitBranchs ? client.unitBranchs : undefined }/>
                                  </div>
                                </div>

                              </div>
                              
                            </div>
                          </div>
                        
                      </div>
                    
                    }
                </div>
            </div>
            : 
            <h1>Carregando...</h1>
        }

      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalDetailsClient}
