/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { TableReports } from './TableReports'
import { useAuth } from '../../modules/auth'
import { TableReportsV2 } from './TableReportsV2'

//import { MyPortal } from './components/my-portal'

const reportsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportsPage: FC = () => {
  const user = useAuth();
  //const initialHtml = '<h1>Seu HTML inicial aqui...</h1>';

  return (
    <div className='column g-5 g-xl-8'>
      {user.currentUser?.role.slug === "CLIENTE" ?
        <TableReportsV2 
          className='card-xl-stretch mb-xl-8'
        />
      :
        <TableReports 
          className='card-xl-stretch mb-xl-8'
        />
      }


    {/* <PdfViewer /> */}
    {/* <PdfViewerV2 /> */}
      
      {/* <HtmlEditor initialHtml={initialHtml} />
      <HtmlEditorV2 initialHtml={undefined} /> */}
      {/* <HtmlEditorV3 initialHtml={undefined} /> */}
       {/*<ReportsTeste />*/}
    </div>
  )
}

const ReportsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={reportsBreadcrumbs}>Gerenciamento de relatórios</PageTitle>
      <ReportsPage />
    </>
  )
}

export {ReportsWrapper}
// function toggleToast(arg0: string, arg1: string) {
//     throw new Error('Function not implemented.');
// }

