import React, { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getInvoicePDF, getInvoicePDFBase64 } from '../../../services/invoices';
import { useToast } from '../Toats';

type Props = {
  link_file: string | null
}

const MyPDFViewer = ({link_file} : Props) => {
  const [viewPdf, setViewPdf] = useState<string | null>(null);
  const newPlugin = defaultLayoutPlugin();
  const [loading, setLoading] = useState(false)
  const { toggleToast } = useToast();

  // const handleChangle = () => {
  //   // Substitua o link abaixo pelo link do seu PDF
  //   //const pdfUrl = ""
  //   const pdfUrl = link_file;
  //   setViewPdf(pdfUrl);
  // };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     handleChangle(); // Pode chamar a função diretamente no envio do formulário
//   };

const getPDF = async () => {
  try {
    setLoading(true)
    let myPDF = await getInvoicePDF(link_file ? link_file : "")
    setViewPdf(myPDF.url);

  } catch (error) {
    console.log(error)
    //toggleToast("Fatura não encontrada. ", "error")

  }finally{
    setLoading(false)
  }
}

const getPDFBase64 = async () => {
  try {
    setLoading(true)
    let myPDF = await getInvoicePDFBase64(link_file ? link_file : "")
    //setViewPdf(myPDF.url);
    console.log('base64', myPDF)
    if(myPDF && myPDF.base64){
      setViewPdf(`data:application/pdf;base64,${myPDF.base64}`)
    }

  } catch (error) {
    console.log(error)
    //toggleToast("Fatura não encontrada. ", "error")

  }finally{
    setLoading(false)
  }
}
  useEffect(() => {
    //getPDF()
    getPDFBase64()
    //handleChangle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link_file])

  return (
    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', gap: '0.5rem',  }}>
      <div style={{ display: 'flex', gap: '1rem' }}>
        {/* Remova a tag input para upload de arquivo */}
        {/* <button type='button' className='btn btn-primary' onClick={handleSubmit}>
          Ver
        </button> */}
      </div>
      <div style={{ flexGrow: '1', padding: '1rem', height: '100%' }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div style={{ width: '100%', height: '50rem' }}>
            {loading 
              ? 
                <div
                    className='fw-bold fs-6 text-gray-400'
                    style={{
                      height: '40rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                >
                  Carregando..
                </div> 
              : 
                viewPdf 
                  ? 
                    <Viewer fileUrl={viewPdf} plugins={[newPlugin]}/> 
                  : 
                    <div
                      className='fw-bold fs-6 text-gray-400'
                      style={{
                        height: '40rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Não há PDFs de faturas disponíveis!
                    </div>
            }
            {/*viewPdf && <Viewer fileUrl={viewPdf} plugins={[newPlugin]}/>*/}
            {/*!viewPdf && (
              <div
                className='fw-bold fs-6 text-gray-400'
                style={{
                  height: '40rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Não há PDFs de faturas disponíveis!
              </div>
              )*/}
          </div>
        </Worker>
      </div>
    </div>
  );
};

export default MyPDFViewer;

