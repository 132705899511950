import api from "./api";

export const postTypeServiceOne = async (body: any) => {
    const response = await api.post(`typeService/`, body);
    return response.data;
};

export const getTypeServicesAll = async () => {
    const response = await api.get(`typeService/listAll`,);
    return response.data;
};

export const putTypeServiceAll = async (body: any, id: string) => {
    const response = await api.put(`typeService/${id}`, body);
    return response.data;
};

export const deleteTypeServiceOne = async (id: string) => {
    const response = await api.delete(`typeService/${id}`);
    return response.data;
};