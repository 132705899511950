/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
//import { Dropdown1 } from '../../../../../../_metronic/partials/content/dropdown/Dropdown1'
import { Loading } from '../../../../components/Loadings/loading'
import { LoadingSpan } from '../../../../components/Loadings/loadingSpan'
import { SearchInput } from '../../../../components/SearchInput'
import { FormPlantsNotImported } from './formPlantsNotImported'
import { useAuth } from '../../../../../modules/auth'
//import {KTSVG, toAbsoluteUrl} from '../../../helpers'
//import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string,
  plants: any[],
  loading: boolean,
  addValuesData: any,
  submit: any,
  loadingSubmit: any;
  plants_list: any[]
  unit_branchs: any[]
  addValuesDataAll: any
  addValuesDataAllStatus: any
  plantsImported: any[]
}

const TablePlantNotImported: React.FC<Props> = ({
  className, 
  plants, 
  loading, 
  addValuesData, 
  submit, 
  loadingSubmit, 
  plants_list, 
  unit_branchs,
  addValuesDataAll,
  addValuesDataAllStatus,
  plantsImported
}) => {
  const [data, setData] = useState<any>([]);
  const [mySearch, setMySearch] = useState([]);
  const [control, setControl] = useState(false);
  const [inputControl, setInputControl] = useState(false);
  const [myUnitBranch, setMyUnitBranch] = useState("");
  const [largura, setLargura] = useState(window.innerWidth);
  const {currentUser} = useAuth()
  console.log(currentUser)

  const updateData = (idPlant : string | number, check: boolean) => {

    setData((prevData: any) => {
      if (check) {
        if (!prevData.includes(idPlant)) {
          return [...prevData, idPlant];
        }
      } else {
        return prevData.filter((plant: any) => plant !== idPlant);
      }
      return prevData; 

    });
    addValuesData(idPlant, check)

    // if(check === false){
    //   setInputControl(false)
    // }
  }

  const updateDataUnitBranch = (idUnitBranch: string) => {
    setMyUnitBranch(idUnitBranch)
    addValuesDataAll(idUnitBranch)
  }

  const checkedAll = (check: boolean) =>{
    if(check === true){
      plants.map((plant: any) => {
        if(typeof plant.plantId === "string"){
          updateData(plant.plantId.toString(), true)
        }else{
          updateData(Number(plant.plantId), true)
        }
        
      })
    }else{
      plants.map((plant: any) => {
        if(typeof plant.plantId === "string"){
          updateData(plant.plantId.toString(), false)
        }else{
          updateData(Number(plant.plantId), false)
        }
      })
    }
  }

  const atualizarTamanhoDaTela = () => {
    setLargura(window.innerWidth);
  };

  useEffect(() => {
    if(plants.length > 0 && plants.length  === data.length){
      setInputControl(true)
    }else{
      setInputControl(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    window.addEventListener('resize', atualizarTamanhoDaTela);
    return () => {
      window.removeEventListener('resize', atualizarTamanhoDaTela);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`card ${className}`} style={{paddingRight: '1rem', paddingTop: largura < 1200 ? '1rem' : ''}}>

      {/* begin::Header */}
      <div className='card-header border-0 mt-xl-9' style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
          <h3 style={{display: "flex", flexDirection: "column"}}>
              <span className='card-label fw-bold fs-3'>Usinas - Não Importadas <KTSVG path="/media/icons/duotune/arrows/arr015.svg" className="svg-icon-danger svg-icon-2hx" /></span>
              <span className='text-muted mt-1 fw-semibold fs-7'><span style={{fontWeight: '800'}}>Total:</span> {plants.length} usinas <span style={{fontWeight: '800'}}>não importadas</span></span>
          </h3>

          {(plants_list.length - plantsImported.length) > 0 &&
          (<h3 style={{display: "flex", flexDirection: "column"}}>
              {/* <span className='card-label fw-bold fs-3'>Usinas - Não Importadas <KTSVG path="/media/icons/duotune/arrows/arr015.svg" className="svg-icon-danger svg-icon-2hx" /></span> */}
              <span className='text-muted mt-1 fw-semibold fs-7'><span style={{fontWeight: '800'}}>Quantidade de usinas selecionadas: <span style={{fontSize: '500'}}>{plants_list.length - plantsImported.length}</span></span></span>
          </h3>)}
        </div>
        
        <div 
          className='card-toolbar' 
          style={{
            display: 'flex', 
            justifyContent: 'space-between', 
            paddingRight: '0.3rem',
            gap: '1rem'
            }}
          >
            <SearchInput 
              className='form-control form-control-solid ps-14'
              placeholder='Buscar Usina'
              myDate={plants}
              setMySearch={setMySearch}
              control={control}
              setControl={setControl}
              disabled={loading || loadingSubmit ? true : false}
              searchParameter="plantName"
              style={{width: largura < 1200 ? "100%" : "200px"}}
            />
            <div style={{display: 'flex', width: largura < 1200 ? '100%' : '', justifyContent: largura < 1200 ? "end" : "center", alignItems: 'center'}}>
              <h3 style={{display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'center'}}>
                <span className='text-muted mt-1 fw-semibold fs-6'>Selecionar todas</span>
              </h3>
              <div className={`form-check form-check-custom form-check-solid mx-5`}>
                <input 
                  className={`form-check-input ${!inputControl ? 'bg-secondary' : 'bg-primary'}`} 
                  type='checkbox'
                  disabled={loading || loadingSubmit}
                  //value={inputControl}
                  
                  checked={inputControl}
                  onChange={(e) =>{
                    checkedAll(e.target.checked)
                    setInputControl(e.target.checked)
                    //updateData(e.target.value, e.target.checked)
                  }} 
                />
              </div>
            </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {
        loading 
        ? 
          (
            <div className='card-body pt-2' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <h3 style={{display: "flex", flexDirection: "column"}}>
                <Loading myClass='spinner-border text-secondary'/>
                {/* <span className='text-muted mt-1 fw-semibold fs-7'>Carregando...</span> */}
              </h3>
            </div>
          ) 
        : 
          plants.length > 0 ?
            (<div 
              className='card-body pt-5' 
              style={{
                overflowY: "auto", 
                height: '25rem',
                margin: "1rem 0rem 1rem 0rem"
              }}
            >
                <FormPlantsNotImported 
                  plants={control === false ? plants : mySearch} 
                  updateData={updateData} 
                  inputGlobal={inputControl}
                  plants_list={plants_list}
                  loadingSubmit={loadingSubmit}
                />
              </div>)
          :
            <div className='card-body pt-2' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <h3 style={{display: "flex", flexDirection: "column"}}>
                <span className='text-muted mt-1 fw-semibold fs-7'>Não há usinas importadas.</span>
              </h3>
            </div> 
      }

      {/* end::Body */}
      <div className='card-footer' style={{display: 'flex', justifyContent: largura < 1200 ? '' : 'space-between', flexWrap: 'wrap', gap: '1rem'}}>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
        {
          <select
            style={{width: largura < 1200 ? '100%' : '20rem'}}
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            defaultValue={'1'}
            onChange={(e) =>
              updateDataUnitBranch(e.target.value)
            }
          >
            <option value={'1'}>Escolha uma unidade</option>
            {unit_branchs && unit_branchs.map((myUnitBranch: any) => {
              return (
                <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
              )
            })}
          </select>
        }
        {
          <select
            style={{width: largura < 1200 ? '100%' : '20rem'}}
            className='form-select form-select-solid'
            data-kt-select2='true'
            data-placeholder='Select option'
            data-allow-clear='true'
            defaultValue={''}
            onChange={(e) =>
              addValuesDataAllStatus(e.target.value)
            }
          >
            <option value={''} disabled>Selecione um plano</option>
            {
              currentUser 
              && currentUser.companies 
              && currentUser.companies.length > 0 
              && currentUser.companies[0].planosCompany
              && currentUser.companies[0].planosCompany.qtd_plants_plano > 0
              && (
                <option value={'PREMIUM'}>PREMIUM</option>
              )
            }
            {
              currentUser 
              && currentUser.companies 
              && currentUser.companies.length > 0 
              && currentUser.companies[0].planosCompany
              && currentUser.companies[0].planosCompany.qtd_plants_plano_starter > 0
              && (
                <option value={'STARTER'}>STARTER</option>
              )
            }

{
              currentUser 
              && currentUser.companies 
              && currentUser.companies.length > 0 
              && currentUser.companies[0].planosCompany
              && currentUser.companies[0].planosCompany.qtd_plants_gratuita > 0
              && (
                <option value={'GRATUITA'}>GRATUITA</option>
              )
            }

            {/* <option value={'PREMIUM'}>PREMIUM</option>
            <option value={'STARTER'}>STARTER</option>
            <option value={'GRATUITA'}>GRATUITA</option> */}

          </select>
        }

        </div>
        
        <button
          type='button'
          disabled={loadingSubmit || !myUnitBranch || myUnitBranch === "1" ? true : data.length === 0 ? true : false}
          className='btn btn-bg btn-primary'
          style={{width: largura < 1200 ? '100%' : ''}}
          //data-kt-stepper-action='submit'
          onClick={submit}
        >
          Importar{' '}
          {
            loadingSubmit ? 
              <LoadingSpan style={{marginLeft: '0.5rem'}}/>
            :
            <KTSVG
              path='/media/icons/duotune/arrows/arr064.svg'
              className='svg-icon-3 ms-2 me-0'
            />
          }
        </button>
      </div>
    </div>
  )
}

export {TablePlantNotImported}
