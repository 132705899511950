import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../../../modules/auth';
import { getRoleAll } from '../../../../../../services/roles';
import { Loading } from '../../../../../components/Loadings/loading';
import { useFormik } from 'formik'
import * as Yup from "yup";
import { updateUsersAll } from '../../../../../../services/usersService';
import { initialLetters } from '../../../../../../utils/initialLetters';
import useSize from '../../../../../../hooks/useSize';

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string().required("Informe um nome para o usuário"),
  email: Yup.string().required("Informe um email para o cliente"),
  status: Yup.string(),
  idRole: Yup.string()
});

export function BodySettingsProfile() {
  const { currentUser } = useAuth()
  const [user] = useState(currentUser)
  const [loadingRoles, setLoadingRoles] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<any[]>([]);
  const {width} = useSize()
  const [initialValues] = useState<any>({
    id: null,
    name: null,
    email: null,
    status: null,
    idRole: null
})


  const getRoles = async () => {
    try {
      setLoadingRoles(true)
      const roles = await getRoleAll()
      setRoles(roles)

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao buscar os tipos de usuário. ", "error")

    }finally{
      setLoadingRoles(false)
    }
  }

  const updateUserSubmit = async (id: string) => {
    try {
      setLoading(true)
      const myData = {
        email: formik.values.email,
        name: formik.values.name,
        //password: false
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await updateUsersAll(id, myData)
      toggleToast("Sucesso ao atualizar o usuário. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar o usuário. ", "error")
    }finally{
      setLoading(false)
      //handleClose()
      setTimeout(()=>{
        window.location.reload()
      }, 500);
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
});

  const submit = () => {
    updateUserSubmit(currentUser && currentUser.id ? currentUser.id : "")
  }

  useEffect(() => {
    getRoles()
    const myDate = {
      id: currentUser?.id ? currentUser.id : null,
      name: currentUser?.name ? currentUser.name : null,
      email: currentUser?.email ? currentUser.email : null,
      status: currentUser?.status ? currentUser.status : null,
      idRole: currentUser?.role ? currentUser.role.id : null
    }
    formik.setValues(myDate)
  }, []);

  if (loadingRoles) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
      </div>
    );
  }
  
  return (
    <div className={`card mb-${width < 1200 ? '20' : '5'} mb-xl-10`}>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Dados do Perfil</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <div>
        <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Logo do Perfil</label>
              <div className='col-lg-8'>
                <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
                  {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' /> */}
                  <span className={`symbol-label bg-light-primary text-primary fs-1 fw-bolder`}>
                      {user ? initialLetters(user.name) : "Não identificado."}
                  </span>
                  {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Nome do usuário'
                      defaultValue={formik.values.name}
                      onChange={formik.handleChange("name")}
                      // {...formik.getFieldProps('fName')}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>Necessário informar um nome de usuário</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>E-mail</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='E-mail do perfil'
                  defaultValue={formik.values.email}
                  onChange={formik.handleChange("email")}
                  // {...formik.getFieldProps('company')}
                />
                {/* {formik.touched.company && formik.errors.company && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.company}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Tipo de Usuário</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  defaultValue={formik.values.idRole}
                  // {...formik.getFieldProps('country')}
                >
                  {/* <option value='' disabled>Selecione o tipo de usuário...</option> */}
                  {
                    roles.length > 0 && 
                      roles.map((role: any) => {
                        return (<option value={role.id} disabled>{role.slug}</option>)
                      })
                  }      
                </select>
                {/* {formik.touched.country && formik.errors.country && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.country}</div>
                  </div>
                )} */}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Status</label>

              <div className='col-lg-8 fv-row'>
              <a
                  href='#'
                  className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_upgrade_plan'
                >
                  {formik.values.status && formik.values.status ? formik.values.status : "Não identificado"}
                </a>
                {/* <select
                  className='form-select form-select-solid form-select-lg'
                  // {...formik.getFieldProps('currency')}
                >
                  <option value=''>Select a currency..</option>
                  <option value='USD'>USD - USA dollar</option>
                  <option value='GBP'>GBP - British pound</option>
                  <option value='AUD'>AUD - Australian dollar</option>
                  <option value='JPY'>JPY - Japanese yen</option>
                  <option value='SEK'>SEK - Swedish krona</option>
                  <option value='CAD'>CAD - Canadian dollar</option>
                  <option value='CHF'>CHF - Swiss franc</option>
                </select> */}
                {/* {formik.touched.currency && formik.errors.currency && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.currency}</div>
                  </div>
                )} */}
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button /*type='submit'*/ onClick={submit} disabled className='btn btn-primary' /*disabled={loading}*/>
              {!loading && 'Salvar Perfil'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function toggleToast(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}
