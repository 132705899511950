import api from "./api";

export const postFile = async (formData: any) => {
    const response = await api.post(`uploads/`, formData);
    return response.data;
};

export const getFile = async (key: string) => {
    const response = await api.get(`uploads/link/${key}/`);
    return response.data;
};

export const getFileBase64 = async (key: string) => {
    const response = await api.get(`uploads/base64/${key}/`);
    return response.data;
};