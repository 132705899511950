import api from "./api";

export const getUnitBranchAll = async () => {
    const response = await api.get(`unitBranch/listAll`);
    return response.data;
};

export const getUnitBranchOne = async (id: string) => {
    const response = await api.get(`unitBranch/${id}`);
    return response.data;
};

export const postUnitBranch = async (body: any) => {
    const response = await api.post(`unitBranch/`, body);
    return response.data;
};

export const updateUnitBranch = async (body: any, id: string) => {
    const response = await api.put(`unitBranch/${id}`, body);
    return response.data;
};

export const deleteUnitBranch = async (id: string) => {
    const response = await api.delete(`unitBranch/${id}`);
    return response.data;
};



