/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { Loading } from '../components/Loadings/loading';
import { TableColaboradores } from './components/TableCollaborators';
import { getCollaboratosAll } from '../../services/collaboratorsService';
import { ComponentUnidadeGeradora } from '../components/ImgFluxograma';
//import { MyPortal } from './components/my-portal'

const clientsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CollaboratorsPage: FC = () => {
    const [collaborators, setCollaborators] = useState<any>([]);
    const [collaboratosLoading, setCollaboratorsLoading] = useState<any>(true);

    const getCollaboratos = async () => {
        try {
          setCollaboratorsLoading(true)
          const myCollaborators = await getCollaboratosAll()
          setCollaborators(myCollaborators)

        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar os collaboradores!", "error")
        }finally{
          setCollaboratorsLoading(false)
        }
    }

    const refresh = () => {
      getCollaboratos()
    }

    useEffect(() => {
      getCollaboratos()
    }, []);


  return (
    <div className='column g-5 g-xl-8'>
        {collaboratosLoading ? 
        (
            <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
                <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
            </div>
        )
        :
        (
            // 
            <TableColaboradores
              className='card-xl-stretch mb-xl-8'
              collaboradores={collaborators}
              refresh={refresh} 
            />
        )
    }
        
    </div>
  )
}

const CollaboratorsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={clientsBreadcrumbs}>Gerenciamento de Colaboradores</PageTitle>
      <CollaboratorsPage />
    </>
  )
}

export {CollaboratorsWrapper}
function toggleToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}

