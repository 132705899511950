/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers'


type Props = {
  className: string;
  unitBranchs: any[]
  formik: any
  funnel?: string
}

const ListUnitBranchLinked: React.FC<Props> = ({className, unitBranchs, formik, funnel}) => {
  
  const updateData = (value: string, checked: boolean) => {
    let valuesFormik = formik.values.idsUnitBranch
    if(checked === true){
      valuesFormik.push(value)
    }else{
      valuesFormik = valuesFormik.filter((id: string) => id !== value)
    }
    formik.setFieldValue('idsUnitBranch', valuesFormik)
  }

  return (
    <div className={`card ${className}`} style={{paddingBottom: '2rem'}}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Lista de Unidades (Filiais)</span>
          {funnel ? 
          <span className='text-muted mt-1 fw-semibold fs-7'>Quais unidades visualizarão esse funil</span>
            : <span className='text-muted mt-1 fw-semibold fs-7'>Quais unidades fornecerão esse produto</span>
        }
        </h3>

        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5' style={{height: '20rem', overflowY: "auto"}}>
        {/* begin::Item */}
        {
          unitBranchs && unitBranchs.length > 0 ?
          unitBranchs.map((unitBranch: any) =>{
              return (
                <div className='d-flex align-items-center mb-7' style={{justifyContent: 'space-between'}}>
                  {/* begin::Symbol */}
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div className='symbol symbol-50px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <img src={toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm009.svg')} className='' alt='' />
                        </div>
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Text */}
                    <div className='d-flex flex-column'>
                      <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                        {unitBranch.companyName}
                      </a>
                      <span className='text-muted fw-semibold'>{unitBranch.city ? unitBranch.city : "Não localizado."}, {unitBranch.state ? unitBranch.state : "Não localizado."}</span>
                    </div>
                  </div>
                  <div className='form-check form-check-custom form-check-success form-check-solid mx-5'>
                    <input 
                      className={`form-check-input ${formik.values.idsUnitBranch.some((id: string) => id === unitBranch.id)  ? 'bg-primary' : 'bg-secondary'}`} 
                      type='checkbox'
                      //checked={plant.imported}
                      checked={formik.values.idsUnitBranch.some((id: string) => id === unitBranch.id) ? true : false}
                      value={unitBranch.id}
                      onChange={(e) =>{
                        updateData(e.target.value, e.target.checked)
                      }}
                      //onChange={formik.handleChange("idsPlants")}
                    />
                  </div>
                  {/* end::Text */}
                </div>
              )
            })
          :
          <div className='d-flex align-items-center mb-7' style={{height: '100%', justifyContent: 'center'}}>
            <h3 className='card-title align-items-start flex-column'>
              <span className='text-muted mt-1 fw-semibold fs-7'>Não há unidades vinculadas.</span>
            </h3>
          </div>
        }
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListUnitBranchLinked}
