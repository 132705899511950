/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from "yup";
import { useToast } from '../../../../../../../../../components/Toats';
import { StepperComponent } from '../../../../../../../../../../../_metronic/assets/ts/components';
import { KTSVG } from '../../../../../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../../../../../components/Loadings/loadingSpan';
import { putProposalOne } from '../../../../../../../../../../services/proposalsService';


type Props = {
    id?: string;
    data?: any;
    show: boolean
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  value_discont:  Yup.number(),
  value_sub_final: Yup.number(),
  value_porcent: Yup.number(),
  type: Yup.string()
});

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddDescontoProposal = ({show, handleClose, data, id}: Props) => {
  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [initialValues, setInitialValues] = useState({
    value_discont: 0,
    value_sub_final: 0,
    value_porcent: 0,
    type: '1'
  })
  const [hasError, setHasError] = useState(false)
  const [myError, MyError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectdTipoDesconto, setSelectdTipoDesconto] = useState<string>('1')
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
  }

  // const checkInputs = (): boolean => {
  //   if (!formik.values.nameResponsible 
  //       || !formik.values.cpfResponsible 
  //       || !formik.values.email 
  //       || !formik.values.idUnitBranch) {
  //     return false
  //   }
  //   return true
  // }

  const closed = () => {
    formik.setValues(initialValues)
    handleClose()
  }

  const postDiscontSubmit = async () => {
    try {
      setLoading(true)
      const myData = {
        value_discont: formik.values.type === '1' ?
        Number(formik.values.value_discont)
        : Number((formik.values.value_sub_final * (formik.values.value_porcent / 100)).toFixed(2))
      }
      Object.keys(myData).forEach((prop) => {
        if (myData[prop] === null) {
          delete myData[prop];
        }
      });
      await putProposalOne(id ? id : "", myData)
      //const OS = await postServicesOne(myData)
      toggleToast("Sucesso ao atualizar a proposta. ", "success")

    } catch (error) {
      toggleToast("Erro ao atualizar a proposta. ", "error")
    }finally{
      setLoading(false)
      closed()
    }
  }

  // const updateClientSubmit = async (id: string) => {
  //   try {
  //     setLoading(true)
  //     const myData = {
  //       email: formik.values.email,
  //       nameResponsible: formik.values.nameResponsible,
  //       cellphone: formik.values.cellphone,
  //       cpfResponsible: formik.values.cpfResponsible,
  //       telephone: formik.values.telephone,
  //       idUser: formik.values.idUser,
  //       idUnitBranch: formik.values.idUnitBranch,
  //       idsPlants: formik.values.idsPlants
  //     }
  //     Object.keys(myData).forEach((prop) => {
  //       if (myData[prop] === null) {
  //         delete myData[prop];
  //       }
  //     });
  //     const myFilial = await updateClient(myData, id)
  //     toggleToast("Sucesso ao atualizar o cliente. ", "success")

  //   } catch (error) {
  //     toggleToast("Erro ao atualizar o cliente. ", "error")
  //   }finally{
  //     setLoading(false)
  //     closed()
  //   }
  // }

  const submit = () => {
    if(id){
      postDiscontSubmit() 
    }
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    // if (!checkInputs()) {
    // setHasError(true)
    // return
    // }
    submit()
    
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: nextStep,
  });


  useEffect(() => {
    if(data){
      const myObject = {
        value_discont: data && data.value_discont ? data.value_discont : 0,
        value_sub_final: data && data.value_sub_final ? data.value_sub_final : 0,
        value_porcent: data && data.value_discont && data.value_sub_final ? Number(((data.value_discont / data.value_sub_final) * 100).toFixed(2)) : 0,
        type: '1'
      }
      formik.setValues(myObject)
    }else{
      formik.setValues(initialValues)
    }
  }, [show])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header bg-primary'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{id ? "Editar" : "Adicionar"} Desconto {" "}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          <div className='flex-row-fluid py-lg-1 px-lg-2'>
            <form noValidate id='kt_modal_create_app_form' >
              {/* <StepModal data={initialValues} updateData={updateData} hasError={hasError} formik={formik} /> */}

              <div style={{display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>
                    <div className='fv-row mb-10' style={{flex: '1'}}>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                            <span className='required'>Tipo de Desconto</span>
                            <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Digite um nome para a unidade(filial)'
                            ></i>
                        </label>
                        <select
                            className='form-select form-select-solid'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            defaultValue={'1'}
                            onChange={(event: any) => {
                                setSelectdTipoDesconto(event.target.value)
                                formik.setValues({...formik.values, type: event.target.value})
                            }}
                        >
                            <option value={'1'}>R$ Valor</option>
                            <option value={'2'}>% Porcentagem</option>
                        </select>
                    </div>
                
                {
                    selectdTipoDesconto === '1' ?
                    (
                    <div className='fv-row mb-10' style={{flex: '1'}}>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span className='required'>Valor</span>
                        <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Digite um nome para a unidade(filial)'
                        ></i>
                        </label>
                        <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='title'
                        placeholder=''
                        value={formik.values.value_discont}
                        onChange={formik.handleChange("value_discont")}
                        />
                    </div>
                    )
                    :
                    (                
                    <div className='fv-row mb-10' style={{flex: '1'}}>
                        <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                        <span className='required'>%</span>
                        <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Digite um nome para a unidade(filial)'
                        ></i>
                        </label>
                        <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='title'
                        placeholder=''
                        value={formik.values.value_porcent}
                        onChange={formik.handleChange("value_porcent")}
                        />
                    </div>
                )
                }
                
              </div>

              <div className='d-flex flex-stack pt-10' style={{justifyContent: 'end'}}>
 
                <div style={{display: 'flex', gap: '1rem'}}>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    disabled={loading}
                    //data-kt-stepper-action='submit'
                    onClick={nextStep}
                  >
                    {id ? "Editar Desconto" : "Adicionar Desconto" }{' '}
                    {
                      loading ? 
                        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                      :
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-3 ms-2 me-0'
                        />
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddDescontoProposal}
