import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { KTSVG } from '../../../../_metronic/helpers';

interface Props {
  selectedDate: Date;
  setSelectedDate: any
}

const CustomDatePickerInput = ({ value, onClick }: { value: any; onClick: any }) => (
  <div className="input-group">
    <input
      type="text"
      value={value}
      className="form-control bg-body"
      onClick={onClick}
      readOnly
    />
    <div className="input-group-append" >
      <span className="input-group-text bg-dark svg-icon svg-icon-1" style={{height: '100%'}}>
        <KTSVG path="/media/icons/duotune/files/fil002.svg" className="svg-icon-muted svg-icon-1hx" />
      </span>
    </div>
  </div>
);

const DateSelectorDays: React.FC<Props> = ({selectedDate, setSelectedDate}) => {
  //const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  return (
    <div>
        <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            customInput={<CustomDatePickerInput value={selectedDate} onClick={() => {}} />}
        />
    </div>
  );
};

export default DateSelectorDays;
