import React from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Report } from '../../../@types/report';
import { DateAno, DateMes } from '../../../utils/date';
import { Loading } from '../Loadings/loading';

type Props = {
    reports: Report[]
    loading: boolean
}


export function TableReportModalPlant({reports, loading} : Props) {
    //const [control, setControl] = useState(false);
    
    const contInvoices = (invoices: any[]) => {
        let cont = 0;
        invoices.map((invoice : any) => {
            if(invoice.status === "INFORMADO"){
                cont += 1;
            }
        })
        return cont
    }
    
  return (
    <div className='table-responsive'>
          <table className='table table-row-dashed table-hover table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='text-muted text-start fw-bolder fs-8 text-uppercase gs-0'>
                <th className='min-w-100px'>Mês</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-100px'>Faturas</th>
              </tr>
            </thead>
              {
                loading ? 
                  <tbody>
                    <tr>
                      <td colSpan={5} className="loadingTableReportsModal">
                        <Loading myClass='spinner-border loadingCenter'/>
                      </td>
                    </tr>
                  </tbody>
                : 
                   reports.length > 0 ? (
                    reports.map((report: Report) => {
                        return (
                            <tr 
                                onClick={() =>{
                                    // if(report.status === "GERADO"){
                                    //     setMyReport(report)
                                    //     setShowCreateAppModalDetails(true)
                                    // }
                                    // else{
                                    //     setMyReport(report)
                                    //     setShowCreateAppModalView(true)
                                    // }
                                }}
                            >
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-30px symbol-circle me-5'>
                                            <img src={toAbsoluteUrl("/media/icons/duotune/files/fil005.svg")} />
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                                <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                                                    {report.data ? DateMes(report.data) : "Não encontrado. "}
                                                </a>
                                                <span className='text-muted fw-semibold text-muted d-block fs-8'>
                                                    {report.data ? DateAno(report.data) : "Nao encontrado"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <span 
                                        className={
                                            `badge badge-light-${
                                                report.status !== null
                                                ? report.status === "GERADO"
                                                ? "success"
                                                : report.status === "FALHA"
                                                ? "danger"
                                                : report.status === "INCOMPLETO"
                                                ? "warning"
                                                : "secondary"
                                            : "secondary"
                                            } fs-8 fw-semibold`
                                        }
                                    >
                                        {
                                            report.status ? report.status : "Não identificado"
                                        }
                                    </span>
                                </td>

                                
                                <td>
                                    <div className='d-flex align-items-center'>
                                        {report.invoices && report.invoices.length > 0 
                                            ?
                                                <div className='d-flex justify-content-start' style={{gap: '0.3rem'}}>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                        {contInvoices(report.invoices)}
                                                    </a>
                                                    <span className='text-muted fw-semibold text-muted d-block fs-6'>
                                                        de {report.invoices.length} 
                                                    </span>
                                                </div>
                                            : 
                                                <div className='d-flex justify-content-start' style={{gap: '0.3rem'}}>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                        0
                                                    </a>
                                                    <span className='text-muted fw-semibold text-muted d-block fs-6'>
                                                        de 0 
                                                    </span>
                                                </div>
                                        }
                                    </div>
                                    
                                </td>
                        
                            </tr>
                        )
                    })
                   )
                   :
                   (
                    <tr>
                        <td colSpan={6} style={{textAlign: 'center'}}>
                            <div className='d-flex justify-content-center' style={{padding: '2rem'}}>
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                    Relatórios não disponíveis!
                                </span>
                            </div>
                        </td>
                    </tr>
                   )
              }
          </table>
        </div>
  );
}