import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useAuth } from '../../../../../../modules/auth';
import { useToast } from '../../../../../components/Toats';
import { postPermissionsOne, putPermissionsOne } from '../../../../../../services/permissionsService';


const validationSchema = Yup.object().shape({
    notification_email: Yup.boolean(),
    idUser: Yup.string(),
  });

export function BodyPermissionsUser() {
    const { currentUser } = useAuth()
    const { toggleToast } = useToast();

    const [loading, setLoading] = useState(false)
    const [initialValues, setInitialValues] = useState<any>({
      notification_email: false,
      idUser: currentUser ? currentUser.id : null
    })

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        onSubmit: () => {},
    });


    console.log('user logadpo: ', currentUser)
    const updatePermissions = async () => {
        try {
          setLoading(true)
     
          const body = {
            notification_email: formik.values.notification_email,
          }
          await putPermissionsOne(currentUser 
            && currentUser.permissions  ? currentUser.permissions.id : "", body)

          toggleToast("Sucesso ao atualizar permissões. ", "success")

    
        } catch (error) {
          console.log('erro',error)
          toggleToast("Erro ao atualizar permissões. ", "error")

        }finally{
          setLoading(false);
          setTimeout(()=>{
            window.location.reload()
          }, 700);
        }
    }

    const postPermissions = async () => {
        try {
          setLoading(true)
          const body = {
            notification_email: formik.values.notification_email,
            idUser: formik.values.idUser
          }
          await postPermissionsOne(body)
          toggleToast("Sucesso ao salvar permissões. ", "success")
    
        } catch (error) {
          console.log('erro',error)
          toggleToast("Erro ao salvar as permissões. ", "error")
        }finally{
          setLoading(false)
          setTimeout(()=>{
            window.location.reload()
          }, 700);
        }
    }

    const submit = () => {
        if(currentUser){
            if(currentUser.permissions){
              updatePermissions()
            }else{
              postPermissions()
            }
        }
    }

    useEffect(() => {
        if (currentUser && currentUser.permissions) {
          const body = {
            notification_email: currentUser.permissions.notification_email,
            idUser: currentUser.id
          }
          formik.setValues(body)
        }
    }, [currentUser])

    
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_email_preferences'
        aria-expanded='true'
        aria-controls='kt_account_email_preferences'
        >
        <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Permissões</h3>
        </div>
      </div>

      <div id='kt_account_email_preferences' className='collapse show'>
        <form className='form'>
            <div className='card-body border-top px-9 py-9'>
                <label className='form-check form-check-custom form-check-solid align-items-start'>
                  <input
                    className='form-check-input me-3'
                    type='checkbox'
                    name='email-preferences[]'
                    defaultChecked={formik.values.notification_email}
                    checked={formik.values.notification_email}
                    onChange={formik.handleChange("notification_email")}
                  />

                  <span className='form-check-label d-flex flex-column align-items-start'>
                    <span className='fw-bolder fs-5 mb-0'>Notificações por E-mail</span>
                    <span className='text-muted fs-6'>
                      Enviar notificações para o e-mail do usuário
                    </span>
                  </span>
                </label>

                {/* <div className='separator separator-dashed my-6'></div> */}

            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button className='btn btn-lightbtn-active-light-primary me-2'>Cancelar</button>
            <button type='button' onClick={() => {submit()}} className='btn btn-primary'>
                {!loading && 'Salvar Permissões'}
                {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                    Salvando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                )}
            </button>
            </div>
        </form>
      </div>
    </div>
  );
}